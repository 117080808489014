import { useState, useEffect } from 'react';

const getOffset = (el) => {
  const x = el?.offsetLeft - el.scrollLeft;
  const y = window.pageYOffset + el.getBoundingClientRect().top;

  return { top: y, left: x };
};

const getFontFamily = (size) => {
  const html = document.documentElement;
  const ieFontFamily = html.currentStyle ? html.currentStyle.fontFamily : null;
  const fontFamily = window.getComputedStyle(html).fontFamily;
  return ieFontFamily === `"${size}"` || fontFamily === `"${size}"` || fontFamily === size;
};

// New HCMDS breakpoints
const isXSmall = () => getFontFamily('xs'); // 0 - 599
const isSmall = () => getFontFamily('sm'); // 600 - 899
const isMedium = () => getFontFamily('md'); // 900 - 1199
const isLarge = () => getFontFamily('lg'); // 1200 - 1439
const isXLarge = () => getFontFamily('xl'); // 1440 - 1599
const isXXLarge = () => getFontFamily('xxl'); // 1600+

// Backwards compatible old breakpoints
const isMobile = () => getFontFamily('xs') || getFontFamily('sm'); // 0 - 767
const isTablet = () => getFontFamily('sm2') || getFontFamily('md'); // 768 - 1119
const isDesktop = () => getFontFamily('md2') || getFontFamily('lg') || getFontFamily('xl') || getFontFamily('xxl'); // 1120+

const getUElsPadding = () => {
  return isXSmall() ? 'u-ckm-inner-container u-els-padding-1x' : 'u-ckm-inner-container u-els-padding-2x';
};

// Check if an element is in the viewport
const useIntersection = (element, rootMargin) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(entry.isIntersecting);
      }, { rootMargin }
    );

    element.current && observer.observe(element.current);

    return () => observer.unobserve(element.current);
  }, []);

  return isVisible;
};

export {
  getOffset,
  isMobile,
  isTablet,
  isDesktop,
  isXSmall,
  isSmall,
  isMedium,
  isLarge,
  isXLarge,
  isXXLarge,
  getUElsPadding,
  useIntersection
};
