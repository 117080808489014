import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@els/els-react--button';

const ModalButons = (props) => {
  return (
    <div className='c-ckm-modal-buttons'>
      <Button
        type={props.primaryButton.type ? props.primaryButton.type : Button.Types.PRIMARY}
        className={props.primaryButton.className ? `${props.primaryButton.className} u-ckm-margin--left` : 'u-ckm-margin--left'}
        onClick={props.primaryButton.onClick}
        aria-label={props.primaryButton.label}
        title={props.primaryButton.label}
        id={props.primaryButton.id}
      >
        {props.primaryButton.label}
      </Button>
      <Button
        type={Button.Types.SECONDARY}
        className={props.secondaryButton.className ? props.secondaryButton.className : ''}
        onClick={props.secondaryButton.onClick}
        aria-label={props.secondaryButton.label}
        title={props.secondaryButton.label}
        id={props.secondaryButton.id}
      >
        {props.secondaryButton.label}
      </Button>
    </div>
  );
};

ModalButons.propTypes = {
  primaryButton: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
    id: PropTypes.string,
    type: PropTypes.string
  }),
  secondaryButton: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
    id: PropTypes.string,
    type: PropTypes.string
  })
};

ModalButons.defaultProps = {
  primaryButton: {
    id: '',
    type: Button.Types.PRIMARY
  },
  secondaryButton: {
    id: '',
    type: Button.Types.SECONDARY
  }
};

ModalButons.displayName = 'ModalButons';

export default ModalButons;
