// This was originally created by Mat Hampson and copied from the Precision Medicine reposityory.
// We should eventually move this to the React component library when available.
// Refactor to use forwardRef once this PR is approved for Enzyme: https://github.com/airbnb/enzyme/pull/1592

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import StableUniqueId from 'react-stable-uniqueid';
import {MOUSEFLOW_INPUT_IGNORE_CLASS} from '../../constants';
import { Icon } from '@els/els-react--icon';

const FormTextInput = (props) => {
  const className = classNames('c-ckm-field__input', {
    'c-ckm-field__input--readonly': props.readOnly,
    [MOUSEFLOW_INPUT_IGNORE_CLASS]: props.mouseflowIgnore
  });
  const requiredIsValid = props.requiredIsValid(props.name, props.value);
  const formatIsValid = props.formatIsValid(props.name, props.value);
  const showError = (uniqueId) => {
    if (!requiredIsValid && props.showValidationErrors) {
      return <span id={`${uniqueId}-required`} className='c-ckm-field__message c-ckm-field--error'>{props.requiredErrorMessage}</span>;
    } else if (!formatIsValid && props.showValidationErrors) {
      return <span id={`${uniqueId}-format`} className='c-ckm-field__message c-ckm-field--error'>{props.formatErrorMessage}</span>;
    } else return null;
  };
  const ariaDescribedBy = (uniqueId) => {
    return !requiredIsValid && props.showValidationErrors
      ? `${uniqueId}-required`
      : !formatIsValid && props.showValidationErrors
        ? `${uniqueId}-format`
        : props.instructions
          ? `${uniqueId}-instructions`
          : null;
  };

  const [showPassword, setShowPassword] = useState(false);

  const setShowPasswordState = (e) => {
    if (e?.keyCode !== 9) {
      e.preventDefault();
      setShowPassword(!showPassword);
    }
  };

  const handleKeyDownShowPasswordBtn = (e) => {
    if (e?.keyCode === 13 || e?.keyCode === 32) {
      e.preventDefault();
      setShowPassword(!showPassword);
    }
  };

  return (
    <StableUniqueId
      prefix='c-ckm-field__input-' render={({ uniqueId }) =>
        <div className='c-ckm-field__item'>
          <div className='c-ckm-field__label-container'>
            <label className='c-ckm-field__label c-ckm-field__label--instructions' htmlFor={uniqueId}>{props.label}</label>
            {props.instructions && <span id={`${uniqueId}-instructions`} className='c-ckm-field__label c-ckm-field__label--instructions'>{`(${props.instructions})`}</span>}
            {props.isRequired && <span className='c-ckm-field__label c-ckm-field__label--instructions' aria-hidden='true'>*</span>}
          </div>
          <input
            id={uniqueId}
            type={showPassword ? 'text' : props.type}
            className={className}
            name={props.name}
            aria-required={props.isRequired}
            aria-invalid={props.showValidationErrors && (!requiredIsValid || !formatIsValid)}
            value={props.value}
            onChange={(event) => props.onChange(event)}
            placeholder={props.placeholder}
            readOnly={props.readOnly}
            tabIndex={props.readOnly ? -1 : null}
            autoComplete={props.autocomplete}
            aria-describedby={ariaDescribedBy(uniqueId)}
            ref={props.inputRef}
          />
          {props.showPasswordToggle &&
            <button
              type='button'
              onClick={setShowPasswordState}
              onKeyDown={handleKeyDownShowPasswordBtn}
              aria-label={showPassword ? props.hidePasswordLabel : props.showPasswordLabel}
              className={`c-ckm-field__toggle-btn ${props.moveIcon ? 'c-ckm-field__top' : ''} show-password`}
            >
              <Icon
                a11y={{ name: props.showPasswordLabel, description: props.showPasswordLabel }}
                sprite={Icon.Sprites.EYE_VISIBLE}
                textAlignment='middle'
                size={Icon.Sizes.S}
                isVisible
                id={`${props.showPasswordLabel}-eye-icon`}
                className={`c-ckm-field__toggle-btn-input-icon ${showPassword ? 'c-ckm-field__toggle-btn-input-icon--active' : ''} o-els-icon-svg o-els-icon-svg--1x1o2`}
              />
            </button>}
          {showError(uniqueId)}
        </div>}
    />
  );
};

const formatPropType = function (props, format, FormTextInput) {
  if (props.format && props.format.regex) {
    if (!(props.format.regex instanceof RegExp) || props.format.method !== 'regex') {
      return new Error('When the prop `format` has the property `regex`, that property must be a valid regular expression. In addition, `format` must have a property `method` with a value of `regex`.');
    }
  } else if (props.format && props.format.matchInputName) {
    if (typeof props.format.matchInputName !== 'string' || props.format.method !== 'matchValue') {
      return new Error('When the prop \'format\' has the property \'matchInputName\', that property must be a string - the name of the input being matched. In addition, \'format\' must have a property \'method\' with a value of \'matchValue\'.');
    }
  }
};

FormTextInput.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  autocomplete: PropTypes.string,
  instructions: PropTypes.string,
  readOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  requiredErrorMessage: PropTypes.string,
  format: formatPropType,
  formatErrorMessage: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  showValidationErrors: PropTypes.bool,
  requiredIsValid: PropTypes.func,
  formatIsValid: PropTypes.func,
  inputRef: PropTypes.object,
  initialValue: PropTypes.string,
  mouseflowIgnore: PropTypes.bool,
  showPasswordToggle: PropTypes.bool,
  showPasswordLabel: PropTypes.string,
  hidePasswordLabel: PropTypes.string
};

FormTextInput.defaultProps = {
  value: ''
};

FormTextInput.displayName = 'FormTextInput';
export default FormTextInput;
