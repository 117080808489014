import React, {useState, useEffect, useContext, useRef} from 'react';
import {defineMessages} from 'react-intl';
import PropTypes from 'prop-types';
import {loadBookTOC, loadEMCTOC} from '../../services';
import useIntl from '../../hooks/use-intl';
import Dropdown from '../../components/common/Dropdown';
import InternalLink from '../../components/common/InternalLink';
import {MainContext} from '../../context/main-context';
import {contains} from 'ramda';

const {formatUriContext} = require('../../../server/utils/url-utils');

const messages = defineMessages({
  viewing: {
    id: 'BookReader.toc.viewing',
    defaultMessage: 'Viewing'
  },
  chapter: {
    id: 'BookReader.toc.chapter',
    defaultMessage: 'chapter'
  }
});

const ChapterList = ({citation: {eid, hubeid, chapternum, chptitle, exactitemtitle, contenttype, issn, sid}, displayType, selectedChapterName, placeholder, handleSelectCallback}) => {
  const intl = useIntl();
  const context = useContext(MainContext);
  const [chapterList, setChapterList] = useState([]);
  const currentItemTitle = contains(contenttype, ['JOURNAL', 'EMC']) ? exactitemtitle : chptitle;
  const formatChapterNum = (chapternum?.match(/\d+/g) || [])[0];
  const chapterRef = useRef();

  const formattedChapterTitle = formatChapterNum
    ? `${intl.formatMessage(messages.viewing)} ${intl.formatMessage(messages.chapter)} ${formatChapterNum}`
    : `${intl.formatMessage(messages.viewing)} ${currentItemTitle}`;
  const chapterDropdownLabel = selectedChapterName || placeholder || formattedChapterTitle;

  useEffect(() => {
    if (contenttype === 'EMC') {
      loadEMCTOC(issn, sid)
        .then((data) => {
          setChapterList(data);
        });
    } else {
      loadBookTOC(hubeid)
        .then(({chapters}) => {
          // Front matter, copyright, acknowledgments, etc. appear in the chapters array
          setChapterList(chapters);
        });
    }
  }, [hubeid]);

  const executeScrolltoChapter = () => {
    if (chapterRef.current) {
      chapterRef.current.scrollIntoView();
    }
  };

  const returnChapterLabel = (chapterNum, title) => {
    return chapterNum ? chapterNum + '. ' + title : title;
  };

  const chapterListItems = (eid, itemClass, linkClass) => {
    return (
      <>
        {chapterList.map((chapter, index) =>

          <li ref={eid === chapter.eid ? chapterRef : null} key={index} className={`${itemClass} ${eid === chapter.eid ? 'c-ckm-toc-panel__list-link--current' : ''}`}>
            {handleSelectCallback
              ? <InternalLink href='' onClick={handleSelectCallback} eid={chapter.eid}>{returnChapterLabel(chapter.chapternum, chapter.itemtitle)}</InternalLink>
            : <InternalLink href={formatUriContext(`/content/${contenttype.toLowerCase()}/${chapter.eid}`, context.productConfig.path)} className={linkClass || ''}>
              {returnChapterLabel(chapter.chapterNum, chapter.itemtitle)}
            </InternalLink>}
          </li>
        )}
      </>
    );
  };
  if (displayType === 'menu') {
    return (
      <ul className='c-ckm-toc-panel__chapter-outline'>
        {chapterListItems(eid, 'c-ckm-toc-panel__list-item', 'c-ckm-toc-panel__list-link')}
      </ul>
    );
  } else {
    return (
      <Dropdown label={chapterDropdownLabel} scrollToItem={executeScrolltoChapter} className='c-ckm-dropdown--chapter-dropdown'>
        <ul className='c-ckm-dropdown-list c-ckm-toc-panel__chapter-list'>
          {chapterListItems(eid, 'c-ckm-toc-panel__chapter-list-item')}
        </ul>
      </Dropdown>
    );
  }
};

ChapterList.propTypes = {
  citation: PropTypes.shape({
    hubeid: PropTypes.string.isRequired,
    chapternum: PropTypes.string,
    chptitle: PropTypes.string,
    contenttype: PropTypes.string.isRequired
  }),
  displayType: PropTypes.string,
  placeholder: PropTypes.string,
  handleSelectCallback: PropTypes.func
};

ChapterList.displayName = 'ChapterList';

export default ChapterList;
