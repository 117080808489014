import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import CKApi from '../../services/api-helper';
import { defineMessages } from 'react-intl';
import InternalLink from '../../components/common/InternalLink';
import InlineLoader from '../../components/common/InlineLoader';
import SavedEmpty from '../saved/SavedEmpty.js';
import { useIntl } from '../../hooks';
import { getBookThumbnailUrl } from '../../utils/stringUtils';
import { isXSmall } from '../../utils/elementUtils';
import BrokenImage from '../../assets/img/ico_broken-img.png';
import '../../assets/img/book.svg';

const messages = defineMessages({
  widgetTitle: {
    id: 'Home.message.savedBooks',
    defaultMessage: 'Saved books'
  }
});

const SavedBooks = (props) => {
  const { onLoadCallback } = props;
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [isEmpty, setIsEmpty] = useState(undefined);
  const [items, setItems] = useState([]);

  useEffect(() => {
    // Load saved book data
    CKApi.get('/student/api/content/savedBooks')
      .then(({data}) => {
        setItems(data);
        const missingItems = data.filter(item => item.missing);
        if (onLoadCallback) {
          onLoadCallback(data.length === 0, missingItems);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setHasError(true);
      });
  }, []);

  useEffect(() => {
    setIsEmpty(items.length === 0);
  }, [items]);

  return (
    <>
      {loading && !hasError && <InlineLoader />}
      {!loading && !hasError && <div className={isXSmall() ? 'c-ckm-saved-books-home c-ckm-showcase-catalog u-ckm-inner-container u-els-padding-1x' : 'c-ckm-saved-books-home c-ckm-showcase-catalog u-ckm-inner-container u-els-padding-2x'}>
        <div className={`c-ckm-saved-books-home__container${isEmpty ? '--empty' : ''}`}>
          <h2 className='c-ckm-showcase-catalog__heading c-ckm-saved-books-home__heading' id='header-home-saved-books'>
            {intl.formatMessage(messages.widgetTitle)}
          </h2>
          {isEmpty
          ? <SavedEmpty />
          : <ul className='c-ckm-showcase-catalog__grid'>
            {items.map((item, index) => !item.missing && <li className='c-ckm-showcase-catalog__grid-item' key={index}>
              <InternalLink
                href={`/content/toc/${item.hubEid}`} className='c-ckm-showcase-catalog__link'
                role='presentation'
              >
                <img src={item.hubEid ? getBookThumbnailUrl(item.hubEid) : BrokenImage} className='c-ckm-showcase-catalog__book-cover' alt={`Book cover: ${item.sourceTitle}`} />
              </InternalLink>
              <div className='c-ckm-showcase-catalog__grid-label'>
                <div className='c-ckm-showcase-catalog__grid-label-text'>
                  <InternalLink href={`/content/toc/${item.hubEid}`} className='c-ckm-internal-link'>{item.sourceTitle}</InternalLink>
                </div>
              </div>
            </li>)}
          </ul>}
        </div>
      </div>}
    </>
  );
};

SavedBooks.displayName = 'SavedBooks';
SavedBooks.propTypes = {
  onLoadCallback: PropTypes.func
};
export default SavedBooks;
