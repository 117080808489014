import React, {useState, useEffect} from 'react';
import { defineMessages } from 'react-intl';
import { oneOf, string } from 'prop-types';
import { useLocalStorage, useIntl } from '../../hooks';
import { LOCAL_STORAGE } from '../../constants';
import Breadcrumb from './Breadcrumb';

const messages = defineMessages({
  linkNNN: {
    id: 'Breadcrumbs.link.nnn',
    defaultMessage: 'NNN'
  },
  linkNANDA: {
    id: 'Breadcrumbs.link.NANDA',
    defaultMessage: 'NANDA'
  },
  linkNIC: {
    id: 'Breadcrumbs.link.NIC',
    defaultMessage: 'NIC'
  },
  linkNOC: {
    id: 'Breadcrumbs.link.NOC',
    defaultMessage: 'NOC'
  },
  linkDiagnosticosMedicos: {
    id: 'Breadcrumbs.link.diagnosticosMedicos',
    defaultMessage: 'Diagnósticos Médicos'
  },
  nnnBarCodigos: {
    id: 'NNNBar.type.codigos',
    defaultMessage: 'Códigos'
  },
  nnnBarAlfabetico: {
    id: 'NNNBar.type.alfabetico',
    defaultMessage: 'Alfabético'
  },
  nnnBarDominios: {
    id: 'NNNBar.type.dominios',
    defaultMessage: 'Dominios/Clases'
  },
  nnnBarNecesidades: {
    id: 'NNNBar.type.necesidades',
    defaultMessage: 'Necesidades'
  },
  nnnBarPatrones: {
    id: 'NNNBar.type.patrones',
    defaultMessage: 'Patrones'
  },
  nnnBarEspecialidades: {
    id: 'NNNBar.type.especialidades',
    defaultMessage: 'Especialidades'
  },
  nnnBarEscalas: {
    id: 'NNNBar.type.escalas',
    defaultMessage: 'Escalas'
  }
});

const NNNBreadcrumb = (props) => {
  const intl = useIntl();
  const [, setLocalStorageItem, removeLocalStorageItem] = useLocalStorage(LOCAL_STORAGE.BREADCRUMB);
  const { url, crumbType, sortType } = props;
  const nnnName = (() => {
    switch (crumbType) {
      case 'nanda': return 'linkNANDA';
      case 'nic': return 'linkNIC';
      case 'noc': return 'linkNOC';
      case 'diagmed': return 'linkDiagnosticosMedicos';
    }
  })();
  const nnnSortName = (() => {
    switch (sortType) {
      case 'codigos': return 'nnnBarCodigos';
      case 'alfabetico': return 'nnnBarAlfabetico';
      case 'dominios': return 'nnnBarDominios';
      case 'patrones': return 'nnnBarPatrones';
      case 'necesidades': return 'nnnBarNecesidades';
      case 'especialidades': return 'nnnBarEspecialidades';
      case 'escalas': return 'nnnBarEscalas';
    }
  })();

  const name = {name: intl.formatMessage(messages[nnnName]), url: `/nnn/${crumbType}/codigos`};
  const sort = sortType ? {name: intl.formatMessage(messages[nnnSortName]), url} : {};
  const createPath = () => {
    if (crumbType === 'nanda' || crumbType === 'nic' || crumbType === 'noc' || crumbType === 'diagmed') {
      const pathArray = [
        {name: intl.formatMessage(messages.linkNNN), url: '/nnn/nanda/codigos'},
        name
      ];

      if (sortType !== undefined) {
        pathArray.push(sort);
      }
      return pathArray;
    }
    // temporarily delete crumbs if on browse until we get to browse breadcrumb story
    removeLocalStorageItem();
  };

  const [breadcrumbs, setBreadcrumbs] = useState(createPath());

  useEffect(() => {
    setBreadcrumbs(createPath());
    setLocalStorageItem({name});
  }, [url]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    breadcrumbs ? <Breadcrumb path={breadcrumbs} /> : null
  );
};

NNNBreadcrumb.propTypes = {
  url: string.isRequired,
  crumbType: oneOf(['nanda', 'nic', 'noc', 'diagmed', 'wrong-crumb-type']).isRequired,
  sortType: oneOf(['codigos', 'alfabetico', 'dominios', 'patrones', 'necesidades', 'especialidades', 'escalas', 'wrong-sort-type'])
};

NNNBreadcrumb.displayName = 'NNNBreadcrumb';
export default NNNBreadcrumb;
