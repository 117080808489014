import {head} from 'ramda';

const getFirstSearchIndex = (user) => head(user.search_indexes);

const SELF_STUDY_COURSE_NAME = 'MedEd Test';
const isSelfStudyUser = courseOptions => courseOptions.every(course => course.courseName === SELF_STUDY_COURSE_NAME);

export {
  getFirstSearchIndex,
  isSelfStudyUser
};
