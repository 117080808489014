const CONTENT_TYPE_NAMES = {
  BOOK: 'BOOK',
  EMC: 'EMC',
  JOURNAL: 'JOURNAL',
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  ANC: 'ANC',
  EXPERT_CONSULT: 'EXPERT_CONSULT',
  MODEL: 'MODEL',
  SPC: 'SPC',
  CASES: 'CASE_BANK',
  SUMMARIES: 'SUMMARIES',
  PDF: 'PDF',
  ARTICLE: 'ARTICLE',
  NIC: 'NIC',
  NOC: 'NOC',
  DIAGMED: 'DIAGMED',
  NANDA: 'NANDA'
};

const THUMBNAIL_BASE_URL = 'https://cdn.clinicalkey.com/ck-thumbnails';

const PRACTICE_THUMBNAIL_BASE_URL = 'https://cdn.clinicalkey.com';

const USAGE_EVENTS = {
  VIEW_ONLY: 'VIEW_ONLY',
  DOWNLOAD: 'DOWNLOAD'
};

const USER_TYPE = {
  STUDENT: 'Stu',
  INSTRUCTOR: 'Ins',
  ADMIN: 'Admin'
};

const USER_INFO = {
  STUDENT: 'STUDENT',
  INSTRUCTOR: 'INSTRUCTOR',
  PROFESSIONAL: 'PROFESSIONAL',
  LEARNER: 'LEARNER'
};

const PERMISSIONS = {
  LEARN_ACCESS: 'CKMEDED_LEARN_ACCESS',
  TEST_ACCESS: 'CKMEDED_TEST_ACCESS'
};

const EOLS_COOKIES = {
  EOLS_ROLE_COOKIE: 'eolsRole',
  EOLS_INST_COOKIE: 'eolsInstitutionId',
  EOLS_RESOURCE_COOKIE: 'eolsResourceLinkId',
  EOLS_COURSE_COOKIE: 'eolsCourseTitle',
  PRODUCT_NAME: 'CK_MEDED'
};

const MOUSEFLOW_INPUT_IGNORE_CLASS = 'no-mouseflow';

const KEYS = {
  // when updating, add both e.key and e.keyCode values
  // e.key
  Enter: 'Enter',
  ' ': 'Spacebar',
  ArrowLeft: 'ArrowLeft',
  ArrowUp: 'ArrowUp',
  ArrowRight: 'ArrowRight',
  ArrowDown: 'ArrowDown',
  // e.keyCode
  13: 'Enter',
  32: 'Spacebar',
  37: 'ArrowLeft',
  38: 'ArrowUp',
  39: 'ArrowRight',
  40: 'ArrowDown'
};

const OFF_IP_PASS_TOKEN = 'x-ck-no-session-token';

const OPENATHENS_ENTITYID = 'https://idp.eduserv.org.uk/openathens';

const SEARCH_SORT = {
  RELEVANCE: '',
  PUBDATE: 'pubdate',
  NEWEST: 'newest',
  OLDEST: 'oldest',
  PUBDATE_DESC: 'pubdate desc',
  A_Z: 'sourcetitle asc',
  Z_A: 'sourcetitle desc'
};

const SEARCH_CONTENT_TYPE_FACETS = {
  BOOKS: '+contenttype:BK',
  IMAGES: '+contenttype:IM',
  VIDEOS: '+contenttype:VD',
  SUMMARIES: '+contenttype:UM',
  JOURNALS: '+contenttype:JL',
  ANC: '+contenttype:ANC',
  EMC: '+contenttype:EM'
};

const SHORT_FACETS_NAMES = {
  BOOKS: '!ct~BK',
  IMAGES: '!ct~IM',
  VIDEOS: '!ct~VD',
  SUMMARIES: '!ct~UM',
  JOURNALS: '!ct~JL',
  ANC: '!ct~ANC',
  EMC: '!ct~EM'
};

const BOOKSTORE_URLS = {
  FRENCH: 'https://www.elsevier-masson.fr/product.jsp',
  GERMAN: 'https://shop.elsevier.de/product.jsp',
  SPANISH: 'https://tienda.elsevier.es/product.jsp',
  GLOBAL: 'https://www.elsevierhealth.com/product.jsp',
  ANZ: 'https://www.elsevierhealth.com.au/'
};

const LOCAL_STORAGE = {
  BREADCRUMB: 'ck_breadcrumb',
  SEARCH_PAYLOAD_ID: 'ck_search_payload_id',
  CASE_PLAYER_STORE: 'ck_case_player_store',
  CASE_PLAYER_DIFFERENTIAL_STATES: 'ck_case_differentials_states',
  SHOW_MODALS: 'ck_show_modals'
};

const CA_APP_ID = '26a2bac5-d9bf-4688-acaf-d374801d576d'; // from prod CA User Cabinet

const IMAGE_VIEWS = {
  IMAGE: 'IMAGE',
  MODEL: 'MODEL',
  COMPARE: 'COMPARE'
};

const MODEL_STATE = {
  LOADING: 'LOADING',
  DISPLAYED: 'DISPLAYED',
  PAUSED: 'PAUSED',
  ERROR: 'ERROR'
};

const TAXONOMY_TABS = {
  diagmedCorrect: 'En Otros Diagnósticos Médicos',
  diagmedWrong: 'En Otros Diagnosticos Mèdicos',
  caracteristicasCorrect: 'Características y Factores',
  caracteristicasWrong: 'Caracterìsticas y Factores'
};

const PASSWORD_ERROR = {
  INVALID_PASSWORD: 'INVALID_PASSWORD',
  NEW_PASSWORD_INVALID: 'NEW_PASSWORD_INVALID'
};

const PASSWORD_ERROR_MESSAGES = {
  DUPLICATE: 'New password must be different from current password',
  INVALID_NEW_PASSWORD: 'Your password must contain at least 8 characters.',
  SPECIAL_CHAR: "Your password must contain at least 1 symbol (special character), e.g. ~ ! @ # $ % ^ & * _ + { } | : £ ? ` - = [ ] \\ ; ' , . / ( ) \" ."
};

const DEFAULT_COLLECTION_NAME = 'For later';

const SOURCE_PATHS = {
  BOOK: '/content/toc',
  EMC: '/content/emc/toc',
  JOURNAL: '/content/journal/issues'
};

const FEATURE_NAMES = {
  COLLECTIONS: 'collections',
  SEARCH_2: 'search2',
  TEST_FLAG: 'testFlag',
  TEST_FROM_SEARCH_RESULTS: 'testFromSearchResults'
};

const SOURCE_TYPES = {
  BOOK: 'Book',
  EMC: 'EMC',
  JOURNAL: 'Journal',
  ARTICLE: 'Article',
  VIDEO: 'Video',
  IMAGE: 'Image',
  SUMMARIES: 'Summary',
  ANC: 'ANC'
};

const SEARCH_TABS = {
  ALL: 'All',
  BOOKS: 'Books',
  BOOK: 'BOOK',
  IMAGES: 'Images',
  VIDEOS: 'Videos',
  SUMMARIES: 'Summaries',
  JOURNALS: 'Journals',
  TEACHING_MATERIALS: 'Teaching Materials',
  ANC: 'Teaching Materials',
  EMC: 'EMC',
  ARTICLE: 'Article'
};

const ECS_BUTTON = {
  FILLED: 'filled',
  OUTLINED: 'outlined',
  DARK_BLUE: 'dark-blue',
  GREY: 'grey'
};

const ECS_DROPDOWN = {
  DEFAULT: 'default',
  BORDERLESS: 'borderless'
};

const ECS_DROPDOWN_SIZES = {
  SMALL: 'small',
  LARGE: 'large'
};

module.exports = {
  CONTENT_TYPE_NAMES,
  THUMBNAIL_BASE_URL,
  PRACTICE_THUMBNAIL_BASE_URL,
  USAGE_EVENTS,
  PERMISSIONS,
  EOLS_COOKIES,
  MOUSEFLOW_INPUT_IGNORE_CLASS,
  USER_TYPE,
  USER_INFO,
  KEYS,
  OFF_IP_PASS_TOKEN,
  OPENATHENS_ENTITYID,
  SEARCH_SORT,
  BOOKSTORE_URLS,
  LOCAL_STORAGE,
  CA_APP_ID,
  IMAGE_VIEWS,
  MODEL_STATE,
  TAXONOMY_TABS,
  PASSWORD_ERROR,
  PASSWORD_ERROR_MESSAGES,
  DEFAULT_COLLECTION_NAME,
  SOURCE_PATHS,
  FEATURE_NAMES,
  SOURCE_TYPES,
  SEARCH_TABS,
  SEARCH_CONTENT_TYPE_FACETS,
  SHORT_FACETS_NAMES,
  ECS_BUTTON,
  ECS_DROPDOWN,
  ECS_DROPDOWN_SIZES
};
