import React from 'react';
import PropTypes from 'prop-types';

const createMarkup = (htmlString) => ({__html: htmlString});

const HTMLString = (props) => {
  const html = createMarkup(props.content);

  return (
    <span dangerouslySetInnerHTML={html} />
  );
};

export default HTMLString;

HTMLString.propTypes = {
  content: PropTypes.string.isRequired
};
