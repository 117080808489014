import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import Form from '../../components/form/Form';
import FormSelect from '../../components/form/FormSelect';
import FormRadioGroup from '../../components/form/FormRadioGroup';
import CKApi from '../../services/api-helper';
import { USER_INFO } from '../../constants';
import { useIntl } from '../../hooks';

const messages = defineMessages({
  UserProfileYear: {
    id: 'UserProfilePage.label.yearOfStudy',
    defaultMessage: 'My class year is'
  },
  UserProfileYearPlaceholder: {
    id: 'UserProfilePage.placeholder.yearOfStudy',
    defaultMessage: 'Choose year of study'
  },
  UserProfileAPIError: {
    id: 'UserProfile.Error.apiError',
    defaultMessage: 'Failed to save new settings; please try again later'
  },
  UserYearErrorMessage: {
    id: 'User.yearOfStudy.error',
    defaultMessage: 'Please select a year'
  },
  UserRoleStudent: {
    id: 'User.role.student',
    defaultMessage: 'Student'
  },
  UserRoleProfessional: {
    id: 'User.role.professional',
    defaultMessage: 'Professional'
  },
  UserRoleLabel: {
    id: 'User.role.label',
    defaultMessage: 'I am a:'
  },
  UserRoleErrorMessage: {
    id: 'User.role.error',
    defaultMessage: 'Please select a role'
  },
  Save: {
    id: 'YOS.Modal.Save',
    defaultMessage: 'Save'
  },
  YearOne: {
    id: 'User.studyYear.year1',
    defaultMessage: 'First Year'
  },
  YearTwo: {
    id: 'User.studyYear.year2',
    defaultMessage: 'Second Year'
  },
  YearThree: {
    id: 'User.studyYear.year3',
    defaultMessage: 'Third Year'
  },
  YearFour: {
    id: 'User.studyYear.year4',
    defaultMessage: 'Fourth Year'
  },
  YearFive: {
    id: 'User.studyYear.year5',
    defaultMessage: 'Fifth Year'
  },
  YearSix: {
    id: 'User.studyYear.year6',
    defaultMessage: 'Sixth Year'
  }
});

// also used by UserProfileForm
export const yearOptions = (intl) => {
  return [
    {
      label: intl.formatMessage(messages.YearOne),
      value: 1
    },
    {
      label: intl.formatMessage(messages.YearTwo),
      value: 2
    },
    {
      label: intl.formatMessage(messages.YearThree),
      value: 3
    },
    {
      label: intl.formatMessage(messages.YearFour),
      value: 4
    },
    {
      label: intl.formatMessage(messages.YearFive),
      value: 5
    },
    {
      label: intl.formatMessage(messages.YearSix),
      value: 6
    }
  ];
};

export const roleOptions = (intl) => {
  return [
    {
      label: intl.formatMessage(messages.UserRoleStudent),
      value: USER_INFO.STUDENT
    },
    {
      label: intl.formatMessage(messages.UserRoleProfessional),
      value: USER_INFO.PROFESSIONAL
    }
  ];
};

const YearOfStudyModalContent = ({ closeYearOfStudyModal, userSettings }) => {
  const getPreSelectedRole = () => {
    const role = userSettings.role ? userSettings.role : undefined;
    return role;
  };
  const [apiError, setApiError] = useState({});
  const [userRole, setUserRole] = useState(undefined);
  const [yearOfStudy, setYearOfStudy] = useState([]);
  const intl = useIntl();

  useEffect(() => {
    setUserRole(getPreSelectedRole());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectUserRole = (name, value) => {
    setUserRole(value);
  };

  const selectYearOfStudy = (name, value) => {
    setYearOfStudy([value]);
  };

  const saveYearOfStudy = (userInputs) => {
    CKApi.put('/student/api/user-settings', userInputs)
      .then(handleApiSuccess)
      .catch(handleApiError);
  };

  const handleApiSuccess = (response) => {
    setApiError({});
    closeYearOfStudyModal();
  };

  const handleApiError = (response) => {
    const apiError = { message: intl.formatMessage(messages.UserProfileAPIError) };
    setApiError(apiError);
  };

  return (
    <div>
      <h2 className='c-ckm-form__section-heading u-ckm-margin--top u-ckm-margin--bottom'>
        <FormattedMessage
          id='UserProfilePage.heading.aboutYourself'
          defaultMessage='Tell us a bit about yourself'
        />
      </h2>
      <p className='c-ckm-form-sub-text u-ckm-margin--bottom-half'>
        <FormattedMessage
          id='UserProfilePage.message.information'
          defaultMessage='We will use this information to tailor your experience.'
        />
        <br />
        <FormattedMessage
          id='UserProfilePage.message.informationEdit'
          defaultMessage='You can edit your selection at any time in your account settings.'
        />
      </p>
      <Form
        intl={intl}
        validSubmit={(userInputs) => { saveYearOfStudy(userInputs); }}
        apiError={apiError}
        buttonSubmitText={intl.formatMessage(messages.Save)}
      >
        {userSettings.role !== USER_INFO.STUDENT &&
          <FormRadioGroup
            label={intl.formatMessage(messages.UserRoleLabel)}
            options={roleOptions(intl)}
            onChange={selectUserRole}
            selectedByDefault={false}
            name='role'
            value={userRole}
            requiredErrorMessage={intl.formatMessage(messages.UserRoleErrorMessage)}
            isRequired
            mouseflowIgnore
          />}
        {userRole === USER_INFO.STUDENT &&
          <FormSelect
            name='yearOfStudy'
            id='yearOfStudy'
            label={intl.formatMessage(messages.UserProfileYear)}
            placeholder={intl.formatMessage(messages.UserProfileYearPlaceholder)}
            entries={yearOptions(intl)}
            selectedEntries={yearOfStudy}
            onSelectChange={selectYearOfStudy}
            requiredErrorMessage={intl.formatMessage(messages.UserYearErrorMessage)}
            isRequired
            mouseflowIgnore
          />}
      </Form>
    </div>
  );
};

YearOfStudyModalContent.propTypes = {
  closeYearOfStudyModal: PropTypes.func.isRequired,
  userSettings: PropTypes.object.isRequired
};

YearOfStudyModalContent.displayName = 'YearOfStudyModalContent';
export default YearOfStudyModalContent;
