import React, { useState, useEffect } from 'react';
import CKApi from '../../../../services/api-helper';
import InternalLink from '../../../../components/common/InternalLink';
import InlineLoader from '../../../../components/common/InlineLoader';
import { getPracticeImageThumbnailUrl } from '../../../../utils/stringUtils';
import { Icon } from '@els/els-react--icon';
import { CASE_STATES } from '../../utils/pjbConstants';
import { defineMessages } from 'react-intl';
import {getStatusString} from '../../utils/helpers/caseStatusUtils';

const messages = defineMessages({
  homepageCaseSectionBrowse: {
    id: 'Home.caseSection.browse',
    defaultMessage: 'Browse cases'
  }
});

const CaseSection = (props) => {
  const [cases, setCases] = useState([]);
  const [loading, setLoading] = useState(cases.length === 0);
  const [hasError, setHasError] = useState(false);
  const mostRecentCase = props.mostRecentCase;
  const caseSectionType = props.isSavedSection ? 'save' : 'continue';
  const caseDescriptionClass = 'c-ckm-practice__' + caseSectionType + '-case-description ' + (props.status === CASE_STATES.NOT_STARTED ? 'c-ckm-practice__' + caseSectionType + '-case-description--notstarted' : '');
  const statusClass = 'c-ckm-practice__continue-case-started ' + (props.status === CASE_STATES.COMPLETED ? 'c-ckm-practice__continue-case-started--completed' : '');
  useEffect(() => {
    CKApi.get('/student/api/browse/spc', {
      params: {
        rows: 9999
      }
    })
      .then(({data}) => {
        const cases = (data.docs).filter(item => item.pii === mostRecentCase);
        setCases(cases);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        setHasError(true);
      });
  }, [mostRecentCase]);

  return (
    <div className='c-ckm-practice__continue-case-section'>
      {loading && !hasError && <InlineLoader />}
      {!loading && !hasError && <div>
        <h2 className='c-ckm-practice__continue-case-title'>{props.title}</h2>
        {cases.length === 0 &&
          <div className='c-ckm-practice__continue-case-none'>
            <div className='c-ckm-practice__continue-case-none-text'>
              <div className='c-ckm-practice__continue-case-none-description'>
                {props.message}
              </div>
              <div className='c-ckm-practice__continue-case-browse-btn'>
                <InternalLink
                  href='/browse/spc'
                  className='c-els-button c-els-button--secondary c-els-button--small'
                  onClick={(e) => { e.stopPropagation(); }}
                >
                  {props.intl.formatMessage(messages.homepageCaseSectionBrowse)}
                </InternalLink>
              </div>
            </div>
            <span className={`c-ckm-practice__${caseSectionType}-case-none-image`}>
                  &nbsp;
            </span>
          </div>}
        {cases.length > 0 &&
          <div className='c-ckm-practice__continue-case-display'>
            <div className='c-ckm-practice__continue-case-list'>
              <InternalLink className='c-ckm-practice__continue-case-single' role='link' href={`/practice/${cases[0].pii}/`}>
                <div className='c-ckm-practice__continue-case-text'>
                  <h4 className='c-ckm-practice__continue-case-name'>
                    {cases[0].casename}
                  </h4>
                  {props.status && caseSectionType !== 'continue' && props.status !== CASE_STATES.NOT_STARTED &&
                    <p className={statusClass}>
                      <Icon
                        a11y={{ name: 'Patient chart' }}
                        sprite={props.status === CASE_STATES.COMPLETED ? Icon.Sprites.CONFIRMATION_SOLID_CIRCLE : Icon.Sprites.CLOCK}
                        className='u-els-margin-right-1o2'
                        size={Icon.Sizes.XS}
                        id='patient-chart-icon'
                        textAlignment='middle'
                        isVisible
                      >
                        {getStatusString(props.status, props.intl)}
                      </Icon>
                    </p>}
                  <p className={caseDescriptionClass}>
                    {cases[0].casedescription}
                  </p>
                </div>
                <div className='c-ckm-practice__continue-case-image'>
                  <img src={getPracticeImageThumbnailUrl([`SPC/case-images/${cases[0].pii}.jpg`])} alt={cases[0].casename} className='c-ckm-practice__continue-case-image-asset' />
                </div>
              </InternalLink>
            </div>
          </div>}
      </div>}
    </div>
  );
};

export default CaseSection;
