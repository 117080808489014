import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import useIntl from '../../hooks/use-intl';
import { defineMessages} from 'react-intl';
import { Button } from '@els/els-react--button';
import CKApi from '../../services/api-helper';
import '../../assets/img/icon_slides.svg';
import { TextInput } from '@els/els-react--text-input';
import { MainContext } from '../../context/main-context';

const messages = defineMessages({
  placeholder: {
    id: 'MediaPopout.presentationPanel.createPresentation.placeholder',
    defaultMessage: 'Enter presentation name'
  },
  cancelButton: {
    id: 'MediaPopout.presentationPanel.createPresentation.cancel',
    defaultMessage: 'Cancel'
  },
  saveButton: {
    id: 'MediaPopout.presentationPanel.createPresentation.save',
    defaultMessage: 'Save'
  },
  serverError: {
    id: 'MediaPopout.presentationPanel.createPresentation.serverError',
    defaultMessage: 'There was an error saving your presentation. Please try again.'
  },
  existingNameError: {
    id: 'MediaPopout.presentationPanel.createPresentation.existingNameError',
    defaultMessage: ' already exists. Please provide a unique name.'
  },
  addedImage: {
    id: 'MediaPopout.presentationPanel.createPresentation.addedImage',
    defaultMessage: 'Image added to'
  },
  zeroSlides: {
    id: 'MediaPopout.presentationPanel.createPresentation.zeroSlides',
    defaultMessage: '0 Slides'
  }
});

const existingNameStatus = 409;

const MediaPopoutCreatePresentation = (props) => {
  const intl = useIntl();
  const context = useContext(MainContext);
  const [newPresentationName, setNewPresentationName] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [existingNameError, setExistingNameError] = useState(false);

  const handlePresentationNameChange = (event) => {
    setNewPresentationName(event.target.value);
  };

  const createNewPresentation = (evt) => {
    evt.preventDefault();

    CKApi.post('/student/api/presentations', {
      name: newPresentationName
    }).then((response) => {
      setExistingNameError(false);
      setServerError(false);

      // Add the selected image to the new presentation
      CKApi.put(`/student/api/presentations/${response.data.id}`, {
        eid: props.eid
      }).then((response) => {
        const newPresentationURL = `${context.productConfig.path}/presentations?presentationId=${response.data.id}`;
        context.addToast({
          body:
            `<SuccessMessage>
              ${intl.formatMessage(messages.addedImage)} <strong><a href=${newPresentationURL}>${newPresentationName}</a></strong>
            </SuccessMessage>`,
          isVisible: true,
          autoHide: true,
          icon: '#icon-sprite_els-hmds-icon-checkmark',
          iconColor: 'positive',
          color: 'positive'
        });
        props.handleSave();
      });
    }).catch(({ response }) => {
      setExistingNameError(response.status === existingNameStatus);
      setServerError(response.status !== existingNameStatus);
    });
  };

  return (
    <div className='c-ckm-presentationList__createForm'>
      <div className='o-els-flex-layout'>
        <div className='c-ckm-presentationList__presentation-thumb o-els-flex-layout__item'>
          <svg
            className='icon o-els-icon-svg o-els-icon-svg--1x1o2 o-els-icon-svg--bottom'
          >
            <use xlinkHref='#icon_slides' />
          </svg>
        </div>
        <div className='o-els-flex-layout__item o-els-flex-layout__item--grow'>
          <form
            onSubmit={createNewPresentation}
          >
            <TextInput
              label=''
              isLabelInline={false}
              shouldHideLabel
              id='inline-name-input'
              placeholder={intl.formatMessage(messages.placeholder)}
              onChange={handlePresentationNameChange}
            />
            <div className='u-els-font-size-meta u-els-color-n0'>{intl.formatMessage(messages.zeroSlides)}</div>
            {serverError && <div className='u-els-color-warn-on-dark u-els-margin-bottom'>{intl.formatMessage(messages.serverError)}</div>}
            {existingNameError && <div className='u-els-color-warn-on-dark u-els-margin-bottom'><strong>{newPresentationName}</strong> {intl.formatMessage(messages.existingNameError)}</div>}
          </form>
        </div>
      </div>
      <div className='u-els-text-right u-els-margin-top'>
        <Button
          onClick={props.handleCancel}
          type={Button.Types.SECONDARY}
          id='info-btn'
          className='c-els-button--x-small'
        >
          {intl.formatMessage(messages.cancelButton)}
        </Button>

        <Button
          onClick={createNewPresentation}
          type={Button.Types.PRIMARY}
          id='info-btn'
          className='c-els-button--x-small u-els-margin-left'
        >
          {intl.formatMessage(messages.saveButton)}
        </Button>
      </div>
    </div>
  );
};

MediaPopoutCreatePresentation.displayName = 'MediaPopoutCreatePresentation';
export default MediaPopoutCreatePresentation;

MediaPopoutCreatePresentation.propTypes = {
  handleCancel: PropTypes.func,
  handleSave: PropTypes.func,
  eid: PropTypes.string.isRequired
};
