import React, { useState, useEffect, useContext, forwardRef } from 'react';
import {defineMessages} from 'react-intl';
import PropTypes from 'prop-types';
import { MainContext } from '../../context/main-context';
import InlineLoader from '../../components/common/InlineLoader';

const messages = defineMessages({
  zoomIn: {
    id: 'MediaPopoutImage.aria.zoomIn',
    defaultMessage: 'Image zoom in'
  },
  zoomOut: {
    id: 'MediaPopoutImage.aria.zoomOut',
    defaultMessage: 'Image zoom out'
  },
  zoomInstructions: {
    id: 'MediaPopoutImage.aria.zoomInstructions',
    defaultMessage: 'Use arrow keys to move zoomed image'
  }
});

const MediaPopoutImage = forwardRef((props, ref) => {
  const context = useContext(MainContext);
  const { source, imageZoom } = props;
  const [isImageLoading, setIsImageLoading] = useState(false);

  const onImageLoad = (evt) => {
    evt.preventDefault();
    setIsImageLoading(false);
    props.handleImageLoad.call(evt, evt);
  };

  useEffect(() => {
    setIsImageLoading(true);
  }, [source]);

  return (
    /* eslint-disable jsx-a11y/no-noninteractive-tabindex */
    <>
      <div
        className={`c-ckm-modal__media-zoom c-ckm-modal__media-zoom--${imageZoom}`}
        tabIndex='0'
      >
        {isImageLoading &&
          <InlineLoader />}
        <span className='u-els-hide-visually'>{context.intl.formatMessage(messages.zoomInstructions)}</span>
        <img
          className={`c-ckm-modal__media-image ${isImageLoading && 'c-ckm-modal__media-image--loading'}`}
          src={source}
          alt={props.summary}
          onLoad={onImageLoad}
          ref={ref}
        />
      </div>
    </>
  );
});

MediaPopoutImage.propTypes = {
  source: PropTypes.string.isRequired,
  summary: PropTypes.string,
  handleImageLoad: PropTypes.func,
  handleImageInit: PropTypes.func,
  imageZoom: PropTypes.number
};

MediaPopoutImage.displayName = 'MediaPopoutImage';

export default MediaPopoutImage;
