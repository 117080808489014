import React from 'react';
import PropTypes from 'prop-types';
import querystring from 'querystring';
import classNames from 'classnames';
import {Route, Link} from 'react-router-dom';
import { omitEmpty } from '../../utils';

const activeClass = 'active';

const buildLinkTo = (basePath, path, searchString, params) => {
  const parsedSearch = querystring.parse(searchString.substr(1));
  const modifiedSearch = omitEmpty({...parsedSearch, ...params});

  // for array values in search, make sure to not completely override the values here (filters, etc) unless needed
  const modifiedSearchString = querystring.stringify(modifiedSearch);

  return {
    pathname: basePath || path,
    search: `?${modifiedSearchString}`,
    state: {}
  };
};

const getLinkClass = (isActive, customClassName = '') => {
  return classNames(customClassName, {
    [activeClass]: isActive
  });
};

const ParamLink = (props) => {
  return (
    <Route render={({match, location}) => <Link to={buildLinkTo(props.basePath, match.url, location.search, props.params)} onClick={props.focusResults} className={getLinkClass(props.isActive, props.customClassName)}>{props.children}</Link>} />
  );
};

ParamLink.displayName = 'ParamLink';
export default ParamLink;

ParamLink.propTypes = {
  params: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
  customClassName: PropTypes.string,
  basePath: PropTypes.string,
  focusResults: PropTypes.func
};
