import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import ResultToolMenu from '../../features/search/ResultToolMenu';
import ANCIconButton from './ANCIconButton';
import CardSource from './CardSource';
import ANCPopout from './ANCPopout';
import ANCTypeLabel from './ANCTypeLabel';
import InternalLink from './InternalLink';
import SafeHTMLString from './SafeHTMLString';
import {MainContext} from '../../context/main-context';
import SaveToCollectionButton from './SaveToCollectionButton';
import FeatureOn from '../feature-flag/FeatureOn';
import FeatureOff from '../feature-flag/FeatureOff';

export default class ANCListItem extends Component {
  state = {
    isPopupOpen: false
  };

  static contextType = MainContext;

  handleClick = (evt) => {
    evt.preventDefault();
    this.showPopup();
    if (this.props.clickHandler) this.props.clickHandler(this.props.eid, this.props.position)(evt);
  };

  showPopup = () => {
    this.setState({isPopupOpen: true});
  };

  handlePopoutClose = () => {
    this.setState({isPopupOpen: false});
  };

  render () {
    const type = this.props.sourcetype || this.props.srctype;
    const summary = this.props.summary || this.props.summary_s;

    return (
      <li data-testid='ancListItemTestId' className='s-ckm-anc c-ckm-search-results-list__item'>
        <ResultToolMenu />
        {this.state.isPopupOpen && <ANCPopout handlePopoutClose={this.handlePopoutClose} {...this.props} />}
        <FeatureOff featureName='search2'>
          <div className='c-ckm-search-results__anc-icon'>
            <ANCIconButton
              showPopup={this.handleClick}
              type={type}
              itemtitle={this.props.itemtitle}
              sourcetitle={this.props.sourcetitle}
              intl={this.props.intl}
              itemRef={this.props.itemRef}
              size='medium'
            />
          </div>
        </FeatureOff>
        <div className='c-ckm-search-results__anc-content'>
          <p className='c-ckm-search-results__content-type'>
            <ANCTypeLabel type={type} />
          </p>
          <h3 className='c-ckm-search-results__content-title'>
            <button className='c-ckm-search-results__content-title-button c-ckm-link' onClick={this.handleClick}>
              <SafeHTMLString content={this.props.itemtitle} />
            </button>
            <FeatureOn featureName='collections'>
              <SaveToCollectionButton
                eid={this.props.eid}
                defaultCollectionId={this.props.defaultCollectionId}
                collections={this.props.collections}
                sourcetitle={this.props.sourcetitle}
                type='multimedia'
                placement='top'
                updateCollections={this.props.updateCollections}
                updateDefaultCollectionId={this.props.updateDefaultCollectionId}
              />
            </FeatureOn>
          </h3>
          <p className='c-ckm-search-results__content-summary'>{summary}</p>
          <FeatureOn featureName='search2'>
            {this.props.sourcetitle &&
              <p className='c-ckm-search-results__content-type'>
                <span className='c-ckm-search-results-chapter-display'>
                  <FormattedMessage
                    id='SearchPage.display.book'
                    defaultMessage='Book:'
                  />
                  {this.props.hubeid
              ? <InternalLink href={`/content/toc/${this.props.hubeid}`}>{this.props.sourcetitle}</InternalLink>
              : this.props.sourcetitle}
                </span>
              </p>}
          </FeatureOn>
          <FeatureOff featureName='search2'>
            <CardSource
              authorlist={this.props.authorlist}
              pubdate={this.props.pubdate}
            />
            {this.props.sourcetitle &&
              <p className='c-ckm-search-results__content-type'>
                <span className='c-ckm-search-results-chapter-display'>
                  <FormattedMessage
                    id='SearchPage.display.from'
                    defaultMessage='from'
                  />
                  {this.props.hubeid
              ? <InternalLink href={`/content/toc/${this.props.hubeid}`}>{this.props.sourcetitle}</InternalLink>
              : this.props.sourcetitle}
                </span>
              </p>}
          </FeatureOff>
        </div>
      </li>
    );
  }
}

ANCListItem.displayName = 'ANCListItem';
