import React, { Component } from 'react';
import { defineMessages } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { MainContext } from '../../context/main-context';

const generateClearButtonClass = (currentSearch) => classNames({
  'c-ckm-toc__search-clear': true,
  'js-active': currentSearch.length !== 0
});

const messages = defineMessages({
  TOCSearchClearSearch: {
    id: 'TOCSearchForm.aria.clearSearch',
    defaultMessage: 'Clear search'
  },
  TOCSearchSubmitSearch: {
    id: 'TOCSearchForm.aria.submitSearch',
    defaultMessage: 'Submit search'
  }
});

class TOCSearchForm extends Component {
  state = {
    currentSearch: '',
    isValid: false
  };

  static propTypes = {
    sourceId: PropTypes.string.isRequired,
    secondarySourceId: PropTypes.string,
    contentTypeLimiter: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    isEMC: PropTypes.bool
  };

  static displayName = 'TOCSearchForm';

  static contextType = MainContext;

  // refs for focus management
  inputRef = React.createRef();

  handleInputChange = (evt) => {
    const isValid = Boolean(evt.target.value);
    this.setState({ currentSearch: evt.target.value, isValid });
  };

  handleClearSearch = (evt) => {
    evt.preventDefault();
    if (this.state.currentSearch.length !== 0) {
      this.setState({ currentSearch: '' });
    }
    this.inputRef.current.focus();
  };

  handleSubmit = (evt) => {
    evt.preventDefault();
    if (this.state.currentSearch.length === 0) {
      return false;
    }

    let url = '';
    if (this.props.secondarySourceId) {
      url = `/search/source/${this.props.sourceId}/${this.props.secondarySourceId}/${this.state.currentSearch}`;
    } else {
      url = `/search/source/${this.props.sourceId}/${this.state.currentSearch}`;
    }
    if (this.props.contentTypeLimiter) {
      url = `${url}?facets=!ct~${this.props.contentTypeLimiter}`;
    }
    this.context.doRedirect(url);
  };

  render () {
    const clearButtonClass = generateClearButtonClass(this.state.currentSearch);
    return (
      <form onSubmit={this.handleSubmit} className={`c-ckm-toc__search${this.props.isEMC ? '-emc' : ''}`}>
        <input
          className='c-ckm-search-generic__input'
          aria-label={this.props.placeholder}
          type='text'
          placeholder={this.props.placeholder}
          value={this.state.currentSearch}
          onChange={this.handleInputChange}
          ref={this.inputRef}
        />
        <button
          className={clearButtonClass}
          type='button'
          onClick={this.handleClearSearch}
          aria-label={this.props.intl.formatMessage(messages.TOCSearchClearSearch)}
        >
          <span className='c-ckm-icon c-ckm-icon--cross u-ckm-margin--auto' />
        </button>
        <button
          className='c-ckm-toc__search-submit'
          aria-label={this.props.intl.formatMessage(messages.TOCSearchSubmitSearch)}
        >
          <span className='c-ckm-icon c-ckm-icon--search-light-gray' />
        </button>
      </form>
    );
  }
}

export default TOCSearchForm;
