import React, { useState, useEffect} from 'react';
import {filter, test} from 'ramda';
import CKApi from '../../services/api-helper';
import {defineMessages} from 'react-intl';
import InternalLink from '../../components/common/InternalLink';
import useIntl from '../../hooks/use-intl';

const messages = defineMessages({
  usmleBooksTitles: {
    id: 'Home.usmleBooks.title',
    defaultMessage: 'USMLE review books'
  }
});

const USMLEBooks = () => {
  const intl = useIntl();

  const [USMLEBookcaseItems, setUSMLEBookItems] = useState([]);

  const USMLEBooks = ['3-s2.0-C20180051385', '3-s2.0-C20200013241', '3-s2.0-C20200024655', '3-s2.0-C20170023972'];
  useEffect(() => {
    Promise.all(USMLEBooks.map(hubEid => CKApi.get(`/student/content/toc/${hubEid}`)))
      .then(responseArray => {
        const allUSMLEBookItems = [];

        responseArray.forEach(response => {
          const match = response.data.match(/window\.__APP_INITIAL_STATE__ = (\{.*?\});/);
          if (match && match[1]) {
            const jsonData = JSON.parse(match[1]);
            if (jsonData.parent_info) {
              allUSMLEBookItems.push(jsonData.parent_info);
            }
          }
        });
        setUSMLEBookItems(allUSMLEBookItems);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const findLargestCoverImage = filter(test(/cov200h.gif/));

  return (
    <>
      {USMLEBookcaseItems && <div className='usmle-books-widget'>
        <div className='usmle-books-widget-header'>
          <h3>{intl.formatMessage(messages.usmleBooksTitles)}</h3>
        </div>
        <div className='c-els-divider c-els-divider--1o2' />
        <ul className='usmle-books-widget-catalog'>

          {USMLEBookcaseItems.map((showcaseItem, index) => <li className='usmle-books-widget-catalog-item' key={index}>
            <InternalLink href={`/content/toc/${showcaseItem.hubeid}`}> <img src={findLargestCoverImage([showcaseItem.coverimage])} alt={`Book cover: ${showcaseItem.srctitle}`} /></InternalLink>
            <InternalLink href={`/content/toc/${showcaseItem.hubeid}`} className='c-ckm-internal-link'>{showcaseItem.srctitle}</InternalLink>
          </li>)}
        </ul>
      </div>}
    </>
  );
};

USMLEBooks.displayName = 'USMLEBooks';

export default USMLEBooks;
