export const escalasData = [
  { 'ESCALA 01 Grado de deterioro de la salud o el bienestar': 'Gravemente comprometido - Sustancialmente comprometido - Moderadamente comprometido - Levemente comprometido - No comprometido' },
  { 'ESCALA 02 Grado de desviación de una norma o estándar establecido': 'Desviación grave del rango normal - Desviación sustancial del rango normal - Desviación moderada del rango normal - Desviación leve del rango normal - Sin desviación del rango normal' },
  { 'ESCALA 06 Grado de suficiencia en cantidad o calidad para conseguir un estado deseado': 'Inadecuado - Ligeramente adecuado - Moderadamente - Sustancialmente adecuado - Completamente adecuado' },
  { 'ESCALA 07 Número de presentaciones': 'Mayor de 10 - 7-9 - 4-6 - 1-3 - Ninguno' },
  { 'ESCALA 09 Rango sobre el que se extiende una entidad': 'Ninguno - Escaso - Moderado - Sustancial - Extenso' },
  { 'ESCALA 11 Frecuencia de una percepción o características afirmativas o de aceptación': 'Nunca positivo - Raramente positivo - A veces positivo - Frecuentemente positivo - Siempre positivo' },
  { 'ESCALA 12 Grado de intensidad': 'Muy débil - Débil - Moderado - Intenso - Muy intenso' },
  { 'ESCALA 13 Frecuencia de aclarar por informe o conducta': 'Nunca demostrado - Raramente demostrado - A veces demostrado - Frecuentemente demostrado - Siempre demostrado' },
  { 'ESCALA 14 Grado de un estado o respuesta negativo o adverso': 'Grave - Sustancial - Moderado - Leve - Ninguno' },
  { 'ESCALA 17 Grado de proximidad a un estado deseado': 'Escasa - Justa - Buena - Muy buena - Excelente' },
  { 'ESCALA 18 Grado de percepción de expectativas positivas': 'No del todo satisfecho - Algo satisfecho - Moderadamente satisfecho - Muy satisfecho - Completamente satisfecho' },
  { 'ESCALA 19 Frecuencia de aclarar por informe o conducta': 'Siempre demostrado - Frecuentemente demostrado - A veces demostrado - Raramente demostrado - Nunca demostrado' },
  { 'ESCALA 20 Grado de información cognitiva que se comprende': 'Ningún conocimiento - Conocimiento escaso - Conocimiento moderado - Conocimiento sustancial - Conocimiento extenso' },
  { 'ESCALA 23B Extenso - Ninguno': 'Extenso - Sustancial - Moderado - Escaso - Ninguno' }
];
