import React, { useEffect, useState } from 'react';
import { defineMessages} from 'react-intl';
import PropTypes from 'prop-types';
import { Loader } from '@els/els-react--loader';
import { Icon } from '@els/els-react--icon';
import { IMAGE_VIEWS, MODEL_STATE } from '../../constants';
import useIntl from '../../hooks/use-intl';
import { MediaModelError } from './MediaModelError';

const messages = defineMessages({
  loading: {
    id: 'MediaInlineModel.loading',
    defaultMessage: 'Loading...'
  },
  modelPaused: {
    id: 'MediaInlineModel.modelPaused',
    defaultMessage: '3D image paused because you\'re currently viewing another 3D image'
  },
  clickToReload: {
    id: 'MediaInlineModel.clickToReload',
    defaultMessage: 'To resume, click the play button'
  },
  playButtonA11yText: {
    id: 'MediaInlineModel.playButtonA11yText',
    defaultMessage: '3D Model Play Button'
  },
  chooseLabel: {
    id: 'MediaInlineModel.chooseLabel',
    defaultMessage: 'Choose Label'
  },
  mediaModelErrorTitle: {
    id: 'MediaPopoutModel.error.title',
    defaultMessage: 'Failed to load 3D model'
  },
  mediaModelErrorBody: {
    id: 'MediaPopoutModel.error.body',
    defaultMessage: 'There is a technical problem on ClinicalKey Student that has prevented this 3D model from loading. An automated report has been sent to the technical team to fix the issue. We apologize for the inconvenience. Please come back later.'
  },
  mediaModelErrorBtn: {
    id: 'MediaPopoutModel.error.BackToImgBtn',
    defaultMessage: 'Back to 2D image'
  }
});

const MediaInlineModel = (props) => {
  const intl = useIntl();
  const [isDisplayingLabels, setIsDisplayingLabels] = useState(false);

  useEffect(() => {
    if (props.modelState === MODEL_STATE.DISPLAYED) {
      setTimeout(() => {
      }, 500);
      setIsDisplayingLabels(false);
    }
  }, [props.modelState]);

  const backTo2D = () => {
    props.viewChangeCallback(IMAGE_VIEWS.IMAGE, props.currentScreenId);
  };

  return (
    <>
      <div data-testid='modalMediaInline' id={props.canvasParentId} className={`c-ckm-modal__media-inline ${window.innerWidth > 750 ? 'draggable' : ''} ${isDisplayingLabels ? 'c-ckm-modal__media-inline--labels' : ''}`} style={{height: `${props.frameDimensions.height}px`}}>
        {props.modelState === MODEL_STATE.LOADING && <div className='c-ckm-modal__loading' style={{height: `${props.frameDimensions.height}px`}}>
          <Loader isBlocking={false} onDark />
          <h4>{intl.formatMessage(messages.loading)}</h4>
        </div>}

        {props.modelState === MODEL_STATE.ERROR && <div className='c-ckm-modal__loading' style={{height: `${props.frameDimensions.height}px`}}>
          <MediaModelError className='' title={intl.formatMessage(messages.mediaModelErrorTitle)} body={intl.formatMessage(messages.mediaModelErrorBody)} onClose={backTo2D} onClick={backTo2D} mainButtonText={intl.formatMessage(messages.mediaModelErrorBtn)} />
        </div>}

        {props.modelState === MODEL_STATE.PAUSED && <button type='button' data-testid='resumeBtnTestId' onClick={props.resumeModel} className='c-ckm-modal__paused' style={{height: `${props.frameDimensions.height}px`}}>
          <Icon
            id='model-reload-btn-icon'
            sprite={Icon.Sprites.PLAY_SOLID}
            a11y={{name: intl.formatMessage(messages.playButtonA11yText)}}
            size={Icon.Sizes.M}
          />
          <h5>{intl.formatMessage(messages.modelPaused)}</h5>
          <h5>{intl.formatMessage(messages.clickToReload)}</h5>
        </button>}
      </div>
    </>
  );
};

MediaInlineModel.propTypes = {
  resumeModel: PropTypes.func.isRequired,
  canvasParentId: PropTypes.string.isRequired,
  labelsVisible: PropTypes.bool,
  showLabels: PropTypes.bool
};

MediaInlineModel.defaultProps = {
  showLabels: true
};

MediaInlineModel.displayName = 'MediaInlineModel';

export default MediaInlineModel;
