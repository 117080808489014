const {replace, join, compose, split, defaultTo, fromPairs, tail, map} = require('ramda');
const querystring = require('querystring');

const substDoubleQuotes = replace(/"/g, '.');
const substAmpersand = replace(/&/g, '$');
const substContentType = replace(/\+contenttype/g, '!ct');
const substSubjMain = replace(/\+subjmain/g, '!sm');
const substRefType = replace(/\+reftype/g, '!rt');
const substSrcType = replace(/\+srctype/g, '!st');
const substSpace = replace(/ /g, '_');
const substColon = replace(/:/g, '~');

const replaceDoubleQuotes = replace(/\./g, '"');
const replaceAmpersand = replace(/\$/g, '&');
const replaceContentType = replace(/!ct/g, '+contenttype');
const replaceSubjMain = replace(/!sm/g, '+subjmain');
const replaceRefType = replace(/!rt/g, '+reftype');
const replaceSrcType = replace(/!st/g, '+srctype');
const replaceSpace = replace(/_/g, ' ');
const replaceColon = replace(/~/g, ':');

const joinWithSemicolon = join('*');
const splitOnSemicolon = split('*');

module.exports.stringifyFacetArray = compose(substContentType, substSubjMain, substRefType, substSrcType, substColon, substSpace, substDoubleQuotes, substAmpersand, joinWithSemicolon);

module.exports.parseFacetArray = compose(splitOnSemicolon, replaceContentType, replaceSubjMain, replaceRefType, replaceSrcType, replaceColon, replaceSpace, replaceDoubleQuotes, replaceAmpersand, defaultTo(''));

module.exports.getFacetName = replace(/(^\+?\w+:)|"/ig, '');

module.exports.getObjectFromQueryString = compose(fromPairs, map(split('=')), split('&'), tail);

module.exports.parse = compose(
  querystring.parse,
  replace('?', ''), // if argument is window.location.search, we need to remove the leading ? character
  defaultTo('')
);

module.exports.stringify = querystring.stringify;

module.exports.addStudyToolsParam = (urlPath, studyToolsType) => {
  return studyToolsType
    ? `${urlPath}?studyToolsLogin=${studyToolsType}`
    : urlPath;
};
