import { defineMessages } from 'react-intl';
import {CASE_STATES} from '../pjbConstants';

const messages = defineMessages({
  homepageCaseSectionCaseStarted: {
    id: 'Home.caseSection.caseStarted',
    defaultMessage: 'Case started'
  },
  homepageCaseSectionCaseCompleted: {
    id: 'Home.caseSection.caseCompleted',
    defaultMessage: 'Case completed'
  }
});

export const getStatusString = (caseStatus, intl) => {
  switch (caseStatus) {
    case CASE_STATES.COMPLETED:
      return intl.formatMessage(messages.homepageCaseSectionCaseCompleted);
    case CASE_STATES.STARTED:
      return intl.formatMessage(messages.homepageCaseSectionCaseStarted);
    default:
      return '';
  }
};
