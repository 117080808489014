import React from 'react';
import PropTypes from 'prop-types';

const Widget = (props) => {
  return (
    <div className={`c-ckm-widget o-els-flex-layout__item ${props.class || ''}`}>
      {props.children}
    </div>
  );
};

export default Widget;

Widget.propTypes = {
  class: PropTypes.string
};
