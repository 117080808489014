import React, { Component } from 'react';
import HTMLString from '../../components/common/HTMLString';
import {
  getImageThumbnailUrl,
  getThumbnailFromCoverImage,
  getVideoThumbnailUrl
} from '../../utils/stringUtils';
import PropTypes from 'prop-types';
import CKApi from '../../services/api-helper';
import { defineMessages } from 'react-intl';
import SaveButton from '../../components/common/SaveButton';
import AuthorListV2 from '../../components/common/AuthorListV2';
import BrokenImage from '../../assets/img/ico_broken-img.png';
import { MainContext } from '../../context/main-context';
import ContentStudyTools from '../../components/common/ContentStudyTools';
import { CONTENT_TYPE_NAMES } from '../../constants';
import MediaPopout from '../../components/common/MediaPopout';
import ANCPopout from '../../components/common/ANCPopout';
import InternalContentLink from '../../components/common/InternalContentLink';
import { Icon } from '@els/els-react--icon';
import { handleEditionsResponse } from '../../utils/editionUtils';
import EditionNotificationBanner from '../../components/common/EditionNotificationBanner';

const messages = defineMessages({
  published: {
    id: 'TitleResultThumbnail.message.published',
    defaultMessage: 'Published'
  }
});

class TitleResultThumbnail extends Component {
  static propTypes = {
    coverimage: PropTypes.array,
    hubeid: PropTypes.string,
    sourcetitle: PropTypes.string,
    focusIndex: PropTypes.bool,
    isSaved: PropTypes.bool,
    showButtons: PropTypes.bool,
    isCollectionItem: PropTypes.bool,
    savedId: PropTypes.string,
    issn: PropTypes.string,
    coverImages: PropTypes.array,
    refImages: PropTypes.array,
    type: PropTypes.string,
    itemTypeName: PropTypes.string,
    sectionId: PropTypes.string,
    eid: PropTypes.string,
    imageRef: PropTypes.string,
    tile: PropTypes.bool,
    pubdate: PropTypes.number,
    authorlist: PropTypes.array,
    isBrowse: PropTypes.bool
  };

  static defaultProps = {
    showButtons: true
  };

  state = {
    isSaved: this.props.isSaved || false,
    isShowPopup: false,
    oldEdition: {},
    newEdition: {},
    isOld: false,
    isShowBanner: true
  };

  static contextType = MainContext;
  linkRef = React.createRef();

  isImage = this.props.type === CONTENT_TYPE_NAMES.IMAGE;
  isVideo = this.props.type === CONTENT_TYPE_NAMES.VIDEO;
  isANC = this.props.type === CONTENT_TYPE_NAMES.ANC;
  isBook = this.props.type === CONTENT_TYPE_NAMES.BOOK;
  isNNN = this.props.type === CONTENT_TYPE_NAMES.NIC ||
    this.props.type === CONTENT_TYPE_NAMES.NOC ||
    this.props.type === CONTENT_TYPE_NAMES.DIAGMED ||
    this.props.type === CONTENT_TYPE_NAMES.NANDA;

  componentDidMount () {
    if (this.isBook) {
      this.getEditions(this.props.hubeid);
    }
    if (this.props.focusIndex) {
      this.linkRef.current.focus();
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.focusIndex) {
      this.thumbnail.focus();
    }
    if (prevProps.isSaved !== this.props.isSaved) {
      this.setState({
        isSaved: this.props.isSaved
      });
    }
  }

  getEditions = (eid) => {
    CKApi.get(`/student/api/${eid}/editions`)
      .then(data => {
        const editionResponse = handleEditionsResponse(data, this.props.hubeid);
        this.setState({
          oldEdition: editionResponse.oldEdition,
          newEdition: editionResponse.newEdition,
          isOld: editionResponse.isOld
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getImgSrc = () => {
    const { refImages, coverimage, coverImages, imageRef } = this.props;

    if (this.isVideo) {
      const videoThumbnailUrl = getVideoThumbnailUrl(refImages);
      return videoThumbnailUrl && videoThumbnailUrl !== ''
        ? videoThumbnailUrl
        : BrokenImage;
    }

    if ((this.isANC || this.isBook) && coverimage) {
      return getImageThumbnailUrl(coverimage) ?? BrokenImage;
    }

    if (this.isImage && imageRef) {
      return imageRef;
    }

    if (coverImages && coverImages.length > 0) {
      return getThumbnailFromCoverImage(coverImages) ?? BrokenImage;
    }

    return BrokenImage;
  };

  handleClick = (evt) => {
    if (this.isImage || this.isANC) {
      evt.preventDefault();
      this.setState({ isShowPopup: true });
    }
  };

  handlePopoutClose = () => {
    this.setState({ isShowPopup: false });
  };

  handleBannerClose = () => {
    this.setState({ isShowBanner: false });
  };

  returnImagesAndAncThumbnail = (imgSrc, isANC) => {
    return (
      <>
        <button
          className='c-ckm-thumbnail__button c-ckm-browse-list__link-thumbnail'
          onClick={this.handleClick}
          ref={this.linkRef}
          disabled={!this.props.isEntitled}
        >
          {this.props.isCollectionItem && isANC
            ? <Icon
                a11y={{ name: this.props.description || 'ANC' }}
                sprite={Icon.Sprites.TEXT_FILE}
                id='anc-icon'
                className=''
                color='secondary'
                textAlignment='bottom'
                size={Icon.Sizes.L}
                isVisible
              />
          : <img
              className='c-ckm-browse-list__image'
              src={imgSrc}
              alt={`Cover of ${this.props.sourcetitle}`}
            />}

        </button>
      </>
    );
  };

  returnImagesAndAncTitle = (linkClassName) => {
    return (
      <button
        className={`c-ckm-link c-ckm-browse-list__link-nowidth ${linkClassName}`}
        onClick={this.handleClick}
        disabled={!this.props.isEntitled}
      >
        {this.props.sourcetitle || this.props.description}
      </button>
    );
  };

  pubDate = (pubdate) => {
    return new Date(Number(pubdate));
  };

  render () {
    const { intl } = this.context;
    const linkClassName = this.props.isEntitled ? '' : 'disabled-link';
    const imgSrc = this.props.imageRef
      ? this.props.imageRef
      : this.props.coverimage
        ? getImageThumbnailUrl(this.props.coverimage)
        : BrokenImage;

    return (
      <>
        {this.state.isShowPopup ? (
          this.isANC ? (
            <ANCPopout
              {...this.props}
              handlePopoutClose={this.handlePopoutClose}
            />
          ) : (
            <MediaPopout
              {...this.props}
              handlePopoutClose={this.handlePopoutClose}
            />
          )
        ) : null}
        {(this.isImage || this.isANC) &&
          this.returnImagesAndAncThumbnail(imgSrc, this.isANC)}
        {!(this.isImage || this.isANC || this.isNNN) && (
          <InternalContentLink
            contentType={this.props.type}
            hubeid={this.props.hubeid}
            eid={this.props.eid}
            issn={this.props.issn}
            sectionId={this.props.sectionId}
            className={`c-ckm-browse-list__link ${linkClassName}`}
            linkRef={this.linkRef}
            isEntitled={this.props.isEntitled}
          >
            <img
              className='c-ckm-browse-list__image'
              src={this.props.isCollectionItem ? this.getImgSrc() : imgSrc}
              alt={`Cover of ${this.props.sourcetitle}`}
            />
          </InternalContentLink>
        )}
        <div className='c-ckm-browse-list__row'>
          {this.props.tile ? (
            <div>
              <div className='c-ckm-browse-list-subheader'>
                {this.props.itemTypeName ?? this.props.type}
              </div>
              {(this.isImage || this.isANC) && this.returnImagesAndAncTitle(linkClassName)}
              {!(this.isImage || this.isANC) && (
                <InternalContentLink
                  contentType={this.props.type}
                  hubeid={this.props.hubeid}
                  eid={this.props.eid}
                  issn={this.props.issn}
                  sectionId={this.props.sectionId}
                  className={`c-ckm-browse-list__link ${linkClassName}`}
                  linkRef={this.linkRef}
                  isEntitled={this.props.isEntitled}
                >
                  <HTMLString content={this.props.sourcetitle} />
                </InternalContentLink>
              )}
              {// if don't have sourcetitle, we used description as a title and we don't want to show it twice
              this.props.sourcetitle &&
                <div className='c-ckm-browse-list-subheader'>
                  {this.props.description}
                </div>
}
            </div>
          ) : (
            <div>
              <InternalContentLink
                contentType={this.props.type}
                eid={this.props.eid}
                hubeid={this.props.hubeid}
                issn={this.props.issn}
                sectionId={this.props.sectionId}
                className='c-ckm-browse-list__link'
                linkRef={this.linkRef}
              >
                <HTMLString content={this.props.sourcetitle} />
              </InternalContentLink>
              {this.props.isBrowse && (
                <>
                  <div className='c-ckm-browse-list-authors'>
                    <AuthorListV2 authors={this.props.authorlist} />
                  </div>
                  <div className='c-ckm-browse-list-pubdate'>
                    {intl.formatMessage(messages.published)}{' '}
                    {this.pubDate(this.props.pubdate).getFullYear()}
                  </div>
                </>
              )}
            </div>
          )}

          {this.props.showButtons && (
            <div className='c-ckm-browse-list__buttons-group'>
              {this.props.tile ? (
                ''
              ) : (
                <ContentStudyTools
                  className='c-ckm-browse-list__bookshelf-button'
                  hubEid={this.props.hubeid}
                  suppressPopup
                  isBrowsePage
                />
              )}
              <div className='c-ckm-browse-list__buttons-visible'>
                <SaveButton
                  showLabel={false}
                  hubeid={this.props.hubeid}
                  sourcetitle={this.props.sourcetitle}
                  isSaved={this.state.isSaved}
                  savedId={this.props.savedId}
                />
              </div>
            </div>
          )}
        </div>
        {this.isBook && this.state.isOld && this.props.tile && this.state.isShowBanner &&
          <EditionNotificationBanner
            intl={this.context.intl}
            isOld={this.state.isOld}
            oldEdition={this.state.oldEdition}
            newEdition={this.state.newEdition}
            onClose={this.handleBannerClose}
          />}
      </>
    );
  }
}

TitleResultThumbnail.displayName = 'TitleResultThumbnail';

export default TitleResultThumbnail;
