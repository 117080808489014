import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import { createPortal } from 'react-dom';
import { pathOr } from 'ramda';
import { MainContext } from '../context/main-context';

const FixedHeaderExtension = (props) => {
  const context = useContext(MainContext);
  const contextHeaderHeight = pathOr(null, ['headerRef', 'offsetHeight'], context);
  const [headerHeight, setHeaderHeight] = useState(null);
  const headerExtension = useRef();
  const headerOffset = useRef();

  if (!headerExtension.current) headerExtension.current = document.querySelector('.c-ckm-header-extension');
  if (!headerOffset.current) headerOffset.current = document.querySelector('.o-ckm-header-offset');

  useEffect(() => {
    setHeaderHeight(contextHeaderHeight);
  }, [contextHeaderHeight]);

  return (
    <>
      {headerExtension.current
        ? createPortal(
          props.children,
          headerExtension.current)
        : null}
      {headerOffset.current
        ? createPortal(
          <div style={{marginTop: `${props.hideOffset ? 0 : headerHeight}px`}} />,
          headerOffset.current)
        : null}
    </>
  );
};

FixedHeaderExtension.displayName = 'FixedHeaderExtension';
export default FixedHeaderExtension;
