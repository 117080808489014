import React, {useState, useEffect} from 'react';
import { defineMessages } from 'react-intl';
import { oneOf, string } from 'prop-types';
import { useLocalStorage, useIntl } from '../../hooks';
import { LOCAL_STORAGE } from '../../constants';
import Breadcrumb from './Breadcrumb';

const messages = defineMessages({
  linkHome: {
    id: 'Breadcrumbs.link.home',
    defaultMessage: 'Home'
  },
  linkNNN: {
    id: 'Breadcrumbs.link.nnn',
    defaultMessage: 'NNN'
  },
  linkSearch: {
    id: 'Breadcrumbs.link.search',
    defaultMessage: 'Search results'
  },
  linkBrowse: {
    id: 'Breadcrumbs.link.browse',
    defaultMessage: 'Browse results'
  },
  linkPresentation: {
    id: 'Breadcrumbs.link.presentation',
    defaultMessage: 'Presentations'
  },
  linkSaved: {
    id: 'Breadcrumbs.link.saved',
    defaultMessage: 'Saved books'
  },
  linkSavedBooks: {
    id: 'Breadcrumbs.link.savedBooks',
    defaultMessage: 'Saved books'
  },
  linkSavedCases: {
    id: 'Breadcrumbs.link.savedCases',
    defaultMessage: 'Saved cases'
  },
  linkBookshelf: {
    id: 'Breadcrumbs.link.bookshelf',
    defaultMessage: 'Bookshelf'
  },
  linkTOC: {
    id: 'Breadcrumbs.link.TOC',
    defaultMessage: 'Table of contents'
  }
});

const RootBreadcrumb = (props) => {
  const intl = useIntl();
  const [, setLocalStorageItem, removeLocalStorageItem] = useLocalStorage(LOCAL_STORAGE.BREADCRUMB);
  const { url, crumbType } = props;
  const rootName = (() => {
    switch (crumbType) {
      case 'search': return 'linkSearch';
      case 'browse': return 'linkBrowse';
      case 'presentation': return 'linkPresentation';
      case 'saved': return 'linkSaved';
      case 'savedCases': return 'linkSavedCases';
      case 'bookshelf': return 'linkBookshelf';
      case 'TOC': return 'linkTOC';
    }
  })();

  const root = {name: intl.formatMessage(messages[rootName]), url};
  const createPath = () => {
    if (crumbType === 'search' || crumbType === 'browse' || crumbType === 'presentation' || crumbType === 'saved' || crumbType === 'savedCases' || crumbType === 'bookshelf' || crumbType === 'TOC') {
      return [
        {name: intl.formatMessage(messages.linkHome), url: '/'},
        root
      ];
    }
    // temporarily delete crumbs if on browse until we get to browse breadcrumb story
    removeLocalStorageItem();
  };

  const [breadcrumbs, setBreadcrumbs] = useState(createPath());

  useEffect(() => {
    setBreadcrumbs(createPath());
    setLocalStorageItem({root});
  }, [url]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    breadcrumbs ? <Breadcrumb path={breadcrumbs} /> : null
  );
};

RootBreadcrumb.propTypes = {
  url: string.isRequired,
  crumbType: oneOf(['search', 'browse', 'presentation', 'saved', 'savedCases', 'bookshelf', 'TOC', 'wrong-crumb-type']).isRequired
};

RootBreadcrumb.displayName = 'RootBreadcrumb';
export default RootBreadcrumb;
