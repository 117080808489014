import React, { useEffect, forwardRef, useState } from 'react';
import { defineMessages} from 'react-intl';
import PropTypes from 'prop-types';
import { Loader } from '@els/els-react--loader';
import useIntl from '../../hooks/use-intl';
import { Button } from '@els/els-react--button';
import { Icon } from '@els/els-react--icon';
import { Flyout } from '@els/els-react--flyout';
import { isMobile, isTablet } from '../../utils/elementUtils';
import { IMAGE_VIEWS } from '../../constants';
import { MediaModelError } from './MediaModelError';

const { indexOf } = require('ramda');

const messages = defineMessages({
  loading: {
    id: 'MediaPopoutModel.loading',
    defaultMessage: 'Loading...'
  },
  navLabel_previous: {
    id: 'MediaPopoutModel.label.previous',
    defaultMessage: 'Previous'
  },
  navLabel_next: {
    id: 'MediaPopoutModel.label.next',
    defaultMessage: 'Next'
  },
  mediaModelErrorTitle: {
    id: 'MediaPopoutModel.error.title',
    defaultMessage: 'Failed to load 3D model'
  },
  mediaModelErrorBody: {
    id: 'MediaPopoutModel.error.body',
    defaultMessage: 'There is a technical problem on ClinicalKey Student that has prevented this 3D model from loading. An automated report has been sent to the technical team to fix the issue. We apologize for the inconvenience. Please come back later.'
  },
  mediaModelErrorBtn: {
    id: 'MediaPopoutModel.error.BackToImgBtn',
    defaultMessage: 'Back to chapter'
  }
});

const MediaPopoutModel = forwardRef((props, ref) => {
  const intl = useIntl();
  const { screenIds, screenId, currentView, screenData } = props;
  const [currentScreen, setCurrentScreen] = useState((screenId && screenIds) ? indexOf(screenId.toString(), screenIds) : 0);
  const [previousScreenName, setPreviousScreenName] = useState('');
  const [nextScreenName, setNextScreenName] = useState('');
  const [showModelErrorMessage, setModelErrorMessage] = useState(false);

  useEffect(() => {
    if (props.showModelError) {
      setModelErrorMessage(true);
    }
  }, [props.showModelError]);

  useEffect(() => {
    if (screenData) {
      showModel();
    }
  }, [currentScreen, screenData]);

  useEffect(() => {
    setCurrentScreen((screenId && screenIds) ? indexOf(screenId.toString(), screenIds) : 0);
  }, [screenId]);

  const showModel = () => {
    if (screenData[currentScreen]) {
      props.showModelRef(getCanvasParentId(), screenIds[currentScreen], screenData[currentScreen].deep_link);
      setButtonLabels();
    }
  };

  const getCanvasParentId = () => {
    return `canvas-parent-fullscreen-${props.eid}`;
  };

  const showPreviousScreen = (evt) => {
    setCurrentScreen(currentScreen - 1);
  };

  const showNextScreen = (evt) => {
    setCurrentScreen(currentScreen + 1);
  };

  const setButtonLabels = () => {
    if (screenData.length > 0) {
      setPreviousScreenName(currentScreen === 0 ? '' : screenData[currentScreen - 1].name);
      setNextScreenName(currentScreen === (screenIds.length - 1) ? '' : screenData[currentScreen + 1].name);
    }
  };

  const backToChapter = () => {
    setModelErrorMessage(false);
    props.handlePopoutClose();
  };

  return (
    <div id={getCanvasParentId()} data-testid='CanvasScreen' className={`c-ckm-modal__media-content-model c-ckm-media-model ${props.modelLoaderShown || showModelErrorMessage ? 'c-ckm-media-model--loading' : ''} ${showModelErrorMessage && 'c-ckm-modal__media c-els-modal'} ${window.innerWidth > 750 ? 'draggable' : ''}`}>

      {props.modelLoaderShown && <div className='c-ckm-media-model__loader'>
        <Loader isBlocking={false} />
        <h4>{intl.formatMessage(messages.loading)}</h4>
      </div>}
      {showModelErrorMessage && <MediaModelError className={currentView === 'MODEL' ? 'media-model-error__half-width' : ''} title={intl.formatMessage(messages.mediaModelErrorTitle)} body={intl.formatMessage(messages.mediaModelErrorBody)} onClose={backToChapter} onClick={backToChapter} mainButtonText={intl.formatMessage(messages.mediaModelErrorBtn)} />}

      {currentView === IMAGE_VIEWS.COMPARE && screenIds?.length > 1 &&
        <div className={`c-ckm-media-model__nav o-els-flex-layout ${props.isFooterVisible || isMobile() || isTablet() ? 'c-ckm-media-model__nav--is-open' : ''}`}>

          {currentScreen !== 0
            ? <Flyout
                className=''
                trigger='hover'
                placement='top-end'
                theme='simple'
                flyout={intl.formatMessage(messages.navLabel_previous) + ': ' + previousScreenName}
                shouldCloseOnESC
                id='model-next-btn-flyout'
              >
              <Button
                className='o-els-flex-layout__item'
                onClick={showPreviousScreen}
                type={Button.Types.LINK}
                linkOptions={['on-dark', 'icon']}
              >
                <Icon
                  a11y={{ name: intl.formatMessage(messages.navLabel_previous) }}
                  sprite={Icon.Sprites.CHEVRON_LEFT}
                  id='media_model_previous'
                  size={Icon.Sizes.S}
                  textAlignment='bottom'
                  isVisible
                  isTextFirst
                />
              </Button>
            </Flyout>
            : <div className='u-els-margin-right-1x1o2' />}

          <div className='c-ckm-media-model__nav-label o-els-flex-layout__item'>
            {(currentScreen + 1)}
            /
            {screenIds.length}
          </div>

          {currentScreen !== (screenIds.length - 1)
            ? <Flyout
                className=''
                trigger='hover'
                placement='top-start'
                theme='simple'
                flyout={intl.formatMessage(messages.navLabel_next) + ': ' + nextScreenName}
                shouldCloseOnESC
                id='model-next-btn-flyout'
              >
              <Button
                className='o-els-flex-layout__item'
                onClick={showNextScreen}
                type={Button.Types.LINK}
                linkOptions={['on-dark', 'icon']}
              >
                <Icon
                  a11y={{ name: intl.formatMessage(messages.navLabel_next) }}
                  sprite={Icon.Sprites.CHEVRON_RIGHT}
                  id='media_model_next'
                  size={Icon.Sizes.S}
                  textAlignment='bottom'
                  isVisible
                  isTextFirst
                />
              </Button>
            </Flyout>
          : <div className='u-els-margin-right-1x1o2' />}
        </div>}
    </div>
  );
});

MediaPopoutModel.propTypes = {
  currentView: PropTypes.string,
  eid: PropTypes.string.isRequired,
  screenIds: PropTypes.array,
  screenId: PropTypes.string,
  screenData: PropTypes.arrayOf(PropTypes.object),
  showModelRef: PropTypes.func.isRequired,
  isFooterVisible: PropTypes.bool
};

MediaPopoutModel.displayName = 'MediaPopoutModel';

export default MediaPopoutModel;
