import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { MainContext } from '../../context/main-context';

const FeatureOn = (props) => {
  const { featureFlags } = useContext(MainContext);
  let showFeature = false;
  if (featureFlags && featureFlags.hasOwnProperty(props.featureName)) {
    showFeature = featureFlags[props.featureName];
  }
  return (
    <>
      {showFeature && props.children}
    </>
  );
};

FeatureOn.propTypes = {
  featureName: PropTypes.string,
  children: PropTypes.node
};

FeatureOn.displayName = 'FeatureOn';

export default FeatureOn;
