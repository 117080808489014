import jsCookie from 'js-cookie';
import {
  ELSCommonConfig,
  ELSTokenHelper,
  ELSRestClientHelper,
  ELSTokenServiceRegistrar,
  ELSCourseService,
  ELSMedEdStoreHelper
} from '@els/meded-ui-common-react';
import constants from '../constants';
import {configs} from '../../server/config/productConfig';
import CKApi from './api-helper';

const featureFlagCall = async () => {
  const response = await ELSRestClientHelper
    .get(`${ELSCommonConfig.buildUrl}/api/mededtest/features/app/MEDED/key/LOGIN_THROUGH_FACADE?defaultValue=false`);

  return response.data.featureValue === 'true';
};

const facadeLoginCall = async () => {
  const [DEFAULT, NURSING] = configs;
  const isNursingProduct = window.location.pathname.includes(NURSING.path);
  const productName = isNursingProduct ? NURSING.productCode : DEFAULT.productCode;

  const eolsCookies = {
    courseTitle: jsCookie.get(constants.EOLS_COOKIES.EOLS_COURSE_COOKIE),
    institutionId: jsCookie.get(constants.EOLS_COOKIES.EOLS_INST_COOKIE),
    product: productName,
    resourceLinkId: jsCookie.get(constants.EOLS_COOKIES.EOLS_RESOURCE_COOKIE),
    role: jsCookie.get(constants.EOLS_COOKIES.EOLS_ROLE_COOKIE)
  };

  const {data} = await ELSRestClientHelper.post(`${ELSCommonConfig.buildUrl}/api/mededtest/ck/token`, {data: eolsCookies, ignoreToken: true});

  return data.eolsToken;
};

const directToCkAppLoginCall = async () => {
  const { data } = await CKApi.post('/student/api/eols/login', {});

  return data.token;
};

const getCourseId = async (userId) => {
  const activeCourseResponse = await ELSCourseService.getActiveCourse(userId);
  let courseId;
  if (activeCourseResponse.data.courseName !== 'MedEd Test') {
    courseId = activeCourseResponse.data.id;
  } else {
    const getCoursesResponse = await ELSCourseService.getCourses(userId);
    const hasRealCourse = getCoursesResponse.data.some(course => course.courseName !== 'MedEd Test');
    if (hasRealCourse) {
      courseId = getCoursesResponse.data.find(course => course.courseName !== 'MedEd Test').id;
    } else {
      courseId = activeCourseResponse.data.id;
    }
  }
  return courseId;
};

const setupLocalState = (userId, roleId, courseId) => {
  ELSMedEdStoreHelper.setMedEdAppStateUserInfo({
    userId,
    roleId,
    courseId
  });
};

const loginCompleteHandler = (token) => {
  token = token.replace('Bearer ', '');
  jsCookie.remove('ticUrlAuth');
  // jsCookie.remove(constants.EOLS_COOKIES.EOLS_ROLE_COOKIE);
  jsCookie.remove(constants.EOLS_COOKIES.EOLS_RESOURCE_COOKIE);
  jsCookie.remove(constants.EOLS_COOKIES.EOLS_INST_COOKIE);
  jsCookie.remove(constants.EOLS_COOKIES.EOLS_COURSE_COOKIE);
  ELSTokenServiceRegistrar.initializeFromLogin({ token });

  return ELSTokenHelper.getUserRole();
};

export const eolsLogin = async () => {
  ELSTokenHelper.removeToken();
  ELSMedEdStoreHelper.clearRedundantStates();

  let token;

  try {
    const isFacadeLoginFeatureEnabled = await featureFlagCall();
    const loginCall = isFacadeLoginFeatureEnabled ? facadeLoginCall : directToCkAppLoginCall;

    token = await loginCall();
  } catch (error) {
    token = await directToCkAppLoginCall();
  }

  const userRole = loginCompleteHandler(token);
  const userId = ELSTokenHelper.getUserId();

  try {
    const courseId = await getCourseId(userId);
    setupLocalState(userId, userRole, courseId);
  } catch (err) {
    // do nothing
    console.log(err);
  }

  return userRole;
};
