import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import InternalLink from '../common/InternalLink';

const messages = defineMessages({
  tableOfContentsNewEditionWithExp: {
    id: 'TocPage.editions.newEditionWithExpiration',
    defaultMessage: 'until {expirationDate}'
  },
  tableOfContentsOldEditionWithExp: {
    id: 'TocPage.editions.oldEditionWithExpiration',
    defaultMessage: 'Version available until {expirationDate}.'
  },
  tableOfContentsEditionPrevious: {
    id: 'TocPage.editions.previous',
    defaultMessage: 'Previous version'
  },
  tableOfContentsEditionNewer: {
    id: 'TocPage.editions.newer',
    defaultMessage: 'Newer version'
  },
  tableOfContentsEditionAvailable: {
    id: 'TocPage.editions.available',
    defaultMessage: 'available'
  }
});

const EditionNotification = (props) => {
  if (props.isOld) {
    return (
      <div className='c-ckm-editions'>
        <p className='c-ckm-editions__text'>
          {props.hasExpiration &&
            <span className='u-ckm-margin--right-space'>
              {props.intl.formatMessage(messages.tableOfContentsOldEditionWithExp, {expirationDate: props.oldEdition.expirationDate})}
            </span>}
          <InternalLink href={`/content/toc/${props.newEdition.hubEid}`}>{props.intl.formatMessage(messages.tableOfContentsEditionNewer)}</InternalLink>
          <span className='u-ckm-margin--left-space'>
            {props.intl.formatMessage(messages.tableOfContentsEditionAvailable)}
          </span>
        </p>
      </div>
    );
  } else {
    return (
      <div className='c-ckm-editions'>
        <p className='c-ckm-editions__text'>
          <InternalLink href={`/content/toc/${props.oldEdition.hubEid}`}>{props.intl.formatMessage(messages.tableOfContentsEditionPrevious)}</InternalLink>
          <span className='u-ckm-margin--left-space'>
            {props.intl.formatMessage(messages.tableOfContentsEditionAvailable)}
          </span>
          {props.hasExpiration &&
            <span className='u-ckm-margin--left-space'>
              {props.intl.formatMessage(messages.tableOfContentsNewEditionWithExp, {expirationDate: props.oldEdition.expirationDate})}
            </span>}
        </p>
      </div>
    );
  }
};

EditionNotification.propTypes = {
  intl: PropTypes.object.isRequired,
  isOld: PropTypes.bool.isRequired,
  hasExpiration: PropTypes.bool.isRequired,
  oldEdition: PropTypes.object.isRequired,
  newEdition: PropTypes.object.isRequired
};

EditionNotification.displayName = 'EditionNotification';
export default EditionNotification;
