import {useState, useEffect} from 'react';
import CKApi from '../services/api-helper';

const useApiOnce = (config = {}, defaultData = {}) => {
  const [hasFetched, setHasFetched] = useState(false);
  const [data, setData] = useState(defaultData);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!hasFetched) {
      setHasFetched(true);
      CKApi(config)
        .then(({data}) => {
          setData(data);
          setLoading(false);
        })
        .catch(error => {
          setError(error);
          setLoading(false);
        });
    }
  }, [config, hasFetched]);

  return [data, loading, error];
};

export default useApiOnce;
