import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const positionToClassMapper = {
  bottomLeft: 'c-els-toast--bottom c-els-toast--left',
  bottomRight: 'c-els-toast--bottom',
  center: 'c-els-toast--center',
  topRight: 'c-els-toast--top'
};

export const colors = {
  primary: 'c-els-toast__item--primary',
  secondary: 'c-els-toast__item--secondary',
  negative: 'c-els-toast__item--negative',
  positive: 'c-els-toast__item--positive'
};

export const iconColors = {
  primary: 'u-els-color-primary',
  secondary: 'u-els-color-secondary',
  negative: 'u-els-color-negative',
  positive: 'u-els-color-positive'
};

const getClassName = (position, noWidth, hide) => {
  return classNames('c-els-toast ', positionToClassMapper[position], { ' c-els-toast--no-width': noWidth }, hide && 'c-els-toast--hide');
};

const Toast = ({ title, body, onClose, link, position, color, noWidth, icon, iconColor, autoHide, hideAnchor, maxWidth }) => {
  const visibleTime = 5000;
  const fadeTime = 1000;

  const [fadeTimeoutRef, setFadeTimeoutRef] = useState(null);
  const [closeTimeoutRef, setCloseTimeoutRef] = useState(null);
  const [hide, setHide] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const toastRef = useRef(null);

  const hideToast = () => {
    setIsVisible(false);
    onClose.call();
  };

  const getAnchor = () => {
    return link ? (
      <a href={link || '#'} className='c-els-toast__close' data-testid='toast-close-link'>
        <svg title={title} className='icon__close o-els-icon o-els-icon--1x1o2'>
          <use href='#icon-sprite_els-hmds-icon-close' />
        </svg>
      </a>
    ) : (
      <div className='c-els-toast__close anchor'>
        <button className='button button--link u-els-anchorize c-els-link c-ckm-practice__case-save-button' onClick={hideToast}>
          <svg title={title} className='icon__close o-els-icon o-els-icon--1x1o2' data-testid='toast-close-icon'>
            <use href='#icon-sprite_els-hmds-icon-close' />
          </svg>
        </button>
      </div>
    );
  };

  useEffect(() => {
    if (autoHide && isVisible) {
      clearTimeout(fadeTimeoutRef);
      clearTimeout(closeTimeoutRef);

      // Fade
      setFadeTimeoutRef(
        setTimeout(() => {
          setHide(true);
        }, visibleTime)
      );

      // Close toast
      setCloseTimeoutRef(
        setTimeout(() => {
          hideToast();
        }, visibleTime + fadeTime)
      );
    }
  }, [isVisible]);

  useEffect(() => {
    if (isVisible && toastRef.current) {
      toastRef.current.focus();
    }
  }, [isVisible]);

  const iconTag = icon ? (
    <div className='toast-icon' data-testid='toast-icon'>
      <span className={iconColors[iconColor] || iconColors.primary}>
        <svg className='o-els-icon-svg o-els-icon-svg--2x' alt='toast-icon'>
          <use href={icon} />
        </svg>
      </span>
    </div>
  ) : null;

  return isVisible ? (
    <div className='toast-container' data-testid='toastContainer' ref={toastRef} tabIndex='-1'>
      <div className={getClassName(position, noWidth, hide)}>
        <div className={`c-els-toast__item ${colors[color]}`}>
          <div className='toast-body'>
            {iconTag}
            <div className={maxWidth ? 'toast-content-max-width' : 'toast-content'}>
              <h4 className='toast-title'>{title}</h4>
              {body && <div className='toast-text' dangerouslySetInnerHTML={{__html: body}} />}
            </div>
            {!hideAnchor && getAnchor()}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

Toast.defaultProps = {
  isVisible: false,
  title: '',
  body: '',
  onClose: () => {
    return null;
  },
  position: 'topRight',
  color: null,
  noWidth: false,
  icon: null,
  iconColor: null
};

Toast.propTypes = {
  isVisible: PropTypes.bool,
  title: PropTypes.string,
  body: PropTypes.string,
  onClose: PropTypes.func,
  link: PropTypes.string,
  position: PropTypes.string,
  color: PropTypes.string,
  noWidth: PropTypes.bool,
  icon: PropTypes.string,
  iconColor: PropTypes.string
};

export default Toast;
