import jsCookie from 'js-cookie';

export default (cookieName) => {
  if (!cookieName) {
    // If no cookie name is provided, simply return all cookies,
    // and no cookie management methods
    return jsCookie.get();
  } else {
    // If cookie name is provided, return:
    // cookie, cookie setter method, and cookie remove method

    let cookie = jsCookie.get(cookieName);

    // cookie values are stored as strings
    // if "true" or "false", cast as boolean
    if (cookie === 'true' || cookie === 'false') {
      cookie = JSON.parse(cookie);
    }

    const setCookie = (value) => {
      return jsCookie.set(cookieName, value);
    };

    const removeCookie = () => { return jsCookie.remove(cookieName); };

    return [cookie, setCookie, removeCookie];
  }
};
