import React, {Component} from 'react';

export default class InlineLoader extends Component {
  render () {
    return (
      <div className='c-ckm-inline-loader' aria-label='Active Loading Indicator' role='alert' aria-live='assertive'>
        <div className='c-els-loader-animation'>
          <div className='c-els-loading-bar' />
          <span className='u-ckm-visuallyhidden'>Loading</span>
        </div>
      </div>
    );
  }
}

InlineLoader.displayName = 'InlineLoader';
