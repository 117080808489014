import React, { Component, Children, Fragment } from 'react';
import PropTypes from 'prop-types';
import FormSummary from './FormSummary';
import FormTextInput from './FormTextInput';
import FormCheckboxSingle from './FormCheckboxSingle';
import FormSelect from './FormSelect';
import FormRadioGroup from './FormRadioGroup';
import InlineLoader from '../common/InlineLoader';
import { Button } from '@els/els-react--button';
import { defineMessages } from 'react-intl';
import { clone, filter, prop, compose, propEq, findIndex, whereEq, isEmpty, equals, is, pathOr } from 'ramda';
const { notEmptyOrNil } = require('../../utils');

const messages = defineMessages({
  FormSummaryTitle: {
    id: 'Form.heading.errorSummary',
    defaultMessage: 'Error: there {verb} {count} {problem} in this form'
  },
  FormInputIsRequired: {
    id: 'Form.errorDefault.isRequired',
    defaultMessage: 'Input is required'
  },
  FormInputMinLength: {
    id: 'Form.errorDefault.minLength',
    defaultMessage: 'Input must be at least {count} characters'
  },
  FormInputValueMatch: {
    id: 'Form.errorDefault.noValueMatch',
    defaultMessage: 'Input values must match for {input1} and {input2}'
  },
  FormButtonSubmit: {
    id: 'Form.button.submit',
    defaultMessage: 'Submit'
  }
});

export default class Form extends Component {
  state = {
    userInputs: {},
    userInputRefs: {},
    initialInputs: {},
    errorMessages: [],
    errorSummaryMessages: [],
    staticErrors: [],
    successMessage: '',
    shouldFocus: false,
    hasBeenSubmitted: false,
    isLoading: false
  };

  static propTypes = {
    intl: PropTypes.shape({
      formatMessage: PropTypes.func
    }).isRequired,
    validSubmit: PropTypes.func.isRequired,
    apiError: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array
    ]).isRequired,
    apiSuccess: PropTypes.string,
    buttonSubmitText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    buttonCancelText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    buttonRevertText: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  };

  // refs for focus management
  titleRef = React.createRef();

  // select only the children that are form inputs
  childInputs = (children) => {
    const childArray = [];
    Children.map(this.props.children, (child, index) => {
      if (!child) { return; }
      if (child.type === FormTextInput ||
        child.type === FormCheckboxSingle ||
        child.type === FormSelect ||
        child.type === FormRadioGroup) {
        childArray.push(child);
      }
    });
    return childArray;
  }

  // validation tests
  checkIfRequired = (inputName) => filter(compose(whereEq({ isRequired: true, name: inputName }), prop('props')))(this.childInputs(this.props.children));
  getFormat = (inputName) => filter(compose(whereEq({ name: inputName }), prop('props')))(this.childInputs(this.props.children))[0].props.format;
  requiredIsValid = (inputName, inputValue) => inputValue && this.checkIfRequired(inputName).length > 0;
  formatIsValid = (inputName, inputValue) => {
    const format = this.getFormat(inputName);
    if (format && inputValue) {
      switch (format.method) {
        case 'regex':
          return format.regex.test(inputValue);
        case 'matchValue':
          return inputValue === this.state.userInputs[format.matchInputName];
        default:
          return true;
      }
    } else if (format && !inputValue && this.checkIfRequired(inputName).length > 0) {
      return false;
    } else { return true; }
  };

  handleSetup () {
    const userInputNames = {};
    const userInputRefs = {};
    const initialInputNames = {};
    const errorMessages = [];
    Children.map(this.props.children, (child, index) => {
      if (child && (child.type === FormTextInput ||
        child.type === FormCheckboxSingle ||
        child.type === FormSelect ||
        child.type === FormRadioGroup)) {
        let initialValue;
        switch (child.type) {
          case FormTextInput:
            initialValue = child.props.initialValue || '';
            break;
          case FormCheckboxSingle:
            initialValue = child.props.initialCheck;
            break;
          case FormRadioGroup:
            initialValue = child.props.value;
            break;
          case FormSelect:
            initialValue = child.props.selectedEntries[0];
            break;
          default:
            initialValue = child.props.initialValue || '';
        }
        userInputNames[child.props.name] = initialValue;
        userInputRefs[child.props.name] = React.createRef();
        if (this.props.buttonRevertText) {
          initialInputNames[child.props.name] = initialValue;
        }
        child.props.requiredErrorMessage && child.props.isRequired &&
          errorMessages.push({
            name: child.props.name,
            type: 'required',
            message: child.props.requiredErrorMessage,
            ref: userInputRefs[child.props.name]
          });
        child.props.formatErrorMessage && child.props.format &&
          errorMessages.push({
            name: child.props.name,
            type: 'format',
            message: child.props.formatErrorMessage,
            ref: userInputRefs[child.props.name]
          });
      }
    });
    this.setState({
      userInputs: userInputNames,
      initialInputs: initialInputNames,
      userInputRefs,
      staticErrors: errorMessages,
      errorMessages
    });
    // if component mounts with apiError, make sure to set other state properly.
    if (!isEmpty(this.props.apiError)) {
      this.handleApiError(this.props.apiError, userInputRefs);
    }
  }

  handleApiError = (apiError, userInputRefs) => {
    // if just a single object, force into an array
    if (!is(Array, apiError)) apiError = [apiError];

    const errorSummaryMessages = apiError.map((error) => {
      return {
        message: error.message,
        ref: error.name ? userInputRefs[error.name] : null
      };
    });

    this.setState({
      errorSummaryMessages,
      errorSummaryTitle: pathOr(null, [0, 'title'], apiError),
      errorSummaryCount: errorSummaryMessages.length,
      shouldFocus: true,
      isLoading: false,
      hasBeenSubmitted: true
    });
  }

  handleValidation = (inputName, inputValue, errorMessages) => {
    const inputErrorMessages = filter(propEq('name', inputName))(errorMessages);
    inputErrorMessages.map(message => {
      const messageIndex = findIndex(whereEq({ name: message.name, type: message.type }))(errorMessages);
      const messageValidated = message.type === 'required' ? this.requiredIsValid(inputName, inputValue) : this.formatIsValid(inputName, inputValue);
      errorMessages[messageIndex].message = messageValidated ? '' : this.state.staticErrors[messageIndex].message;
    });
  }

  handleTextInputChange = (evt) => {
    const newInputs = { ...this.state.userInputs, [evt.target.name]: evt.target.value };
    const errorMessages = [...this.state.errorMessages];
    this.handleValidation(evt.target.name, evt.target.value, errorMessages);
    this.setState({
      userInputs: newInputs,
      errorMessages
    });
  };

  handleCheckboxSingleChange = (evt) => {
    const newInputs = { ...this.state.userInputs, [evt.target.name]: !this.state.userInputs[evt.target.name] };
    const errorMessages = [...this.state.errorMessages];
    this.handleValidation(evt.target.name, !this.state.userInputs[evt.target.name], errorMessages);
    this.setState({
      userInputs: newInputs,
      errorMessages
    });
  };

  handleSelectChange = (name, value) => {
    const newInputs = { ...this.state.userInputs, [name]: value };
    const errorMessages = [...this.state.errorMessages];
    this.handleValidation([name], value, errorMessages);
    this.setState({
      userInputs: newInputs,
      errorMessages
    });
  };

  handleRadioButtonChange = (name, value) => {
    const newInputs = { ...this.state.userInputs, [name]: value };
    const errorMessages = [...this.state.errorMessages];
    this.handleValidation([name], value, errorMessages);
    this.setState({
      userInputs: newInputs,
      errorMessages
    });
  }

  handleCancel = (evt) => {
    evt.preventDefault();
    const newInputs = clone(this.state.userInputs);
    Object.keys(newInputs).forEach(input => {
      switch (typeof newInputs[input]) {
        case 'string':
          newInputs[input] = '';
          break;
        case 'number':
          newInputs[input] = '0';
          break;
        case 'boolean':
          newInputs[input] = false;
          break;
        default:
          newInputs[input] = '';
      }
    });
    this.setState({ userInputs: newInputs });
  }

  handleRevert = (evt) => {
    evt.preventDefault();
    this.setState({
      userInputs: this.state.initialInputs,
      errorSummaryMessages: [],
      errorSummaryCount: 0
    });
  }

  handleSubmit = (evt) => {
    evt.preventDefault();
    const errorMessages = clone(this.state.errorMessages);
    Object.keys(this.state.userInputs).forEach(input => {
      this.handleValidation(input, this.state.userInputs[input], errorMessages);
    });
    const errorCount = errorMessages.filter((message) => message.message.length > 0).length;
    this.setState({
      successMessage: '',
      hasBeenSubmitted: true,
      errorMessages,
      errorSummaryMessages: errorMessages,
      errorSummaryCount: errorCount,
      shouldFocus: errorCount > 0
    });
    errorCount <= 0 && this.handleValidSubmit();
  }

  handleValidSubmit = () => {
    this.setState({
      isLoading: true
    });
    this.props.validSubmit(this.state.userInputs);
  }

  componentDidMount () {
    this.handleSetup();
  }

  componentDidUpdate (prevProps, prevState) {
    const prevPropsCount = Children.count(React.Children.toArray(prevProps.children).filter(notEmptyOrNil)); // Remove empty, null, or undefined children
    const propsCount = Children.count(React.Children.toArray(this.props.children).filter(notEmptyOrNil));
    if (prevPropsCount !== propsCount) {
      // The form inputs have changed (e.g. a radio button group selection has caused another form input to hide or be shown)
      this.handleSetup();
    }

    if (!isEmpty(this.props.apiError) && !equals(prevProps.apiError, this.props.apiError)) {
      this.handleApiError(this.props.apiError, prevState.userInputRefs);
    }
    if (prevProps.apiSuccess !== this.props.apiSuccess) {
      this.setState({
        userInputs: {},
        successMessage: this.props.apiSuccess,
        errorSummaryMessages: [],
        hasBeenSubmitted: false,
        shouldFocus: true,
        isLoading: false
      });
    }
    if (this.state.shouldFocus && this.titleRef.current) {
      this.titleRef.current.focus();
      this.setState({ shouldFocus: false });
    }
  }

  getSummaryTitle = () => {
    if (this.state.errorSummaryTitle) {
      return this.state.errorSummaryTitle;
    }

    return this.props.intl.formatMessage(messages.FormSummaryTitle, {
      count: this.state.errorSummaryCount,
      verb: this.state.errorSummaryCount > 1 ? 'are' : 'is',
      problem: this.state.errorSummaryCount > 1 ? 'problems' : 'problem'
    });
  }

  render () {
    const showRevertButton = !equals(this.state.initialInputs, this.state.userInputs);
    const children = Children.map(this.props.children, child => {
      if (!child) { return child; }
      if (child.type === FormTextInput) {
        return React.cloneElement(child, {
          value: this.state.userInputs[child.props.name],
          onChange: this.handleTextInputChange,
          showValidationErrors: this.state.hasBeenSubmitted,
          requiredIsValid: this.requiredIsValid,
          formatIsValid: this.formatIsValid,
          inputRef: this.state.userInputRefs[child.props.name]
        });
      } else if (child.type === FormCheckboxSingle) {
        return React.cloneElement(child, {
          onChange: this.handleCheckboxSingleChange,
          showValidationErrors: this.state.hasBeenSubmitted,
          requiredIsValid: this.requiredIsValid,
          checked: this.state.userInputs[child.props.name],
          buttonRef: this.state.userInputRefs[child.props.name]
        });
      } else if (child.type === FormSelect) {
        return React.cloneElement(child, {
          value: this.state.userInputs[child.props.name],
          onSelect: this.handleSelectChange,
          showValidationErrors: this.state.hasBeenSubmitted,
          requiredIsValid: this.requiredIsValid,
          selectRef: this.state.userInputRefs[child.props.name]
        });
      } else if (child.type === FormRadioGroup) {
        return React.cloneElement(child, {
          onFormChange: this.handleRadioButtonChange,
          showValidationErrors: this.state.hasBeenSubmitted,
          requiredIsValid: this.requiredIsValid,
          radioRef: this.state.userInputRefs[child.props.name]
        });
      } else {
        return React.cloneElement(child, {});
      }
    });
    const buttonLabel = this.props.buttonSubmitText ? this.props.buttonSubmitText : this.props.intl.formatMessage(messages.FormButtonSubmit);

    return (
      <section>
        {this.state.hasBeenSubmitted && this.state.errorSummaryCount > 0 &&
          <FormSummary
            error
            formMessages={this.state.errorSummaryMessages}
            titleRef={this.titleRef}
            title={this.getSummaryTitle()}
          />}
        {this.state.successMessage &&
          <FormSummary
            titleRef={this.titleRef}
            title={this.state.successMessage}
          />}
        {!this.state.successMessage &&
          <form className='c-ckm-form' onSubmit={this.handleSubmit} noValidate>
            {children}
            {!this.state.isLoading
              ? <>
                <Button
                  type={Button.Types.PRIMARY}
                  className='c-els-button--responsive u-els-margin-top'
                  onClick={() => {}}
                  htmlType={Button.HtmlTypes.SUBMIT}
                  aria-label={buttonLabel}
                  title={buttonLabel}
                  id='password_change'
                >
                  {buttonLabel}
                </Button>

                {this.props.buttonCancelText &&
                  <Button
                    type={Button.Types.SECONDARY}
                    className='u-els-margin-top u-els-margin-left'
                    onClick={this.handleCancel}
                    aria-label={this.props.buttonCancelText}
                    title={this.props.buttonCancelText}
                  >
                    {this.props.buttonCancelText}
                  </Button>}
                {this.props.buttonRevertText && showRevertButton &&
                  <Button
                    type={Button.Types.SECONDARY}
                    className='u-els-margin-top u-els-margin-left'
                    onClick={this.handleRevert}
                    aria-label={this.props.buttonRevertText}
                    title={this.props.buttonRevertText}
                  >
                    {this.props.buttonRevertText}
                  </Button>}
              </>
              : <InlineLoader />}
          </form>}
      </section>
    );
  }
}

Form.displayName = 'Form';
