import React, {useState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import InternalLink from './InternalLink';
import '../../assets/img/pictogram-video.svg';

import { MainContext } from '../../context/main-context';

const Notice = (props) => {
  const { user, intl } = useContext(MainContext);
  const [banner, setBanner] = useState('');

  const checkNotices = () => {
    if (user.hasIndianVideoAccess()) { // Indian Clinical Learning Site
      setBanner({
        image: '#pictogram-video',
        linkURL: 'https://indiambbsskills.clinicalkey.com/login/index.php',
        linkText: {
          id: 'notice.browseClinicalSkillsVideos.linkText',
          defaultMessage: 'Browse our MBBS Practical & Clinical Skills Videos'
        }
      });
    }
  };

  useEffect(() => {
    checkNotices();
  }, []);

  return (
    <>
      {banner &&
        <div className={props.className} data-testid='notice'>
          <div className='c-ckm-notice c-ckm-widget o-els-flex-layout'>
            <div className='o-els-flex-layout__item'>
              <svg className='c-ckm-notice__image'>
                <use xlinkHref={banner.image} data-testid='notice-image' />
              </svg>
            </div>
            <div className='o-els-flex-layout__item o-els-flex-layout__item--grow'>
              {banner.content && intl.formatMessage(banner.content)}
              &nbsp;
              {
                banner.linkURL &&
                  <InternalLink
                    id='notice-link'
                    href={banner.linkURL}
                    target='_blank'
                  >
                    {intl.formatMessage(banner.linkText)}
                  </InternalLink>
              }
            </div>

          </div>
        </div>}
    </>
  );
};

export default Notice;

Notice.propTypes = {
  className: PropTypes.string
};
