import React, { useEffect, forwardRef, useState } from 'react';
import { defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import useIntl from '../../hooks/use-intl';
import { IMAGE_VIEWS } from '../../constants';
import { Button } from '@els/els-react--button';
import { trackEvent } from '../../utils/eventTrackUtils';
import MediaViewSlider from './MediaViewSlider';
import {isXSmall } from '../../utils/elementUtils';
import '../../assets/img/icon_compare.svg';

const messages = defineMessages({
  thumbnailLabelModel: {
    id: 'readerImage.thumbnailLabelModel',
    defaultMessage: '3D'
  },
  thumbnailLabelCompare: {
    id: 'readerImage.thumbnailLabelCompare',
    defaultMessage: '2D|3D'
  }
});

const MediaViewNavigation = forwardRef((props, ref) => {
  const { screenId, screenData, imageSrc, viewChangeCallback, views } = props;
  const intl = useIntl();
  const [currentView, setCurrentView] = useState(props.currentView);
  const [currentScreenId, setCurrentScreenId] = useState(null);

  const handleViewSelect = (viewType, screenIdSelected) => {
    if (currentView !== viewType || currentScreenId !== screenIdSelected) {
      setCurrentView(viewType);
      setCurrentScreenId(screenIdSelected);
      viewChangeCallback(viewType, screenIdSelected);
    }

    trackEvent('buttonClick', { buttonType: 'model-thumbnail:' + viewType }
    );
  };

  useEffect(() => {
    setCurrentScreenId((currentView === IMAGE_VIEWS.MODEL && screenId) ? screenId : null);
  }, []);

  useEffect(() => {
    setCurrentView(props.currentView);
  }, [props.currentView]);

  const thumbnailsBeforeSlider = 4;

  const shouldRenderSlider = (+views.includes(IMAGE_VIEWS.IMAGE) + screenData?.length + +(views.includes(IMAGE_VIEWS.COMPARE) && screenData)) > thumbnailsBeforeSlider;

  const renderCondition = {
    imageCondition: () => views.includes(IMAGE_VIEWS.IMAGE),
    modelCondition: () => views.includes(IMAGE_VIEWS.MODEL) && screenData,
    compareCondition: () => views.includes(IMAGE_VIEWS.COMPARE) && screenData
  };

  const getImageView = () => {
    return (
      renderCondition.imageCondition() &&
        <div
          className={`c-ckm-media-nav__thumbnail c-ckm-media-nav__thumbnail--full ${currentView === IMAGE_VIEWS.IMAGE && 'c-ckm-media-nav__thumbnail--selected'} o-els-flex-layout__item`}
          data-testid='2d-thumbnail-wrapper'
        >
          <Button
            onClick={() => { handleViewSelect(IMAGE_VIEWS.IMAGE); }}
            type={Button.Types.LINK}
            id='image-thumb-btn'
            linkOptions={['image']}
            data-testid='2d-thumbnail-btn'
          >
            <img
              src={imageSrc}
              alt='thumbnail'
            />
          </Button>
        </div>
    );
  };

  const getModelView = () => {
    return (
      renderCondition.modelCondition() &&
      screenData.map((screen, index) => {
        const isSelected = currentView === IMAGE_VIEWS.MODEL && currentScreenId === screen.id;
        return (
          <div
            className={`c-ckm-media-nav__thumbnail o-els-flex-layout__item ${isSelected && 'c-ckm-media-nav__thumbnail--selected'}`}
            data-testid={`3d-thumbnail-wrapper-${screen.id}`}
            key={index}
          >
            <Button
              onClick={() => { handleViewSelect(IMAGE_VIEWS.MODEL, screen.id); }}
              type={Button.Types.LINK}
              className=''
              id='model-thumb-btn'
              data-testid='model-thumb-btn-testid'
              linkOptions={['image']}
              data-aa-button='model-thumbnail:model-select'
            >
              <div className='c-ckm-media-nav__thumbnail-container'>
                {screen.image &&
                  <img
                    src={screen.image}
                    alt='model thumbnail'
                  />}
              </div>
              <div className='c-ckm-media-nav__thumbnail-label u-els-font-size-meta u-els-text-center'>{intl.formatMessage(messages.thumbnailLabelModel)}</div>
            </Button>
          </div>
        );
      })
    );
  };

  const getCompareView = () => {
    return (
      renderCondition.compareCondition() &&
        <div
          className={`c-ckm-media-nav__thumbnail c-ckm-media-nav__thumbnail--compare ${currentView === IMAGE_VIEWS.COMPARE && 'c-ckm-media-nav__thumbnail--selected'} o-els-flex-layout__item`}
          data-testid='compare-thumbnail-wrapper'
        >
          <Button
            onClick={() => { handleViewSelect(IMAGE_VIEWS.COMPARE, screenData[0].id); }}
            type={Button.Types.LINK}
            className=''
            id='COMPARE-thumb-btn'
            linkOptions={['image']}
            data-aa-button='model:model-compare-2D-3D'
          >
            <div className='c-ckm-media-nav__thumbnail-container o-els-flex-layout'>
              <div className='c-ckm-media-nav__thumbnail--half o-els-flex-layout__item'>
                {imageSrc &&
                  <img
                    src={imageSrc}
                    alt='compare thumbnail'
                  />}
              </div>
              <div className='c-ckm-media-nav__thumbnail--half o-els-flex-layout__item'>
                {screenData[0].image &&
                  <img
                    src={screenData[0].image}
                    alt='compare model thumbnail'
                  />}
              </div>
            </div>
            <div className='c-ckm-media-nav__thumbnail-label u-els-font-size-meta u-els-text-center'>{intl.formatMessage(messages.thumbnailLabelCompare)}</div>
          </Button>
        </div>
    );
  };

  return (
    <>
      {isXSmall() && shouldRenderSlider && props.showSlider ? <MediaViewSlider getModelView={getModelView} getImageView={getImageView} getCompareView={getCompareView} showNavigation={props.showNavigation} />
        : <div className='c-ckm-media-nav o-els-flex-layout'>
          {getImageView()}
          {getModelView()}
          {getCompareView()}
        </div>}</>
  );
});

MediaViewNavigation.propTypes = {
  views: PropTypes.array,
  eid: PropTypes.string,
  screenIds: PropTypes.arrayOf(PropTypes.string),
  screenData: PropTypes.arrayOf(PropTypes.object),
  screenId: PropTypes.string,
  imageSrc: PropTypes.string,
  currentView: PropTypes.string,
  viewChangeCallback: PropTypes.func
};

MediaViewNavigation.defaultProps = {
  currentView: IMAGE_VIEWS.IMAGE
};

MediaViewNavigation.displayName = 'MediaViewNavigation';

export default MediaViewNavigation;
