import { compose, has, not, isEmpty, isNil, either, pickBy, any, when, is, head } from 'ramda';

export const isDevelopment = () => process.env.NODE_ENV === 'development';

export const isProduction = process.env.NODE_ENV === 'production';

export const hasError = has('error');

export const hasNoError = compose(not, hasError);

export const noop = () => {};

export const isEmptyOrNil = either(isEmpty, isNil);
export const notEmptyOrNil = compose(not, isEmptyOrNil);
export const anyDefined = any(notEmptyOrNil);

export const omitEmpty = pickBy(compose(not, isEmptyOrNil));

export const normalizeSingleElementArray = when(is(Array), head);

export default {};
