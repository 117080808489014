import React, { useContext, useState } from 'react';
import { defineMessages } from 'react-intl';
import { SingleSelect } from '@els/els-react--select';
import { TextInput } from '@els/els-react--text-input';
import { MainContext } from '../../context/main-context';
import { isMobile } from '../../utils/elementUtils';

const messages = defineMessages({
  inputPlaceholder: {
    id: 'NNN.searchDropdown.placeholder',
    defaultMessage: 'Buscar todas las taxonomías'
  },
  selectPlaceholder: {
    id: 'NNN.searchDropdown.select',
    defaultMessage: 'Todos'
  }
});

const NNNSearchDropdown = () => {
  const { intl, doRedirect } = useContext(MainContext);
  const [contentType, setContentType] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const onSelectChange = (option) => {
    setContentType(option);
  };
  const onTextInputChange = (e) => {
    setSearchValue(e.target.value);
  };
  const onTextSubmit = (e) => {
    e.preventDefault();
    let filterquery;
    if (contentType) {
      filterquery = contentType === 'D. Médicos' ? 'contenttype:(DIAGMED)' : `contenttype:(${contentType})`;
    } else {
      filterquery = 'contenttype:(NANDA NIC NOC DIAGMED)';
    }
    doRedirect(`/nnn/search/${searchValue}?filterquery=${filterquery}`);
  };
  return (
    <div className='c-ckm-nnn__search-dropdown' data-testid='nnn-search-dropdown'>
      {isMobile() ? <div className='c-ckm-nnn__search-dropdown-descr'>NNN</div> : <SingleSelect
        label=' '
        placeholderText={intl.formatMessage(messages.selectPlaceholder)}
        options={['NANDA', 'NIC', 'NOC', 'D. Médicos']}
        onSelect={onSelectChange}
        blankOptionLabel='Todos'
                                                                                  />}

      <form onSubmit={onTextSubmit} data-testid='nnn-search-dropdown-form'>
        <TextInput
          label=''
          isLabelInline
          id='nnn-filter-input'
          placeholder={intl.formatMessage(messages.inputPlaceholder)}
          className='c-ckm-nnn__search-dropdown--filter'
          onChange={onTextInputChange}
          data-testid='nnn-search-dropdown-input'
          autocomplete='off'
        />
      </form>
    </div>
  );
};

export default NNNSearchDropdown;
