import React from 'react';

import '../../assets/img/printer.svg';
import '../../assets/img/share-btn.svg';
import { replace } from 'ramda';
import NNNHeaderButtons from './NNNHeaderButtons';

const NNNHeader = (props) => {
  const { headerText, shareLink, eid, defaultCollectionId, collections, sourcetitle, updateCollections, updateDefaultCollectionId } = props;
  return (
    <div className='c-ckm-page-header c-ckm-nnn__main'>
      <div className='c-ckm-nnn__header-wrapper'>
        <h1 className='c-ckm-page-header__text'>
          {replace('DIAGMED', 'D. Médicos', headerText)}
        </h1>
      </div>
      <NNNHeaderButtons
        eid={eid}
        defaultCollectionId={defaultCollectionId}
        collections={collections}
        sourcetitle={sourcetitle}
        updateCollections={updateCollections}
        updateDefaultCollectionId={updateDefaultCollectionId}
        shareLink={shareLink}
        headerText={headerText}
      />
    </div>
  );
};

export default NNNHeader;
