import React, {Component, Fragment} from 'react';
import CKApi from '../../../services/api-helper';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import InlineLoader from '../InlineLoader';
import Dropdown from '../../../components/common/Dropdown';
import { isEmpty } from 'ramda';
import {MainContext} from '../../../context/main-context';
import { Button } from '@els/els-react--button';

const existingNameStatus = 409;

const messages = defineMessages({
  title: {
    id: 'PresentationMaker.add.title',
    defaultMessage: 'Add to presentation'
  },
  select: {
    id: 'PresentationMaker.add.select',
    defaultMessage: 'Select presentation'
  },
  inputLabel: {
    id: 'PresentationMaker.add.inputLabel',
    defaultMessage: 'Presentation name'
  },
  create: {
    id: 'PresentationMaker.add.create',
    defaultMessage: '+ Create new presentation'
  },
  saveNew: {
    id: 'PresentationMaker.add.saveNew',
    defaultMessage: 'Save'
  },
  cancel: {
    id: 'PresentationMaker.add.cancel',
    defaultMessage: 'Cancel'
  },
  addAndView: {
    id: 'PresentationMaker.add.addAndView',
    defaultMessage: 'Add and view presentation'
  },
  add: {
    id: 'PresentationMaker.add.addButton',
    defaultMessage: 'Add'
  },
  addSuccess: {
    id: 'PresentationMaker.add.success',
    defaultMessage: 'Successfully added image to '
  },
  initialLoadError: {
    id: 'PresentationMaker.add.initialLoadError',
    defaultMessage: 'There was an error loading your presentations. Please try again.'
  },
  addError: {
    id: 'PresentationMaker.add.addError',
    defaultMessage: 'There was an error adding this image to your presentation. Please try again.'
  },
  createError: {
    id: 'PresentationMaker.add.createError',
    defaultMessage: 'There was an error creating this presentation. Please try again.'
  },
  conflictError: {
    id: 'PresentationMaker.add.conflictError',
    defaultMessage: ' already exists. Please provide a unique name.'
  },
  validationError: {
    id: 'PresentationMaker.add.validationError',
    defaultMessage: 'Please provide a name.'
  },
  noPresentationsError: {
    id: 'PresentationMaker.add.noPresentationsError',
    defaultMessage: 'Please create a presentation before adding images.'
  }
});

const sharedSuccessToastProperties = {
  isVisible: true,
  icon: '#icon-sprite_els-hmds-icon-checkmark',
  iconColor: 'positive',
  color: 'positive',
  autoHide: true
};

const ErrorMessage = (props) => (
  <p className='c-ckm-presentation-maker-modal__text c-ckm-field--error'>
    {props.children}
  </p>
);

export default class PresentationModal extends Component {
  static propTypes = {
    eid: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired
  };

  static contextType = MainContext;

  state = {
    isCreatingNewPresentation: false,
    isLoading: true,
    presentations: [],
    selectedPresentationIndex: 0,
    newPresentationName: '',
    conflictingName: '',
    addSuccess: false,
    initialLoadError: false, // generic API error when initially loading list of presentations
    addError: false, // generic API error when adding image to presentation
    createError: false, // generic API error when creating a new presentation
    conflictError: false, // conflict-specific error when creating a new presentation with an existing name
    validationError: false, // client-side error when creating a new presentation without providing a name,
    noPresentationsError: false // client-side error when trying to add an image with no presentation selected
  };

  statusRef = React.createRef();

  componentDidMount = () => {
    CKApi.get('/student/api/presentations')
      .then((response) => {
        this.setState({
          presentations: response.data,
          isLoading: false
        });
      })
      .catch(() => {
        this.setState({
          isLoading: false,
          initialLoadError: true
        }, this.focusStatusMessage);
      });
  };

  clearErrors = () => {
    this.setState({
      addError: false,
      createError: false,
      conflictError: false,
      validationError: false,
      noPresentationsError: false
    });
  };

  showCreatePresentationPrompt = () => {
    this.setState({
      isCreatingNewPresentation: true
    });
  };

  handlePresentationNameChange = (event) => {
    this.setState({
      newPresentationName: event.target.value
    });
  };

  createNewPresentation = (event) => {
    event.preventDefault();

    this.setState({
      isLoading: true
    }, () => {
      this.clearErrors();

      CKApi.post('/student/api/presentations', {
        name: this.state.newPresentationName
      }).then((response) => {
        this.setState((prevState) => ({
          isCreatingNewPresentation: false,
          newPresentationName: '',
          presentations: [response.data, ...prevState.presentations],
          selectedPresentationIndex: 0,
          isLoading: false
        }));
      }).catch(({ response }) => {
        if (response.status === existingNameStatus) {
          this.setState(prevState => ({
            isLoading: false,
            conflictingName: prevState.newPresentationName,
            conflictError: true
          }), this.focusStatusMessage);
        } else {
          this.setState({
            isLoading: false,
            serverError: true
          }, this.focusStatusMessage);
        }
      });
    });
  };

  showValidationError = (event) => {
    event.preventDefault();

    this.clearErrors();

    this.setState({
      validationError: true
    }, this.focusStatusMessage);
  };

  addToPresentation = (redirectToPresentation = false) => (event) => {
    this.setState({
      isLoading: true
    }, () => {
      this.clearErrors();

      CKApi.put(`/student/api/presentations/${this.state.presentations[this.state.selectedPresentationIndex].id}`, {
        eid: this.props.eid
      }).then(() => {
        this.setState({
          isLoading: false,
          addSuccess: true
        }, () => {
          if (redirectToPresentation) {
            this.context.doRedirect(`/presentations?presentationId=${this.state.presentations[this.state.selectedPresentationIndex].id}`);
          }
        });
        this.props.hideAddToPresentation();
        const redirectToCurrentPresentation = `${this.context.productConfig.path}/presentations?presentationId=${this.state.presentations[this.state.selectedPresentationIndex].id}`;
        this.context.addToast({
          body: `<SuccessMessage>
          ${this.context.intl.formatMessage(messages.addSuccess)}
          &nbsp;
            <strong>
              <a href=${redirectToCurrentPresentation}>${this.state.presentations[this.state.selectedPresentationIndex].name}</a>
            </strong>
          </SuccessMessage>`,
          ...sharedSuccessToastProperties
        });
      }).catch(() => {
        this.setState({
          isLoading: false,
          addError: true
        }, this.focusStatusMessage);
      });
    });
  };

  selectPresentation = (index) => (event) => {
    event.preventDefault();
    this.setState({
      selectedPresentationIndex: index
    });
  };

  cancelNewPresentation = () => {
    this.clearErrors();

    this.setState({
      isCreatingNewPresentation: false,
      newPresentationName: ''
    });
  };

  focusStatusMessage = () => {
    this.statusRef.current.focus();
  };

  showNoPresentationsError = () => (event) => {
    this.setState({
      noPresentationsError: true
    }, this.focusStatusMessage);
  };

  render () {
    const { intl } = this.context;
    const createNewPresentationHandler = isEmpty(this.state.newPresentationName) ? this.showValidationError : this.createNewPresentation;
    const addToPresentationHandler = isEmpty(this.state.presentations) ? this.showNoPresentationsError : this.addToPresentation;
    const showInterface = !(this.state.addSuccess || this.state.initialLoadError);

    return (
      <div>
        {this.state.isLoading
          ? <InlineLoader />
          : <div>
            <h1>
              {intl.formatMessage(messages.title)}
            </h1>
            <div ref={this.statusRef} tabIndex='-1'>
              {this.state.initialLoadError &&
                <ErrorMessage>{intl.formatMessage(messages.initialLoadError)}</ErrorMessage>}
              {this.state.addError &&
                <ErrorMessage>{intl.formatMessage(messages.addError)}</ErrorMessage>}
              {this.state.createError &&
                <ErrorMessage>{intl.formatMessage(messages.createError)}</ErrorMessage>}
              {this.state.conflictError &&
                <ErrorMessage><strong>{this.state.conflictingName}</strong> {intl.formatMessage(messages.conflictError)}</ErrorMessage>}
              {this.state.validationError &&
                <ErrorMessage>{intl.formatMessage(messages.validationError)}</ErrorMessage>}
              {this.state.noPresentationsError &&
                <ErrorMessage>{intl.formatMessage(messages.noPresentationsError)}</ErrorMessage>}
            </div>
            {showInterface &&
              <>
                {this.state.presentations.length > 0 &&
                  <div className='c-ckm-presentation-maker__dropdown u-ckm-inline-block u-ckm-divider--right u-ckm-padding--right'>
                    <Dropdown
                      hiddenLabel={messages.select.defaultMessage}
                      label={this.state.presentations[this.state.selectedPresentationIndex].name}
                    >
                      <ul className='c-ckm-dropdown-list c-ckm-presentation-maker__dropdown-container'>
                        {this.state.presentations.map((presentation, index) =>
                          <li
                            className='c-ckm-dropdown-list__item'
                            key={presentation.id}
                          >
                            <button
                              className='c-ckm-dropdown-list__link c-ckm-link'
                              onClick={this.selectPresentation(index)}
                            >
                              {presentation.name}
                            </button>
                          </li>
                        )}
                      </ul>
                    </Dropdown>
                  </div>}
                <div className='u-ckm-margin--left u-ckm-inline-block'>
                  {this.state.isCreatingNewPresentation
                    ? (
                      <form
                        className='c-ckm-form'
                        onSubmit={createNewPresentationHandler}
                      >
                        <label
                          className='c-ckm-field__label-text'
                          htmlFor='createPresentationInput'
                        >
                          {intl.formatMessage(messages.inputLabel)}
                        </label>
                        <div className='c-ckm-presentation-maker-modal__input-container u-ckm-inline-block u-ckm-margin--right'>
                          <input
                            className='c-ckm-field__input'
                            id='createPresentationInput'
                            onChange={this.handlePresentationNameChange}
                            value={this.state.newPresentationName}
                            type='text'
                          />
                        </div>
                        <button
                          className='c-ckm-button c-ckm-button--transparent u-ckm-margin--right'
                          type='submit'
                          onClick={createNewPresentationHandler}
                        >
                          <span className='c-ckm-icon c-ckm-icon--save-blue c-ckm-toolbar__icon' />
                          {intl.formatMessage(messages.saveNew)}
                        </button>
                        <button
                          className='c-ckm-button c-ckm-button--transparent c-ckm-button--clipped'
                          onClick={this.cancelNewPresentation}
                        >
                          {intl.formatMessage(messages.cancel)}
                        </button>
                      </form>
                    ) : (
                      <button
                        onClick={this.showCreatePresentationPrompt}
                        className='c-ckm-button--transparent'
                      >
                        {intl.formatMessage(messages.create)}
                      </button>
                    )}
                </div>
                <div className='c-ckm-presentation-maker-modal__button-container c-ckm-presentation-maker-modal__button-container--left'>
                  <button
                    className='c-ckm-button c-ckm-button--transparent c-ckm-button--clipped c-ckm-button--small c-ckm-presentation-maker-modal__button u-els-margin-right'
                    onClick={this.props.handleClose}
                  >
                    {intl.formatMessage(messages.cancel)}
                  </button>
                  <Button
                    type={Button.Types.SECONDARY}
                    className='c-els-button--responsive u-els-margin-right'
                    onClick={addToPresentationHandler(true)}
                    aria-label={intl.formatMessage(messages.addAndView)}
                    title={intl.formatMessage(messages.addAndView)}
                    id='btn-add-and-view-presentation'
                  >
                    {intl.formatMessage(messages.addAndView)}
                  </Button>
                  <Button
                    type={Button.Types.PRIMARY}
                    className='c-els-button--responsive'
                    onClick={addToPresentationHandler()}
                    aria-label={intl.formatMessage(messages.add)}
                    title={intl.formatMessage(messages.add)}
                    id='btn-presentation-add'
                    data-testid='btnPresentationAdd'
                  >
                    {intl.formatMessage(messages.add)}
                  </Button>
                </div>
              </>}
          </div>}
      </div>
    );
  }
}

PresentationModal.displayName = 'PresentationModal';
