import React, { useState, useEffect } from 'react';
import { Dropdown } from '@els/els-react--dropdown';
import {
  ELSPerformanceService,
  ELSMededApi
} from '@els/meded-ui-common-react';
import '../../assets/img/Bullseye.svg';
import '../../assets/img/SpaceProgress.svg';
import { availableQuestionBanksOpt } from '../../utils/availableQuestionBanksOpt';

const ProgressBar = ({
  courseId,
  messages,
  intl,
  isInstructor
}) => {
  const [availableQuestionBanks, setAvailableQuestionBanks] = useState([]);
  const [selectedQuestionBank, setSelectedQuestionBank] = useState({});

  const handleQuestionBankClick = (option) => {
    setSelectedQuestionBank(option.questionBank);
  };

  useEffect(() => {
    ELSPerformanceService.PerformanceService.getQuestionBanksPerformance(courseId, 10000)
      .then(({ data: allData }) => {
        const facultySequesteredQuestionsISBN = '2000000000004';
        const firstData = isInstructor ? allData : allData.filter((obj) => obj?.questionSet?.isbn && obj?.questionSet.isbn !== facultySequesteredQuestionsISBN);

        const isbns = firstData?.map((obj) => obj?.questionSet?.isbn) ?? [];

        Promise.all(isbns.map(isbn => ELSMededApi.get(`/assessment/mastery/${isbn}`)))
          .then(results => {
            const getVtwIds = data => {
              const set = new Set([]);
              data.forEach(parentTopic => {
                return parentTopic.children.forEach(childTopic => {
                  if (!set.has(childTopic.vtwId)) {
                    set.add(childTopic.vtwId);
                  }
                });
              });
              return [...Array.from(set)];
            };

            let newResults = [];
            if (results?.length > 0) {
              newResults = results.map((result, index) => {
                return {
                  isbn: isbns[index],
                  vtwIds: getVtwIds(result.data)
                };
              });
            }

            Promise.all(newResults.map(({ isbn, vtwIds }) => ELSMededApi.post(`/recommendation/isbn/${isbn}/count`, { data: vtwIds })))
              .then(results => {
                setAvailableQuestionBanks(
                  results.map((obj, index) => {
                    const firstDataObj = firstData?.[index];
                    return {
                      name: firstDataObj?.questionSet?.title ?? '',
                      totalQuestions: obj.data ?? 0,
                      data: {
                        answered: firstDataObj?.performance?.totalAnswered ?? 0,
                        confident: firstDataObj?.performance?.totalConfident ?? 0,
                        correct: firstDataObj?.performance?.totalCorrect ?? 0
                      }
                    };
                  })
                );
              }).catch(error => console.error(error));
          }).catch(error => console.error(error));
      }).catch(error => console.error(error));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectedQuestionBank(availableQuestionBanks?.[0]);
  }, [availableQuestionBanks]);

  const calculatePercentage = (numerator, denominator) => {
    if (numerator * 100 / denominator < 1) {
      return (numerator * 100 / denominator).toFixed(1);
    }
    return (numerator * 100 / denominator).toFixed();
  };

  const correctAnswered = selectedQuestionBank?.data?.correct;
  const incorrectAnswered = selectedQuestionBank?.data?.answered - selectedQuestionBank?.data?.correct;
  const unanswered = selectedQuestionBank?.totalQuestions - selectedQuestionBank?.data?.answered;

  const correctAnswersPresents = correctAnswered ? calculatePercentage(correctAnswered, selectedQuestionBank?.totalQuestions) : 0;
  const incorrectAnswersPresents = incorrectAnswered ? calculatePercentage(incorrectAnswered, selectedQuestionBank?.totalQuestions) : 0;
  const unansweredQuestionsPresents = unanswered ? calculatePercentage(unanswered, selectedQuestionBank?.totalQuestions) : 0;

  const renderProgressBarHeaderValues = (extraClass = '') => <div className={`home-progress-bar__header-values ${extraClass}`}>
    <div><span className='dot dot-incorrect' /><span className='value'>{intl.formatMessage(messages.progressBarIncorrect)} {incorrectAnswersPresents}%</span></div>
    <div><span className='dot dot-correct' /><span className='value'>{intl.formatMessage(messages.progressBarCorrect)} {correctAnswersPresents}%</span></div>
    <div><span className='dot dot-unanswered' /><span className='value'>{intl.formatMessage(messages.progressBarUnanswered)} {unansweredQuestionsPresents}%</span></div>
  </div>;

  const renderDropdown = (extraClass = '') => <div className={`home-progress-bar__header-banks-dropdown ${extraClass}`}>
    {availableQuestionBanks?.length > 1 ? (
      <Dropdown
        id='dropdown'
        options={availableQuestionBanksOpt(availableQuestionBanks, isInstructor).map(item => ({ label: item.name, questionBank: item, onClick: handleQuestionBankClick }))}
        label={selectedQuestionBank?.name}
        className='dropdown'
        placement='bottom-start'
        shouldCloseOnEsc
      />
) : (
  <span data-testid='single-question-bank'>{selectedQuestionBank?.name}</span>
)}
  </div>;

  const renderProgressLine = (extraClass = '') => <div className={`home-progress-bar__progress ${extraClass}`}>
    <div className='home-progress-bar__progress-values'>
      {selectedQuestionBank?.data?.answered}/{selectedQuestionBank?.totalQuestions}
    </div><div data-testid='progressBarlineTestId' className='home-progress-bar__progress-line'>
      <div style={{width: `${incorrectAnswersPresents}%`}} className='home-progress-bar__progress-line-incorrect' />
      <div style={{width: `${correctAnswersPresents}%`}} className='home-progress-bar__progress-line-correct' />
      <div style={{width: '100%'}} className={`home-progress-bar__progress-line-unanswered ${selectedQuestionBank?.data?.answered === 0 ? 'home-progress-bar__progress-line-unanswered-extra' : ''}`}>
        <div className='home-progress-bar__progress-line-image'>
          <svg width='32' height='32' fill='white'>
            <use xlinkHref='#SpaceProgress' />
          </svg>
        </div>
      </div>
    </div>
  </div>;

  return (
    <div data-testid='progress-bar-widget' className='home-progress-bar-widget'>
      <div className='home-progress-bar'>
        <div className='home-progress-bar__content'>
          <div className='home-progress-bar__content-image-container'>
            <div className='home-progress-bar__content-image'>
              <svg width='32' height='32'>
                <use xlinkHref='#Bullseye' />
              </svg>
            </div>
          </div>
          <div className='home-progress-bar__content-info'>
            <div className='home-progress-bar__header'>
              <div className='home-progress-bar__header-banks'>
                {window.innerWidth < 450 && renderDropdown('home-progress-bar__header-banks-dropdown-extra')}
                <h1 className='home-progress-bar__header-banks-title'>
                  {intl.formatMessage(messages.progressBarTitle)}
                </h1>
                {window.innerWidth >= 450 && renderDropdown()}
              </div>
              {window.innerWidth >= 840 && renderProgressBarHeaderValues()}
            </div>
            {window.innerWidth >= 450 && renderProgressLine()}
          </div>
        </div>
      </div>
      {window.innerWidth < 450 && renderProgressLine('home-progress-bar__progress-extra')}
      {window.innerWidth < 840 && renderProgressBarHeaderValues('home-progress-bar__header-values-extra')}
    </div>
  );
};

export default ProgressBar;
