import React from 'react';
import { TextInput } from '@els/els-react--text-input';
import { Modal } from '@els/els-react--modal';
import ModalButtons from '../../components/common/ModalButtons';

const RenameModal = (props) => {
  return (
    <Modal
      onClose={props.closeRenameModal}
      className='c-ckm-modal'
    >
      <div className='c-ckm-modal__container'>
        <h1 className=''>
          {props.title}
        </h1>
        <div className='u-els-margin-top'>
          {props.isRenamingError &&
            <div className='u-els-color-warn u-els-margin-bottom'>
              <strong>{props.newName}</strong> {props.warning}
            </div>}
          <form
            onSubmit={props.handleRename}
          >
            <TextInput
              label=''
              value={props.newName}
              isLabelInline={false}
              shouldHideLabel
              onChange={props.handleRenameInputChange}
              maxlength='100'
            />
          </form>
        </div>
        <ModalButtons
          primaryButton={{ label: props.renameButtonLabel, onClick: props.handleRename }}
          secondaryButton={{ label: props.cancelButtonLabel, onClick: props.closeRenameModal }}
        />
      </div>
    </Modal>
  );
};
RenameModal.displayName = 'RenameModal';
export default RenameModal;
