import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import StableUniqueId from 'react-stable-uniqueid';
import { RadioButtonGroup } from '@els/els-react--radio-button';
import { MOUSEFLOW_INPUT_IGNORE_CLASS } from '../../constants';

const FormRadioGroup = (props) => {
  const className = classNames({ [MOUSEFLOW_INPUT_IGNORE_CLASS]: props.mouseflowIgnore });
  const getLabel = () => {
    return props.isRequired
      ? `${props.label} *`
      : `${props.label}`;
  };

  const requiredIsValid = props.requiredIsValid(props.name, props.value);

  const showError = (uniqueId) => {
    if (!requiredIsValid && props.showValidationErrors) {
      return <span id={`${uniqueId}-required`} className='c-ckm-field__message c-ckm-field--error'>{props.requiredErrorMessage}</span>;
    } else return null;
  };

  const radioChange = (evt) => {
    // Since Leyden RadioButtonGroup requires an onChange prop, and we additionally add a handler prop
    // in our Form.js, we have two functions to call on the selection of a radio button. The second option
    // is needed for cases such as conditionally rendering another component based on the state of the radio button
    // group. It's easier to keep track of the radio group state in the page it's used than through the Form.
    props.onFormChange(props.name, evt.target.value); // update form state
    props.onChange(props.name, evt.target.value); // update page state where Form is used (year of study modal, etc.)
  };

  return (
    <StableUniqueId
      prefix='c-ckm-field__input-' render={({ uniqueId }) =>
        <div className='c-ckm-field__item'>
          <RadioButtonGroup
            id={uniqueId}
            className={className}
            a11y={{ name: getLabel() }}
            onChange={radioChange}
            options={props.options}
            isInline={props.isInline}
            shouldBeSelectedByDefault={props.selectedByDefault}
            value={props.value}
            disabled={props.disabled}
            isRequired={props.isRequired}
            ref={props.radioRef}
          />
          {showError(uniqueId)}
        </div>}
    />
  );
};

FormRadioGroup.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  isInline: PropTypes.bool,
  instructionsID: PropTypes.string,
  selectedByDefault: PropTypes.bool,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  name: PropTypes.string
};

FormRadioGroup.displayName = 'FormRadioGroup';
export default FormRadioGroup;
