// This was originally created by Mat Hampson and copied from the Precision Medicine reposityory.
// We should eventually move this to the React component library when available.
// Refactor to use forwardRef once this PR is approved for Enzyme: https://github.com/airbnb/enzyme/pull/1592

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import StableUniqueId from 'react-stable-uniqueid';
import { MOUSEFLOW_INPUT_IGNORE_CLASS } from '../../constants';
import { SingleSelect } from '@els/els-react--select';

const FormSelect = (props) => {
  const className = classNames('c-ckm-field__select', {
    'c-ckm-field__select--readonly': props.readOnly,
    [MOUSEFLOW_INPUT_IGNORE_CLASS]: props.mouseflowIgnore
  });

  const handleSelectChange = (selected) => {
    props.onSelect && props.onSelect(props.name, selected.value);
    props.onSelectChange && props.onSelectChange(props.name, selected.value);
  };

  const requiredIsValid = props.requiredIsValid(props.name, props.value);

  const showError = (uniqueId) => {
    if (!requiredIsValid && props.showValidationErrors) {
      return <span id={`${uniqueId}-required`} className='c-ckm-field__message c-ckm-field--error'>{props.requiredErrorMessage}</span>;
    } else return null;
  };

  return (
    <StableUniqueId
      prefix='c-ckm-field__select-' render={({ uniqueId }) =>
        <div className='c-ckm-field__item'>
          <SingleSelect
            id={uniqueId}
            className={className}
            label={props.label}
            placeholderText={props.placeholder}
            required={props.isRequired}
            options={props.entries}
            selectedOptions={props.selectedEntries}
            onSelect={handleSelectChange}
            isLabelInline={false}
            ref={props.selectRef}
          />
          {showError(uniqueId)}
        </div>}
    />
  );
};

FormSelect.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  entries: PropTypes.array.isRequired,
  selectedEntries: PropTypes.array,
  instructions: PropTypes.string,
  readOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  requiredErrorMessage: PropTypes.string,
  onSelect: PropTypes.func,
  mouseflowIgnore: PropTypes.bool
};

FormSelect.defaultProps = {};

FormSelect.displayName = 'FormSelect';
export default FormSelect;
