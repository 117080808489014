const {head, test, split, join, map, lensIndex, compose, over, toUpper, toLower, replace, defaultTo, ifElse, isEmpty, identity, last} = require('ramda');
const {THUMBNAIL_BASE_URL, PRACTICE_THUMBNAIL_BASE_URL, SOURCE_TYPES, CONTENT_TYPE_NAMES, SEARCH_TABS, SEARCH_CONTENT_TYPE_FACETS} = require('../constants');

const L = {
  headLens: lensIndex(0)
};

const uppercaseFirstLetterOnly = compose(
  join(''),
  over(L.headLens, toUpper),
  split(''),
  toLower
);

// only works with non-empty string values
const safeTitleCase = compose(
  join(' '),
  map(uppercaseFirstLetterOnly),
  split(' ')
);

const toTitleCase = compose(
  ifElse(isEmpty, identity, safeTitleCase),
  defaultTo('')
);

// Search likes to delimit lists with garbage characters
const replaceSearchDelimiter = replace(/__\|__/g);

const isJPG = test(/\.jpg$/);

const cleanVideoThumbnailPath = compose(
  replace(/\..{3,4}$/, ''),
  replace(/^.*?:/g, '')
);

const getVideoThumbnailUrl = (refimage) => {
  if (!refimage) {
    return '';
  }
  const path = Array.isArray(refimage) ? head(refimage) : refimage;

  if (isJPG(path)) {
    return `${THUMBNAIL_BASE_URL}/${path}`;
  }

  const cleanPath = cleanVideoThumbnailPath(path);

  return `${THUMBNAIL_BASE_URL}/${cleanPath}-t-big.jpg`;
};

const isVideoThumbnailUrl = (url) => {
  return url.includes('t-big');
};

const getImageThumbnailUrl = (refimage) => `${THUMBNAIL_BASE_URL}/${head(refimage)}`;

const getPracticeImageThumbnailUrl = (refimage) => `${PRACTICE_THUMBNAIL_BASE_URL}/${head(refimage)}`;

const getThumbnailFromCoverImage = (coverimage) => {
  if (!coverimage) {
    return null;
  }
  if (coverimage && Array.isArray(coverimage) && coverimage.length > 0) {
    return `${THUMBNAIL_BASE_URL}/${head(coverimage)}`;
  }
  return `${THUMBNAIL_BASE_URL}/${coverimage}`;
};

const getBookThumbnailUrl = (hubeid) => {
  if (!hubeid) {
    return null;
  }

  const thumbnailFilename = 'cov200h.gif';
  const getSubDirFromHubeid = compose(
    last,
    split('-')
  );
  return `${THUMBNAIL_BASE_URL}/${getSubDirFromHubeid(hubeid)}/${thumbnailFilename}`;
};

const getCollectionItemThumbnailUrl = (hubeid) => {
  if (!hubeid) {
    return null;
  }
  const thumbnailFilename = 'cov200h.gif';
  const getSubDirFromHubeid = compose(
    last,
    split('-')
  );
  return [`${getSubDirFromHubeid(hubeid)}/${thumbnailFilename}`];
};

const getEMCThumbnailUrl = (hubeid, issn) => {
  const thumbnailFilename = 'cov200h.gif';
  const getSubDirFromHubeid = compose(
    last,
    split('-')
  );
  return `${THUMBNAIL_BASE_URL}/${issn}/${getSubDirFromHubeid(hubeid)}/${thumbnailFilename}`;
};

const removeWhitespace = replace(/\s/g, '');

const onlyContainsWhitespace = test(/^\s*$/);

const isNumericString = test(/^(\d|\.)+$/);

/**
 * safely decodes URI component string without throwing an error
 * @param {string} uri - string/uri to decode
 * @param {boolean} blankIfMalformed - if uri is malformed, return blank string instead of supplied string argument
 */
const safeDecodeURIComponent = (uri, blankIfMalformed = true) => {
  try {
    return decodeURIComponent(uri);
  } catch (error) {
    return blankIfMalformed ? '' : uri;
  }
};

const hightlightTerm = (text, term) => {
  if (!text) {
    return;
  }
  if (!term) {
    return text;
  }
  const searchValue = term.split(/\s/);
  const regex = new RegExp(`(${searchValue.join('|')})`, 'gi');
  const hightlitedText = text.toString().replace(regex, match => `<b>${match}</b>`);

  return hightlitedText ?? text;
};

const getContentViewPath = (contentType, options) => {
  const {hubeid, issn, eid, sectionId, isISSN} = options;

  switch (contentType) {
    case CONTENT_TYPE_NAMES.BOOK:
      if (sectionId && !Array.isArray(sectionId) && eid) {
        return `/content/book/${eid}#${sectionId}`;
      }
      if (eid) {
        return `/content/book/${eid}`;
      }
      return `/content/toc/${hubeid}`;
    case CONTENT_TYPE_NAMES.EMC:
      if (sectionId) {
        return `/content/emc/${eid}#${sectionId}`;
      }
      if (eid) {
        return `/content/emc/${eid}`;
      }
      return `/content/emc/toc/${issn}`;
    case CONTENT_TYPE_NAMES.ARTICLE:
      return `/content/journal/articles/${hubeid}`;
    case CONTENT_TYPE_NAMES.JOURNAL:
      if (sectionId && !isISSN) {
        return `/content/journal/${eid}#${sectionId}`;
      }
      if (eid && !isISSN) {
        return `/content/journal/${eid}`;
      }

      if (isISSN) {
        return `/content/journal/issues/${issn && issn.replace('-', '')}/${hubeid}`;
      }
      return '';
    case CONTENT_TYPE_NAMES.PDF:
      return `/api/content/pdf/${eid}`;
    case CONTENT_TYPE_NAMES.VIDEO:
      return `/content/video/${eid}`;
    case CONTENT_TYPE_NAMES.NANDA:
      return `/nnn/nanda/taxonomy/${eid}`;
    case CONTENT_TYPE_NAMES.NIC:
      return `/nnn/nic/taxonomy/${eid}`;
    case CONTENT_TYPE_NAMES.NOC:
      return `/nnn/noc/taxonomy/${eid}`;
    case CONTENT_TYPE_NAMES.DIAGMED:
      return `/nnn/diagmed/taxonomy/${eid}`;
    default:
      return '';
  }
};

const getJournalImageUrl = (hubeid) => {
  const getSubDirFromHubeid = compose(
    last,
    split('-')
  );
  return `${THUMBNAIL_BASE_URL}/${getSubDirFromHubeid(hubeid)}`;
};

const getMessageByTab = (tabName, isChangedHeader) => {
  switch (tabName) {
    case SEARCH_TABS.BOOKS:
      if (!isChangedHeader) {
        return 'book excerpts';
      }
      return 'book titles';
    case SEARCH_TABS.JOURNALS:
      return 'journals articles';
    case SEARCH_TABS.EMC:
      return 'EMC articles';
    case SEARCH_TABS.IMAGES:
      return 'images';
    case SEARCH_TABS.VIDEOS:
      return 'videos';
    case SEARCH_TABS.SUMMARIES:
      return 'summaries';
    case SEARCH_TABS.ANC:
      return 'teaching materials';
    case SEARCH_TABS.ALL:
      return isChangedHeader ? 'results' : 'other results';
    default:
      return 'results';
  }
};

const getPrimaryAuthor = (authors) => {
  if (authors) {
    return split('|', replaceSearchDelimiter('|', authors))[0];
  } else {
    return '';
  }
};

const checkContentType = (type) => {
  switch (type) {
    case SOURCE_TYPES.BOOK:
      return CONTENT_TYPE_NAMES.BOOK;
    case SOURCE_TYPES.JOURNAL:
      return CONTENT_TYPE_NAMES.JOURNAL;
    case SOURCE_TYPES.EMC:
      return CONTENT_TYPE_NAMES.EMC;
    case SOURCE_TYPES.ARTICLE:
      return CONTENT_TYPE_NAMES.ARTICLE;
    case SOURCE_TYPES.VIDEO:
      return CONTENT_TYPE_NAMES.VIDEO;
    case SOURCE_TYPES.IMAGE:
      return CONTENT_TYPE_NAMES.IMAGE;
    default:
      return type;
  }
};

const getTabFromFacets = (facet) => {
  switch (facet) {
    case SEARCH_CONTENT_TYPE_FACETS.BOOKS:
      return SEARCH_TABS.BOOKS;
    case SEARCH_CONTENT_TYPE_FACETS.EMC:
      return SEARCH_TABS.EMC;
    case SEARCH_CONTENT_TYPE_FACETS.ANC:
      return SEARCH_TABS.ANC;
    case SEARCH_CONTENT_TYPE_FACETS.JOURNALS:
      return SEARCH_TABS.JOURNALS;
    case SEARCH_CONTENT_TYPE_FACETS.IMAGES:
      return SEARCH_TABS.IMAGES;
    case SEARCH_CONTENT_TYPE_FACETS.VIDEOS:
      return SEARCH_TABS.VIDEOS;
    case SEARCH_CONTENT_TYPE_FACETS.SUMMARIES:
      return SEARCH_TABS.SUMMARIES;
    default:
      return null;
  }
};

const getAffiliationString = (arrayOfObjects) => {
  const getAffiliattionIndex = (obj) => {
    if ('cross-ref' in obj && Array.isArray(obj['cross-ref']['#children'])) {
      return obj['cross-ref']['#children'].map(child => child.sup && child.sup['#text'] && child.sup['#text'][0]).join(' ');
    }
    return '';
  };
  return arrayOfObjects.map(obj => getAffiliattionIndex(obj)).join(' ');
};

module.exports = {
  toTitleCase,
  replaceSearchDelimiter,
  isJPG,
  getVideoThumbnailUrl,
  getImageThumbnailUrl,
  getPracticeImageThumbnailUrl,
  getJournalImageUrl,
  removeWhitespace,
  onlyContainsWhitespace,
  isNumericString,
  safeDecodeURIComponent,
  getBookThumbnailUrl,
  getEMCThumbnailUrl,
  getCollectionItemThumbnailUrl,
  hightlightTerm,
  getThumbnailFromCoverImage,
  getPrimaryAuthor,
  getContentViewPath,
  isVideoThumbnailUrl,
  checkContentType,
  getTabFromFacets,
  getMessageByTab,
  getAffiliationString
};
