import React, { useState, useEffect } from 'react';
import { defineMessages } from 'react-intl';
import CKApi from '../../../../services/api-helper';
import InlineLoader from '../../../../components/common/InlineLoader';
import CaseSection from './CaseSection';

const messages = defineMessages({
  saveCaseSectionHeader: {
    id: 'Home.saveCaseSection.header',
    defaultMessage: 'My saved cases'
  },
  saveCaseSectionMessage: {
    id: 'Home.saveCaseSection.message',
    defaultMessage: 'You haven’t saved a case. Browse all cases to find a case.'
  }
});

const SaveCaseSection = (props) => {
  const [savedCases, setSavedCases] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const mostRecentCase = savedCases.length > 0 ? (savedCases.reduce((a, b) => (a.modifiedDate > b.modifiedDate ? a : b))).eid.substring(8) : '';
  const [status, setStatus] = useState('');

  useEffect(() => {
    CKApi.get('/student/api/content/savedCases')
      .then(({data}) => {
        setSavedCases(data);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        setHasError(true);
        console.log(e);
      });
  }, []);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (mostRecentCase !== '') {
      CKApi.post('/student/api/guideline/statuses', {caseIds: [mostRecentCase]})
        .then(({data}) => {
          setStatus(data[0].status);
        })
        .catch((e) => {
          setHasError(true);
          console.log(e);
        });
    }
  }, [isLoading]);

  return (
    <>
      {isLoading && !hasError && <InlineLoader />}
      {!isLoading &&
        <CaseSection
          title={props.intl.formatMessage(messages.saveCaseSectionHeader)}
          message={props.intl.formatMessage(messages.saveCaseSectionMessage)}
          isSavedSection
          intl={props.intl}
          status={status}
          mostRecentCase={mostRecentCase}
        />}
    </>
  );
};

export default SaveCaseSection;
