import CKApi from './api-helper';
import { parse } from '../utils/queryStringUtils';
import { is } from 'ramda';

const parseUrlOverrides = (flags) => {
  let { overrideFlag } = parse(window.location.search);

  if (is(String, overrideFlag)) {
    overrideFlag = [overrideFlag];
  }

  if (overrideFlag) {
    overrideFlag.forEach((flag) => {
      flags[flag] = true;
    });
  }

  return flags;
};

/**
 *
 * @param {Number} accountNumber A&E account number
 * @returns {Object} map of feature flags
 */
export const fetchFeatureFlags = async (accountNumber) => {
  const params = accountNumber ? { accountNumber } : {};
  try {
    const {data} = await CKApi.get('/student/api/user/feature-flags', { params });
    return parseUrlOverrides(data ?? {});
  } catch {
    return {};
  }
};
