import React, { useEffect, useState } from 'react';
import { defineMessages } from 'react-intl';
import {isEmpty} from 'ramda';
import useIntl from '../../hooks/use-intl';
import '../../assets/img/RecentActivityColored.svg';
import '../../assets/img/blueRecentActivities.svg';
import '../../assets/img/RecentlyViewedTest.svg';
import { ELSAssessmentService } from '@els/meded-ui-common-react';
import '../../assets/images/Edit.svg';
import '../../assets/images/Book.svg';
import '../../assets/images/PlayVideo.svg';
import CKApi from '../../services/api-helper';
import '../../assets/img/Up-right.svg';
import { CONTENT_TYPE_NAMES } from '../../constants';
import InternalLink from '../../components/common/InternalLink';
import { getBookThumbnailUrl, getPracticeImageThumbnailUrl, getVideoThumbnailUrl } from '../../utils/stringUtils';
import { Flyout } from '@els/els-react--flyout';

const messages = defineMessages({
  recentActivity: {
    id: 'RecentActivity.text.RecentActivity',
    defaultMessage: 'Recent activities'
  },
  pickUpWhereYouLeft: {
    id: 'RecentActivity.text.PickUpWhereYouLeft',
    defaultMessage: 'Pick up where you left off'
  },
  noActivityFound: {
    id: 'RecentActivity.text.NoActivity',
    defaultMessage: 'No activity found'
  },
  emptyPromptRecentActivity: {
    id: 'RecentActivity.text.EmptyPrompt',
    defaultMessage: 'Your user history is empty at the moment'
  },
  continueTesting: {
    id: 'RecentActivity.text.ContinueTesting',
    defaultMessage: 'Continue testing'
  },
  continueReading: {
    id: 'RecentActivity.text.ContinueReading',
    defaultMessage: 'Continue reading'
  },
  continueWatching: {
    id: 'RecentActivity.text.ContinueWatching',
    defaultMessage: 'Continue watching'
  },
  ChapterLabel: {
    id: 'HomePage.widget.continueLearning.label.chapter',
    defaultMessage: 'Chapter'
  },
  section: {
    id: 'BookTile.section',
    defaultMessage: 'Section'
  },
  video: {
    id: 'BookTile.video',
    defaultMessage: 'Video'
  },
  book: {
    id: 'BookTile.book',
    defaultMessage: 'Book'
  },
  journal: {
    id: 'BookTile.journal',
    defaultMessage: 'Journal'
  },
  emc: {
    id: 'BookTile.emc',
    defaultMessage: 'EMC'
  },
  resume: {
    id: 'SearchPage.text.resume',
    defaultMessage: 'Resume'
  },
  resumeTest: {
    id: 'HomePage.widget.recentActivities.resumeTest',
    defaultMessage: 'Resume test'
  },
  resumeCase: {
    id: 'HomePage.widget.recentActivities.resumeCase',
    defaultMessage: 'Resume case'
  },
  sectionWIthItem: {
    id: 'HomePage.widget.recentActivities.sectionWIthItem',
    defaultMessage: 'Section: {itemName}'
  },
  chiefComplaintSubtitle: {
    id: 'HomePage.widget.recentActivities.chiefComplaintSubtitle',
    defaultMessage: 'Complaint: {chiefComplaint}'
  },
  titleFlyoutDescription: {
    id: 'HomePage.widget.recentActivities.titleFlyoutDescription',
    defaultMessage: 'Item title'
  },
  testTopic: {
    id: 'HomePage.widget.recentActivities.testTopic',
    defaultMessage: 'Test topic'
  },
  defaultTopicText: {
    id: 'HomePage.widget.recentActivities.defaultTopicText',
    defaultMessage: 'Multiple topics'
  }
});

export const RecentActivities = ({assessmentOnly, isIAHomepage}) => {
  const [incompleteTest, setIncompleteTest] = useState({});
  const [recentlyViewed, setRecentlyViewed] = useState();
  const [recentlyViewedBook, setRecentlyViewedBook] = useState();
  const [recentlyViewedJournalOrEMC, setRecentlyViewedJournalOrEMC] = useState();
  const [recentlyViewedVideo, setRecentlyViewedVideo] = useState();
  const [recentlyViewedCase, setRecentlyViewedCase] = useState();

  useEffect(() => {
    ELSAssessmentService.getIncompleteTest()
      .then(res => {
        const incompleteTest = res.data;
        if (incompleteTest.isbn) {
          setIncompleteTest(incompleteTest);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (!isIAHomepage) {
      return;
    }

    CKApi.get('/student/api/content/recentlyViewedCases')
      .then(({data}) => {
        setRecentlyViewedCase(data.case);
      })
      .catch((e) => { console.log(e); });
    CKApi.get('/student/api/content/recentlyViewed', {params: {contentType: 'BOOK'}})
      .then(({data}) => {
        const book = data[0];
        if (book && book.sourceTitle && book.hubEid && book.eid && book.sectionId) setRecentlyViewedBook(book);
      }).catch(error => console.error(error));
    CKApi.get('/student/api/content/recentlyViewed', {params: {contentType: 'VIDEO'}})
      .then(({data}) => {
        const video = data[0];
        if (video && video.eid && video.refImage && video.itemTitle) setRecentlyViewedVideo(video);
      }).catch(error => console.error(error));
    const fetchData = async () => {
      try {
        const [EMCResponse, journalResponse] = await Promise.allSettled([
          CKApi.get('/student/api/content/recentlyViewed', { params: { contentType: 'EMC' } }),
          CKApi.get('/student/api/content/recentlyViewed', { params: { contentType: 'JOURNAL' } })
        ]);

        const EMC = EMCResponse.status === 'fulfilled' &&
          EMCResponse.value.data[0].eid &&
          EMCResponse.value.data[0].sectionId &&
          (journalResponse.value.data[0].coverImageUrl || journalResponse.value.data[0].coverimage) ? EMCResponse.value.data[0] : null;
        const journal = journalResponse.status === 'fulfilled' &&
          journalResponse.value.data[0].eid &&
          journalResponse.value.data[0].sectionId &&
          (journalResponse.value.data[0].coverImageUrl || journalResponse.value.data[0].coverimage) ? journalResponse.value.data[0] : null;

        const journalOrEMC = [EMC, journal].filter(Boolean);
        let selectedItem;

        if (journal && EMC) {
          selectedItem = journalOrEMC.sort((a, b) => b.modifiedDate - a.modifiedDate)[0];
        } else {
          selectedItem = EMC || journal;
        }

        if (selectedItem) setRecentlyViewedJournalOrEMC(selectedItem);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (isIAHomepage) {
      return;
    }
    CKApi.get('/student/api/content/recentlyViewed')
      .then(({data}) => {
        const video = data.find(item => item.contentType === CONTENT_TYPE_NAMES.VIDEO);
        const content = data.find(item => item.contentType !== CONTENT_TYPE_NAMES.VIDEO);
        // const book = data.find(item => item.contentType === CONTENT_TYPE_NAMES.BOOK);
        // const journalOrEMC = data.find(item => item.contentType === CONTENT_TYPE_NAMES.EMC || item.contentType === CONTENT_TYPE_NAMES.JOURNAL);

        if (video) setRecentlyViewedVideo(video);
        if (content) setRecentlyViewed(content);

        // if (journalOrEMC && isIAHomepage) setRecentlyViewedJournalOrEMC(journalOrEMC);
        // if (book && isIAHomepage) setRecentlyViewedBook(book);
      }).catch(error => console.error(error));
  }, []);

  const intl = useIntl();
  const recentActivityMessage = isIAHomepage ? intl.formatMessage(messages.pickUpWhereYouLeft) : intl.formatMessage(messages.recentActivity);
  const noActivityFoundMessage = intl.formatMessage(messages.noActivityFound);
  const emptyPromptRecentActivityMessage = intl.formatMessage(messages.emptyPromptRecentActivity);
  const testLink = `/student/test/#/test?assessmentId=${incompleteTest.id}`;
  const watchLink = `/student/content/video/${recentlyViewedVideo?.eid}?startTime=${recentlyViewedVideo?.videoPosition || 0}`;
  const continueTestDescription = `${intl.formatMessage(messages.testTopic)}: ${incompleteTest?.assessmentTopics?.[0]?.text || intl.formatMessage(messages.defaultTopicText)}`;

  const isObjEmpty = (obj) => Object.keys(obj).length === 0;
  const isContinueReading = recentlyViewed?.sectionTitle;
  const isContinueReadingBook = recentlyViewedBook?.sectionTitle;
  const isContinueCase = recentlyViewedCase;
  const isContinueReadingJournalOrEMC = recentlyViewedJournalOrEMC?.sectionTitle;
  const isContinueWatching = recentlyViewedVideo?.sourceTitle;
  const isContinueTesting = !isObjEmpty(incompleteTest);
  const isDisplayWidget = isIAHomepage
    ? isContinueTesting || isContinueReadingBook || isContinueReadingJournalOrEMC || isContinueWatching || isContinueCase
    : isContinueReading || isContinueTesting;

  const getReadingLink = (recentlyViewed) => {
    switch (recentlyViewed?.contentType) {
      case CONTENT_TYPE_NAMES.EMC:
        return `/content/emc/${recentlyViewed?.eid}#${recentlyViewed?.sectionId}`;
      case CONTENT_TYPE_NAMES.JOURNAL:
        return `/content/journal/${recentlyViewed?.eid}#${recentlyViewed?.sectionId}`;
      case CONTENT_TYPE_NAMES.BOOK:
        return `/content/book/${recentlyViewed?.eid}#${recentlyViewed?.sectionId}`;
      case CONTENT_TYPE_NAMES.VIDEO:
        return watchLink;
      default:
        return '';
    }
  };

  const DATA_TYPES_STRINGS = {
    [CONTENT_TYPE_NAMES.VIDEO]: intl.formatMessage(messages.video),
    [CONTENT_TYPE_NAMES.BOOK]: intl.formatMessage(messages.book),
    [CONTENT_TYPE_NAMES.JOURNAL]: intl.formatMessage(messages.journal),
    [CONTENT_TYPE_NAMES.EMC]: intl.formatMessage(messages.emc)
  };

  const widgetSortByDate = (a, b) => {
    const firstDate = a.modifiedDate || a.updatedAt || a.modifiedTimestamp;
    const secondDate = b.modifiedDate || b.updatedAt || b.modifiedTimestamp;

    return (Date.parse(new Date(secondDate)) - Date.parse(new Date(firstDate)));
  };

  const getContentImage = (contentItem) => {
    if (!contentItem) {
      return '';
    }
    if (contentItem.refImage) {
      return <img src={getVideoThumbnailUrl(contentItem.refImage)} alt={contentItem.itemTitle} />;
    }
    if (contentItem.caseId) {
      return <img src={getPracticeImageThumbnailUrl([`SPC/case-images/${contentItem.caseId}.jpg`])} alt={contentItem.casename} className='c-ckm-practice__continue-case-image-asset' />;
    }
    if (contentItem.hubEid) {
      switch (contentItem.contentType) {
        case CONTENT_TYPE_NAMES.BOOK:
          return <img src={getBookThumbnailUrl(contentItem.hubEid)} alt={contentItem.sourceTitle} />;
        case CONTENT_TYPE_NAMES.JOURNAL:
          return <img src={contentItem.coverimage || contentItem.coverImageUrl} alt={contentItem.sourceTitle} />;
        case CONTENT_TYPE_NAMES.EMC:
          return <img src={contentItem.coverimage || contentItem.coverImageUrl} alt={contentItem.sourceTitle} />;
        default:
          return <img src={getBookThumbnailUrl(contentItem.hubEid)} alt={contentItem.sourceTitle} />;
      }
    }

    return (
      <svg width='85' height='80'>
        <use xlinkHref='#RecentlyViewedTest' />
      </svg>
    );
  };

  const getContentTitle = (contentItem) => {
    if (!contentItem) {
      return '';
    }

    if (contentItem.itemTitle || contentItem.sourceTitle) {
      switch (contentItem.contentType) {
        case CONTENT_TYPE_NAMES.VIDEO:
          return contentItem.itemTitle;
        case CONTENT_TYPE_NAMES.BOOK:
          return contentItem.sourceTitle;
        case CONTENT_TYPE_NAMES.JOURNAL:
          return contentItem.sourceTitle;
        case CONTENT_TYPE_NAMES.EMC:
          return contentItem.sourceTitle;
        default:
          return contentItem.itemTitle;
      }
    }

    if (contentItem.patientName) {
      return contentItem.patientName;
    }

    if (contentItem.assignmentId) {
      return intl.formatMessage(messages.continueTesting);
    }
  };

  const getContentSubTitle = (contentItem) => {
    if (!contentItem) {
      return '';
    }

    if (Object.keys(DATA_TYPES_STRINGS).find(item => item === contentItem.contentType)) {
      return intl.formatMessage(messages.sectionWIthItem, {itemName: DATA_TYPES_STRINGS[contentItem.contentType]});
    }

    if (contentItem.caseId && contentItem.chiefComplaint) {
      return (intl.formatMessage(messages.chiefComplaintSubtitle, {chiefComplaint: contentItem.chiefComplaint}));
    }

    if (contentItem.assignmentId) {
      return continueTestDescription;
    }
  };

  const getContentLink = (contentItem) => {
    if (!contentItem) {
      return '';
    }

    if (Object.keys(DATA_TYPES_STRINGS).find(item => item === contentItem.contentType)) {
      return (
        <div>
          <InternalLink href={getReadingLink(contentItem)}>
            <svg width='24' height='24' fill='white'>
              <use xlinkHref='#Up-right' />
            </svg>
          </InternalLink>
        </div>
      );
    }

    if (contentItem.caseId) {
      return (
        <div>
          <InternalLink href={`/practice/${contentItem.caseId}/`}>
            {intl.formatMessage(messages.resumeCase)}
          </InternalLink>
        </div>
      );
    }

    if (contentItem.assignmentId) {
      return <a href={testLink}>{intl.formatMessage(messages.resumeTest)}</a>;
    }
  };

  const emptyWidgetContent = () => <div className='recent-activities-widget-empty'>
    <div className='recent-activities-widget-empty-image'>
      <svg width='72' height='72' viewBox='0 0 128 128'>
        <use xlinkHref='#blueRecentActivities' />
      </svg>
    </div>
    <div className='recent-activities-widget-empty-content'>
      <div className='recent-activities-widget-empty-content-title'>
        <h6>{noActivityFoundMessage}</h6>
      </div>
      <div className='recent-activities-widget-empty-content-description'>
        {emptyPromptRecentActivityMessage}
      </div>
    </div>
  </div>;

  const widgetContent = () => <div data-testid='RAWContent' className='recent-activities-widget__content'>
    {isContinueTesting && <div data-testid='activity' className='recent-activities-widget__content-activity'>
      <div className='recent-activities-widget__content-activity-container'>
        <div className='recent-activities-widget__content-activity-image'>
          <svg width='28' height='28'>
            <use xlinkHref='#Edit' />
          </svg>
        </div>
        <div className='recent-activities-widget__content-activity-text'>
          <h1>{intl.formatMessage(messages.continueTesting)}</h1>
          <p>{continueTestDescription}</p>
        </div>
      </div>
      <div className='recent-activities-widget__content-activity-button'>
        <a href={testLink}>{intl.formatMessage(messages.resume)}</a>
      </div>
    </div>}
    {isContinueReading && <div data-testid='activity' className='recent-activities-widget__content-activity'>
      <div className='recent-activities-widget__content-activity-container'>
        <div className='recent-activities-widget__content-activity-image'>
          <svg width='24' height='24'>
            <use xlinkHref='#Book' />
          </svg>
        </div>
        <div className='recent-activities-widget__content-activity-text'>
          <h1 className='recent-activities-widget__content-activity-continue-reading'>{intl.formatMessage(messages.continueReading)}</h1>
          <p>{intl.formatMessage(messages.section)}: {recentlyViewed?.sectionTitle}</p>
        </div>
      </div>
      <div className='recent-activities-widget__content-activity-button'>
        <div>
          <span className='u-ckm-visuallyhidden'>{intl.formatMessage(messages.continueReading)}</span>
          <InternalLink href={getReadingLink(recentlyViewed)}>
            <svg width='24' height='24' fill='white' aria-hidden='true'>
              <use xlinkHref='#Up-right' />
            </svg>
          </InternalLink>
        </div>
      </div>
    </div>}
    {isContinueWatching && <div data-testid='activity' className='recent-activities-widget__content-activity'>
      <div className='recent-activities-widget__content-activity-container'>
        <div className='recent-activities-widget__content-activity-image'>
          <svg width='24' height='24'>
            <use xlinkHref='#PlayVideo' />
          </svg>
        </div>
        <div className='recent-activities-widget__content-activity-text'>
          <h1 className='recent-activities-widget__content-activity-continue-reading'>{intl.formatMessage(messages.continueWatching)}</h1>
          <p>{intl.formatMessage(messages.video)}: {recentlyViewedVideo?.itemTitle}</p>
        </div>
      </div>
      <div className='recent-activities-widget__content-activity-button'>
        <div>
          <a href={watchLink}>
            <svg width='24' height='24' fill='white'>
              <use xlinkHref='#Up-right' />
            </svg>
          </a>
        </div>
      </div>
    </div>}
  </div>;

  const IAWidgetContent = () => <div data-testid='RAWContent' className='recent-activities-widget__content'>
    {[recentlyViewedBook, recentlyViewedJournalOrEMC, recentlyViewedVideo, incompleteTest, recentlyViewedCase].sort(widgetSortByDate).map(item => {
      return (
        item && !isEmpty(item) && <div data-testid='activity' className='recent-activities-widget__content-activity'>
          <div className='recent-activities-widget__content-activity-container'>
            <div className='recent-activities-widget__content-activity-image'>
              {getContentImage(item)}
            </div>
            <div className='recent-activities-widget__content-activity-text'>
              <h1 className='recent-activities-widget__content-activity-continue-reading'>
                <Flyout
                  trigger='hover'
                  placement='top'
                  theme='simple'
                  flyout={getContentTitle(item)}
                  shouldCloseOnESC
                  id='save-btn-flyout'
                >
                  {getContentTitle(item)}
                </Flyout>
              </h1>
              <p>{getContentSubTitle(item)}</p>
              <div className='recent-activities-widget__content-activity-button'>
                {getContentLink(item)}
              </div>
            </div>
          </div>
        </div>
  );
})}
  </div>;
  return (
    <div data-testid='recent-activities-widget' className={`recent-activities-widget  ${isIAHomepage ? 'recent-activities-widget--learning-hub' : ''}`}>
      <div className='recent-activities-widget-header'>

        <h3>{recentActivityMessage}</h3>
      </div>
      {isDisplayWidget ? isIAHomepage ? IAWidgetContent() : widgetContent() : emptyWidgetContent()}
    </div>
  );
};
