import React, {PureComponent} from 'react';
import {MainContext} from '../../context/main-context';

const { formatUriContext } = require('../../../server/utils/url-utils');
class InternalLink extends PureComponent {
  static contextType = MainContext;

  render () {
    const href = formatUriContext(this.props.href, this.context.productConfig.path);
    const newProps = {...this.props, href, ref: this.props.linkRef};
    delete newProps.linkRef;
    return (
      <a {...newProps}>{this.props.children}</a>
    );
  }
}

InternalLink.displayName = 'InternalLink';

export default InternalLink;
