const { getFacetName } = require('./queryStringUtils');
const { mapObjToArray } = require('./functionUtils');
const {forEachObjIndexed, map, contains, assoc, prop, propEq, merge, remove, pluck, filter} = require('ramda');

const toggleFacet = (facetValue, sectionKey, activeFacets) => {
  const section = prop(sectionKey, activeFacets);
  const facetIndex = section.indexOf(facetValue);

  if (facetIndex !== -1) {
    // facet already exists, remove it from the list
    return merge(activeFacets, {[sectionKey]: remove(facetIndex, 1, section)});
  }

  // facet doesn't exist, add it to the list
  return merge(activeFacets, {[sectionKey]: section ? section.concat(facetValue) : [facetValue]});
};

// For a given Facets object, extract an array of all active 'facetquery' values
const getActiveFacets = (facets) => {
  const activeFacets = {};

  forEachObjIndexed((facetList, section) => {
    const filteredList = filter(propEq('isActive', true), facetList);
    activeFacets[section] = pluck('facetquery', filteredList);
  }, facets);

  return activeFacets;
};

// used internally by setActiveFacets to add isActive class to facet elements in array
const setActiveFacetsInArray = (facetArray, activeFacets) => map((facet) => {
  return assoc('isActive', contains(facet.facetquery, activeFacets), facet);
}, facetArray);

// For a given Facets object, construct new Facets object with isActive flags set
const setActiveFacets = (activeFacets, facets) => map((facetArray) => setActiveFacetsInArray(facetArray, activeFacets), facets);

const createActiveFacetString = (activeFacets) => {
  let activeFacetArray = mapObjToArray((sectionName, facetList) => {
    if (facetList.length > 0) {
      const sectionFacets = facetList.map(getFacetName).join(',');
      return `${sectionName}: ${sectionFacets}`;
    } else { return null; }
  }, activeFacets);
  activeFacetArray = activeFacetArray.filter((item) => item !== null);
  return activeFacetArray.join(';');
};

// Return active tab or add filter facets to path
const getCurrentTabInFacets = (currentFacets, newFacets, switchTab) => {
  const regex = /!ct~[^+*]+/;
  if (currentFacets && newFacets) {
    if (currentFacets.includes('!ct') && !newFacets.includes('!ct')) {
      const tabFacet = currentFacets.match(regex);
      return `${tabFacet[0]}*${newFacets}`;
    }
    if (newFacets.includes('!ct')) {
      const newTabFacet = newFacets.match(regex);
      return newTabFacet ? newTabFacet[0] : newFacets;
    }
  } else if (!newFacets && !switchTab) {
    const tabFacet = currentFacets ? currentFacets.match(regex) : null;
    return tabFacet ? tabFacet[0] : null;
  }
  return newFacets;
};

module.exports = {
  toggleFacet,
  getActiveFacets,
  setActiveFacets,
  createActiveFacetString,
  getCurrentTabInFacets
};
