export default (localStorageName) => {
  if (!localStorageName) {
    // if no localStorage key is provided, return nothing
    return null;
  } else {
    // if localStorage key is provided, return:
    // localStorage item, item setter method, and item remove method
    const localStorageItem = JSON.parse(window.localStorage.getItem(localStorageName));

    const setLocalStorageItem = (value) => {
      const stringValue = typeof value === 'string' ? value : JSON.stringify(value);
      return window.localStorage.setItem(localStorageName, stringValue);
    };

    const removeLocalStorageItem = () => {
      return window.localStorage.removeItem(localStorageName);
    };

    return [localStorageItem, setLocalStorageItem, removeLocalStorageItem];
  }
};
