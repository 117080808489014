import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {getEMCThumbnailUrl, getThumbnailFromCoverImage} from '../../utils/stringUtils';
import { CONTENT_TYPE_NAMES, SOURCE_TYPES } from '../../constants';
import InternalContentLink from './InternalContentLink';
import { isArray } from 'lodash';

const classNames = {
  thumbnail: 'c-ckm-thumbnail',
  thumbnailImg: 'c-ckm-thumbnail__img'
};

const CoverImg = (props) => {
  const { issn, coverImage, getHeight, thumbnailAlt, contentType, hubeid, src } = props;
  const imgRef = useRef(null);

  const getImgSrc = () => {
    if (src && !isArray(src)) {
      return src;
    }
    if (coverImage || isArray(src)) {
      return getThumbnailFromCoverImage(coverImage);
    }
    return getEMCThumbnailUrl(hubeid, issn);
  };

  const getImgHeight = () => {
    if (getHeight && imgRef) {
      getHeight(imgRef);
    }
  };

  return (
    <InternalContentLink className={classNames.thumbnail} contentType={contentType} issn={issn} hubeid={hubeid}>
      <img
        className={classNames.thumbnailImg}
        ref={imgRef}
        src={getImgSrc()}
        alt={thumbnailAlt ?? ''}
        onLoad={getImgHeight}
      />
    </InternalContentLink>
  );
};

export default CoverImg;

CoverImg.propTypes = {
  hubeid: PropTypes.string.isRequired,
  issn: PropTypes.string,
  src: PropTypes.string,
  coverImage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  getHeight: PropTypes.func,
  thumbnailAlt: PropTypes.string,
  contentType: PropTypes.oneOf(Object.values({...CONTENT_TYPE_NAMES, ...SOURCE_TYPES}))
};
