import { formatDate } from './dateUtils';
import { find, propEq, isEmpty } from 'ramda';

const handleEditionsResponse = ({ data }, hubEid) => {
  const oldEdition = find(propEq('current', false), data) || {};
  const newEdition = find(propEq('current', true), data) || {};
  let isOld = false;

  if (!isEmpty(oldEdition)) {
    // format and add expiration
    if (oldEdition.expiration) {
      const expirationDate = formatDate(new Date(oldEdition.expiration));
      oldEdition.expirationDate = expirationDate;
    }
    // check if we're viewing old edition
    if (oldEdition.hubEid === hubEid) {
      isOld = true;
    }
  }

  return { oldEdition, newEdition, isOld};
};

export { handleEditionsResponse };
