import React from 'react';
import { Footer } from '@els/els-react--footer';
import { defineMessages } from 'react-intl';
import useIntl from '../../hooks/use-intl';

const messages = defineMessages({
  termsAndConditions: {
    id: 'Footer.TermsAndConditions',
    defaultMessage: 'Terms and Conditions'
  },
  privacyPolicy: {
    id: 'Footer.PrivacyPolicy',
    defaultMessage: 'Privacy Policy'
  },
  resourceCenter: {
    id: 'Footer.ResourceCenter',
    defaultMessage: 'Resource Center'
  },
  accessibility: {
    id: 'Footer.accessibility',
    defaultMessage: 'Accessibility'
  },
  cookieDescription: {
    id: 'Footer.cookieDescription',
    defaultMessage: 'We use cookies to help provide and enhance our service and tailor content.'
  },
  cookieLinkText: {
    id: 'Footer.cookieLinkText',
    defaultMessage: 'Cookie notice'
  },
  copyrightHolder: {
    id: 'Footer.copyrightHolder',
    defaultMessage: 'or its licensors and contributors. All rights are reserved, including those for text and data mining, AI training, and similar technologies.'
  },
  copyright: {
    id: 'Footer.copyright',
    defaultMessage: 'Copyright'
  }
});

export const resourceCenterLinks = {
  en: 'https://elsevierresources.com/ckmeded/',
  fr: 'https://elsevierresources.com/ckmeded/home/contenus-et-ressources-en-francais/',
  es: 'https://elsevierresources.com/ckmeded/home/contenido-y-recursos-en-espanol/',
  de: 'https://elsevierresources.com/ckmeded/home/inhalt-und-ressourcen-in-deutscher-sprache/'
};

const AppFooter = () => {
  const intl = useIntl();
  const locale = intl.locale || 'en';

  // let isTestCafeRunning = false;
  // if (window.hasOwnProperty('%hammerhead%')) {
  //   isTestCafeRunning = true;
  // }
  // const cookieConsentConfig = isTestCafeRunning ? {} : { id: '66f2a25b-12f9-4671-9626-9472217b7c3e', linkOnly: false };

  const getLink = () => {
    switch (locale) {
      case 'fr':
        return resourceCenterLinks.fr;
      case 'es':
        return resourceCenterLinks.es;
      case 'de':
        return resourceCenterLinks.de;
      default:
        return resourceCenterLinks.en;
    }
  };

  return (
    <>
      <Footer
        applicationLinks={[{linkText: intl.formatMessage(messages.resourceCenter), link: getLink()}]}
        legalEntity='Elsevier B.V.'
        messages={{
          ACCESSIBILITY_TEXT: intl.formatMessage(messages.accessibility),
          COOKIE_DESCRIPTION: intl.formatMessage(messages.cookieDescription),
          COOKIE_LINK_TEXT: intl.formatMessage(messages.cookieLinkText),
          COPYRIGHT_HOLDER: intl.formatMessage(messages.copyrightHolder),
          COPYRIGHT_LABEL: intl.formatMessage(messages.copyright),
          PRIVACY_POLICY_LINK_TEXT: intl.formatMessage(messages.privacyPolicy),
          TERM_AND_CONDITION_LINK_TEXT: intl.formatMessage(messages.termsAndConditions)
        }}
      />
    </>
  );
};

export default AppFooter;
