import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getImageThumbnailUrl} from '../../utils/stringUtils';
import Image from './Image';
import {defineMessages} from 'react-intl';

const messages = defineMessages({
  ImageResultThumbnailAltRef: {
    id: 'ImageResultThumbnail.message.AltReference',
    defaultMessage: 'Image from chapter "{chapter}" in "{source}"'
  },
  ImageResultThumbnailNoInfo: {
    id: 'ImageResultThumbnail.message.NoImageInfo',
    defaultMessage: 'Image details; no summary available'
  }
});
class ImageResultThumbnail extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    summary: PropTypes.string,
    refimage: PropTypes.array,
    reference: PropTypes.array,
    focusIndex: PropTypes.bool,
    displayLarge: PropTypes.bool,
    source: PropTypes.string,
    sourcechapter: PropTypes.string,
    hideSummary: PropTypes.bool
  };

  componentDidMount () {
    if (this.props.focusIndex) {
      this.thumbnail.focus();
    }
  }

  componentDidUpdate () {
    if (this.props.focusIndex) {
      this.thumbnail.focus();
    }
  }

  render () {
    const imgClass = this.props.displayLarge ? 'c-ckm-thumbnail__img--large' : 'c-ckm-thumbnail__img';
    const imgSrc = getImageThumbnailUrl(this.props.refimage);
    const altReference = this.props.sourcechapter && this.props.source
      ? this.props.intl.formatMessage(messages.ImageResultThumbnailAltRef, {chapter: this.props.sourcechapter, source: this.props.source})
      : this.props.intl.formatMessage(messages.ImageResultThumbnailNoInfo);
    return (
      <button
        className='c-ckm-thumbnail__button'
        onClick={this.props.onClick}
        ref={thumbnail => { this.thumbnail = thumbnail; }}
      >
        <div className='c-ckm-thumbnail__image-container'>
          <Image
            {...this.props}
            className={imgClass}
            emptyClassName={`${imgClass}_empty`}
            errorClassName={`${imgClass}_empty`}
            src={imgSrc}
            reference={this.props.reference}
            altReference={altReference}
          />
        </div>
      </button>
    );
  }
}

ImageResultThumbnail.displayName = 'ImageResultThumbnail';
export default ImageResultThumbnail;
