import React from 'react';
import PropTypes from 'prop-types';
import ANCIcon from './ANCIcon';
import {defineMessages} from 'react-intl';

const messages = defineMessages({
  ANCResultThumbnailAltRef: {
    id: 'ANCResultThumbnail.message.AltReference',
    defaultMessage: 'Open modal: {section} from {source}'
  }
});

const ANCIconButton = (props) => {
  return (
    <button
      onClick={props.showPopup}
      className='c-ckm-icon-anc__button'
      ref={props.itemRef}
      aria-label={props.intl.formatMessage(messages.ANCResultThumbnailAltRef, {section: props.itemtitle, source: props.sourcetitle})}
    >
      <ANCIcon {...props} />
    </button>
  );
};

export default ANCIconButton;

ANCIconButton.displayName = 'ANCIconButton';

ANCIconButton.propTypes = {
  type: PropTypes.array.isRequired,
  size: PropTypes.string,
  itemtitle: PropTypes.string.isRequired,
  sourcetitle: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  itemRef: PropTypes.object
};
