import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ResizeDetector from 'react-resize-detector';
import { Paginator } from '@els/els-react--paginator';
import { defineMessages} from 'react-intl';
import useIntl from '../../hooks/use-intl';

const PAGINATION_SIZES = {
  FULL: 'full',
  MEDIUM: 'medium',
  SMALL: 'small',
  MOBILE: 'mobile'
};

const messages = defineMessages({
  navLabelPrevious: {
    id: 'MediaPopoutModel.label.previous',
    defaultMessage: 'Previous'
  },
  navLabelNext: {
    id: 'MediaPopoutModel.label.next',
    defaultMessage: 'Next'
  }
});

const PaginationLeyden = (props) => {
  const intl = useIntl();
  const [paginationSize, setPaginationSize] = useState(props.paginationType ? props.paginationType : PAGINATION_SIZES.FULL);

  useEffect(() => {
    onResize();
  });

  const onResize = () => {
    if (props.paginationType) {
      return;
    }

    if ((window.innerWidth >= 1200)) {
      setPaginationSize(PAGINATION_SIZES.FULL);
    }
    if ((window.innerWidth < 1200 && window.innerWidth >= 900)) {
      setPaginationSize(PAGINATION_SIZES.MEDIUM);
    }
    if ((window.innerWidth < 900 && window.innerWidth >= 600)) {
      setPaginationSize(PAGINATION_SIZES.SMALL);
    }
    if ((window.innerWidth < 600)) {
      setPaginationSize(PAGINATION_SIZES.MOBILE);
    }
  };

  return (
    <>
      <Paginator
        a11y={{ name: props.name, pagingUnitName: 'Page', morePagingUnit: 'More pages'}}
        shouldShowFirstLastButton={paginationSize === PAGINATION_SIZES.FULL || paginationSize === PAGINATION_SIZES.MEDIUM}
        shouldShowPrevNextLabel={paginationSize === PAGINATION_SIZES.FULL}
        messages={{
          PREVIOUS_TEXT: intl.formatMessage(messages.navLabelPrevious),
          NEXT_TEXT: intl.formatMessage(messages.navLabelNext)
        }}
        isMobileView={paginationSize === PAGINATION_SIZES.MOBILE}
        currentPage={props.currentPage}
        onPageChange={props.onPageChange}
        resultsPerPage={props.resultsPerPage}
        totalResults={props.totalResults}
      />
      <ResizeDetector
        handleWidth
        onResize={onResize}
        refreshOptions={{ trailing: true }}
      />
    </>
  );
};

PaginationLeyden.displayName = 'PaginationLeyden';
export default PaginationLeyden;

PaginationLeyden.propTypes = {
  name: PropTypes.string.isRequired,
  totalResults: PropTypes.number.isRequired,
  resultsPerPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  paginationType: PropTypes.string
};
