import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import StableUniqueId from 'react-stable-uniqueid';
import { MainContext } from '../../context/main-context';

export default class FormSummary extends Component {
  static contextType = MainContext;

  handleErrorClick = (evt, message) => {
    const headerHeight = pathOr(0, ['context', 'headerRef', 'offsetHeight'], this);
    evt.preventDefault();
    // Leyden components are not yet focusable by ref so we have to check before focusing/scrolling
    message.ref.current.focus && message.ref.current.focus();
    message.ref.current.scrollIntoView && message.ref.current.scrollIntoView();
    if (window.scrollY || window.pageYOffset) { window.scroll(0, (window.scrollY || window.pageYOffset) - headerHeight - 35); }
  };

  titleSection = () => {
    return (
      <StableUniqueId
        prefix='c-ckm-field__summary-title-' render={({ uniqueId }) =>
          <p
            id={uniqueId}
            className={this.props.error ? 'c-ckm-field__error-title' : 'c-ckm-field__success-title'}
            tabIndex='-1'
            ref={this.props.titleRef}
          >
            {this.props.title}
          </p>}
      />
    );
  };

  render () {
    return (
      <>
        {this.props.error
          ? <div className='c-ckm-field__error-container'>
            {this.titleSection()}
            <ul className='c-ckm-field__error-list'>
              {this.props.formMessages.map((message, index) => {
                if (typeof message.message === 'function') {
                  return (
                    <li key={index} className='c-ckm-field'>
                      <div className='c-ckm-field__error-item'>{message.message()}</div>
                    </li>
                  );
                }
                return message.message.length > 0 && <li key={index} className='c-ckm-field'>
                  {message.ref
                    ? <button className='c-ckm-field__error-item c-ckm-link' onClick={(evt) => this.handleErrorClick(evt, message)}>{message.message}</button>
                    : <p className='c-ckm-field__error-item'>{message.message}</p>}
                </li>;
              })}
            </ul>
          </div>
          : <div className='c-ckm-field__success-container'>
            <span className='c-ckm-field__label'>
              {this.titleSection()}
            </span>
          </div>}
      </>
    );
  }
}

FormSummary.propTypes = {
  error: PropTypes.bool,
  formMessages: PropTypes.array,
  titleRef: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
};

FormSummary.displayName = 'FormSummary';
