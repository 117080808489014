import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Announcements extends Component {
  componentDidMount () {
    if (this.props.pageLoad) {
      // aria-live only monitors for changes in its container once page load is complete.
      // we need to trigger a change after page load
      setTimeout(() => {
        this.announcement.innerHTML = `${this.announcement.innerHTML}.`;
      }, 2000);
    }
  }

  render () {
    return (
      <div className='u-ckm-visuallyhidden' role='status' aria-live='polite' aria-atomic='true' ref={announcement => { this.announcement = announcement; }}>
        {this.props.message}
      </div>
    );
  }
}

Announcements.propTypes = {
  message: PropTypes.string.isRequired,
  pageLoad: PropTypes.bool.isRequired
};

Announcements.displayName = 'Announcements';

export default Announcements;
