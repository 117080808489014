import React from 'react';
import TruncatedText from './TruncatedText';
import { PropTypes } from 'prop-types';

const getAuthorsString = (authors) => {
  if (!authors || authors?.lenght === 0) {
    return '';
  }

  if (Array.isArray(authors)) {
    return authors.join('; ');
  }

  return authors.replace(/__\|__/g, '; ');
};

const AuthorListV2 = (props) => {
  const { authors, showLessText, showMoreText } = props;

  if (!authors || authors?.length === 0) {
    return null;
  }

  const authorsString = getAuthorsString(authors);

  return (
    <TruncatedText text={authorsString} showMoreText={showMoreText} showLessText={showLessText} />
  );
};

AuthorListV2.propTypes = {
  authors: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  showLessText: PropTypes.string,
  showMoreText: PropTypes.string
};

export default AuthorListV2;
