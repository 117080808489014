import React from 'react';
import {Icon} from '@els/els-react--icon';

const CasesSliderArrows = (props) => {
  return (
    <div className='glide__arrows' data-glide-el='controls'>
      <button className='glide__arrow glide__arrow--left' data-glide-dir='<' aria-label='previous' data-testid='glide__arrow--left'>
        <span aria-hidden role='img'>
          <Icon
            id='case_slider_left'
            className='c-ckm-practice__slider--chevron-icon'
            sprite={Icon.Sprites.CHEVRON_LEFT}
            color={Icon.Colors.default}
            size={props.iconSize || Icon.Sizes.XS}
            textAlignment='sub'
            isDecorative
          />
        </span>
      </button>
      <button className='glide__arrow glide__arrow--right' data-glide-dir='>' aria-label='next' data-testid='glide__arrow--right'>
        <span aria-hidden role='img'>
          <Icon
            id='case_slider_left'
            className='c-ckm-practice__slider--chevron-icon'
            sprite={Icon.Sprites.CHEVRON_RIGHT}
            color={Icon.Colors.default}
            size={props.iconSize || Icon.Sizes.XS}
            textAlignment='sub'
            isDecorative
          />
        </span>
      </button>
    </div>
  );
};

export default CasesSliderArrows;
