import React, { useState, useEffect, useContext} from 'react';
import {slice, filter, test} from 'ramda';
import CKApi from '../../services/api-helper';
import {FormattedMessage, defineMessages} from 'react-intl';
import InternalLink from '../../components/common/InternalLink';
import { Button } from '@els/els-react--button';
import InlineLoader from '../../components/common/InlineLoader';
import {isMobile, isXSmall} from '../../utils/elementUtils';
import {MainContext} from '../../context/main-context';
import useIntl from '../../hooks/use-intl';
import { ELSQuestionBankService, ELSUtilityHelper } from '@els/meded-ui-common-react';

const initialCount = isMobile() ? 4 : 6;

const messages = defineMessages({
  browseMore: {
    id: 'Home.button.browseMore',
    defaultMessage: 'Browse all books'
  },
  popularTitles: {
    id: 'Home.message.popularTitles',
    defaultMessage: 'Most popular titles'
  },
  showMoreBooks: {
    id: 'Home.button.showMoreBooks',
    defaultMessage: 'Show more'
  }
});

const isUSMLE = (banks) => {
  if (!banks) return false;

  return Boolean(banks.filter((bank) => ELSUtilityHelper.determineIfUSMLEQuestionBank(bank.isbn)).length);
};

const ShowcaseCatalog = (props) => {
  const { onLoadCallback } = props;
  const intl = useIntl();
  const { doRedirect } = useContext(MainContext);

  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [showcaseItems, setShowcaseItems] = useState([]);

  // Fetch showcase data from API when component first renders
  useEffect(() => {
    CKApi.get('/student/api/content/showcase')
      .then(({data}) => {
        setLoading(false);
        setShowcaseItems(data?.contents || []);
      })
      .catch(() => {
        setLoading(false);
        setHasError(true);
      });
  }, []);

  useEffect(() => {
    if (onLoadCallback) {
      ELSQuestionBankService.getQuestionBanks().then((res) => {
        onLoadCallback(isUSMLE(res?.data));
      }).catch(error => console.error(error));
    }
  }, []);

  const showcaseRenderItems = expanded ? showcaseItems : slice(0, initialCount, showcaseItems);
  const canExpand = showcaseItems.length > initialCount;

  const findLargestCoverImage = filter(test(/cov200h.gif/));

  return (
    <>
      {loading && !hasError && <InlineLoader />}
      {!loading && !hasError && <div className={isXSmall() ? 'c-ckm-showcase-catalog u-ckm-inner-container u-els-padding-1x' : 'c-ckm-showcase-catalog u-ckm-inner-container u-els-padding-2x'}>
        <h2 className='c-ckm-showcase-catalog__heading'>
          <FormattedMessage
            id='Home.message.popularTitles'
            defaultMessage='Most popular titles'
          />
        </h2>
        <ul className='c-ckm-showcase-catalog__grid'>
          {showcaseRenderItems.map((showcaseItem, index) => <li className='c-ckm-showcase-catalog__grid-item' key={index}>
            <InternalLink
              href={`/content/toc/${showcaseItem.hubEid}`} className='c-ckm-showcase-catalog__link'
              role='presentation'
            >
              <img src={findLargestCoverImage(showcaseItem.coverImages)} className='c-ckm-showcase-catalog__book-cover' alt={`Book cover: ${showcaseItem.sourceTitle}`} />
            </InternalLink>
            <div className='c-ckm-showcase-catalog__grid-label'>
              <div className='c-ckm-showcase-catalog__grid-label-text'>
                <InternalLink href={`/content/toc/${showcaseItem.hubEid}`} className='c-ckm-internal-link'>{showcaseItem.sourceTitle}</InternalLink>
              </div>
            </div>
          </li>)}
        </ul>
        {!expanded && canExpand &&
          <Button
            type={Button.Types.SECONDARY}
            className=''
            onClick={() => { setExpanded(true); }}
            aria-label={intl.formatMessage(messages.showMoreBooks)}
            title={intl.formatMessage(messages.showMoreBooks)}
          >
            {intl.formatMessage(messages.showMoreBooks)}
          </Button>}
        <div className='c-ckm-showcase-catalog__button-container'>
          <Button
            type={Button.Types.PRIMARY}
            className=''
            onClick={() => { doRedirect('/browse/books'); }}
            title={intl.formatMessage(messages.browseMore)}
            id='btn-browse-all-books'
          >
            {intl.formatMessage(messages.browseMore)}
          </Button>
        </div>
      </div>}
    </>
  );
};

ShowcaseCatalog.displayName = 'ShowcaseCatalog';

export default ShowcaseCatalog;
