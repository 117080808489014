const formatDate = (dateObj) => {
  const months = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'];
  const day = dateObj.getDate();
  const month = dateObj.getMonth();
  const year = dateObj.getFullYear();
  return `${months[month]} ${day}, ${year}`;
};

const getCurrentDate = () => {
  const date = new Date();
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  return date.toLocaleDateString('en-GB', options);
};

const formatDatePerformanceTable = (date) => {
  return new Date(date).toLocaleDateString([], { month: '2-digit', day: '2-digit', year: '2-digit'});
};

/**
 * Formats number of seconds to MM:SS (with optional leading zeros)
 * @param {number} seconds
 * @param {number?} [minutesPadding=1] length of minutes string, including leading zeros. Defaults to 1. See String.prototype.padStart()
 *
 * @returns {string} formatted time
 */
const formatSecondsToTimeString = (seconds, minutesPadding = 1) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const paddedMinutes = minutes.toString().padStart(minutesPadding, '0');
  const paddedSeconds = remainingSeconds.toString().padStart(2, '0');

  return `${paddedMinutes}:${paddedSeconds}`;
};

const countDurationFromDateStrings = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return '-';
  }

  const myDuration = new Date(endDate) - new Date(startDate);

  const hours = Math.floor((myDuration / 1000 / 60 / 60));
  const minutes = Math.floor((myDuration / 1000 / 60) % 60);
  const seconds = Math.floor((myDuration / 1000) % 60);

  return ([
    hours.toString().padStart(2, '0'),
    minutes.toString().padStart(2, '0'),
    seconds.toString().padStart(2, '0')
  ].join(':'));
};

const countDurationFromSeconds = (totalSeconds) => {
  if (!totalSeconds) {
    return '-';
  }

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  return ([
    hours.toString().padStart(2, '0'),
    minutes.toString().padStart(2, '0'),
    seconds.toString().padStart(2, '0')
  ].join(':'));
};

const countWeeksAmountBetweenDates = (startDate, endDate) => {
  const startOfWeek = new Date(startDate);
  startOfWeek.setHours(0, 0, 0, 0);
  startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
  const endOfWeek = new Date(endDate);
  endOfWeek.setHours(23, 59, 59, 999);
  endOfWeek.setDate(endOfWeek.getDate() + (6 - endOfWeek.getDay()));

  const timeDiff = endOfWeek - startOfWeek;

  const weeksBetween = Math.ceil(timeDiff / (7 * 24 * 60 * 60 * 1000));

  return weeksBetween;
};

const getPubDate = (date) => {
  const pubDate = new Date(Number(date));
  const options = {
    month: 'short',
    year: 'numeric',
    timeZone: 'UTC'
  };

  return pubDate.toLocaleDateString('en-US', options);
};

const getCertificateDate = () => {
  const pubDate = new Date();
  const options = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    timeZone: 'UTC'
  };

  return pubDate.toLocaleDateString('en-US', options);
};

const getSelfTestDate = ({date, locale}) => {
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit'
  };

  return date.toLocaleDateString(locale, options);
};

export {
  formatDate,
  getCurrentDate,
  formatSecondsToTimeString,
  countDurationFromDateStrings,
  formatDatePerformanceTable,
  countDurationFromSeconds,
  countWeeksAmountBetweenDates,
  getPubDate,
  getCertificateDate,
  getSelfTestDate
};
