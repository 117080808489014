import {test, find} from 'ramda';

const isJPGPath = test(/\.jpg/);

const extractJPGPath = find(isJPGPath);

// make image entitlement call here - will return image URL with auth token embedded
export const getEntitledImage = (eid, refimage) => {
  return extractJPGPath(refimage);
};
