import React, {createRef, Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';
import {defineMessages, FormattedMessage} from 'react-intl';
import DOMPurify from 'dompurify';
import classNames from 'classnames';
import {trackEvent} from '../../utils/eventTrackUtils';
import HTMLString from '../../components/common/HTMLString';
import useIntl from '../../hooks/use-intl';
import { isMobile } from '../../utils/elementUtils';

const messages = defineMessages({
  containsQuery: {
    id: 'BookReader.search.containsQuery',
    defaultMessage: 'contains {query}'
  }
});

const trackContentInteraction = (href, id) => {
  trackEvent('contentInteraction', {
    action: {
      name: `${href}#${id}`
    }
  });
};

const handleClick = (evt, id, cb) => {
  evt.preventDefault();
  trackContentInteraction(evt.target.href, id);
  if (cb) {
    cb();
  }
};

const currentSectionRef = createRef();

const Menu = function Menu ({items, showRefs, scrollFn, currentSectionID, highlightQuery, level, expandSection}) {
  const intl = useIntl();

  useEffect(() => {
    if (!isMobile() && currentSectionRef && currentSectionRef.current) currentSectionRef.current.scrollIntoView();
  }, [currentSectionID]);

  return (
    <>
      <ul className='c-ckm-toc-panel__list'>
        {items.length === 0 && <FormattedMessage id='BookReader.search.noResults' defaultMessage='No results' />}
        {items.map(section => {
          let {subtitle, sectionid, expanded, containsHighlight, children} = section;
          if (highlightQuery) {
            const subtitleRegex = new RegExp(highlightQuery, 'ig');
            if (subtitle.match(subtitleRegex)) {
              subtitle = subtitle.replace(
                subtitleRegex,
                match => `<span class="u-els-background-color-extended-yellow-6">${match}</span>`
              );
              subtitle = <HTMLString content={DOMPurify.sanitize(subtitle, {USE_PROFILES: {html: true}})} />;
            }
          }
          const listItemClass = classNames('c-ckm-toc-panel__list-item', {
            'u-els-display-flex': containsHighlight
          });
          return (
            <li key={sectionid} className={listItemClass}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              {containsHighlight &&
                <span
                  className={`c-ckm-toc-panel__occurrence-indicator c-ckm-toc-panel__occurrence-indicator--left-${level + 1}x`}
                  aria-label={intl.formatMessage(messages.containsQuery, {query: highlightQuery})}
                />}
              <a
                href={`#${sectionid}`}
                className={classNames('c-ckm-toc-panel__list-link', {'c-ckm-toc-panel__list-link--current': currentSectionID === sectionid})}
                onClick={(e) => handleClick(e, sectionid, () => { expandSection(sectionid); scrollFn(sectionid); })}
                aria-current={currentSectionID === sectionid ? 'location' : false}
                ref={currentSectionID === sectionid ? currentSectionRef : null}
              >
                {subtitle}
              </a>
              {children && children.length > 0 && (level > 0 || expanded) &&
                <Menu items={children} scrollFn={scrollFn} currentSectionID={currentSectionID} highlightQuery={highlightQuery} level={level + 1} expandSection={() => {}} />}
            </li>
          );
        })}
        {showRefs &&
          <li key='references' className='c-ckm-toc-panel__list-item'>
            <FormattedMessage
              id='BookReader.link.References'
              defaultMessage='References'
            >
              {(txt) => (
                <a className='c-ckm-toc-panel__list-link' href='#references' onClick={(e) => handleClick(e, 'references', () => { scrollFn('references'); })}>{txt}</a>
              )}
            </FormattedMessage>
          </li>}
      </ul>
    </>
  );
};

Menu.propTypes = {
  items: PropTypes.array.isRequired,
  scrollFn: PropTypes.func.isRequired,
  showRefs: PropTypes.bool,
  highlightQuery: PropTypes.string,
  level: PropTypes.number,
  expandSection: PropTypes.func
};
Menu.defaultProps = {
  level: 0
};

Menu.displayName = 'Recursive Menu';
export default Menu;
