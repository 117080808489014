import React from 'react';
import DOMPurify from 'dompurify';
import HTMLString from './HTMLString';

const SafeHTMLString = (props) => {
  const sanitizedContent = DOMPurify.sanitize(props.content, { USE_PROFILES: { html: true } });
  return (
    <HTMLString content={sanitizedContent} />
  );
};

export default SafeHTMLString;
