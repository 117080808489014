import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import Modal from './Modal';
import InternalLink from './InternalLink';

const messages = defineMessages({
  LoginNursingRedirectPt1: {
    id: 'LoginPage.error.nursingRedirectPt1',
    defaultMessage: 'If you are a nursing user, click'
  },
  LoginNursingRedirectPt2: {
    id: 'LoginPage.error.nursingRedirectPt2',
    defaultMessage: 'to be redirected to the nursing login page.'
  },
  LoginNoAccessNetwork: {
    id: 'LoginPage.error.noAccessNetwork',
    defaultMessage: 'If you use network based access, make sure you\'re on your network.'
  },
  LoginNoAccessRegID: {
    id: 'LoginPage.error.noAccessRegID',
    defaultMessage: 'If you use Registration IDs,'
  },
  LoginNoAccessInst: {
    id: 'LoginPage.error.noAccessInst',
    defaultMessage: 'If you use institutional login,'
  },
  LoginSharedCreds: {
    id: 'LoginPage.aria.sharedCreds',
    defaultMessage: 'Products with shared credentials'
  }
});
class AuthPopout extends Component {
  static propTypes = {
    handlePopoutClose: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    otherInstitutionLoginHref: PropTypes.string,
    intl: PropTypes.object.isRequired
  };

  sharedCredentials = () => {
    return (
      <>
        <h2 className='u-els-margin-bottom-1o2'>
          <FormattedMessage
            id='AuthPopout.heading.CKS'
            defaultMessage='ClinicalKey Student shares credentials with:'
          />
        </h2>
        <p className='u-els-font-size-h4 u-els-margin-bottom'>
          <FormattedMessage
            id='AuthPopout.subheading.CKS'
            defaultMessage='This list may change as we develop new products.'
          />
        </p>
        <ul className='c-ckm-modal__list c-els-list c-els-list--no-indent'>
          <li className='c-ckm-modal__list__item c-els-list__item'>ClinicalKey</li>
          <li className='c-ckm-modal__list__item c-els-list__item'>Elsevier eCommerce</li>
          <li className='c-ckm-modal__list__item c-els-list__item'>Elsevier Editorial System</li>
          <li className='c-ckm-modal__list__item c-els-list__item'>Elsevier Publishing Campus</li>
          <li className='c-ckm-modal__list__item c-els-list__item'>Embase</li>
          <li className='c-ckm-modal__list__item c-els-list__item'>Engineering Village</li>
          <li className='c-ckm-modal__list__item c-els-list__item'>Evise</li>
          <li className='c-ckm-modal__list__item c-els-list__item'>Geofacets</li>
          <li className='c-ckm-modal__list__item c-els-list__item'>Mendeley</li>
          <li className='c-ckm-modal__list__item c-els-list__item'>Mendeley Institutional Insights</li>
          <li className='c-ckm-modal__list__item c-els-list__item'>Pathway Studio</li>
          <li className='c-ckm-modal__list__item c-els-list__item'>PharmPendium</li>
          <li className='c-ckm-modal__list__item c-els-list__item'>Reaxys</li>
          <li className='c-ckm-modal__list__item c-els-list__item'>ScienceDirect</li>
          <li className='c-ckm-modal__list__item c-els-list__item'>SciVal Funding</li>
          <li className='c-ckm-modal__list__item c-els-list__item'>SciVerse Applications</li>
          <li className='c-ckm-modal__list__item c-els-list__item'>SciVerse Hub</li>
          <li className='c-ckm-modal__list__item c-els-list__item'>Scopus</li>
          <li className='c-ckm-modal__list__item c-els-list__item'>Sobotta</li>
        </ul>
      </>
    );
  };

  loginMethods = () => {
    return (
      <>
        <h2 className='u-els-margin-bottom-1o2'>
          <FormattedMessage
            id='AuthPopout.heading.loginMethods'
            defaultMessage='Troubleshooting Guide'
          />
        </h2>
        <ul className='c-els-list c-ckm-field__error-list'>
          <li className='c-els-list__item c-ckm-field__error-list-item'>
            {`${this.props.intl.formatMessage(messages.LoginNursingRedirectPt1)} `}
            <InternalLink href='/nursing/login'>
              <FormattedMessage
                id='LoginPage.link.nursingHere'
                defaultMessage='here'
              />
            </InternalLink>
            {` ${this.props.intl.formatMessage(messages.LoginNursingRedirectPt2)}`}
          </li>
          <li className='c-els-list__item c-ckm-field__error-list-item'>{this.props.intl.formatMessage(messages.LoginNoAccessNetwork)}</li>
          <li className='c-els-list__item c-ckm-field__error-list-item'>
            {`${this.props.intl.formatMessage(messages.LoginNoAccessRegID)} `}
            <InternalLink href='/register'>
              <FormattedMessage
                id='LoginPage.link.redeemNow'
                defaultMessage='redeem your Registration ID.'
              />
            </InternalLink>
          </li>
          <li className='c-els-list__item c-ckm-field__error-list-item'>
            {`${this.props.intl.formatMessage(messages.LoginNoAccessInst)} `}
            <InternalLink href={this.props.otherInstitutionLoginHref}>
              <FormattedMessage
                id='LoginPage.link.otherInstitution'
                defaultMessage='log in via your institution.'
              />
            </InternalLink>
          </li>
        </ul>
      </>
    );
  };

  render () {
    return (
      <Modal
        handleModalClose={this.props.handlePopoutClose}
        ariaTitleText={this.props.intl.formatMessage(messages.LoginSharedCreds)}
      >
        <div className=''>
          <button
            onClick={this.props.handlePopoutClose}
            className='c-ckm-modal__close c-ckm-icon--lg c-ckm-icon--cross-lg u-ckm-cursor-pointer'
            aria-label='Close modal'
          />
          {this.props.type === 'credentials' && this.sharedCredentials()}
          {this.props.type === 'loginMethods' && this.loginMethods()}
        </div>
      </Modal>
    );
  }
}

AuthPopout.displayName = 'AuthPopout';
export default AuthPopout;
