import { useEffect } from 'react';
import { IMAGE_VIEWS } from '../constants';

const useShowModelRef = (currentView, currentScreenId, args) => {
  const {showModelRef, getCanvasParentId, onDisplay, onPause, onInlineModelError, showLabels } = args;

  useEffect(() => {
    if (currentView === IMAGE_VIEWS.MODEL && currentScreenId) {
      showModelRef(getCanvasParentId(), currentScreenId, onDisplay, onPause, onInlineModelError, showLabels);
    }
  }, [currentView, currentScreenId]);
};

export default useShowModelRef;
