import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import {MainContext} from '../../../context/main-context';
import { Button } from '@els/els-react--button';
import { Icon } from '@els/els-react--icon';

const messages = defineMessages({
  label: {
    id: 'Toolbar.addToPresentation.label',
    defaultMessage: 'Add to presentation'
  }
});

const PresentationButton = (props) => {
  const { intl } = useContext(MainContext);

  return (
    <Button
      className=''
      onClick={props.clickHandler}
      id='btn-add-to-presentation'
      type={Button.Types.LINK}
      linkOptions={props.linkOptions}
    >
      <Icon a11y={{ name: intl.formatMessage(messages.label) }} sprite={Icon.Sprites.PPT2} id='addToPresentation' className='u-els-margin-right-1o2' textAlignment='bottom' size={Icon.Sizes.XS} isVisible isTextFirst />
      <span>{intl.formatMessage(messages.label)}</span>
    </Button>
  );
};

PresentationButton.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  linkOptions: PropTypes.array
};

PresentationButton.displayName = 'PresentationButton';

export default PresentationButton;
