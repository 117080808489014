import React, {useContext} from 'react';
import {defineMessages} from 'react-intl';
import { MainContext } from '../../context/main-context';

const messages = defineMessages({
  StudyToolsDownload: {
    id: 'StudyTools.links.downloading',
    defaultMessage: 'Downloading...'
  }
});

const SpinnerLoader = (props) => {
  const {intl} = useContext(MainContext);

  return (
    <button className={`button c-els-button c-els-button--small c-ckm-study-tools__disabled-loader c-els-button--icon c-els-button--secondary ${props.className}`} disabled='true'>
      <svg className='c-ckm-study-tools__loader' />
      {intl.formatMessage(messages.StudyToolsDownload)}
    </button>
  );
};

export default SpinnerLoader;
