import React, {useContext} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import InternalLink from '../../components/common/InternalLink';
import { MainContext } from '../../context/main-context';

const messages = defineMessages({
  NursingRedirectPt1: {
    id: 'NoContentError.error.nursingRedirectPt1',
    defaultMessage: 'If you are a nursing user, click'
  },
  NursingRedirectPt2: {
    id: 'NoContentError.error.nursingRedirectPt2',
    defaultMessage: 'to be redirected to the nursing site.'
  },
  NoAccessNetworkPt1: {
    id: 'NoContentError.error.noAccessNetworkPt1',
    defaultMessage: 'If you use network based access, make sure you\'re on your network, and'
  },
  NoAccessNetworkPt2: {
    id: 'NoContentError.error.noAccessNetworkPt2',
    defaultMessage: 'again.'
  },
  NoAccessRegID: {
    id: 'NoContentError.error.noAccessRegID',
    defaultMessage: 'If you use Registration IDs,'
  },
  NoAccessInst: {
    id: 'NoContentError.error.noAccessInst',
    defaultMessage: 'If you use institutional login,'
  },
  SharedCreds: {
    id: 'NoContentError.aria.sharedCreds',
    defaultMessage: 'Products with shared credentials'
  }
});

const NoContentError = (props) => {
  const {intl} = useContext(MainContext);

  return (
    <div className='c-ckm-background-auth'>
      <div className='u-ckm-inner-container'>
        <div className='c-ckm-auth'>
          <div className='c-ckm-auth__panel'>
            <article className=''>
              <h1 className='c-ckm-auth__heading'>
                <FormattedMessage
                  id='NoContentError.heading.accessError'
                  defaultMessage='Access error'
                />
              </h1>
              <p>
                <FormattedMessage
                  id='NoContentError.description.noAccess'
                  defaultMessage='We do not recognize that you have access to ClinicalKey Student.'
                />
              </p>

              <h2 className='u-els-margin-bottom-1o2'>
                <FormattedMessage
                  id='NoContentError.heading.troubleshoot'
                  defaultMessage='Troubleshooting guide'
                />
              </h2>
              <ul className='c-els-list c-ckm-field__error-list'>
                <li className='c-els-list__item c-ckm-field__error-list-item'>
                  {`${intl.formatMessage(messages.NursingRedirectPt1)} `}
                  <InternalLink href='/nursing'>
                    <FormattedMessage
                      id='NoContentError.link.nursingHere'
                      defaultMessage='here'
                    />
                  </InternalLink>
                  {` ${intl.formatMessage(messages.NursingRedirectPt2)}`}
                </li>
                <li className='c-els-list__item c-ckm-field__error-list-item'>
                  {`${intl.formatMessage(messages.NoAccessNetworkPt1)} `}
                  <InternalLink href='/logout'>
                    <FormattedMessage
                      id='NoContentError.link.logIn'
                      defaultMessage='log in'
                    />
                  </InternalLink>
                  {` ${intl.formatMessage(messages.NoAccessNetworkPt2)}`}
                </li>
                <li className='c-els-list__item c-ckm-field__error-list-item'>
                  {`${intl.formatMessage(messages.NoAccessRegID)} `}
                  <InternalLink href='/logout?target=%2Fregister'>
                    <FormattedMessage
                      id='NoContentError.link.redeemNow'
                      defaultMessage='redeem your Registration ID.'
                    />
                  </InternalLink>
                </li>
                <li className='c-els-list__item c-ckm-field__error-list-item'>
                  {`${intl.formatMessage(messages.NoAccessInst)} `}
                  <InternalLink href='/institution-login'>
                    <FormattedMessage
                      id='NoContentError.link.otherInstitution'
                      defaultMessage='log in via your institution.'
                    />
                  </InternalLink>
                </li>
              </ul>
            </article>
          </div>

        </div>
      </div>

    </div>
  );
};

NoContentError.displayName = 'NoContentError';

export default NoContentError;
