import React, { useCallback, useState, useRef } from 'react';
import { PropTypes } from 'prop-types';
import ResizeDetector from 'react-resize-detector';
import classNames from 'classnames';
import { useIntl } from '../../hooks';

const CONTAINER_HEIGHT = 25;

const messages = {
  showMoreButtonText: {
    id: 'TruncatedText.showMoreButtonText',
    defaultMessage: 'Show all'
  },
  showLessButtonText: {
    id: 'TruncatedText.showLessButtonText',
    defaultMessage: 'Show less'
  }
};

const componentClassNames = {
  truncatedTextContent: 'truncated-text__content'
};

const TruncatedText = (props) => {
  const {text, showMoreText, showLessText} = props;
  const [isTruncated, setIsTruncated] = useState(true);
  const [isOverflow, setIsOverflow] = useState(false);
  const componentRef = useRef(null);
  const truncatedTextClass = classNames(
    'truncated-text',
    { 'truncated-text__truncated': isTruncated }
  );
  const truncatedTextButtonClass = classNames(
    'truncated-text__button',
    { 'truncated-text__button_more': isOverflow && isTruncated }
  );
  const intl = useIntl();

  const handleOnButtonClick = useCallback(() => {
    setIsTruncated(!isTruncated);
  });

  const handleResize = () => {
    if (componentRef.current) {
      const contentHeight = componentRef.current.scrollHeight;

      setIsOverflow(contentHeight > CONTAINER_HEIGHT);
    }
  };

  const getButtonText = () => {
    if (isTruncated) {
      return showMoreText || intl.formatMessage(messages.showMoreButtonText);
    }

    return showLessText || intl.formatMessage(messages.showLessButtonText);
  };

  return (
    <ResizeDetector
      handleWidth
      onResize={handleResize}
      refreshMode='debounce'
      refreshRate={100}
      refreshOptions={{trailing: true}}
      render={() =>
        <div className={truncatedTextClass} ref={componentRef}>
          <div className={componentClassNames.truncatedTextContent}>{text}</div>
          {isOverflow &&
            <button className={truncatedTextButtonClass} onClick={handleOnButtonClick}>
              {getButtonText()}
            </button>}
        </div>}
    />
  );
};

TruncatedText.propTypes = {
  text: PropTypes.string.isRequired,
  showMoreText: PropTypes.string,
  showLessText: PropTypes.string
};

export default TruncatedText;
