import React, {Component} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export default class AccordionSection extends Component {
  state = {
    isOpen: this.props.open || false
  };

  static propTypes = {
    label: PropTypes.string.isRequired,
    open: PropTypes.bool,
    children: PropTypes.node,
    onClick: PropTypes.func
  };

  toggleOpen = (evt) => {
    evt.preventDefault();
    this.props.onClick();
  };

  getSectionClass = () => classNames(
    'c-ckm-accordion__section',
    {'js-open': this.props.open}
  );

  render () {
    const sectionClass = this.getSectionClass();
    return (
      <section className={sectionClass}>
        <button className='c-ckm-accordion__trigger' aria-expanded={this.props.open} onClick={this.toggleOpen}>
          <span className='c-ckm-accordion__trigger-label'>{this.props.label}</span>
          <span className='c-ckm-accordion__trigger-icon c-ckm-icon c-ckm-icon--arrow-right-blue' />
        </button>
        <div className='c-ckm-accordion__content'>
          {this.props.children}
        </div>
      </section>
    );
  }
}
