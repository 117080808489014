import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { MainContext } from '../../context/main-context';

const FeatureOff = (props) => {
  const { featureFlags } = useContext(MainContext);
  let showFeature = false;
  if (featureFlags && featureFlags.hasOwnProperty(props.featureName)) {
    showFeature = featureFlags[props.featureName];
  }
  return (
    <>
      {!showFeature && props.children}
    </>
  );
};

FeatureOff.propTypes = {
  featureName: PropTypes.string,
  children: PropTypes.node
};

FeatureOff.displayName = 'FeatureOff';

export default FeatureOff;
