import CKApi from './api-helper';

export const redeemAndSSO = (hubEid) => {
  return CKApi.post('/student/api/vst/redeem', { hubEid });
};

export const disableVSTPopup = () => {
  return CKApi.get('/student/api/vst/disablepopup');
};

export const getMetadata = (hubEid) => {
  return CKApi.get('/student/api/vst/metadata', { params: { hubEid } });
};
