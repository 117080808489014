import {createContext} from 'react';

export const MainContext = createContext({
  user: {},
  showPathChoice: () => {},
  activeIndex: 'GLOBAL',
  localeConfig: {},
  headerRef: {},
  footerRef: {},
  productConfig: {},
  featureFlags: {},
  regionFlags: {},
  intl: {},
  doRedirect: () => {},
  shibbolethBaseUrl: '',
  userSettings: {},
  showYearOfStudy: true,
  isEmbedded: false,
  courseId: false,
  isSelfStudyUser: false,
  handleCourseChangeById: () => {}
});
