export const availableQuestionBanksOpt = (questionBanks = [], isInstructor = false) => {
  // hide USMLE Step 1 Faculty Sequestered Questions bank from student
  const facultySequesteredQuestionsName = 'USMLE Step 1 Faculty Sequestered Questions';

  if (!isInstructor) {
    return questionBanks.filter(item => item.name !== facultySequesteredQuestionsName);
  }

  return questionBanks;
};
