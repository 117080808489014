import React from 'react';
import PropTypes from 'prop-types';
import AuthorListV2 from './AuthorListV2';
import {FormattedMessage, FormattedDate} from 'react-intl';

const FoundAt = (props) => (<span>Pages {props.text}. </span>);

const VolumeIssue = (props) => (<span>{props.text}. </span>);

const Copyright = (props) => (<span>&copy; {props.text}. </span>);

const PubDate = (props) => {
  const date = new Date(parseInt(props.text));
  const month = date.getUTCMonth();
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  const formattedDate = () => {
    return (
      <FormattedDate
        value={new Date(year, month, day)}
        localeMatcher='best fit'
        year='numeric'
        month='long'
        day='numeric'
      />
    );
  };

  return (
    <span>
      <FormattedMessage
        id='CardSource.content.publishDate'
        defaultMessage='Published {date}'
        values={{date: formattedDate()}}
      />
      {'. '}
    </span>
  );
};

const CardSource = (props) => {
  return (
    <p className='c-ckm-search-results__content-source'>
      {props.authorlist && <AuthorListV2 authors={props.authorlist} />}
      {props.pubdate && <PubDate text={props.pubdate} />}
      {props.volumeissue && <VolumeIssue text={props.volumeissue} />}
      {props.pagerange && <FoundAt text={props.pagerange} />}
      {props.copyrightyear && <Copyright text={props.copyrightyear} />}
    </p>
  );
};

CardSource.propTypes = {
  authorlist: PropTypes.array,
  pubdate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  volumeissue: PropTypes.string,
  pagerange: PropTypes.string,
  copyrightyear: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ])
};

CardSource.displayName = 'CardSource';
export default CardSource;
