import { DEFAULT_COLLECTION_NAME } from '../constants';

export const returnDefaultCollectionId = (collections, user) => {
  if (!collections || !user) return null;

  return collections.find(collection => collection.userId === parseInt(user.user_id))?.id;
};

export const transformId = (arr) => {
  const transformedCollections = arr.map(collection => ({
    ...collection,
    id: parseInt(collection.id)
  }));

  return transformedCollections;
};

export const isDefaultCollection = (collection) => {
  return collection.id === '1' || collection.name === DEFAULT_COLLECTION_NAME;
};

export const isEditable = (user, collection) => {
  if (!user || !collection) return false;

  return parseInt(collection.userId) === parseInt(user.user_id);
};

export const isCollectionFullyEntitled = (user, collection) => {
  if (isEditable(user, collection)) {
    return true;
  } else if (!collection) {
    return true;
  }
  const notEntitledItem = collection.contents.find(item => !item.isEntitled);
  if (notEntitledItem) {
    return false;
  } else {
    return true;
  }
};

export const getCollectionItemById = (collections, collectionId, itemId) => {
  const collection = getCollectionById(collections, collectionId);

  return collection?.contents?.find(item => item.id === itemId);
};

export const getCollectionById = (collections, id) => {
  if (!collections || collections.length === 0 || !id) return;

  return collections.find(collection => collection.id === id);
};

export const getInitialCollectionId = (collections, selectedCollectionId) => {
  if (!collections) {
    return null;
  }

  if (selectedCollectionId) {
    return parseInt(selectedCollectionId);
  }

  if (collections && collections.length > 0) {
    return collections[0].id;
  }

  return null;
};
