import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import { Banner } from '@els/els-react--banner';
import { Icon } from '@els/els-react--icon';
import InternalLink from '../common/InternalLink';

const messages = defineMessages({
  editionAvailable: {
    id: 'EditionNotification.editionAvailable',
    defaultMessage: 'New edition of this book is available.'
  },
  viewNow: {
    id: 'EditionNotification.viewNow',
    defaultMessage: 'View now'
  },
  ButtonClose: {
    id: 'FilterSection.button.close',
    defaultMessage: 'Close'
  }
});

const EditionNotificationBanner = (props) => {
  const newEditionLink = (hubEid, intl) => {
    return (
      <InternalLink
        href={`/content/toc/${hubEid}`}
      >
        {intl.formatMessage(messages.viewNow)}
        <Icon
          sprite='UpRight'
          id='icon-up-right'
          isVisible
          a11y={{ name: 'link', description: 'link to new edition'}}
        />
      </InternalLink>
    );
  };
  return (
    <div className='c-ckm-browse-list__banner'>
      <Banner
        link={newEditionLink(props.newEdition.hubEid, props.intl)}
        isVisible
        onClose={props.onClose}
      >
        {props.intl.formatMessage(messages.editionAvailable)}
      </Banner>
    </div>
  );
};

EditionNotificationBanner.propTypes = {
  intl: PropTypes.object.isRequired,
  isOld: PropTypes.bool.isRequired,
  oldEdition: PropTypes.object.isRequired,
  newEdition: PropTypes.object.isRequired
};

EditionNotificationBanner.displayName = 'EditionNotificationBanner';
export default EditionNotificationBanner;
