import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useIntl from '../../hooks/use-intl';
import { defineMessages} from 'react-intl';
import { Button } from '@els/els-react--button';
import { Icon } from '@els/els-react--icon';
import { Flyout } from '@els/els-react--flyout';
import CKApi from '../../services/api-helper';
import { Loader } from '@els/els-react--loader';
import { MainContext } from '../../context/main-context';
import '../../assets/img/icon_slides.svg';
import CheckboxPresentationPanel from './CheckboxPresentationPanel';
import MediaPopoutCreatePresentation from './MediaPopoutCreatePresentation';

const messages = defineMessages({
  title: {
    id: 'MediaPopout.presentationPanel.title',
    defaultMessage: 'Add to presentation'
  },
  emptyLabel: {
    id: 'MediaPopout.presentationPanel.emptyLabel',
    defaultMessage: 'No presentations found.'
  },
  presentationsLabel: {
    id: 'MediaPopout.presentationPanel.presentationsLabel',
    defaultMessage: 'Your presentations'
  },
  newLabel: {
    id: 'MediaPopout.presentationPanel.newLabel',
    defaultMessage: 'Create new presentation'
  },
  infoMessage: {
    id: 'MediaPopout.presentationPanel.infoMessage',
    defaultMessage: 'Add this image and its citation to a presentation you create in ClinicalKey Student. Download in PPT format.'
  },
  infoButtonLabel: {
    id: 'MediaPopout.presentationPanel.infoButtonLabel',
    defaultMessage: 'Learn about presentation'
  },
  oneSlide: {
    id: 'MediaPopout.presentationPanel.list.slide',
    defaultMessage: 'Slide'
  },
  multipleSlides: {
    id: 'MediaPopout.presentationPanel.list.slides',
    defaultMessage: 'Slides'
  },
  empty: {
    id: 'MediaPopout.presentationPanel.list.empty',
    defaultMessage: 'Empty'
  }
});

const InformationFlyOutContent = () => {
  const intl = useIntl();
  const context = useContext(MainContext);

  return (
    <>
      {intl.formatMessage(messages.infoMessage)} <br />
      <Button
        onClick={() => { context.doRedirect('/presentations', '_blank'); }}
        type={Button.Types.LINK}
        id='info-flyout-btn'
      >
        {intl.formatMessage(messages.infoButtonLabel)}
        <Icon
          a11y={{ name: intl.formatMessage(messages.infoMessage) }}
          sprite={Icon.Sprites.UP_RIGHT}
          textAlignment='bottom'
          size={Icon.Sizes.XS}
          isVisible
          id='info-flyout-btn-icon'
          className='u-els-margin-left-1o2'
        />
      </Button>
    </>
  );
};

const MediaPopoutPresentationPanel = (props) => {
  const intl = useIntl();
  const [isLoadingPresentations, setIsLoadingPresentations] = useState(true);
  const [presentations, setPresentations] = useState([]);
  const [isCreatingNewPresentation, setIsCreatingNewPresentation] = useState(false);

  const flyoutContent = (
    <InformationFlyOutContent />
  );

  const getPresentations = () => {
    setIsLoadingPresentations(true);
    CKApi.get(`/student/api/presentations/containing_eid/${props.eid}`)
      .then(({data}) => {
        setPresentations(data.results.presentations ?? []);
        setIsLoadingPresentations(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleCreateNewPresentation = () => {
    setIsCreatingNewPresentation(true);
  };

  const handleCreateNewPresentationCancel = () => {
    setIsCreatingNewPresentation(false);
  };

  const handleCreateNewPresentationSave = () => {
    setIsCreatingNewPresentation(false);
    getPresentations();
  };

  useEffect(() => {
    getPresentations();
  }, [props.resultPosition]);

  return (
    <div className='c-ckm-modal__presentationPanel o-els-flex-layout__item'>
      <div className='c-ckm-modal__presentationPanel-content'>
        <div className='c-ckm-modal__presentationPanel-header u-els-margin-bottom o-els-flex-layout'>
          <div className='o-els-flex-layout o-els-flex-layout__item o-els-flex-layout__item--grow u-els-margin-right-1o2'>
            <h3 className='o-els-flex-layout__item'>{intl.formatMessage(messages.title)}</h3>
            <div className='o-els-flex-layout__item o-els-flex-layout__item--grow u-els-margin-left-1o2 u-els-margin-top-1o4'>
              <Flyout
                shouldCloseOnESC
                placement='bottom-end'
                theme='HMDS'
                pointerType='primary'
                flyout={flyoutContent}
                id='presentation=panel-info-flyout'
                modifiers={{flip: {enabled: true}, preventOverflow: {enabled: true}}}
              >
                <Button
                  onClick={() => { }}
                  type={Button.Types.LINK}
                  id='info-btn'
                >
                  <Icon
                    a11y={{ name: intl.formatMessage(messages.infoMessage) }}
                    sprite={Icon.Sprites.INFORMATION_SOLID_CIRCLE}
                    textAlignment='bottom'
                    size={Icon.Sizes.XS}
                    isVisible
                    id='info-btn-icon'
                  />
                </Button>
              </Flyout>
            </div>
          </div>
          <Button onClick={props.handleClose} type={Button.Types.LINK} className='o-els-flex-layout__item u-els-margin-top-1o4'>
            <Icon a11y={{ name: 'Close' }} sprite={Icon.Sprites.CLOSE} id='' size={Icon.Sizes.XS} isVisible />
          </Button>
        </div>

        <div>

          {!isLoadingPresentations && presentations.length === 0 && <p className='u-els-margin-bottom'>{intl.formatMessage(messages.emptyLabel)}</p>}

          {isCreatingNewPresentation
          ? <MediaPopoutCreatePresentation handleCancel={handleCreateNewPresentationCancel} handleSave={handleCreateNewPresentationSave} eid={props.eid} />
          : <Button
              onClick={handleCreateNewPresentation}
              type={Button.Types.LINK}
              id='create-new-btn'
              className='c-ckm-modal__presentationPanel--createNewButton o-els-flex-layout'
            >
            <Icon
              a11y={{ name: intl.formatMessage(messages.newLabel) }}
              sprite={Icon.Sprites.PLUS}
              textAlignment='bottom'
              size={Icon.Sizes.S}
              isVisible
              id='create-new-btn-icon'
            />
            <div className='o-els-flex-layout--item u-els-margin-left-1o2 u-els-line-height-heading'>{intl.formatMessage(messages.newLabel)}</div>
          </Button>}

          {isLoadingPresentations && <Loader isBlocking={false} />}

          {(!isLoadingPresentations && Boolean(presentations.length)) &&
            <div data-testid='presentations' className='c-ckm-presentationList'>
              {presentations.map((presentation, index) => {
                return (
                  <CheckboxPresentationPanel
                    presentation={presentation}
                    eid={props.eid}
                    loadPresentationsRef={getPresentations}
                    key={index}
                  />
                );
              })}
            </div>}
        </div>
      </div>
    </div>
  );
};

MediaPopoutPresentationPanel.displayName = 'MediaPopoutPresentationPanel';
export default MediaPopoutPresentationPanel;

MediaPopoutPresentationPanel.propTypes = {
  handleClose: PropTypes.func.isRequired,
  eid: PropTypes.string.isRequired
};
