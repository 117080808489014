import React, { useContext } from 'react';
import { Icon } from '@els/els-react--icon';
import { defineMessages } from 'react-intl';
import { MainContext } from '../../context/main-context';

const messages = defineMessages({
  clearButtonPlaceholder: {
    id: 'Select.placeholder.clear',
    defaultMessage: 'Clear all'
  }
});

const ResetButton = ({ disabled, onClick }) => {
  const { intl } = useContext(MainContext);
  return (
    <button className={disabled ? 'c-ckm-reset__wrapper' : 'c-ckm-reset__wrapper-disabled'} disabled={!disabled} onClick={(e) => onClick(e)} data-testid='reset-btn' aria-disabled={!disabled}>
      <Icon
        a11y={{ name: 'Clear all filters' }}
        sprite={Icon.Sprites.CLOSE}
        id='clear-filters-icon'
        size={Icon.Sizes.XS}
        textAlignment='middle'
        isVisible
        isTextFirst
        className='c-ckm-reset__icon'
      />
      <div className='pt'>{intl.formatMessage(messages.clearButtonPlaceholder)}</div>
    </button>
  );
};

export default ResetButton;
