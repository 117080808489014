import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getVideoThumbnailUrl } from '../../utils/stringUtils';
import Image from './Image';
import InternalLink from './InternalLink';
import { defineMessages } from 'react-intl';
import classNames from 'classnames';

const messages = defineMessages({
  VideoResultThumbnailAltRef: {
    id: 'VideoResultThumbnail.message.AltReference',
    defaultMessage: 'Play video from chapter "{chapter}" in "{source}"'
  },
  VideoResultThumbnailNoInfo: {
    id: 'VideoResultThumbnail.message.NoImageInfo',
    defaultMessage: 'Play video; no summary available'
  },
  VideoResultThumbnailImageAltPrefix: {
    id: 'VideoResultThumbnail.message.videoDetails',
    defaultMessage: 'Video Details'
  }
});

class VideoResultThumbnail extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    summary: PropTypes.string,
    refimage: PropTypes.array,
    focusIndex: PropTypes.bool,
    source: PropTypes.string,
    sourcechapter: PropTypes.string,
    hideSummary: PropTypes.bool,
    className: PropTypes.string
  };

  componentDidMount () {
    if (this.props.focusIndex) {
      this.thumbnail.focus();
    }
  }

  componentDidUpdate () {
    if (this.props.focusIndex) {
      this.thumbnail.focus();
    }
  }

  render () {
    const imgSrc = getVideoThumbnailUrl(this.props.refimage);
    const altReference = this.props.sourcechapter && this.props.source
      ? this.props.intl.formatMessage(messages.VideoResultThumbnailAltRef, { chapter: this.props.sourcechapter, source: this.props.source })
      : this.props.intl.formatMessage(messages.VideoResultThumbnailNoInfo);

    const reference = `${this.props.intl.formatMessage(messages.VideoResultThumbnailImageAltPrefix)}: ${this.props.summary || altReference}`;
    const playIconClass = classNames(
      'c-ckm-thumbnail__play-icon',
      'c-ckm-icon--lg',
      'c-ckm-icon--play-video-lg',
      { 'list-view': this.props.isList }
    );
    return (
      <InternalLink
        href={`/content/video/${this.props.eid}`}
        className='c-ckm-thumbnail__button'
        onClick={this.props.onClick}
        ref={thumbnail => { this.thumbnail = thumbnail; }}
      >
        <div className={`c-ckm-thumbnail ${this.props.className}`}>
          <Image
            {...this.props}
            className='c-ckm-thumbnail__img--large'
            emptyClassName='c-ckm-thumbnail__img--large_empty'
            errorClassName='c-ckm-thumbnail__img--large_empty'
            src={imgSrc}
            reference={reference}
            isModalTrigger={false}
          />
          <div className='c-ckm-thumbnail__play-button'>
            <span className={playIconClass}>&nbsp;</span>
          </div>
        </div>
      </InternalLink>
    );
  }
}

VideoResultThumbnail.displayName = 'VideoResultThumbnail';
export default VideoResultThumbnail;
