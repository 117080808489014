import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import StableUniqueId from 'react-stable-uniqueid';

const FormCheckboxSingle = (props) => {
  const checkboxClasses = classnames({
    'c-ckm-field__checkbox': true,
    'js-checked': props.checked
  });
  const requiredIsValid = props.requiredIsValid(props.name, props.checked);

  const showError = (uniqueId) => {
    if (!requiredIsValid && props.showValidationErrors) {
      return <span id={`${uniqueId}-required`} className='c-ckm-field__message c-ckm-field--error'>{props.requiredErrorMessage}</span>;
    } else return null;
  };

  const ariaDescribedBy = (uniqueId) => {
    return !requiredIsValid && props.showValidationErrors
      ? `${uniqueId}-required`
      : props.instructionsID
        ? props.instructionsID
        : null;
  };

  return (
    <StableUniqueId
      prefix='c-ckm-field__input-' render={({ uniqueId }) =>
        <div className='c-ckm-field__item'>
          <div className='c-ckm-field__checkbox-container'>
            <input
              id={uniqueId}
              type='checkbox'
              className={checkboxClasses}
              name={props.name}
              checked={props.checked}
              required={props.isRequired}
              aria-invalid={props.showValidationErrors && !requiredIsValid}
              aria-checked={props.checked}
              onChange={props.onChange}
              aria-describedby={ariaDescribedBy(uniqueId)}
              ref={props.buttonRef}
            />
            <span className='c-ckm-field__checkbox-control c-ckm-icon c-ckm-icon--checkbox-unchecked' />
            <span className='c-ckm-field__checkbox-control c-ckm-icon c-ckm-icon--checkbox-checked' />
          </div>
          <div className='c-ckm-field__label-container c-ckm-field__label-container--checkbox'>
            <label className='c-ckm-field__label c-ckm-field__label--checkbox c-ckm-field__label--instructions' htmlFor={uniqueId}>{props.label}
              {props.isRequired && <span className='c-ckm-field__label c-ckm-field__label--instructions' aria-hidden='true'>*</span>}
            </label>
            {showError(uniqueId)}
          </div>
        </div>}
    />
  );
};

FormCheckboxSingle.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
  initialCheck: PropTypes.bool,
  isRequired: PropTypes.bool,
  instructionsID: PropTypes.string
};

FormCheckboxSingle.defaultProps = {
  checked: false
};

FormCheckboxSingle.displayName = 'FormCheckboxSingle';
export default FormCheckboxSingle;
