import {is} from 'ramda';
import CKApi from './api-helper';
import {CONTENT_TYPE_NAMES, USAGE_EVENTS} from '../constants';

const postUsageEvents = (events) => {
  events = is(Object, events) ? [events] : events;

  CKApi.post('/student/api/reporting/usage', events);
};

const videoPlayed = (eid, searchIndex) => {
  const event = {
    contentType: CONTENT_TYPE_NAMES.VIDEO,
    event: USAGE_EVENTS.VIEW_ONLY,
    eid,
    searchIndex
  };

  postUsageEvents(event);
};

const imageViewed = (eid, searchIndex) => {
  const event = {
    contentType: CONTENT_TYPE_NAMES.IMAGE,
    event: USAGE_EVENTS.VIEW_ONLY,
    eid,
    searchIndex
  };

  postUsageEvents(event);
};

const vstReaderClicked = (eid, searchIndex) => {
  const event = {
    event: USAGE_EVENTS.DOWNLOAD,
    eid,
    searchIndex
  };

  postUsageEvents(event);
};

export {
  videoPlayed,
  imageViewed,
  vstReaderClicked
};
