import React, { useContext } from 'react';
import { defineMessages } from 'react-intl';
import { Icon } from '@els/els-react--icon';
import { Button } from '@els/els-react--button';
import { MainContext } from '../../context/main-context';
import '../../assets/img/book.svg';

const messages = defineMessages({
  noSavedBooks1: {
    id: 'Home.message.noSavedBooks1',
    defaultMessage: 'You have no saved books yet. Browse books and click'
  },
  noSavedBooks2: {
    id: 'Home.message.noSavedBooks2',
    defaultMessage: 'to add a book to your Saved Books.'
  },
  browseBooksButton: {
    id: 'Home.button.browseBooks',
    defaultMessage: 'Browse all books'
  },
  starIcon: {
    id: 'Home.button.starIcon',
    defaultMessage: 'Star Icon'
  }
});

const SavedEmpty = (props) => {
  const { intl, doRedirect } = useContext(MainContext);

  return (
    <div className='c-ckm-saved__empty-container'>
      <svg className='c-ckm-saved__graphic'>
        <use xlinkHref='#book' />
      </svg>
      <h3>
        {intl.formatMessage(messages.noSavedBooks1)}
        <Icon
          a11y={{ name: intl.formatMessage(messages.starIcon) }}
          sprite={Icon.Sprites.STAR}
          id='saved-books-star-icon'
          className='u-els-margin-right-1o4 u-els-margin-bottom-1o8 u-els-margin-left-1o4'
          textAlignment='sub'
          color='secondary'
          size={Icon.Sizes.S}
          isVisible
        />
        {intl.formatMessage(messages.noSavedBooks2)}
      </h3>
      <div className='c-ckm-showcase-catalog__button-container'>
        <Button
          type={Button.Types.PRIMARY}
          className=''
          onClick={() => { doRedirect('/browse/books'); }}
          title={intl.formatMessage(messages.browseBooksButton)}
          id='btn-home-browse-books'
        >
          {intl.formatMessage(messages.browseBooksButton)}
        </Button>
      </div>
    </div>
  );
};

SavedEmpty.displayName = 'SavedEmpty';

export default SavedEmpty;
