import React from 'react';
import {FormattedMessage} from 'react-intl';

const ResultToolMenu = (props) => {
  return (
    <div className='c-ckm-dropdown-ellip'>
      <button className='c-ckm-dropdown-ellip__trigger' aria-expanded='false'><span className='u-ckm-visuallyhidden'>
        <FormattedMessage
          id='SearchPage.text.searchOption'
          defaultMessage='Choose search result option'
        />
      </span><span className='c-ckm-dropdown-ellip__trigger-icon c-ckm-icon--lg c-ckm-icon--ellip-vert-blue' />
      </button>
      <div className='c-ckm-dropdown-ellip__menu'>
        <div className='c-ckm-dropdown-ellip__menu-list'>
          <ul className='c-ckm-dropdown-ellip-list'>
            <li className='c-ckm-dropdown-ellip-list__item'><button className='c-ckm-dropdown-ellip-list__button'><span className='c-ckm-dropdown-ellip-list__icon c-ckm-icon c-ckm-icon--empty' /><span className='c-ckm-dropdown-ellip-list__label'>
              <FormattedMessage
                id='SearchPage.dropdown.addBookshelf'
                defaultMessage='Add to bookshelf'
              />
            </span>
            </button>
            </li>
            <li className='c-ckm-dropdown-ellip-list__item'><button className='c-ckm-dropdown-ellip-list__button'><span className='c-ckm-dropdown-ellip-list__icon c-ckm-icon c-ckm-icon--empty' /><span className='c-ckm-dropdown-ellip-list__label'>
              <FormattedMessage
                id='SearchPage.dropdown.browser'
                defaultMessage='Read in browser'
              />
            </span>
            </button>
            </li>
            <li className='c-ckm-dropdown-ellip-list__item'><button className='c-ckm-dropdown-ellip-list__button'><span className='c-ckm-dropdown-ellip-list__icon c-ckm-icon c-ckm-icon--logout' /><span className='c-ckm-dropdown-ellip-list__label'>
              <FormattedMessage
                id='SearchPage.dropdown.vitalSourceLaunch'
                defaultMessage='Launch in VitalSource'
              />
            </span>
            </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ResultToolMenu;
