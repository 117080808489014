import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {defineMessages} from 'react-intl';
import { normalizeSingleElementArray } from '../../utils';
import { normalizeANCType } from '../../utils/anc-utils';
import { MainContext } from '../../context/main-context';

const messages = defineMessages({
  audio: {
    id: 'ANC.label.audio',
    defaultMessage: 'Audio'
  },
  case_study: {
    id: 'ANC.label.caseStudy',
    defaultMessage: 'Case Study'
  },
  coloring_template: {
    id: 'ANC.label.coloringTemplate',
    defaultMessage: 'Coloring Template'
  },
  further_reading: {
    id: 'ANC.label.furtherReading',
    defaultMessage: 'Further Reading'
  },
  glossary: {
    id: 'ANC.label.glossary',
    defaultMessage: 'Glossary'
  },
  lesson_plan: {
    id: 'ANC.label.lessonPlan',
    defaultMessage: 'Lesson Plan'
  },
  other_information: {
    id: 'ANC.label.otherInformation',
    defaultMessage: 'Other Information'
  },
  other_student_information: {
    id: 'ANC.label.otherStudentInformation',
    defaultMessage: 'Other Student Information'
  },
  presentation: {
    id: 'ANC.label.presentation',
    defaultMessage: 'Presentation'
  },
  practice_tool: {
    id: 'ANC.label.practiceTool',
    defaultMessage: 'Practice Tool'
  },
  question_answer: {
    id: 'ANC.label.questionsAndAnswers',
    defaultMessage: 'Questions and Answers'
  },
  reference_links: {
    id: 'ANC.label.referenceLinks',
    defaultMessage: 'Reference Links'
  },
  teaching_tool: {
    id: 'ANC.label.teachingTool',
    defaultMessage: 'Other Teaching Tool'
  },
  worksheet: {
    id: 'ANC.label.worksheets',
    defaultMessage: 'Worksheet'
  },
  video: {
    id: 'ANC.label.video',
    defaultMessage: 'Video'
  },
  default: {
    id: 'ANC.label.additionalMaterial',
    defaultMessage: 'Teaching material'
  },
  illustrations: {
    id: 'ANC.label.illustrations',
    defaultMessage: 'Illustrations'
  }
});

const determineANCType = (type, intl) => {
  const typeProp = normalizeSingleElementArray(type);

  const normalizedType = normalizeANCType(typeProp, true);

  const message = messages[normalizedType] ? messages[normalizedType] : messages.default;

  return intl.formatMessage(message);
};

const ANCTypeLabel = (props) => {
  const context = useContext(MainContext);
  const text = determineANCType(props.type, context.intl);
  return (
    <span>{text}</span>
  );
};

export default ANCTypeLabel;

ANCTypeLabel.propTypes = {
  type: PropTypes.array.isRequired
};
