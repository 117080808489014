import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withOutsideEvent from './withOutsideEvent';

class Dropdown extends Component {
  state = {
    isOpen: false
  };

  static propTypes = {
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    hiddenLabel: PropTypes.string,
    position: PropTypes.oneOf(['left', 'right']),
    size: PropTypes.oneOf(['normal', 'small', 'medium', 'auto']),
    className: PropTypes.string,
    children: PropTypes.node,
    scrollToItem: PropTypes.func
  };

  handleClickOutside = (evt) => {
    this.setState({ isOpen: false });
  };

  handleFocusOutside = (evt) => {
    if (this.state.isOpen === true) {
      this.setState({ isOpen: false });
    }
  };

  // In method below we have second argument that will reproduce if we want scroll to item and set relevant props
  toggleDropdownOpen = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }), () => this.props.scrollToItem && this.props.scrollToItem());
  };

  triggerButtonFocus = () => {
    this.toggleDropdownOpen();
    this.dropdownButton.focus();
  };

  generateDropdownClass = () => {
    return classNames(
      'c-ckm-dropdown',
      this.props.className,
      {
        'u-ckm-dropdown-small': this.props.size === 'small',
        'u-ckm-dropdown-medium': this.props.size === 'medium',
        'u-ckm-dropdown-auto': this.props.size === 'auto',
        'js-open': this.state.isOpen
      });
  };

  render () {
    const hiddenLabel = this.props.hiddenLabel || this.props.label;
    const dropDownClass = this.generateDropdownClass();
    return (
      <div className={dropDownClass} data-testid={this.props.dataTestId}>
        <button
          className='c-ckm-dropdown__trigger'
          onClick={this.toggleDropdownOpen}
          aria-expanded={this.state.isOpen}
          ref={dropdownButton => { this.dropdownButton = dropdownButton; }}
        >
          <span className='u-ckm-visuallyhidden'>{hiddenLabel}</span>
          <span className='c-ckm-dropdown__trigger-label'>{this.props.label}</span>
          <span className='c-ckm-dropdown__trigger-icon c-ckm-icon c-ckm-icon--arrow-down-blue' />
        </button>
        {/* <div className={`c-ckm-dropdown__menu ${dropdownExternalClass}`}> */}
        <div className='c-ckm-dropdown__menu'>
          {/* Adding tabIndex to fix conflict between event listeners and Aria Modal focus trap */}
          <div
            tabIndex='-1'
            className='c-ckm-dropdown__menu-list'
            onClick={this.triggerButtonFocus}
            role='presentation'
          >
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

Dropdown.defaultProps = {
  position: 'left',
  size: 'normal'
};

Dropdown.displayName = 'Dropdown';
export default withOutsideEvent(Dropdown);
