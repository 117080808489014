import React, { useEffect } from 'react';
import Glide from '@glidejs/glide';
import CasesSliderArrows from '../../features/case-player/components/case-homepage/CasesSliderArrows';

const MediaViewSlider = ({ getImageView, getModelView, getCompareView, showNavigation }) => {
  useEffect(() => {
    const sliders = document.querySelectorAll('.glide');
    sliders.forEach(item => {
      const glide = new Glide(item, {
        bound: true,
        type: 'slider',
        focusAt: 0,
        perView: 4,
        rewind: true,
        gap: 8
      });
      const forward = document.querySelector('.glide__arrow--right');
      const backward = document.querySelector('.glide__arrow--left');
      if (forward) {
        forward.addEventListener('click', () => {
          glide.go('>');
        });
      }
      if (backward) {
        backward.addEventListener('click', () => {
          glide.go('<');
        });
      }
      glide.mount();
    });
  }, []);

  return (
    <div className={`glide glide-mobile-slider ${showNavigation && 'navigation'}`} data-testid='glide-container'>
      <div className='glide__track' data-glide-el='track'>
        <ul className='glide__slides'>
          {[getImageView(),
          getModelView(),
          ...getCompareView() ? [getCompareView()] : []].flat(Infinity).map(item => {
            return (
              <div>
                <li className='glide-slide'>
                  {item}
                </li>
              </div>
            );
          })}
        </ul>
      </div>
      <CasesSliderArrows />
    </div>
  );
};

export default MediaViewSlider;
