import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import { MainContext } from '../../context/main-context';
import useQuery from '../../hooks/use-query';
import { stringify } from '../../utils/queryStringUtils';
import DOMPurify from 'dompurify';

const constructIDPLink = (baseUrl, entityID, contextPath, target, studyToolsType = null) => {
  const appReturnURL = studyToolsType
    ? `${DOMPurify.sanitize(window.location.origin)}${contextPath}/shibboleth?studyToolsLogin=${studyToolsType}`
    : `${DOMPurify.sanitize(window.location.origin)}${target || contextPath}`;

  const queryParams = {
    entityID,
    appReturnURL
  };

  const serializedParams = stringify(queryParams);

  return `${baseUrl}?${serializedParams}`;
};

const ShibbolethIDPLink = (props) => {
  const context = useContext(MainContext);
  const {target} = useQuery();

  const link = constructIDPLink(
    context.shibbolethBaseUrl,
    props.entityID,
    context.productConfig.path,
    target,
    props.studyToolsLoginType
  );

  return (
    <a href={link} onClick={props.onClick}>{props.children}</a>
  );
};

ShibbolethIDPLink.propTypes = {
  entityID: PropTypes.string.isRequired,
  studyToolsLoginType: PropTypes.string
};

export default ShibbolethIDPLink;
