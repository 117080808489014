import axios, {CancelToken} from 'axios';
import {pathOr} from 'ramda';
import {productHeader, DEFAULT} from '../../server/config/productConfig';
import uuid from 'uuid/v4';

// set up isolated instance of Axios
const instance = axios.create();

const productContext = pathOr(DEFAULT.context, ['__APP_INITIAL_STATE__', 'productConfig', 'context'], window);

// add request ID
instance.interceptors.request.use((config) => {
  config.headers['x-ck-request-id'] = `meded-${uuid()}`;
  config.headers[productHeader] = productContext;
  return config;
});

instance.interceptors.response.use((response) => response, (error) => {
  if (window && error.response.status === 902) {
    window.location.reload();
  } else {
    return Promise.reject(error);
  }
});

export default instance;

export {
  CancelToken
};
