import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useIntl from '../../hooks/use-intl';
import CKApi from '../../services/api-helper';
import { defineMessages } from 'react-intl';
import { Icon } from '@els/els-react--icon';
import { Checkbox } from '@els/els-react--checkbox';
import { Flyout } from '@els/els-react--flyout';
import InternalLink from './InternalLink';
import { isMobile, isTablet } from '../../utils/elementUtils';
import { MainContext } from '../../context/main-context';
import '../../assets/img/icon_slides.svg';

const messages = defineMessages({
  infoMessage: {
    id: 'MediaPopout.presentationPanel.infoMessage',
    defaultMessage: 'Add this image and its citation to a presentation you create in ClinicalKey Student. Download in PPT format.'
  },
  oneSlide: {
    id: 'MediaPopout.presentationPanel.list.slide',
    defaultMessage: 'Slide'
  },
  multipleSlides: {
    id: 'MediaPopout.presentationPanel.list.slides',
    defaultMessage: 'Slides'
  },
  empty: {
    id: 'MediaPopout.presentationPanel.list.empty',
    defaultMessage: 'Empty'
  },
  addedImage: {
    id: 'MediaPopout.presentationPanel.list.addedImage',
    defaultMessage: 'Image added to'
  },
  removedImage: {
    id: 'MediaPopout.presentationPanel.list.removedImage',
    defaultMessage: 'Image removed from'
  },
  errorSavingImage: {
    id: 'MediaPopout.presentationPanel.list.errorSavingImage',
    defaultMessage: 'There was an error trying to save the image. Please try again later.'
  },
  errorRemovingImage: {
    id: 'MediaPopout.presentationPanel.list.errorRemovingImage',
    defaultMessage: 'There was an error trying to remove the image. Please try again later.'
  },
  checkboxFlyoutMessage: {
    id: 'MediaPopout.presentationPanel.list.checkBoxFlyout',
    defaultMessage: 'select to add'
  }
});

const CheckboxPresentationPanel = (props) => {
  const presentation = props.presentation;
  const intl = useIntl();
  const context = useContext(MainContext);
  const [disabled, setDisabled] = useState(false);
  const [checked, setChecked] = useState(Boolean(presentation.matchingContentId));

  const sharedSuccessToastProperties = {
    isVisible: true,
    autoHide: true,
    icon: '#icon-sprite_els-hmds-icon-checkmark',
    iconColor: 'positive',
    color: 'positive'
  };

  const sharedErrorToastProperties = {
    isVisible: true,
    autoHide: true,
    icon: '#icon-sprite_els-hmds-icon-alert-triangle',
    iconColor: 'negative',
    color: 'negative'
  };

  useEffect(() => {
    setChecked(Boolean(presentation.matchingContentId));
  }, [props.presentation.matchingContentId]);

  const handleChange = (presentationId) => {
    const redirectToCurrentPresentation = `${context.productConfig.path}/presentations?presentationId=${presentation.id}`;
    const check = !checked;
    setChecked(!checked);
    setDisabled(true);
    if (check) {
      CKApi.put(`/student/api/presentations/${presentationId}`, {
        eid: props.eid
      })
        .then(() => {
          props.loadPresentationsRef();
          context.addToast({
            body:
              `<SuccessMessage>
              ${intl.formatMessage(messages.addedImage)}
              &nbsp;
              <strong>
                <a href=${redirectToCurrentPresentation}>${presentation.name}</a>
              </strong>
            </SuccessMessage>`,
            ...sharedSuccessToastProperties
          });
          setDisabled(false);
        })
        .catch((e) => {
          setChecked(check);
          setDisabled(false);
          context.addToast({
            body: intl.formatMessage(messages.errorSavingImage),
            ...sharedErrorToastProperties
          });
        });
    } else {
      CKApi.delete(`/student/api/presentations/${presentationId}/contents`, {
        params: {
          contentId: presentation.matchingContentId
        }
      })
        .then(() => {
          props.loadPresentationsRef();
          context.addToast({
            body: `<SuccessMessage>
                    ${intl.formatMessage(messages.removedImage)}
                    &nbsp;
                    <strong>
                      <a href=${redirectToCurrentPresentation}>${presentation.name}</a>
                    </strong>
                  </SuccessMessage>`,
            ...sharedSuccessToastProperties
          });
          setDisabled(false);
        })
        .catch((e) => {
          setChecked(check);
          setDisabled(false);
          context.addToast({
            body: intl.formatMessage(messages.errorRemovingImage),
            ...sharedErrorToastProperties
          });
        });
    }
  };

  const slideCountLabel = (slideCount) => {
    if (slideCount > 1) {
      return `${slideCount} ${intl.formatMessage(messages.multipleSlides)}`;
    }
    return slideCount === 1 ? `1 ${intl.formatMessage(messages.oneSlide)}` : intl.formatMessage(messages.empty);
  };

  const getCheckbox = () => {
    return (
      <Checkbox
        onClick={() => { handleChange(presentation.id); }}
        className=''
        disabled={disabled}
      />
    );
  };

  return (
    <div className='c-ckm-presentationList__presentation o-els-flex-layout'>
      <div className='c-ckm-presentationList__presentation-thumb o-els-flex-layout__item'>
        {presentation.firstImageEid
          ? <img
              src={`/student/api/content/imageByEntitlement/${presentation.firstImageEid}`}
              alt='Presentation Thumbnail'
            />
          : <svg
              className='icon o-els-icon-svg o-els-icon-svg--1x1o2 o-els-icon-svg--bottom'
            >
            <use xlinkHref='#icon_slides' />
          </svg>}
      </div>
      <div className='c-ckm-presentationList__presentation-info o-els-flex-layout__item o-els-flex-layout__item--grow'>
        <div className='c-ckm-presentationList__presentation-info-title'>
          <InternalLink
            className=''
            id='presentation-title-link'
            href={`/presentations?presentationId=${presentation.id}`}
          >
            {presentation.name}
          </InternalLink>
        </div>
        <div id='slide' className='c-ckm-presentationList__presentation-info-slideCount u-els-font-size-meta'>{slideCountLabel(presentation.slideCount)}</div>
      </div>
      {checked
        ? <div className='c-ckm-presentationList__presentation-checkmark o-els-flex-layout__item'>
          <Icon
            a11y={{ name: intl.formatMessage(messages.infoMessage) }}
            sprite={Icon.Sprites.CHECKMARK}
            textAlignment='top'
            size={Icon.Sizes.XS}
            isVisible
            id='presentation-checkmark-icon'
            data-testid='presentation-checkmark-icon'
            onClick={() => !disabled && handleChange(presentation.id)}
          />
        </div>
        : <div className='c-ckm-presentationList__presentation-checkbox o-els-flex-layout__item'>
          {(isMobile() || isTablet())
            ? (getCheckbox())
            : <Flyout
                className=''
                placement='top-end'
                trigger='hover'
                modifiers={{ flip: { enabled: false }, preventOverflow: { enabled: true } }}
                flyout={intl.formatMessage(messages.checkboxFlyoutMessage)}
                shouldCloseOnESC
                id='image-checkbox-flyout'
                theme='simple'
              >
              {getCheckbox()}
            </Flyout>}
        </div>}
    </div>
  );
};

export default CheckboxPresentationPanel;

CheckboxPresentationPanel.propTypes = {
  presentation: PropTypes.object,
  loadPresentationsRef: PropTypes.func,
  eid: PropTypes.string.isRequired
};
