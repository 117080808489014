import React, {Component} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {FormattedMessage, defineMessages} from 'react-intl';
import PropTypes from 'prop-types';
import Announcements from './Announcements';
import { MainContext } from '../../context/main-context';
import { Icon } from '@els/els-react--icon';
import { Button } from '@els/els-react--button';
const { formatUriContext } = require('../../../server/utils/url-utils');

const messages = defineMessages({
  copy: {
    id: 'DeepLinkGenerator.message.copy',
    defaultMessage: 'Copy link'
  },
  share: {
    id: 'ListsPage.label.share',
    defaultMessage: 'Share'
  }
});

export default class DeepLinkGenerator extends Component {
  state = {
    copied: false,
    copyText: ''
  };

  static propTypes = {
    type: PropTypes.string.isRequired,
    linkDescrip: PropTypes.string.isRequired,
    eid: PropTypes.string,
    sectionId: PropTypes.string,
    showLabel: PropTypes.bool,
    showLabelEMC: PropTypes.bool
  };

  static contextType = MainContext;

  componentDidMount () {
    if (this.props.type === 'list') {
      this.setState({copyText: `${window.location.origin}${formatUriContext(`/content/book/${this.props.eid}`, this.context.productConfig.path)}`});
    } else if (this.props.type === 'single') {
      this.setState({copyText: `${window.location.origin}${window.location.pathname}`});
    } else if (this.props.type === 'section') {
      this.setState({copyText: `${window.location.origin}${window.location.pathname}#${this.props.sectionId}`});
    }
  }

  copyLink = () => {
    this.setState({copied: true});
    setTimeout(function () {
      this.setState({copied: false});
    }.bind(this), 1500);
  };

  render () {
    return (
      <span className={`c-ckm-copy-link-${this.props.type}`}>
        <CopyToClipboard text={this.state.copyText} onCopy={this.copyLink}>
          <Button
            type={Button.Types.LINK}
            className='c-ckm-reader-content__copy'
            id='save-collection-btn'
            aria-label={`${this.context.intl.formatMessage(messages.copy)} - ${this.props.linkDescrip}`}
          >
            {this.props.showLabel &&
              <span className='c-ckm-copy-link-btn-text'>
                <FormattedMessage
                  id='DeepLinkGenerator.message.share'
                  defaultMessage='Share'
                />
              </span>}
            <Icon
              a11y={{name: 'Share'}}
              sprite={Icon.Sprites.SHARE}
              id='#els-gizmo-icon-share'
              textAlignment='bottom'
              size={Icon.Sizes.XS}
              isVisible
            >
              {this.props.showLabelEMC && this.context.intl.formatMessage(messages.share)}
            </Icon>
          </Button>
        </CopyToClipboard>
        {this.state.copied
          ? <span className={this.props.showLabelEMC ? 'c-ckm-copy-link-msg-left' : 'c-ckm-copy-link-msg'}>
            <FormattedMessage
              id='DeepLinkGenerator.message.copiedLink'
              defaultMessage='Copied link'
            />
            <FormattedMessage
              id='DeepLinkGenerator.announcement.copiedLink'
              defaultMessage='Link is copied to clipboard'
            >
              {(text) => <Announcements message={text} pageLoad={false} />}
            </FormattedMessage>
          </span>
          : null}
      </span>
    );
  }
}

DeepLinkGenerator.displayName = 'DeepLinkGenerator';
