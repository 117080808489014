import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { MainContext } from '../../context/main-context';
import { BOOKSTORE_URLS } from '../../constants';

const getBookStoreUrl = (isbn, searchIndex, regionFlags = {}) => {
  switch (searchIndex) {
    case 'FRENCH':
      return `${BOOKSTORE_URLS.FRENCH}?isbn=${isbn}&utm_source=CKS&utm_medium=link&utm_campaign=CKS_book_link&campid=CKSB_book`;
    case 'GERMAN':
      return `${BOOKSTORE_URLS.GERMAN}?isbn=${isbn}&utm_source=CKS&utm_medium=link&utm_campaign=CKS_book_link&campid=CKSB_book`;
    case 'SPANISH':
      return `${BOOKSTORE_URLS.SPANISH}?isbn=${isbn}&utm_source=CKS&utm_medium=link&utm_campaign=CKS_book_link&campid=CKSB_book`;
    default:
      if (regionFlags.isANZ) {
        return `${BOOKSTORE_URLS.ANZ}${isbn}`;
      }
      return `${BOOKSTORE_URLS.GLOBAL}?isbn=${isbn}&utm_source=CKS&utm_medium=link&utm_campaign=CKS_book_link&campid=CKSB_book`;
  }
};

const PurchaseBook = ({ isbn, refLink }) => {
  // TODO refactor to get store link based on search index
  const { activeIndex, user, regionFlags } = useContext(MainContext);

  // French customers do not want to see the Purchase Book option
  if (activeIndex === 'FRENCH') {
    return null;
  }

  let bookstoreUrl = getBookStoreUrl(isbn, activeIndex, regionFlags);

  if (user.account_number === 'C000774374') {
    bookstoreUrl = 'https://www.amazon.in/stores/page/B665D631-77C3-406A-83B4-518437C2034E?ingress=3';
  }

  return (
    <div className='c-ckm-purchase-book'>
      <hr />
      <a
        className='c-ckm-purchase-book__link'
        href={bookstoreUrl}
        ref={refLink}
        target='_blank' rel='noreferrer'
      >
        <h4 className='c-ckm-purchase-book__heading'>
          <FormattedMessage
            id='External.buyBook.link'
            defaultMessage='Want to own this book?'
          />
        </h4>
      </a>
      <span className='c-ckm-icon c-ckm-icon--external-link-blue c-ckm-purchase-book__icon' />
      <p className='c-ckm-purchase-book__description'>
        <FormattedMessage
          id='External.buyBook.description'
          defaultMessage='Buy your copy at Elsevier Health Bookstore today'
        />
      </p>
    </div>
  );
};

PurchaseBook.propTypes = {
  isbn: PropTypes.string.isRequired
};

PurchaseBook.displayName = 'PurchaseBook';

export default PurchaseBook;
