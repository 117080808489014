import React from 'react';
import { isXSmall } from '../../utils/elementUtils';
import Tooltip from './ToolTip';

const ProgressBar = ({ testMessage, correctAnswerValue, incorrectAnswerValue, unansweredQuestionsValue, totalQuestions, handleTestButtonClick, topic, incorrectOptMessage, correctOptMessage }) => {
  const progressBarStyle = {
    width: '100%',
    height: '0.375rem',
    display: 'flex',
    background: 'white',
    borderRadius: '0.125rem'
  };

  const barStyle = {
    height: '100%',
    transition: 'width 0.3s ease',
    marginRight: '0.25rem'
  };

  const correctAnswerStyle = {
    backgroundColor: '#7ECE73',
    borderRadius: '0.125rem'
  };

  const incorrectAnswerStyle = {
    backgroundColor: '#FF6A5A',
    borderRadius: '0.125rem'
  };

  const unansweredQuestionsStyle = {
    backgroundColor: '#C8CCD6',
    borderRadius: '0.125rem'
  };

  const totalQuestionsStyle = {
    marginTop: '-0.5rem',
    marginRight: '0.5rem'
  };

  const testBtn = {
    marginTop: '-1.4rem',
    marginLeft: '1.5rem'
  };

  const totalQuestionsCheck = totalQuestions > 0 ? totalQuestions : Math.abs(unansweredQuestionsValue);
  const correctPercent = (correctAnswerValue / totalQuestionsCheck) * 100;
  const incorrectPercent = (incorrectAnswerValue / totalQuestionsCheck) * 100;
  const totalPercent = 100 - (correctPercent + incorrectPercent);

  const tooltipInfo = () => {
    return (
      <>
        <div>{correctPercent >= 1 ? correctPercent.toFixed(0) : correctPercent.toFixed(1)}% {correctOptMessage}</div>
        <div>{incorrectPercent >= 1 ? incorrectPercent.toFixed(0) : incorrectPercent.toFixed(1)}% {incorrectOptMessage}</div>
      </>
    );
  };

  return (
    <>
      <div className='progress-bar' data-testid='progress-bar' style={progressBarStyle}>
        <span style={{...totalQuestionsStyle}}>{correctAnswerValue}/{totalQuestionsCheck}</span>
        <Tooltip message={tooltipInfo}>
          <div className='incorrect-answer' data-testid='incorrect-answer' style={{ ...barStyle, ...incorrectAnswerStyle, width: `${incorrectPercent}%` }} />
          <div className='correct-answer' data-testid='correct-answer' style={{ ...barStyle, ...correctAnswerStyle, width: `${correctPercent}%` }} />
          {unansweredQuestionsValue > 0 && <div className='unanswered-questions' data-testid='unanswered-questions' style={{ ...barStyle, ...unansweredQuestionsStyle, width: incorrectAnswerValue > 0 && correctAnswerValue > 0 ? `${totalPercent}%` : `${100}%` }} />}
        </Tooltip>
        {!isXSmall() && (
          <span id='start-btn-lowest-scoring' style={{...testBtn}}>
            <button onClick={() => handleTestButtonClick(topic)} className='c-els-button c-els-button--x-small c-els-button--secondary btn-lowest-scoring-topic-and-lowest-confident-topic' data-testid='btn-lowest-scoring-topic-and-lowest-confident-topic'>
              <span className='test-button-container'>
                <span className='icon-container'>
                  <svg className='o-els-icon-svg--3o4 test-edit-icon'>
                    <use xlinkHref='#icon-sprite_icon__edit' fill='#005CE0' />
                  </svg>
                </span>
                <span className='text-container'>
                  {testMessage}
                </span>
              </span>
            </button>
          </span>
        )}
      </div>
      {isXSmall() && (
        <span id='start-btn-lowest-scoring'>
          <button onClick={() => handleTestButtonClick(topic)} className='c-els-button c-els-button--x-small c-els-button--secondary btn-lowest-scoring-topic-and-lowest-confident-topic' data-testid='btn-lowest-scoring-topic-and-lowest-confident-topic'>
            <span className='test-button-container'>
              <span className='icon-container'>
                <svg className='o-els-icon-svg--3o4 test-edit-icon'>
                  <use xlinkHref='#icon-sprite_icon__edit' fill='#005CE0' />
                </svg>
              </span>
              <span className='text-container'>
                {testMessage}
              </span>
            </span>
          </button>
        </span>
      )}
    </>
  );
};

export default ProgressBar;
