import CKApi from './api-helper';
import queryStringUtils from '../utils/queryStringUtils';

export const loadBookSectionContent = async (eid, sectionid) => {
  const { data } = await CKApi.get(`/student/api/content/html?eid=${eid}&sectionid=${sectionid}&html=true&meta=false&initialrequest=false`);
  return data;
};

export const loadBookSections = (eid, sections, contentType, nursing = false, toc = false) => {
  const params = {
    eid,
    sectionid: sections,
    html: true,
    meta: false,
    initialrequest: false,
    ...(contentType === 'EMC' && {toc: toc})
  };
  const searchString = queryStringUtils.stringify(params);
  const apiURL = contentType === 'EMC' ? (nursing ? `/student/nursing/api/contentPOC/html?${searchString}` : `/student/api/contentPOC/html?${searchString}`) : (nursing ? `/student/api/content/html?${searchString}` : `/student/api/content/html?${searchString}`);
  return CKApi.get(apiURL)
    .then(({data}) => data);
};

export const loadBookTOC = async (hubEid) => {
  const { data } = await CKApi.get(`/student/api/browse/${hubEid}/toc`);
  return data;
};

export const loadEMCTOC = async (issn, sid) => {
  const params = {
    issn: issn,
    sid: sid
  };
  const searchString = queryStringUtils.stringify(params);
  const { data } = await CKApi.get(`/student/api/browse/emc/toc?${searchString}`);
  return data.docs;
};
