const { toPairs, sortBy, prop, compose, toLower } = require('ramda');

const debounce = function debounce (func, wait, immediate) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

const throttle = (func, limit) => {
  let inThrottle;
  return (...args) => {
    if (!inThrottle) {
      func(...args);
      inThrottle = setTimeout(function () { inThrottle = false; }, limit);
    }
  };
};

// object -> map(key, value) -> array
const mapObjToArray = (mapFn, object) => {
  const pairs = toPairs(object);
  const sortByFirstItemCaseInsensitive = sortBy(compose(toLower, prop(0)));

  return sortByFirstItemCaseInsensitive(pairs).map((pair, index) => mapFn(pair[0], pair[1], index));
};

const moveInArray = (fromIndex, toIndex, array) => {
  const elemToMove = array.splice(fromIndex, 1)[0];
  array.splice(toIndex, 0, elemToMove);
  return array;
};

const setTimeoutPromise = (delay) => {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, delay);
  });
};

const getCode = ({ code, crumbType }) => {
  switch (crumbType) {
    case 'Indicadores':
      return `[${code?.replace(/[#-]/, '')}]`;
    case 'nanda':
    case 'NANDA Relacionados':
    case 'En Otros NANDA':
      return `[${code?.replace(/[*#-]/, '').padStart(5, '0')}]`;
    case 'nic':
    case 'noc':
    case 'En Otros NOC':
    case 'NOC Relacionados':
    case 'NIC Relacionados':
      return `[${code?.replace(/[#-]/, '').padStart(4, '0')}]`;
    case 'diagmed':
    case 'En Otros Diagnósticos Médicos':
      return `[${code?.replace(/[#-]/, '').padStart(2, '0')}]`;
  }
};

const getAffiliattionList = (affiliations) => {
  return affiliations.map(child => {
    if (child.label && Array.isArray(child.label['#children'])) {
      return child.label['#children'][0];
    } else if (child.textfn && child.textfn['#text']) {
      return child.textfn['#text'];
    }
  }).join(' ');
};

module.exports = {
  debounce,
  throttle,
  mapObjToArray,
  moveInArray,
  setTimeoutPromise,
  getCode,
  getAffiliattionList
};
