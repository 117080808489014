import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, defineMessages} from 'react-intl';
import {head} from 'ramda';
import Modal from './Modal';
import ANCIcon from './ANCIcon';
import ANCTypeLabel from './ANCTypeLabel';
import CardSource from './CardSource';
import InternalLink from './InternalLink';
import {imageViewed} from '../../services/usageReporting';
import { normalizeSingleElementArray } from '../../utils';
import {trackEvent} from '../../utils/eventTrackUtils';
import {MainContext} from '../../context/main-context';
import SafeHTMLString from './SafeHTMLString';
import CKApi from '../../services/api-helper';
import DOMPurify from 'dompurify';

const messages = defineMessages({
  ancPopoutAriaTitle: {
    id: 'AncPopout.aria.title',
    defaultMessage: 'Get File'
  }
});
export default class ANCPopout extends Component {
  state = {
    isPDF: (this.props.reftype || normalizeSingleElementArray(this.props.filetype)) === 'pdf',
    type: null
  };

  static contextType = MainContext;

  static propTypes = {
    contenttype: PropTypes.string,
    authorList: PropTypes.array,
    eid: PropTypes.string,
    itemtitle: PropTypes.string,
    hubeid: PropTypes.string,
    handlePopoutClose: PropTypes.func,
    sourcetitle: PropTypes.string,
    summary_s: PropTypes.string,
    filetype: PropTypes.array,
    reftype: PropTypes.string
  };

  componentDidMount () {
    const type = this.props.reftype || normalizeSingleElementArray(this.props.filetype);
    const contentFormat = type?.toUpperCase();

    CKApi.get(`/student/api/content/contentModel/${this.props.eid}`)
      .then(({ data: { AncillaryContent } }) => {
        this.setState({type: AncillaryContent?.ContentType});
        trackEvent('contentView', {
          content: [{
            format: `MIME-${contentFormat}`,
            id: this.props.eid,
            title: AncillaryContent?.Title,
            type: `${AncillaryContent?.FileFormat}:${AncillaryContent?.ContentType}:${AncillaryContent?.Specialty}`
          }]
        });
      });
  }

  handleImageLoad = (evt) => {
    evt.preventDefault();
    const searchIndex = head(this.context.user.search_indexes);

    imageViewed(this.props.eid, searchIndex);

    this.setState({
      imageLoaded: true
    });
  };

  render () {
    const summary = this.props.summary || this.props.summary_s;
    const type = this.props.sourcetype || this.props.srctype || (this.state.type ? this.state.type : 'anc');

    return (
      <Modal
        handleModalClose={this.props.handlePopoutClose}
        ariaTitleText={this.context.intl.formatMessage(messages.ancPopoutAriaTitle)}
      >
        <div className='c-ckm-modal__media'>
          <button
            onClick={this.props.handlePopoutClose}
            className='c-ckm-modal__close c-ckm-icon--lg c-ckm-icon--cross-lg u-ckm-cursor-pointer'
            aria-label='Close modal'
          />
          <div className='c-ckm-modal__media-container'>
            <div className='c-ckm-modal__anc-icon'>
              <ANCIcon type={type} size='large' />
            </div>
            <div className='c-ckm-modal__media-info'>
              <div className='c-ckm-modal__media-type'>
                <ANCTypeLabel type={type} />
              </div>
              <>
                <h2>
                  <SafeHTMLString content={this.props.itemtitle} />
                </h2>
                <p className='c-ckm-modal__media-summary'>{summary}</p>
                <CardSource {...this.props} />
                {this.props.sourcetitle &&
                  <p className='c-ckm-modal__media-source'>
                    <FormattedMessage
                      id='SearchPage.display.from'
                      defaultMessage='from'
                    />
                    {this.props.hubeid
                      ? <InternalLink className='c-ckm-modal__media-source-title' href={`/content/toc/${this.props.hubeid}`}>{this.props.sourcetitle}</InternalLink>
                      : <span className='c-ckm-modal__media-source-title'>{this.props.sourcetitle}</span>}
                  </p>}
              </>
              <p className='c-ckm-modal__media-link-container'>
                <a
                  className='c-ckm-button--mobile-expanded c-ckm-button c-ckm-button--primary c-ckm-button--small u-ckm-margin--top'
                  href={`/student/nursing/api/content/anc-download/${DOMPurify.sanitize(this.props.eid)}`}
                  target='_blank' rel='noreferrer'
                >
                  {this.state.isPDF
                    ? (
                      <FormattedMessage
                        id='ANCModal.button.openPDF'
                        defaultMessage='Open PDF'
                      />
                    ) : (
                      <FormattedMessage
                        id='ANCModal.button.download'
                        defaultMessage='Download'
                      />
                    )}
                </a>
              </p>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

ANCPopout.displayName = 'ANCPopout';
