import React, { useContext } from 'react';
import { defineMessages } from 'react-intl';
import { Button } from '@els/els-react--button';
import { MainContext } from '../../context/main-context';

const messages = defineMessages({
  infoMessage: {
    id: 'InfoMessage.message',
    defaultMessage: 'Enhance your 3D experience! Try a larger screen for optimal viewing.'
  },
  infoMessageButton: {
    id: 'InfoMessage.button',
    defaultMessage: 'Okay'
  }
});

const InfoMessage = () => {
  const { intl, toggleInfoMessage, showInfoMessage } = useContext(MainContext);
  return (
    <div className={showInfoMessage ? 'c-ckm-info-message' : 'c-ckm-info-message__hide'}>
      <div className='c-ckm-info-message__description'>
        {intl.formatMessage(messages.infoMessage)}
      </div>
      <Button
        type={Button.Types.PRIMARY}
        className='c-els-button--small u-els-margin-top-1o2 c-ckm-info-message__btn'
        onClick={() => { toggleInfoMessage(); }}
        aria-label={intl.formatMessage(messages.infoMessageButton)}
        title={intl.formatMessage(messages.infoMessageButton)}
        id='hide-info-message'
      >
        {intl.formatMessage(messages.infoMessageButton)}
      </Button>
    </div>
  );
};

export default InfoMessage;
