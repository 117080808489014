import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {checkContentType, getContentViewPath} from '../../utils/stringUtils';
import { CONTENT_TYPE_NAMES, SOURCE_TYPES } from '../../constants';
import { formatUriContext } from '../../../server/utils/url-utils';
import { MainContext } from '../../context/main-context';

const InternalContentLink = (props) => {
  const context = useContext(MainContext);
  const {contentType, children, hubeid, issn, to, linkRef, eid, sectionId, isISSN, isEntitled} = props;
  const checkedType = checkContentType(contentType);
  const path = to ?? getContentViewPath(checkedType, {hubeid, issn, eid, sectionId, isISSN});
  const fullPath = formatUriContext(path, context.productConfig.path);
  const disabled = isEntitled !== undefined && !isEntitled;
  const newProps = {
    ...props,
    href: fullPath,
    ref: linkRef
  };
  delete newProps.issn;
  delete newProps.hubeid;
  delete newProps.eid;
  delete newProps.linkRef;
  delete newProps.to;
  delete newProps.contentType;
  delete newProps.isEntitled;

  return (
    <a {...newProps} aria-disabled={disabled}>{children}</a>
  );
};

InternalContentLink.propTypes = {
  contentType: PropTypes.oneOf(Object.values({ ...CONTENT_TYPE_NAMES, ...SOURCE_TYPES })).isRequired
};

InternalContentLink.displayName = 'InternalContentLink';

export default InternalContentLink;
