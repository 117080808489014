import React, { useContext } from 'react';
// import { Icon } from '@els/els-react--icon';
import { defineMessages } from 'react-intl';
import { MainContext } from '../context/main-context';

const messages = defineMessages({
  dropwdownHeader: {
    id: 'SearchDropwodn.placeholder.header',
    defaultMessage: 'I\'m looking for'
  },
  dropwdownRecent: {
    id: 'SearchDropwodn.placeholder.recent',
    defaultMessage: 'Recent searches'
  }
});

const SearchDropdown = ({ searchState, onClick, searchHistory, onSearchItemClick }) => {
  const { intl } = useContext(MainContext);
  // TO DO add variables for scss file relate to this component

  // const getIcon = () => {
  //   return (
  //     <Icon
  //       a11y={{ name: 'clock' }}
  //       sprite={Icon.Sprites.CLOCK}
  //       className='c-ckm-search-dropdown__recent-icon'
  //       id='search-clock'
  //       size={Icon.Sizes.S}
  //       textAlignment='middle'
  //       isVisible
  //       isTextFirst
  //     />
  //   );
  // };

  return (
    <div className={searchState.isTabs ? 'c-ckm-search-dropdown__wrapper' : 'c-ckm-search-dropdown__wrapper-hide'} data-testid='search-dropdown-container'>
      <div className='c-ckm-search-dropdown__tabs'>
        <div className='c-ckm-search-dropdown__header'>{intl.formatMessage(messages.dropwdownHeader)}</div>
        <div className='c-ckm-search-dropdown__wrapper-btn'>
          {searchState.scopeData?.slice(1).map(({ label, value }) => <button key={value} onClick={(e) => onClick(e, value)} className={searchState.activeTab === value?.toString().slice(-2) ? 'c-ckm-search-dropdown__btn-highlighted' : 'c-ckm-search-dropdown__btn'}>{label}</button>)}
        </div>
      </div>
      {/* <div className={searchState.isRecent ? 'c-ckm-search-dropdown__recent' : 'c-ckm-search-dropdown__recent-hide'}>
        <hr className='c-ckm-search-dropdown__recent-hr' />
        <div className='c-ckm-search-dropdown__recent-title'>{intl.formatMessage(messages.dropwdownRecent)}</div>
        <ul>
          {searchHistory.map(({id, term, facetQueryData}) => {
            return (
              <li key={id} className='c-ckm-search-dropdown__recent-item'><button className='c-ckm-search-dropdown__recent-btn' onClick={() => onSearchItemClick({term, contenttype: facetQueryData?.facetquery[0] })}>{getIcon()}{term}</button></li>
            );
          })}
        </ul>
      </div> */}
    </div>
  );
};

export default SearchDropdown;
