import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import InternalLink from '../../components/common/InternalLink';
import { getBookThumbnailUrl } from '../../utils/stringUtils';

const BookCoverImg = (props) => {
  const { hubeid, issn, src, getHeight, sourcetitle, contentType } = props;
  const imgRef = useRef(null);
  const hrefMap = {
    BOOK: {
      href: `/content/toc/${props.hubeid}`,
      src: src ?? getBookThumbnailUrl(hubeid)
    },
    EMC: {
      href: `/content/emc/toc/${issn}`,
      src
    },
    JOURNAL: {
      href: `/content/journal/issues/${issn && issn.replace('-', '')}/${hubeid}`,
      src
    }
  };

  const hrefPage = hrefMap[contentType];
  const getImgHeight = () => {
    if (getHeight && imgRef) {
      getHeight(imgRef);
    }
  };

  return (
    <InternalLink href={hrefPage.href} className='c-ckm-thumbnail'>
      <img
        className={`c-ckm-thumbnail__img ${props.className || ''}`}
        ref={imgRef}
        src={hrefPage.src}
        alt={sourcetitle ?? ''}
        onLoad={getImgHeight}
      />
    </InternalLink>
  );
};

export default BookCoverImg;

BookCoverImg.propTypes = {
  hubeid: PropTypes.string.isRequired,
  issn: PropTypes.string,
  src: PropTypes.string,
  getHeight: PropTypes.func,
  sourcetitle: PropTypes.string
};
