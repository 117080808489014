import React from 'react';
import Widget from '../../components/common/Widget';
import RecentItems from './RecentItems';
import AssessmentTopics from './AssessmentTopics';
import USMLEBooks from './USMLEBooks';
import FeatureOn from '../../components/feature-flag/FeatureOn';
import FeatureOff from '../../components/feature-flag/FeatureOff';
import QuickActions from './QuickActions';
import cx from 'classnames';
import { ELSWidgetActivitiesOverview } from '@els/meded-ui-common-react';
import Assignments from './Assignments';
import { RecentActivities } from './RecentActivities';

const HomeWidgets = ({
  userId,
  courseId,
  isInstructor,
  hasFoundationAccess,
  hasAssessmentAccess,
  isProfessional,
  isDesktop,
  showSummaries,
  isSelfStudyUserCallback,
  isSelfStudyUser,
  hasUSMLEBanks
}) => {
  const AssessmentOnly = !hasFoundationAccess && hasAssessmentAccess;
  const widgetClass = AssessmentOnly ? 'assessment-lowest-scoring-topic-widget' : 'lowest-scoring-topic-widget';

  return (
    <div className={cx('o-ckm-widgets', 'o-els-flex-layout', 'o-els-flex-layout--wrap', {
      'o-els-flex-layout--space-between': !isInstructor
    })}
    >
      {!isInstructor && hasFoundationAccess && hasAssessmentAccess && (
        <>
          {!isProfessional &&
            <>
              <FeatureOn featureName='NewStudentHomePage'>
                <div className='home-widgets__container'>
                  {!isDesktop && <><div className='home-widgets__container-right-col'>
                    <QuickActions isInstructor={isInstructor} isProfessional={isProfessional} showSummaries={showSummaries} />
                    {!isSelfStudyUser && <Assignments userId={userId} courseId={courseId} isSelfStudyUserCallback={isSelfStudyUserCallback} assessmentOnly={false} />}
                  </div>
                    <div className='home-widgets__container-left-col'>
                      <Widget class='lowest-scoring-topic-widget'>
                        <AssessmentTopics assessmentOnly={false} />
                      </Widget>
                      <RecentActivities assessmentOnly={false} />
                      {hasUSMLEBanks &&
                        <Widget class='lowest-scoring-topic-widget'>
                          <USMLEBooks />
                        </Widget>}
                    </div>
                  </>}
                  {isDesktop && <><div className='home-widgets__container-left-col'>
                    <Widget class='lowest-scoring-topic-widget'>
                      <AssessmentTopics assessmentOnly={false} />
                    </Widget>
                    {hasUSMLEBanks &&
                      <Widget class='lowest-scoring-topic-widget'>
                        <USMLEBooks />
                      </Widget>}
                  </div>
                    <div className='home-widgets__container-right-col'>
                      <QuickActions isInstructor={isInstructor} isProfessional={isProfessional} showSummaries={showSummaries} />
                      {!isSelfStudyUser && <Assignments courseId={courseId} userId={userId} isSelfStudyUserCallback={isSelfStudyUserCallback} assessmentOnly={false} />}
                      <RecentActivities assessmentOnly={false} />
                    </div></>}
                </div>
              </FeatureOn>
              <FeatureOff featureName='NewStudentHomePage'>
                <div className='home-widgets__container'>
                  <div className='home-widgets__container-left-col'>
                    <Widget>
                      <AssessmentTopics assessmentOnly={false} />
                    </Widget>
                  </div>
                </div>
              </FeatureOff>
            </>}
        </>
      )}
      {!isInstructor && hasFoundationAccess && !hasAssessmentAccess && (
        <Widget>
          <RecentItems />
        </Widget>
      )}
      {!isInstructor && isProfessional && hasFoundationAccess && hasAssessmentAccess && (
        <Widget>
          <RecentItems />
        </Widget>
      )}
      {!isInstructor && !hasFoundationAccess && hasAssessmentAccess && (
        <>
          <FeatureOn featureName='NewStudentHomePage'>
            <div className='home-widgets__container'>
              {!isDesktop && <><div className='home-widgets__container-right-col'>
                {!isSelfStudyUser && <Assignments courseId={courseId} isSelfStudyUserCallback={isSelfStudyUserCallback} assessmentOnly userId={userId} />}
              </div>
                <div className='home-widgets__container-left-col'>
                  <Widget class={widgetClass}>
                    <AssessmentTopics assessmentOnly />
                  </Widget>
                  <RecentActivities assessmentOnly />
                </div>
              </>}
              {isDesktop && <><div className='home-widgets__container-left-col'>
                <Widget class={widgetClass}>
                  <AssessmentTopics assessmentOnly />
                </Widget>
              </div>
                <div className='home-widgets__container-right-col'>
                  {!isSelfStudyUser && <Assignments userId={userId} courseId={courseId} isSelfStudyUserCallback={isSelfStudyUserCallback} assessmentOnly />}
                  <RecentActivities assessmentOnly />
                </div></>}
            </div>
          </FeatureOn>
          <FeatureOff featureName='NewStudentHomePage'>
            <div className='home-widgets__container'>
              <div className='home-widgets__container-left-col'>
                <Widget>
                  <AssessmentTopics assessmentOnly />
                </Widget>
              </div>
            </div>
          </FeatureOff>
        </>
      )}
      {isInstructor && hasFoundationAccess && hasAssessmentAccess && (
        <>
          <Widget class='c-ckm-widget--half'>
            <ELSWidgetActivitiesOverview
              courseId={courseId}
            />
          </Widget>
          <Widget class='c-ckm-widget--half'>
            <ELSWidgetActivitiesOverview
              courseId={courseId}
              lowestScoringTopic
            />
          </Widget>
        </>
      )}
      {isInstructor && hasFoundationAccess && !hasAssessmentAccess && (
        <Widget>
          <RecentItems />
        </Widget>
      )}
      {isInstructor && !hasFoundationAccess && hasAssessmentAccess && (
        <>
          <Widget class='c-ckm-widget--half'>
            <ELSWidgetActivitiesOverview
              courseId={courseId}
            />
          </Widget>
          <Widget class='c-ckm-widget--half'>
            <ELSWidgetActivitiesOverview
              courseId={courseId}
              lowestScoringTopic
            />
          </Widget>
        </>
      )}
    </div>
  );
};

export default HomeWidgets;
