import React from 'react';
import NNNSearchDropdown from './common/NNNSearchDropDown';

const NNNMobileSearch = () => {
  return (
    <div className='c-ckm-nnn__mobile-search' data-testid='nnn-mobile-search'><NNNSearchDropdown /></div>
  );
};

export default NNNMobileSearch;
