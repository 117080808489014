import React from 'react';
import { ELSUtilityHelper } from '@els/meded-ui-common-react';
import { Flyout } from '@els/els-react--flyout';
import { Button } from '@els/els-react--button';
import { FormattedMessage, defineMessages } from 'react-intl';
import useIntl from '../../hooks/use-intl';
import FeatureOn from '../../components/feature-flag/FeatureOn';
import FeatureOff from '../../components/feature-flag/FeatureOff';
import '../../assets/img/icon-help.svg';
import '../../assets/images/picto-0016-demonstrate-impact-128px-rgb.svg';
import '../../assets/images/TargetLowestScoringTopic.svg';
import '../../assets/img/AssessmentColored.svg';
import '../../assets/img/AssessmentOnly.svg';

const messages = defineMessages({
  notEnoughData: {
    id: 'AssessmentTopics.text.notEnoughData',
    defaultMessage: 'Not Enough Data'
  },
  lowestScoringTopics: {
    id: 'AssessmentTopics.text.lowestScoringTopics',
    defaultMessage: 'Lowest scoring topics'
  },
  basedOnPracticeQuestions: {
    id: 'AssessmentTopics.text.basedOnPracticeQuestions',
    defaultMessage: 'Based on practice questions you took'
  },
  seAllButtonLabel: {
    id: 'BookBestBets.labels.seeAllButton',
    defaultMessage: 'See all'
  },
  description: {
    id: 'AssessmentTopics.text.description',
    defaultMessage: 'Showing up to 3 topics from topics in which you have answered more than 10 questions'
  }
});
const productCode = ELSUtilityHelper.getNursingProductCode();

const NotEnoughData = ({emptyPrompt, haventTakenAnyPracticeTestsYet, getStartedCreatingFirstTest, assessmentOnly }) => {
  const intl = useIntl();
  return (
    <>
      <FeatureOn featureName='NewStudentHomePage'>
        <div
          className='o-els-flex-layout c-widget-assessment-topics__not-enough-data-v2-container'
          data-testid='assessment-topics-not-enough-data'
        >
          <div className='lower-scoring-topic'>
            <div className='lower-scoring-topic-header' id='empty-state-header'>
              <span className='lower-scoring-topic-header-title' id='empty-state-title-with-icon'>
                <h3>
                  {intl.formatMessage(messages.lowestScoringTopics)}
                  <Flyout
                    placement='top'
                    className='lower-scoring-topic-header-flyout'
                    modifiers={{flip: {enabled: false}, preventOverflow: {enabled: false}}}
                    trigger='hover'
                    id='help-flyout'
                    flyout={<span>{intl.formatMessage(messages.description)}</span>}
                  >
                    <svg height='24' width='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' aria-hidden='true' className='lower-scoring-topic-header-icon-help'>
                      <use xlinkHref='#icon-help' />
                    </svg>
                  </Flyout>
                </h3>
              </span>
              <p>{intl.formatMessage(messages.basedOnPracticeQuestions)}</p>
            </div>
          </div>
          <div className='c-els-divider c-els-divider--1o2 u-els-margin-top-1x1o2 u-els-margin-bottom-3x' />

          <svg width='72' height='72' viewBox='0 0 72 72'>
            <use xlinkHref='#AssessmentOnly' />
          </svg>

          <div className='c-widget-assessment-topics__not-enough-data-v2-prompt u-els-margin-top-1o2'>
            <div>{haventTakenAnyPracticeTestsYet}</div>
            <div>{getStartedCreatingFirstTest}</div>
          </div>
          <Button
            onClick={() => {
          window.location.href = ELSUtilityHelper.getUrlStudentCreateTestPage(productCode);
        }}
            type={Button.Types.PRIMARY}
            className='c-els-button create-a-test'
          >
            <FormattedMessage
              id='BookReader.button.createATest'
              defaultMessage='Create a test'
            />
          </Button>
        </div>
      </FeatureOn>
      <FeatureOff featureName='NewStudentHomePage'>
        <div
          className='o-els-flex-layout c-widget-assessment-topics__not-enough-data-container'
          data-testid='assessment-topics-not-enough-data'
        >
          <h3>{intl.formatMessage(messages.notEnoughData)}</h3>

          <svg width='73' height='73' viewBox='0 0 73 73'>
            <use xlinkHref='#AssessmentOnly' />
          </svg>

          <div className='c-widget-assessment-topics__not-enough-data-prompt'>{emptyPrompt}</div>
          <Button
            onClick={() => {
          window.location.href = ELSUtilityHelper.getUrlStudentCreateTestPage(productCode);
        }}
            type={Button.Types.PRIMARY}
            className='c-els-button--small'
          >
            <FormattedMessage
              id='BookReader.button.createATest'
              defaultMessage='Create a test'
            />
          </Button>
        </div>
      </FeatureOff>
    </>
  );
};

export default NotEnoughData;
