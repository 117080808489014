import React from 'react';
import PropTypes from 'prop-types';
import AriaModal from 'react-aria-modal';
import classNames from 'classnames';

const getApplicationNode = () => {
  return document.getElementById('app');
};

const getModalClass = (props) => classNames(
  'c-ckm-modal__window',
  {
    ['c-ckm-modal__window--' + props.size]: props.size,
    [props.classExtra]: props.classExtra
  }
);

const Modal = (props) => {
  return (
    <AriaModal
      dialogClass={getModalClass(props)}
      underlayClass='c-ckm-modal'
      includeDefaultStyles={false}
      focusDialog
      focusTrapPaused={props.focusTrapPaused || false}
      titleText={props.ariaTitleText}
      onExit={props.handleModalClose}
      getApplicationNode={getApplicationNode}
      underlayClickExits={props.underlayClickExits}
    >
      {props.children}
    </AriaModal>
  );
};

Modal.SIZES = {
  FULL: 'full',
  LARGE: 'large',
  DEFAULT: ''
};

Modal.propTypes = {
  handleModalClose: PropTypes.func,
  size: PropTypes.oneOf([
    Modal.SIZES.FULL,
    Modal.SIZES.LARGE,
    Modal.SIZES.DEFAULT
  ]),
  classExtra: PropTypes.string,
  ariaTitleText: PropTypes.string.isRequired
};

Modal.defaultProps = {
  handleModalClose: () => {}
};

Modal.displayName = 'Modal';

export default Modal;
