
const productHeader = 'x-ckm-product';

const DEFAULT = {
  context: 'student',
  path: '/student',
  regexp: /^\/student/,
  productCode: 'CK_MEDED',
  showVideos: true
};

const NURSING = {
  context: 'nursing',
  path: '/student/nursing',
  regexp: /^\/student\/nursing/,
  productCode: 'CK_MEDED_NURSING',
  showVideos: true
};

const RESIDENT = {
  context: 'resident',
  path: '/wba',
  regexp: /^\/wba/,
  productCode: 'CK_MEDED',
  showVideos: true
};

const configs = [DEFAULT, NURSING, RESIDENT];

module.exports = {
  productHeader,
  DEFAULT,
  configs
};
