import React from 'react';
import InternalLink from '../../../../components/common/InternalLink';
import { getPracticeImageThumbnailUrl } from '../../../../utils/stringUtils';
import { Icon } from '@els/els-react--icon';
import { CASE_STATES } from '../../utils/pjbConstants';

import {getStatusString} from '../../utils/helpers/caseStatusUtils';

const SimilarCaseSingle = (props) => {
  const { pii, casename, casedescription, status} = props.case;

  return (
    <li className='glide__slide'>
      <InternalLink
        className='c-ckm-practice__similar-case'
        href={`/practice/${pii}/`}
      >
        <div className='c-ckm-practice__similar-case-image'>
          <img src={getPracticeImageThumbnailUrl([`SPC/case-images/${pii}.jpg`])} alt={casename} className='c-ckm-practice__similar-case-image-asset' />
        </div>
        <p className='c-ckm-practice__similar-case-name'>
          {casename}
        </p>
        {status && status !== CASE_STATES.NOT_STARTED &&
          <p className={`c-ckm-practice__similar-case-started ${status === CASE_STATES.COMPLETED ? 'c-ckm-practice__similar-case-started--completed' : ''}`}>
            <Icon
              a11y={{ name: 'Patient chart' }}
              sprite={status === CASE_STATES.COMPLETED ? Icon.Sprites.CONFIRMATION_SOLID_CIRCLE : Icon.Sprites.CLOCK}
              className='u-els-margin-right-1o2'
              size={Icon.Sizes.XS}
              id='patient-chart-icon'
              textAlignment='middle'
              isVisible
            >
              {getStatusString(status, props.intl)}
            </Icon>
          </p>}
        <p className={`c-ckm-practice__similar-case-description ${status ? 'c-ckm-practice__similar-case-description--status' : ''}`}>{casedescription}</p>
      </InternalLink>
    </li>
  );
};

export default SimilarCaseSingle;
