const {map, split, values, mapObjIndexed, groupBy, head, compose, last, isNil, either, is, ifElse, identity, toString} = require('ramda');

// Confirmed with Richard that this should be a valid test
// We test that an object exists from the injected scripts, and that the 'readyState' is 'complete'
const analyticsLoaded = () => !isNil(window._analyticsStatus) && document.readyState === 'complete';

const groupByFacetName = groupBy(head);

const mapFacetTypes = mapObjIndexed((value, key) => {
  return {
    name: key,
    values: map(last, value)
  };
});

// ['+contenttype:BK', '+contenttype:IM', '+subjmain:Cardiology'] ->
// [{ name: '+contenttype', values: ['BK', 'IM']}, { name: '+subjmain', values: ['Cardiology'] }]
const formatFacetsForDTM = compose(
  values,
  mapFacetTypes,
  groupByFacetName,
  map(split(':'))
);

const isObjOrArr = either(is(Object), is(Array));
const ifElseStringify = ifElse(is(String), identity, toString);

const deepStringify = map((item) => {
  return ifElse(isObjOrArr, deepStringify, ifElseStringify)(item);
});

const trackEvent = (eventName, eventData = {}) => {
  const modifiedEventData = deepStringify(eventData);

  if (!isNil(window.__APP_INITIAL_STATE__) && !eventData.visitor) {
    modifiedEventData.visitor = window.__APP_INITIAL_STATE__.dtmPageData.visitor;
  }

  if (analyticsLoaded()) {
    pageDataTracker.trackEvent(eventName, modifiedEventData); // eslint-disable-line
  } else {
    queueEvent(eventName, modifiedEventData);
  }
};

const queueEvent = (eventName, eventData = {}) => {
  const analyticsInterval = setInterval(() => {
    if (analyticsLoaded()) {
      clearInterval(analyticsInterval);
      pageDataTracker.trackEvent(eventName, eventData); // eslint-disable-line
    }
  }, 250);
};

module.exports = {
  formatFacetsForDTM,
  trackEvent
};
