import React, {useEffect, useState, useContext} from 'react';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import { MainContext } from '../../context/main-context';
import { DEFAULT_COLLECTION_NAME} from '../../constants';

const messages = defineMessages({
  oneSlide: {
    id: 'Presentations.list.slide',
    defaultMessage: 'Slide'
  },
  multipleSlides: {
    id: 'Presentations.list.slides',
    defaultMessage: 'Slides'
  },
  empty: {
    id: 'Presentations.list.empty',
    defaultMessage: 'Empty'
  },
  forLater: {
    id: 'saveButton.toast.forLater',
    defaultMessage: 'For later'
  },
  skills: {
    id: 'saveButton.skills',
    defaultMessage: 'skills'
  },
  skill: {
    id: 'saveButton.skill',
    defaultMessage: 'skill'
  }
});

const getSlideWording = (slideCount, intl) => {
  if (slideCount > 1) {
    return ` ${intl.formatMessage(messages.multipleSlides)}`;
  }
  return slideCount === 1 ? ` ${intl.formatMessage(messages.oneSlide)}` : intl.formatMessage(messages.empty);
};

const MenuListItem = (props) => {
  const { intl } = useContext(MainContext);
  const [menuItem, setMenuItem] = useState(props.item);

  useEffect(() => {
    setMenuItem(props.item);
  }, [props.label, props.item]);

  const condition = menuItem.category || menuItem.id || menuItem.specialty;

  return (
    <>
      <div
        className={`c-ckm-menu-list__item ${props.isSelected && 'c-ckm-menu-list__item--selected'}`}
        onClick={() => props.clickHandler(condition)}
        onKeyDown={() => props.clickHandler(condition)}
        role='button'
        tabIndex={props.key}
      >
        <button className='c-ckm-link'>
          {menuItem.name === DEFAULT_COLLECTION_NAME ? intl.formatMessage(messages.forLater) : menuItem.name || menuItem.category || menuItem.specialty}
        </button>
        {menuItem.slideCount !== undefined && <p className='c-ckm-slide_count'>{menuItem.slideCount === 0 ? '' : menuItem.slideCount}{getSlideWording(menuItem.slideCount, intl)}</p>}
        {menuItem.titles && <p className='c-ckm-menu-list__item-title'>{menuItem.titles.length} {menuItem.titles.length > 1 ? intl.formatMessage(messages.skills) : intl.formatMessage(messages.skill)}</p>}
      </div>
    </>
  );
};

MenuListItem.displayName = 'MenuListItem';
export default MenuListItem;

MenuListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  isSelected: PropTypes.bool,
  clickHandler: PropTypes.func,
  key: PropTypes.number
};
