import React, { useEffect, useState } from 'react';
import { Pill } from '@els/els-react--pill';
import SimilarCaseSingle from './SimilarCaseSingle';
import CKApi from '../../../../services/api-helper';
import InlineLoader from '../../../../components/common/InlineLoader';
import { pluck, propEq, findIndex } from 'ramda';
import Glide from '@glidejs/glide';
import CasesSliderArrows from './CasesSliderArrows';
import { defineMessages } from 'react-intl';
import { TabGroup, TabPanel } from '@els/els-react--tab-group';
import ResizeDetector from 'react-resize-detector';

const messages = defineMessages({
  homepageFeaturedSectionNoCases: {
    id: 'Home.featuredCasesSection.noCases',
    defaultMessage: 'No featured cases'
  }
});

export const FACETS = {
  SPECIALTIES: 'Specialties',
  SETTINGS: 'Settings',
  PREVALENCE: 'Prevalence',
  DIFFICULTY: 'Difficulty',
  CHIEF_COMPLAINT: 'Chief Complaint',
  BODY_SYSTEMS: 'Body Systems',
  AGE_CATEGORY: 'Age Category'
};

export const FACET_QUERIES = {
  Specialties: 'subjmain',
  'Chief Complaint': 'chiefcomplaint'
};

const FeaturedCases = (props) => {
  const [cases, setCases] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [tabPanels, setTabPanels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const CaseCount = 'glide__hp-count--' + cases.length;

  useEffect(() => {
    if (!isLoading && cases.length !== 0) {
      const selector = `.${FACET_QUERIES[props.type]}-${activeTabIndex}`;
      const slider = document.querySelector(selector);

      if (slider) {
        const glide = new Glide(slider, {
          bound: true,
          gap: 32,
          peek: 0,
          perView: 4,
          rewind: false,
          type: 'slider',
          breakpoints: {
            1300: {
              perView: 3
            },
            700: {
              perView: 2.5
            },
            400: {
              perView: 1.5
            }
          }
        });

        glide.mount();
      }
    }
  }, [isLoading, tabPanels]);

  useEffect(() => {
    CKApi.get('/student/api/browse/spc', {
      params: {
        rows: 99
      }
    })
      .then(({data}) => {
        const formattedFacets = data.facet_counts[props.type].map((item, index) => {
          return ({name: item.name, isChecked: index === 0});
        });
        setTabs(formattedFacets);
        getCases(formattedFacets[0].name);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    getTabPanels(tabs);
  }, [cases, tabs]);

  const getCases = (tab) => {
    setIsLoading(true);
    CKApi.get('/student/api/browse/spc', {
      params: {
        rows: 12,
        facetquery:
          `+${FACET_QUERIES[props.type]}:${tab}`
      }
    })
      .then(({data}) => {
        setCases(data.docs);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    // Retrieve statuses of featured cases
    if (isLoading) {
      return;
    }
    const caseIds = pluck('pii', cases);
    if (cases.length > 0) {
      CKApi.post('/student/api/guideline/statuses', { caseIds })
        .then(({data}) => {
          // Add case status to cases
          setCases(cases.map((element) => {
            data.forEach(obj => {
              if (obj.caseId === element.pii) {
                const dataIndex = findIndex(propEq('caseId', element.pii))(data);
                element.status = data[dataIndex].status;
              }
            });
            return element;
          }));
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [isLoading]);

  const getFormattedTabs = () => {
    const formattedTabs = [];
    tabs.forEach((item, index) => {
      formattedTabs.push(<Pill className='c-ckm-practice__case-filter-pill' key={item.name + index} label={item.name} />);
    });
    return formattedTabs;
  };

  const similarCasesList = () => {
    return (
      <div className='glide__track' data-glide-el='track'>
        <ul className='glide__slides'>
          {cases.map((item, index) => <SimilarCaseSingle intl={props.intl} key={item.pii + index} case={item} />)}
        </ul>
      </div>
    );
  };

  const handleTabChange = (tabIndex) => {
    setActiveTabIndex(tabIndex);
    getCases(tabs[tabIndex].name);
    getTabPanels(tabs);
  };

  const onResize = () => {
    if (window.innerWidth > 700) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  };

  const getTabPanels = (tabsToRender) => {
    const newPanels = [];
    tabsToRender.forEach((item, index) => {
      newPanels.push(<TabPanel>
        {isLoading && <InlineLoader />}
        {!isLoading && <div className='c-ckm-practice__similar-cases-list'>
          {cases.length !== 0 && <div className={`glide ${FACET_QUERIES[props.type]}-${index} ${CaseCount}`}>
            {tabsToRender[activeTabIndex]?.name === item.name ? similarCasesList() : ''}
            <CasesSliderArrows />
          </div>}
          {cases.length === 0 && <p className='c-ckm-practice__similar-cases-empty'>{props.intl.formatMessage(messages.homepageFeaturedSectionNoCases)}</p>}
        </div>}
      </TabPanel>);
    });
    setTabPanels(newPanels);
  };

  return (
    <div className='c-ckm-practice__similar-cases c-ckm-practice__similar-cases--featured'>
      <ResizeDetector
        handleWidth
        onResize={() => onResize()}
        refreshOptions={{ trailing: true }}
      />
      <h2 className='c-ckm-practice__featured-cases-title'>{props.title}</h2>
      <TabGroup noDropdown={!isMobile} onTabChange={handleTabChange} tabs={getFormattedTabs()}>
        {tabPanels}
      </TabGroup>
      {isLoading && <InlineLoader />}
    </div>
  );
};

export default FeaturedCases;
