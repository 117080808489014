import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import useIntl from '../../hooks/use-intl';
import { Button } from '@els/els-react--button';
import { Icon } from '@els/els-react--icon';

const messages = defineMessages({
  removeButtonLabel: {
    id: 'sectionButton.removeButtonLabel',
    defaultMessage: 'Remove'
  },
  addButtonLabel: {
    id: 'sectionButton.addButtonLabel',
    defaultMessage: 'Add'
  }
});

const SectionButtons = (props) => {
  const intl = useIntl();
  const [isSelected, setIsSelected] = useState(props.isSelected ? props.isSelected : false);
  const iconSprite = isSelected ? Icon.Sprites.MINUS : Icon.Sprites.PLUS;
  const buttonLabel = isSelected ? intl.formatMessage(messages.removeButtonLabel) : intl.formatMessage(messages.addButtonLabel);

  const handleButtonClick = (evt) => {
    setIsSelected(!isSelected);
    if (props.buttonHandler) props.buttonHandler(!isSelected, props.sectionId);
  };

  return (
    <div className='c-ckm-section-buttons'>
      <Button
        onClick={handleButtonClick}
        type={Button.Types.LINK}
        className='c-ckm-section-button'
        data-testid='sectionBtnTestId'
        id='section-btn'
        linkOptions={['icon']}
      >
        <Icon
          a11y={{ name: buttonLabel }}
          sprite={iconSprite}
          className=''
          textAlignment='top'
          size={Icon.Sizes.XS}
          isVisible
          id='section-btn-icon'
        >
          {buttonLabel}
        </Icon>
      </Button>
    </div>
  );
};

SectionButtons.displayName = 'SectionButtons';

SectionButtons.propTypes = {
  sectionId: PropTypes.string,
  isSelected: PropTypes.bool,
  buttonHandler: PropTypes.func,
  addCallback: PropTypes.func,
  removeCallback: PropTypes.func
};

export default SectionButtons;
