import React, {Fragment, useMemo} from 'react';
import {defineMessages} from 'react-intl';
import PropTypes from 'prop-types';
import useIntl from '../../hooks/use-intl';

const messages = defineMessages({
  label: {
    id: 'ArticleInPress.text.label',
    defaultMessage: 'Article in Press'
  },
  S5: {
    id: 'ArticleInPress.text.acceptedManuscript',
    defaultMessage: 'Accepted Manuscript'
  },
  S100: {
    id: 'ArticleInPress.text.uncorrectedProof',
    defaultMessage: 'Uncorrected Proof'
  },
  S200: {
    id: 'ArticleInPress.label.text',
    defaultMessage: 'Corrected Proof'
  }
});

const getAipLabel = (status, intl) => {
  if (status === 'S5' || status === 'S100' || status === 'S200') {
    return `${intl.formatMessage(messages.label)}: ${intl.formatMessage(messages[status])}`;
  }
};

export const ArticleInPressLabel = (props) => {
  const intl = useIntl();
  const aipText = useMemo(() => getAipLabel(props.status, intl), [props.status, intl]);

  return (
    <>
      {aipText && <span className='u-ckm-text-color-utility--negative'>
        {aipText}
      </span>}
    </>
  );
};

ArticleInPressLabel.propTypes = {
  status: PropTypes.string.isRequired
};
