import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, defineMessages} from 'react-intl';
import { useIntl } from '../../hooks';
import { MainContext } from '../../context/main-context';
import FeatureOn from '../../components/feature-flag/FeatureOn';
import FeatureOff from '../../components/feature-flag/FeatureOff';
import '../../assets/img/picto-book.svg';
import '../../assets/img/picto-faceted-search.svg';
import '../../assets/img/picto-health-educator.svg';
import '../../assets/img/picto-journals.svg';
import '../../assets/img/picto-manuscript.svg';
import '../../assets/img/picto-multimedia.svg';

const messages = defineMessages({
  header: {
    id: 'Search.noResults.header',
    defaultMessage: 'No results for'
  },
  suggestions: {
    id: 'Search.noResults.suggestions',
    defaultMessage: 'Suggestions'
  },
  noQuery: {
    id: 'Search.noResults.noQuery',
    defaultMessage: 'Enter a search query to find targeted information, images, videos and more.'
  },
  noQueryKeywords: {
    id: 'Search.noResults.noQueryKeywords',
    defaultMessage: 'Some effective keywords include:'
  },
  noQueryKeywords1: {
    id: 'Search.noResults.noQueryKeywords1',
    defaultMessage: 'Medical terms and concepts (e.g cascade coagulation)'
  },
  noQueryKeywords2: {
    id: 'Search.noResults.noQueryKeywords2',
    defaultMessage: 'Diseases (e.g hypertension)'
  },
  noQueryKeywords3: {
    id: 'Search.noResults.noQueryKeywords3',
    defaultMessage: 'Anatomical terms (e.g heart anatomy)'
  },
  noQueryKeywords4: {
    id: 'Search.noResults.noQueryKeywords4',
    defaultMessage: 'Medical procedures (e.g dissection of aorta)'
  },
  noQueryKeywords5: {
    id: 'Search.noResults.noQueryKeywords5',
    defaultMessage: 'Drugs/ medications (e.g insulin)'
  },
  noQueryKeywords6: {
    id: 'Search.noResults.noQueryKeywords6',
    defaultMessage: 'Book titles or authors ( e.g Gray’s anatomy, Robbins)'
  },
  suggestionSpelling: {
    id: 'Search.noResults.suggestions.spelling',
    defaultMessage: 'Make sure all words are spelled correctly.'
  },
  allEffectiveKeywords: {
    id: 'Search.noResults.suggestions.all.effectiveKeywords',
    defaultMessage: 'Try different effective keywords including:'
  },
  allEffectiveKeywords1: {
    id: 'Search.noResults.suggestions.all.effectiveKeywords1',
    defaultMessage: 'Medical terms and concepts'
  },
  allEffectiveKeywords2: {
    id: 'Search.noResults.suggestions.all.effectiveKeywords2',
    defaultMessage: 'Diseases'
  },
  allEffectiveKeywords3: {
    id: 'Search.noResults.suggestions.all.effectiveKeywords3',
    defaultMessage: 'Medical procedures'
  },
  allEffectiveKeywords4: {
    id: 'Search.noResults.suggestions.all.effectiveKeywords4',
    defaultMessage: 'Drugs/medications'
  },
  allEffectiveKeywords5: {
    id: 'Search.noResults.suggestions.all.effectiveKeywords5',
    defaultMessage: 'Exact textbook titles'
  },
  allIneffectiveKeywords: {
    id: 'Search.noResults.suggestions.all.ineffectiveKeywords',
    defaultMessage: 'Avoid using ineffective keywords including:'
  },
  allIneffectiveKeywords1: {
    id: 'Search.noResults.suggestions.all.ineffectiveKeywords1',
    defaultMessage: 'Disciplines'
  },
  allIneffectiveKeywords2: {
    id: 'Search.noResults.suggestions.all.ineffectiveKeywords2',
    defaultMessage: 'Systems'
  },
  allIneffectiveKeywords3: {
    id: 'Search.noResults.suggestions.all.ineffectiveKeywords3',
    defaultMessage: 'Combined topics'
  },
  booksSearch: {
    id: 'Search.noResults.suggestions.books.search',
    defaultMessage: 'Search for books including:'
  },
  booksSearch1: {
    id: 'Search.noResults.suggestions.books.search1',
    defaultMessage: 'Exact textbook titles (e.g “Robbins & Kumar Basic Pathology”)'
  },
  booksSearch2: {
    id: 'Search.noResults.suggestions.books.search2',
    defaultMessage: 'Author name (e.g “Gray”)'
  },
  booksRemove: {
    id: 'Search.noResults.suggestions.books.remove',
    defaultMessage: 'Remove “book” in your search term (e.g “physiology book”)'
  },
  booksAvoid: {
    id: 'Search.noResults.suggestions.books.avoid',
    defaultMessage: 'Avoid searching with name of title and author together (e.g "Gray\'s anatomy Richard")'
  },
  stillCantFind1: {
    id: 'Search.noResults.stillCantFind1',
    defaultMessage: 'If you still can’t find what you’re looking for, send us'
  },
  stillCantFind2: {
    id: 'Search.noResults.stillCantFind2',
    defaultMessage: 'feedback'
  },
  stillCantFind3: {
    id: 'Search.noResults.stillCantFind3',
    defaultMessage: 'or'
  },
  stillCantFind4: {
    id: 'Search.noResults.stillCantFind4',
    defaultMessage: 'contact us'
  }
});

const returnContent = (intl, term, tab) => {
  if (term === '') { // No search query
    return (
      <>
        <h4>{intl.formatMessage(messages.noQuery)}</h4>
        <h4 className='u-els-margin-top'>{intl.formatMessage(messages.noQueryKeywords)}</h4>
        <div className='c-ckm-search-noresults__suggestions'>
          <ul className='c-els-list'>
            <li className='c-els-list__item'>{intl.formatMessage(messages.noQueryKeywords1)}</li>
            <li className='c-els-list__item'>{intl.formatMessage(messages.noQueryKeywords2)}</li>
            <li className='c-els-list__item'>{intl.formatMessage(messages.noQueryKeywords3)}</li>
            <li className='c-els-list__item'>{intl.formatMessage(messages.noQueryKeywords4)}</li>
            <li className='c-els-list__item'>{intl.formatMessage(messages.noQueryKeywords5)}</li>
            <li className='c-els-list__item'>{intl.formatMessage(messages.noQueryKeywords6)}</li>
          </ul>
        </div>
      </>
    );
  }

  switch (tab) {
    case 'Books':
      return (
        <>
          <h3 className='u-els-margin-bottom'>{intl.formatMessage(messages.header)} <strong>{term}.</strong></h3>
          <h4>{intl.formatMessage(messages.suggestions)}:</h4>
          <div className='c-ckm-search-noresults__suggestions'>
            <ul className='c-els-list'>
              <li className='c-els-list__item'>{intl.formatMessage(messages.suggestionSpelling)}</li>
              <li className='c-els-list__item'>
                {intl.formatMessage(messages.booksSearch)}
                <ul className='c-els-list'>
                  <li className='c-els-list__item'>{intl.formatMessage(messages.booksSearch1)}</li>
                  <li className='c-els-list__item'>{intl.formatMessage(messages.booksSearch2)}</li>
                </ul>
              </li>
              <li>{intl.formatMessage(messages.booksRemove)}</li>
              <li>{intl.formatMessage(messages.booksAvoid)}</li>
            </ul>
          </div>
        </>
      );
    default:
      return (
        <>
          <h3 className='u-els-margin-bottom'>{intl.formatMessage(messages.header)} <strong>{term}.</strong></h3>
          <h4>{intl.formatMessage(messages.suggestions)}:</h4>
          <div className='c-ckm-search-noresults__suggestions'>
            <ul className='c-els-list'>
              <li className='c-els-list__item'>{intl.formatMessage(messages.suggestionSpelling)}</li>
              <li className='c-els-list__item'>
                {intl.formatMessage(messages.allEffectiveKeywords)}
                <ul className='c-els-list'>
                  <li className='c-els-list__item'>{intl.formatMessage(messages.allEffectiveKeywords1)}</li>
                  <li className='c-els-list__item'>{intl.formatMessage(messages.allEffectiveKeywords2)}</li>
                  <li className='c-els-list__item'>{intl.formatMessage(messages.allEffectiveKeywords3)}</li>
                  <li className='c-els-list__item'>{intl.formatMessage(messages.allEffectiveKeywords4)}</li>
                  <li className='c-els-list__item'>{intl.formatMessage(messages.allEffectiveKeywords5)}</li>
                </ul>
              </li>
              <li>
                {intl.formatMessage(messages.allIneffectiveKeywords)}
                <ul className='c-els-list'>
                  <li className='c-els-list__item'>{intl.formatMessage(messages.allIneffectiveKeywords1)}</li>
                  <li className='c-els-list__item'>{intl.formatMessage(messages.allIneffectiveKeywords2)}</li>
                  <li className='c-els-list__item'>{intl.formatMessage(messages.allIneffectiveKeywords3)}</li>
                </ul>
              </li>
            </ul>
          </div>
        </>
      );
  }
};

const returnImage = (tab) => {
  switch (tab) {
    case 'Books':
      return '#picto-book';
    case 'Images':
      return '#picto-multimedia';
    case 'Videos':
      return '#picto-multimedia';
    case 'Summaries':
      return '#picto-manuscript';
    case 'Journals':
      return '#picto-journals';
    case 'EMC':
      return '#picto-journals';
    case 'Teaching Materials':
      return '#picto-health-educator';
    default:
      return '#picto-faceted-search';
  }
};

const NoResults = (props) => {
  const context = useContext(MainContext);
  const intl = useIntl();
  const {FEEDBACK_LINK, EMAIL_US_LINK } = context.localeConfig;

  return (
    <div data-testid='searchNoResults'>
      <FeatureOn featureName='search2'>
        <div className='c-ckm-search-noresults'>
          <svg className='c-ckm-search-noresults__image'>
            <use xlinkHref={returnImage(props.tab)} />
          </svg>
          {returnContent(intl, props.term, props.tab)}

          {props.term &&
            <p>
              {intl.formatMessage(messages.stillCantFind1)}&nbsp;
              <a href={FEEDBACK_LINK} target='_blank' rel='noreferrer'>{intl.formatMessage(messages.stillCantFind2)}</a>&nbsp;
              {intl.formatMessage(messages.stillCantFind3)}&nbsp;
              <a href={EMAIL_US_LINK} target='_blank' rel='noreferrer'>{intl.formatMessage(messages.stillCantFind4)}</a>
            </p>}
        </div>
      </FeatureOn>
      <FeatureOff featureName='search2'>
        <div>
          <h3>
            <FormattedMessage
              id='SearchPage.text.resultNotFound'
              defaultMessage='Sorry, there are no results that match the search'
            /> "{props.term ? `${props.term}` : ''}"
          </h3>
        </div>
      </FeatureOff>
    </div>
  );
};

NoResults.propTypes = {
  term: PropTypes.string,
  tab: PropTypes.string
};

NoResults.defaultProps = {
  tab: 'All'
};

NoResults.displayName = 'NoResults';

export default NoResults;
