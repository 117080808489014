import React from 'react';
import { Button } from '@els/els-react--button';
import PropTypes from 'prop-types';

export const MediaModelError = ({className, title, body, onClose, onClick, mainButtonText}) => <div className={`media-model-error__container ${className}`} data-testid='MMEC'>
  <div className='media-model-error__body'>
    <div className='media-model-error__icon' data-testid='toast-icon'>
      <span className='u-els-color-primary'>
        <svg className='o-els-icon-svg o-els-icon-svg--2x' alt='toast-icon'>
          <use href='#icon-sprite_els-hmds-icon-alert-triangle' />
        </svg>
      </span>
    </div>
    <div className='media-model-error__content'>
      <div className='media-model-error__title'>{title}
      </div>
      <div className='media-model-error__text'>
        {body}
      </div>
    </div>
    <div className='c-els-toast__close'>
      <button onClick={onClose} className='button button--link u-els-anchorize c-els-link c-els-modal__close c-els-link--with-icon' type='button' id='modal-close-button'>
        <span className='c-els-link__icon icon__wrapper' id='modal-container-close-icon'>
          <svg viewBox='14 28 42 69' aria-hidden='false' aria-labelledby='modal-container-close-icon-name modal-container-close-icon-description' id='modal-container-close-icon-sprite' className='icon o-els-icon-svg o-els-icon-svg--1x o-els-icon-svg--middle' role='img'>
            <title id='modal-container-close-icon-name'>Close button</title><desc id='modal-container-close-icon-description'>Close the modal</desc>
            <path d='M68.94 36.12L62 29 35 56 8 29l-7 7 27 27L1 90l7 7 27-27 27 27 7-7-27-27 26.94-26.88' />
          </svg>
        </span>
      </button>
    </div>
  </div>
  <div className='media-model-error__btn-container'>
    <Button
      type={Button.Types.SECONDARY}
      className='c-els-button--small '
      onClick={onClick}
      data-testid='backToChapterBtn'
    >
      {mainButtonText}
    </Button>
  </div>
</div>;

MediaModelError.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  mainButtonText: PropTypes.string
};
