import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { head, trim, find, propEq, has, insert, map, test, when, filter, compose, isEmpty } from 'ramda';
import queryStringUtils, { stringifyFacetArray } from '../utils/queryStringUtils';
import { isMobile, isXSmall } from '../utils/elementUtils';
import { defineMessages } from 'react-intl';
import CKApi, { CancelToken } from '../services/api-helper';
import withOutsideEvent from './common/withOutsideEvent';
import Autosuggest from 'react-autosuggest';
import Announcements from './common/Announcements';
import { getBookThumbnailUrl, getContentViewPath, getEMCThumbnailUrl } from '../../src/utils/stringUtils';
import { Icon } from '@els/els-react--icon';
import { MainContext } from '../context/main-context';
import SearchDropdown from './SearchDropdown';
import { Flyout } from '@els/els-react--flyout';
import { CONTENT_TYPE_NAMES } from '../constants';

const messages = defineMessages({
  searchBarPlaceholder: {
    id: 'SearchPage.placeholder.searchBar',
    defaultMessage: 'Search medical concepts, diseases, book titles...'
  },
  searchBarPlaceHolderNoVideos: {
    id: 'SearchPage.placeholder.searchbarNoVideos',
    defaultMessage: 'Search books and images'
  },
  searchBarPlaceholderMobile: {
    id: 'SearchPage.placeholder.searchBarMobile',
    defaultMessage: 'Search medical concepts, book titles...'
  },
  searchBarArialabel: {
    id: 'SearchPage.arialabel.searchBar',
    defaultMessage: 'Search books, images, and videos'
  },
  searchBarArialabelNoVideos: {
    id: 'SearchPage.arialabel.searchBarNoVideos',
    defaultMessage: 'Search books and images'
  },
  submitArialabel: {
    id: 'SearchPage.arialabel.submit',
    defaultMessage: 'Search'
  },
  submitTooltipText: {
    id: 'SearchPage.tooltip.submit',
    defaultMessage: 'Search'
  },
  clearSearchArialabel: {
    id: 'SearchPage.arialabel.clear',
    defaultMessage: 'Clear search'
  },
  clearSearchTooltipText: {
    id: 'SearchPage.tooltip.clear',
    defaultMessage: 'Clear'
  },
  searchBarAllTypes: {
    id: 'SearchPage.dropdown.types',
    defaultMessage: 'All types'
  },
  searchBarAllTypesMobile: {
    id: 'SearchPage.dropdown.typesMobile',
    defaultMessage: 'All'
  },
  searchBarBooks: {
    id: 'SearchPage.dropdown.books',
    defaultMessage: 'Books'
  },
  searchBarEMC: {
    id: 'SearchPage.dropdown.emc',
    defaultMessage: 'EMC'
  },
  searchBarJournals: {
    id: 'SearchPage.dropdown.journals',
    defaultMessage: 'Journals'
  },
  searchBarImages: {
    id: 'SearchPage.dropdown.images',
    defaultMessage: 'Images'
  },
  searchBarVideos: {
    id: 'SearchPage.dropdown.videos',
    defaultMessage: 'Videos'
  },
  searchBarANC: {
    id: 'SearchPage.dropdown.anc',
    defaultMessage: 'Teaching material'
  },
  searchBarSPC: {
    id: 'SearchPage.dropdown.spc',
    defaultMessage: 'Cases'
  },
  searchBarSUM: {
    id: 'SearchPage.dropdown.sum',
    defaultMessage: 'Summaries'
  },
  searchBarAutosuggestAnnouncement: {
    id: 'SearchPage.autosuggest.announcementv2',
    defaultMessage: '{count} auto suggest results for term {searchTerm}'
  },
  searchBarOnlyBooks: {
    id: 'SearchPage.placeholder.onlyBooks',
    defaultMessage: 'Search books'
  },
  searchBarPlaceholderNursing: {
    id: 'SearchPage.placeholder.searchBarNursing',
    defaultMessage: 'Search nursing topics, book titles, multimedia...'
  },
  searchBarPlaceholderNursingMobile: {
    id: 'SearchPage.placeholder.searchBarNursingMobile',
    defaultMessage: 'Search nursing topics, book titles...'
  },
  searchBarPlaceholderBooks: {
    id: 'SearchPage.placeholder.searchBarPlaceholderBooks',
    defaultMessage: 'Search books by title or author'
  },
  searchBarPlaceholderImages: {
    id: 'SearchPage.placeholder.searchBarPlaceholderImages',
    defaultMessage: 'Search images by concepts or diseases'
  },
  searchBarPlaceholderVideos: {
    id: 'SearchPage.placeholder.searchBarPlaceholderVideos',
    defaultMessage: 'Search videos by concepts or procedures'
  },
  searchBarPlaceholderTM: {
    id: 'SearchPage.placeholder.searchBarPlaceholderTM',
    defaultMessage: 'Search teaching materials by book titles, key concepts'
  },
  searchBarPlaceholderEMC: {
    id: 'SearchPage.placeholder.searchBarPlaceholderEMC',
    defaultMessage: 'Search EMC by title or concepts'
  },
  searchBarPlaceholderJL: {
    id: 'SearchPage.placeholder.searchBarPlaceholderJL',
    defaultMessage: 'Search journals by title or key concepts'
  },
  searchBarPlaceholderUM: {
    id: 'SearchPage.placeholder.searchBarPlaceholderUM',
    defaultMessage: 'Search summaries by diseases or conditions'
  }
});

// add more complex validation here - slashes, special characters, etc
const isSearchTextValid = (text) => text.length !== 0;

// trim whitespace, remove/encode invalid characters, etc
const sanitizeQueryText = compose(
  encodeURIComponent,
  trim
);

const addBold = (text) => <b>{text}</b>;

const contentTypeFilter = filter(test(/contenttype/));

const generateSearchUrl = (searchText, scope, source, isSearchingWithin, sourceId, contenttype) => {
  const query = sanitizeQueryText(searchText);

  const queryParams = {};

  const getFacets = (contenttype) => {
    switch (contenttype) {
      case 'BK':
      case 'IM':
      case 'VD':
      case 'EM':
      case 'JL':
      case 'UM':
        return `&facets=!ct~${contenttype}`; // TO DO add Summaries and etc.
      case 'NC':
        return '&facets=!ct~ANC';
      case '':
      case 'DV': // default value, when contenttype is not defined
        return '';
    }
  };

  if (source) {
    queryParams.source = source;
  }

  if (scope) {
    queryParams.facets = stringifyFacetArray([scope]);
  }

  let url;
  if (isSearchingWithin) {
    url = `/search/source/${sourceId}/${query}`;
  } else {
    contenttype ? url = isEmpty(queryParams) ? `/search/v2/${query}${getFacets(contenttype)}` : `/search/v2/${query}?${queryStringUtils.stringify(queryParams)}${getFacets(contenttype)}` : url = isEmpty(queryParams) ? `/search/${query}` : `/search/${query}?${queryStringUtils.stringify(queryParams)}`; // delete ternary operator for search v2
  }
  return url;
};

const generateClearButtonClass = (searchText) => {
  return classNames({
    'c-ckm-searchbar__clear': true,
    'js-active': searchText.length
  });
};

const generateSearchButtonClass = (searchText) => {
  return classNames({
    'c-ckm-searchbar__submit': true,
    'js-active': searchText.length
  });
};

const generateIconBlackClass = (searchText, loopView) => {
  if (!loopView || searchText.length) {
    return 'c-ckm-searchbar__icon-result';
  } else {
    return 'c-ckm-searchbar__icon-none';
  }
};

const generateIconBlueClass = (searchText, loopView) => {
  if (!loopView || searchText.length) {
    return 'c-ckm-searchbar__icon-none';
  } else {
    return 'c-ckm-searchbar__icon-search';
  }
};

const atLeastOneLetter = 1;

class SearchBar extends Component {
  static contextType = MainContext;

  state = {
    searchText: this.props.searchTerm || '',
    selectedScope: {},
    suggestions: [],
    scopeData: [],
    activeSuggestion: null,
    autosuggestAnnouncement: { count: 0, term: null },
    source: this.props.source,
    loopView: true,
    isTabs: false,
    isRecent: !!this.props.searchHistory?.length,
    activeTab: this.props.searchHistoryContenttype || ''
  };

  wrapperRef = React.createRef();
  handleClickOutside = this.handleClickOutside.bind(this);

  autoSuggestCancelToken = null;
  autosuggestRef = React.createRef();

  componentWillMount () {
    const { user, productConfig, intl } = this.context;
    localStorage.setItem('searchName', this.state.searchText);

    let scopeData = [{
      label: isMobile() ? intl.formatMessage(messages.searchBarAllTypesMobile) : intl.formatMessage(messages.searchBarAllTypes),
      value: null
    }, {
      label: intl.formatMessage(messages.searchBarBooks),
      value: '+contenttype:BK'
    }, {
      label: intl.formatMessage(messages.searchBarImages),
      value: '+contenttype:IM'
    }
    ];

    if (user.hasEMCAccess()) {
      scopeData = insert(2, {
        label: this.context.intl.formatMessage(messages.searchBarEMC),
        value: '+contenttype:EM'
      }, scopeData);
    }

    if (user.hasJournalAccess(productConfig.context)) {
      scopeData = insert(2, {
        label: this.context.intl.formatMessage(messages.searchBarJournals),
        value: '+contenttype:JL'
      }, scopeData);
    }

    if (productConfig.showVideos) {
      scopeData.push({
        label: this.context.intl.formatMessage(messages.searchBarVideos),
        value: '+contenttype:VD'
      });
    }

    if (user.hasANCAccess(productConfig.context)) {
      scopeData.push({
        label: this.context.intl.formatMessage(messages.searchBarANC),
        value: '+contenttype:ANC'
      });
    }

    if (user.hasSummariesAccess(productConfig.context)) {
      scopeData.push({
        label: this.context.intl.formatMessage(messages.searchBarSUM),
        value: '+contenttype:SUM'
      });
    }
    // if (user.hasPracticeAccess()) {
    //   scopeData.push({
    //     label: 'Cases',
    //     value: '+contenttype:SPC'
    //   });
    // }

    this.setState({ scopeData: scopeData, selectedScope: head(scopeData) });
  }

  componentDidMount () {
    this.handleScopeChange();
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentWillReceiveProps () {
    this.handleScopeChange();
  }

  handleClickOutside (event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({
        isTabs: false,
        activeTab: ''
      });
    }
  }

  static propTypes = {
    source: PropTypes.string
  };

  handleScopeChange = () => {
    const facets = this.getOptionsFromSearchString(window.location.search) || [];
    let selectedScope = null;
    const contentTypeFilters = contentTypeFilter(facets);

    if (facets && contentTypeFilters.length === 1) {
      selectedScope = find(propEq('value', contentTypeFilters[0]))(this.state.scopeData);
      selectedScope && this.setState({ selectedScope: selectedScope });
    } else {
      this.setState({ selectedScope: head(this.state.scopeData) });
    }
  };

  getOptionsFromSearchString = (searchString = '') => {
    const parsedSearch = queryStringUtils.parse(searchString);
    if (has('facets')(parsedSearch)) {
      return queryStringUtils.parseFacetArray(parsedSearch.facets);
    }
  };

  getLocale = (loc) => {
    switch (loc) {
      case 'en':
        return 'en_US';
      case 'fr':
        return 'fr_FR';
      case 'es':
        return 'es_ES';
      case 'de':
        return 'de_DE';
      case 'pt':
        return 'pt_BR';
    }
  };

  handleSearchInputChange = (event, { newValue }) => {
    localStorage.setItem('searchName', newValue);
    this.setState({ searchText: newValue });
  };

  getAutosuggestResults = ({ value, reason }) => {
    if (value.length >= 2) {
      if (this.autoSuggestCancelToken) {
        this.autoSuggestCancelToken.cancel();
      }

      this.autoSuggestCancelToken = CancelToken.source();
      const locale = this.getLocale(this.context.intl.locale);

      this.callAutosuggestAPI(value, locale)
        .then(({ data }) => this.handleAutoSuggestResponse(data, value))
        .catch((error) => {
          if (CKApi.isCancel && !CKApi.isCancel(error)) {
            this.setState({ errorMessage: 'Suggestions Failed' });
          }
        });
    } else {
      this.handleClearSuggestions();
    }
  };

  callAutosuggestAPI = (value) => {
    return CKApi.get('/student/api/search/autosuggest', {
      params: {
        query: value,
        rows: 50
      },
      cancelToken: this.autoSuggestCancelToken.token
    });
  }

  handleAutoSuggestResponse = (data, searchText) => {
    let concepts = data.topics.data;
    let books = this.props.contentTypeFilter ? data.documents.data.filter(item => item.contentType && item.contentType === this.props.contentTypeFilter) : data.documents.data;

    if (this.props.useGroups) {
      const maxGroupSize = isMobile() ? 3 : this.props.maxGroupSize;
      // Select the maxGroupSize of each type of result and then combine again
      concepts = books.length < maxGroupSize ? concepts.slice(0, maxGroupSize + (maxGroupSize - books.length)) : concepts.slice(0, maxGroupSize);
      books = concepts.length < maxGroupSize ? books.slice(0, maxGroupSize + (maxGroupSize - concepts.length)) : books.slice(0, maxGroupSize);

      const groupedSuggestions = [
        {
          title: '',
          suggestions: concepts
        },
        {
          title: '',
          suggestions: books
        }
      ];

      this.setState({ suggestions: groupedSuggestions });
    } else {
      concepts = data.topics.data.slice(0, this.props.maxResults);
      this.props.contentTypeFilter ? this.setState({ suggestions: books }) : this.setState({ suggestions: concepts });
    }

    this.handleAutoSuggestAnnouncement(data.docs.length, searchText);
    this.autoSuggestCancelToken = null;
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.activeSuggestion = -1;
  };

  handleAutoSuggestAnnouncement = (count, term) => {
    // stop screen reader announcement update until user has paused typing for at least a second
    setTimeout(() => {
      if (term !== this.state.searchText) { return; }
      this.setState({
        autosuggestAnnouncement: {
          count,
          term
        }
      });
    }, 1000);
  };

  getSuggestionValue = (suggestion) => suggestion.term;

  renderSectionTitle = (section) => {
    return section.title ? section.title : null;
  }

  getSectionSuggestions = (section) => {
    return section.suggestions;
  }

  formatSuggestion = (suggestion, { query }) => {
    const matchRegExp = new RegExp(this.state.searchText, 'ig');
    // convert string into array of strings broken into matches & non-matches
    const deconstructedString = this.getSuggestionValue(suggestion).replace(matchRegExp, (match) => `||${match}||`).split('||');
    // conditionally add bold tags to matches
    const rebuiltArray = map(when(test(matchRegExp), addBold), deconstructedString);
    const getImageSrc = () => suggestion.contentType === CONTENT_TYPE_NAMES.BOOK ? getBookThumbnailUrl(suggestion.hubEid) : getEMCThumbnailUrl(suggestion.hubEid, suggestion.uniqueId);

    return (
      <div className={`c-ckm-autosuggest__result c-ckm-autosuggest__result--${suggestion.type} o-els-flex-layout`}>
        {suggestion.sourceType === 'SOURCE_TITLE'
          ? <div className='c-ckm-autosuggest__result-thumbnail o-els-flex-layout--item o-els-flex-layout'>
            <img
              src={getImageSrc()}
              alt={`Book cover of ${suggestion.title}`}
            />
          </div>
          : <div className='c-ckm-autosuggest__result-icon o-els-flex-layout--item'>
            {isXSmall()
              ? <Icon
                  a11y={{ name: 'Search concept' }}
                  sprite={Icon.Sprites.SEARCH}
                  className=''
                  id='auto-suggest-concept'
                  size={Icon.Sizes.XS}
                  textAlignment='middle'
                  isVisible
                  isTextFirst
                />
              : <Icon
                  a11y={{ name: 'Search concept' }}
                  sprite={Icon.Sprites.SEARCH}
                  className=''
                  id='auto-suggest-concept'
                  size={Icon.Sizes.S}
                  textAlignment='middle'
                  isVisible
                  isTextFirst
                />}
          </div>}
        <div className='c-ckm-autosuggest__result-label o-els-flex-layout--item o-els-flex-layout__item--grow'>
          {React.Children.toArray(rebuiltArray)}
        </div>
      </div>
    );
  };

  handleScopeSelection = (newScope) => {
    this.setState(
      { selectedScope: newScope },
      () => {
        if (isSearchTextValid(this.state.searchText)) {
          this.handleValidSubmit(null, this.state.searchText);
        }
      }
    );
  }

  handleSuggestionSelection = (evt, { suggestion }) => this.setState({ searchText: this.getSuggestionValue(suggestion) }, () => this.handleValidSubmit(evt, suggestion));

  handleClearSearch = (evt) => {
    evt.preventDefault();

    if (this.state.searchText.length !== 0) {
      this.setState({ searchText: '' });
      this.autosuggestRef.current.input?.focus(); // eslint-disable-line no-unused-expressions
    }

    if (this.props.searchHistory?.length) {
      this.setState({ isRecent: true });
    }
  };

  handleClearSuggestions = () => {
    this.setState({ suggestions: [] });
  }

  handleValidSubmit = (evt, suggestion) => {
    document.querySelector('.react-autosuggest__input').blur();

    if (evt) {
      evt.preventDefault();
    }

    // eslint-disable-next-line eqeqeq
    suggestion = suggestion == undefined ? this.state.lastHighlightedSuggestion : suggestion;

    if (this.props.submitHandler) {
      this.props.submitHandler(suggestion);
    } else {
      if (suggestion?.sourceType === 'SOURCE_TITLE') {
        const path = getContentViewPath(suggestion.contentType, {hubeid: suggestion?.hubEid, issn: suggestion?.uniqueId, isISSN: true});

        this.context.doRedirect(path);
      } else {
        this.context.doRedirect(generateSearchUrl(this.state.searchText, this.state.selectedScope.value, this.state.source, this.props.isSearchingWithin, this.props.sourceId, this.state.activeTab));
      }
    }

    // CKApi.post('/student/api/search-history', {
    //   term: this.state.searchText,
    //   contenttype: this.state.activeTab || 'DV'
    // });
  };

  // No need to do anything here, just prevent the search from submitting
  handleInvalidSubmit = (evt) => evt.preventDefault();

  handleFocus = (index, suggestion) => {
    this.setState({
      activeSuggestion: index,
      searchText: suggestion
    });
  };

  handleSuggestionKeyPress = (suggestion, evt) => {
    if (evt.which === 13) {
      this.setState({ searchText: suggestion }, function () {
        this.context.doRedirect(generateSearchUrl(this.state.searchText, this.state.selectedScope.value, this.state.source, this.props.isSearchingWithin, this.props.sourceId, this.state.activeTab));
      });
    } else {
      this.setState({ searchText: '' }, function () {
        document.getElementsByClassName('c-ckm-searchbar__input')[0].focus();
      });
    }
  };

  getPlaceholder = (contenttype) => {
    const { productConfig } = this.context;

    if (productConfig.productCode === 'CK_MEDED') {
      switch (contenttype) {
        case 'BK':
          return this.context.intl.formatMessage(messages.searchBarPlaceholderBooks);
        case 'IM':
          return this.context.intl.formatMessage(messages.searchBarPlaceholderImages);
        case 'VD':
          return this.context.intl.formatMessage(messages.searchBarPlaceholderVideos);
        case 'EM':
          return this.context.intl.formatMessage(messages.searchBarPlaceholderEMC);
        case 'UM':
          return this.context.intl.formatMessage(messages.searchBarPlaceholderUM);
        default:
          return this.context.intl.formatMessage(messages.searchBarPlaceholder);
      }
    }

    if (productConfig.productCode === 'CK_MEDED_NURSING') {
      switch (contenttype) {
        case 'BK':
          return this.context.intl.formatMessage(messages.searchBarPlaceholderBooks);
        case 'IM':
          return this.context.intl.formatMessage(messages.searchBarPlaceholderImages);
        case 'VD':
          return this.context.intl.formatMessage(messages.searchBarPlaceholderVideos);
        case 'NC':
          return this.context.intl.formatMessage(messages.searchBarPlaceholderTM);
        case 'EM':
          return this.context.intl.formatMessage(messages.searchBarPlaceholderEMC);
        case 'JL':
          return this.context.intl.formatMessage(messages.searchBarPlaceholderJL);
        default:
          return this.context.intl.formatMessage(messages.searchBarPlaceholderNursing);
      }
    }

    if (!this.context.productConfig.showVideos) {
      return this.context.intl.formatMessage(messages.searchBarPlaceHolderNoVideos);
    }

    return this.context.intl.formatMessage(messages.searchBarPlaceholder);
  };

  handleSuggestionHighlighted = ({ suggestion }) => {
    // Clicking the search button unfocuses the combo box and submits an empty value, so as a backup we use whatever suggestion was highlighted last
    if (suggestion != null) {
      this.setState({
        lastHighlightedSuggestion: suggestion
      });
    }
  }

  handleInputPlaceholder = () => {
    this.setState({ loopView: false });
  }

  handleInputPlaceholderOnBlur = () => {
    if (this.state.searchText.length <= atLeastOneLetter) {
      this.setState({ loopView: true });
    }
  }

  onClickHandler = () => {
    if (document.querySelector('.react-autosuggest__input')?.value?.length) {
      document.querySelector('.react-autosuggest__input')?.focus(); // eslint-disable-line no-unused-expressions
    }

    if (this.state.isTabs === false) {
      this.setState({
        isTabs: true
      });
    }
  }

  onChangeHandler = () => {
    this.setState({
      isRecent: false
    });
    if (!document.querySelector('.react-autosuggest__input')?.value?.length) {
      this.setState({
        isRecent: true
      });
    }
  }

  onDorpdownClick = (e, value) => {
    e.preventDefault();
    this.setState({
      activeTab: value.toString().slice(-2)
    });
  };

  onSearchItemClick = ({ term, contenttype }) => {
    this.context.doRedirect(generateSearchUrl(term, this.state.selectedScope.value, this.state.source, this.props.isSearchingWithin, this.props.sourceId, contenttype));
  };

  render () {
    const submitHandler = isSearchTextValid(this.state.searchText) ? this.handleValidSubmit : this.handleInvalidSubmit;
    const clearButtonClass = generateClearButtonClass(this.state.searchText);
    const searchButtonClass = generateSearchButtonClass(this.state.searchText);
    const iconSearchBlackClass = generateIconBlackClass(this.state.searchText, this.state.loopView);
    const iconSearchBlueClass = generateIconBlueClass(this.state.searchText, this.state.loopView);
    const placeholder = this.props.placeholder || this.getPlaceholder(this.state.activeTab);
    const { intl } = this.context;
    const inputProps = {
      placeholder: placeholder,
      value: this.state.searchText,
      onChange: this.handleSearchInputChange,
      onClick: this.handleInputPlaceholder,
      onBlur: this.handleInputPlaceholderOnBlur,
      name: 'search',
      maxLength: 140,
      'aria-label': placeholder
    };
    return (
      <div className='o-els-flex-layout c-ckm-searchbar' ref={this.wrapperRef}>
        <Announcements
          message={intl.formatMessage(messages.searchBarAutosuggestAnnouncement, { count: this.state.autosuggestAnnouncement.count, searchTerm: this.state.autosuggestAnnouncement.term })}
          pageLoad={false}
        />
        <div className='c-ckm-searchbar__form' id='home-searchbar' onClick={this.onClickHandler} onChange={this.onChangeHandler} onKeyDown={() => { }} role='presentation'>
          {isXSmall()
            ? <Icon
                a11y={{ name: 'Search black icon' }}
                sprite={Icon.Sprites.SEARCH}
                className={iconSearchBlackClass}
                id='search-black-icon'
                size={Icon.Sizes.XS}
                textAlignment='middle'
                isVisible
                isTextFirst
              />
            : <Icon
                a11y={{ name: 'Search black icon' }}
                sprite={Icon.Sprites.SEARCH}
                className={iconSearchBlackClass}
                id='search-black-icon'
                size={Icon.Sizes.S}
                textAlignment='middle'
                isVisible
                isTextFirst
              />}
          {isXSmall()
            ? <Icon
                a11y={{ name: 'Search blue icon' }}
                sprite={Icon.Sprites.SEARCH}
                className={iconSearchBlueClass}
                id='search-black-icon'
                size={Icon.Sizes.XS}
                textAlignment='middle'
                isVisible
                isTextFirst
              />
            : <Icon
                a11y={{ name: 'Search blue icon' }}
                sprite={Icon.Sprites.SEARCH}
                className={iconSearchBlueClass}
                id='search-black-icon'
                size={Icon.Sizes.S}
                textAlignment='middle'
                isVisible
                isTextFirst
              />}
          <form className={this.props.dropdown ? 'c-ckm-search-dropdown__show' : 'c-ckm-search-dropdown__hide'} onSubmit={submitHandler}>
            <Autosuggest
              suggestions={this.state.suggestions}
              onSuggestionsFetchRequested={this.getAutosuggestResults}
              onSuggestionsClearRequested={this.handleClearSuggestions}
              onSuggestionSelected={this.handleSuggestionSelection}
              onSuggestionHighlighted={this.handleSuggestionHighlighted}
              getSuggestionValue={this.getSuggestionValue}
              renderSectionTitle={this.renderSectionTitle}
              getSectionSuggestions={this.getSectionSuggestions}
              renderSuggestion={this.formatSuggestion}
              highlightFirstSuggestion={false}
              inputProps={inputProps}
              ref={this.autosuggestRef}
              multiSection={this.props.useGroups}
            />
          </form>
          {this.props.dropdown && <SearchDropdown
            searchState={this.state}
            searchHistory={this.props.searchHistory}
            onClick={this.onDorpdownClick}
            onSearchItemClick={this.onSearchItemClick}
                                  />}
          <div className='c-ckm-searchbar__wrapper-btns'>
            <div className={`c-ckm-search-bar-button ${clearButtonClass}`}>
              <Flyout
                flyout={intl.formatMessage(messages.clearSearchTooltipText)}
                placement='bottom-end'
                shouldCloseOnESC
                id='clear-search-tooltip'
                trigger='hover'
                theme='simple'
              >
                <button type='button' onClick={this.handleClearSearch} aria-label={intl.formatMessage(messages.clearSearchArialabel)}>
                  {isXSmall()
                ? <Icon
                    a11y={{ name: 'Clear search icon' }}
                    sprite={Icon.Sprites.CLOSE}
                    id='clear-search-icon'
                    size={Icon.Sizes.XS}
                    textAlignment='middle'
                    isVisible
                    isTextFirst
                  />
                : <Icon
                    a11y={{ name: 'Clear search icon' }}
                    sprite={Icon.Sprites.CLOSE}
                    id='clear-search-icon'
                    size={Icon.Sizes.S}
                    textAlignment='middle'
                    isVisible
                    isTextFirst
                  />}
                </button>
              </Flyout>
            </div>
            <div className={`c-ckm-search-bar-button ${searchButtonClass}`}>
              <Flyout
                flyout={intl.formatMessage(messages.submitTooltipText)}
                placement='bottom-start'
                shouldCloseOnESC
                id='arrow-search-tooltip'
                trigger='hover'
                theme='simple'
              >
                <button type='submit' aria-label={intl.formatMessage(messages.submitArialabel)} onClick={submitHandler}>
                  {isXSmall() ? <Icon
                    a11y={{ name: 'Arrow search icon' }}
                    sprite='ArrowRight'
                    id='arrow-search-icon'
                    size={Icon.Sizes.XS}
                    textAlignment='middle'
                    isVisible
                    isTextFirst
                                />
                : <Icon
                    a11y={{ name: 'Arrow search icon' }}
                    sprite='ArrowRight'
                    id='arrow-search-icon'
                    size={Icon.Sizes.S}
                    textAlignment='middle'
                    isVisible
                    isTextFirst
                  />}
                </button>
              </Flyout>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SearchBar.defaultProps = {
  searchText: '',
  useGroups: false,
  maxGroupSize: 4,
  hideSubmitButton: false,
  source: '',
  maxResults: 10
};

SearchBar.displayName = 'SearchBar';
export { SearchBar, generateSearchUrl };
export default withOutsideEvent(SearchBar);
