import React from 'react';
import { defineMessages } from 'react-intl';
import { arrayOf, shape, string } from 'prop-types';
import { useIntl } from '../../hooks';
import InternalLink from '../common/InternalLink';

const messages = defineMessages({
  ariaNavLabel: {
    id: 'Breadcrumbs.label.nav',
    defaultMessage: 'breadcrumbs'
  }
});

const Breadcrumb = (props) => {
  const intl = useIntl();
  const breadcrumbs = (
    <nav data-testid='c-ckm-breadcrumb' className='c-ckm-breadcrumb' aria-label={intl.formatMessage(messages.ariaNavLabel)}>
      <ol className='c-ckm-breadcrumb__list'>
        {props.path.map((crumb, i) => {
          const lastLink = i === props.path.length - 1;
          return (
            <li key={i} className='c-ckm-breadcrumb__list-item'>
              <InternalLink
                href={crumb.url}
                className='c-ckm-breadcrumb__link'
                aria-current={lastLink ? 'page' : null}
              >
                {crumb.name}
              </InternalLink>
              {!lastLink &&
                <svg className='c-ckm-breadcrumb__icon o-els-icon-svg o-els-icon-svg--1x o-els-icon-svg--middle' aria-hidden='true'>
                  <use xlinkHref='#icon-sprite_els-hmds-icon-chevron-right' />
                </svg>}
            </li>
          );
        })}
      </ol>
    </nav>
  );

  return (
    breadcrumbs
  );
};

Breadcrumb.propTypes = {
  path: arrayOf(shape({
    name: string.isRequired,
    url: string.isRequired
  }))
};

Breadcrumb.displayName = 'Breadcrumb';
export default Breadcrumb;
