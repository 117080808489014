import React, { useState } from 'react';
import '../../assets/img/tooltip-right.svg';
import '../../assets/img/tooltip-left.svg';

const Tooltip = ({ children, message }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  return (
    <>
      <div
        id='custom-tooltip'
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
       position: 'relative',
       width: '100%',
       display: 'flex',
       justifyContent: 'center'

}}
      >
        {children}
        {isTooltipVisible && (
          <>
            <div
              style={{
            position: 'absolute',
            height: '4.5625rem',
            width: '11.125rem',
            top: '-5.8rem',
            backgroundColor: '#F3F5F7',
            color: '#242D36',
            borderRadius: '0.375rem',
            zIndex: 2,
            border: '3px solid #DCDCDC'
          }}
            >
              <div style={{
              padding: '0.5rem'
            }}
              >  {message()}
              </div>
              <div style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '0.15rem'
            }}
              >
                <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <use xlinkHref='#tooltip-left' />
                </svg>
                <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <use xlinkHref='#tooltip-right' />
                </svg>
              </div>
            </div>
          </>
      )}
      </div>
    </>
  );
};

export default Tooltip;
