import React, { useEffect, useState } from 'react';
import '../../assets/img/AssignmentColored.svg';
import '../../assets/img/blueAssignment.svg';
import useIntl from '../../hooks/use-intl';
import { FormattedMessage, defineMessages } from 'react-intl';
import { ELSCourseService, ELSMededApi, ELSUtilityHelper } from '@els/meded-ui-common-react';
import { Button } from '@els/els-react--button';
import { CASE_STATES } from '../case-player/utils/pjbConstants';
import InternalLink from '../../components/common/InternalLink';
import { isSelfStudyUser } from '../../utils/userUtils';
export const getTruncatedObject = assessmentDetails => ({
  assessmentGoals: assessmentDetails.assessmentGoals,
  assessmentTopics: assessmentDetails.assessmentTopics,
  isbn: assessmentDetails.isbn,
  studyMode: assessmentDetails.studyMode,
  type: assessmentDetails.type,
  assignmentId: assessmentDetails.assignmentId,
  courseSectionId: assessmentDetails.courseSectionId
});

const messages = defineMessages({
  AssignmentsTitle: {
    id: 'HomePage.widget.assignments.title',
    defaultMessage: 'Assignments'
  },
  newAssignmentsTitle: {
    id: 'HomePage.widget.assignments.newAssigments.title',
    defaultMessage: 'New assignments'
  },
  seeAllButtonLabel: {
    id: 'BookBestBets.labels.seeAllButton',
    defaultMessage: 'See all'
  },
  startButtonLabel: {
    id: 'SearchPage.text.start',
    defaultMessage: 'Start'
  },
  resumeButtonLabel: {
    id: 'Home.widgets.continueTesting.resume',
    defaultMessage: 'Resume'
  }
});

const Assignments = ({courseId, userId, isSelfStudyUserCallback, assessmentOnly}) => {
  const [dueAssignments, setDueAssignments] = useState([]);
  const productCode = ELSUtilityHelper.getNursingProductCode();

  const getAssignments = () => ELSCourseService.getAssignments(courseId).then((res) => {
    const assignments = res.data.filter(assignment => {
      const assignmentDueDate = assignment.dueDate;
      const assignmentStatus = assignment.students[0].status;
      return (
        assignment.userId !== userId &&
          assignmentStatus !== CASE_STATES.COMPLETED &&
          assignmentDueDate
      );
    });

    setDueAssignments(assignments);
  }).catch(error => console.error(error));

  const getCourses = () => ELSCourseService.getCourses(userId).then((res) => {
    const isSelfStudy = isSelfStudyUser(res.data);
    isSelfStudyUserCallback(isSelfStudy);
  }).catch(error => console.error(error));

  useEffect(() => {
    getAssignments();
  }, [courseId]);

  useEffect(() => {
    getCourses();
  }, [userId]);

  const intl = useIntl();

  const startAssignmentHandler = (assessmentDetails) => {
    ELSMededApi.post('/assessment', { data: getTruncatedObject(assessmentDetails)}).then((res) => {
      const url = `test/#/assignment?assessmentId=${res.data.id}`;
      window.history.pushState(null, null, url);
      window.location.reload();
    }).catch(error => console.error(error));
  };

  const Assignment = (assignment) => {
    assignment.courseSectionId = courseId;
    const startAssignmentButton = (
      <Button
        type={Button.Types.LINK}
        onClick={() => startAssignmentHandler(assignment)}
        className=''
        data-testid='startBtn'
      >{intl.formatMessage(messages.startButtonLabel)}</Button>
    );

    const internalLink = (
      <InternalLink
        className=''
        id='goToAssignmentLink'
        href={`/test/#/assignment?assessmentId=${assignment.students[0]?.assessmentId}`}
      >
        {intl.formatMessage(messages.resumeButtonLabel)}
      </InternalLink>
    );

    const dueDate = assignment.dueDate;
    const assignmentButton = assignment.students[0].status === 'NOT_STARTED' ? startAssignmentButton : internalLink;
    const month = new Date((new Date(dueDate)).valueOf() - 1000 * 60 * 60 * 24).toLocaleDateString('en-us', { month: 'short'});
    const day = new Date((new Date(dueDate)).valueOf() - 1000 * 60 * 60 * 24).getDate();

    return (
      <div data-testid='assignment' className='assignments-widget__content-assignment'>
        <div className='assignments-widget__content-assignment-container'>
          <div className='assignments-widget__content-assignment-date'>
            <p>{month}</p>
            <p>{day}</p>
          </div>
          <div className='assignments-widget__content-assignment-text'>
            <h1>{assignment.title}</h1>
            <p><FormattedMessage
              id='HomePage.widget.assignments.assignmentQuestions'
              defaultMessage='{number} Questions'
              values={{number: assignment.gradePoints}}
               />
            </p>
          </div>
        </div>
        <div className='assignments-widget__content-assignment-button'>
          {assignmentButton}
        </div>
      </div>
    );
  };

  const widget = () => {
    const sortedAssigmentsByDate = dueAssignments.sort((a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime());
    const dueAssignmentsToRender = sortedAssigmentsByDate.slice(0, 2);

    return (
      <div data-testid='assignments-widget' className='assignments-widget'>
        <div className='assignments-widget__title'>
          <h1 className='assignments-widget__title-text'>
            {intl.formatMessage(messages.AssignmentsTitle)}
          </h1>
          <Button
            type={Button.Types.LINK}
            className='assignments-widget__title-button'
            onClick={() => {
              window.location.href = ELSUtilityHelper.getUrlStudentAssignmentsPage(productCode);
            }}
            aria-label='shawAllBtn'
            title={`See all ${sortedAssigmentsByDate.length}`}
            id='showAllBtnId'
          >
            <span>{`${intl.formatMessage(messages.seeAllButtonLabel)} ${sortedAssigmentsByDate.length}`}</span>
            <svg className='c-ckm-breadcrumb__icon o-els-icon-svg o-els-icon-svg--1x o-els-icon-svg--middle' aria-hidden='true'>
              <use xlinkHref='#icon-sprite_els-hmds-icon-chevron-right' />
            </svg>
          </Button>

        </div>
        <div className='assignments-widget__content'>
          {dueAssignmentsToRender.map((assignment) => Assignment(assignment))}
        </div>
      </div>
    );
  };

  const widgetEmpty = (
    <div data-testid='assignments-empty-widget' className='assignments-empty-widget'>
      <h1 className='assignments-empty-widget__title'>
        {intl.formatMessage(messages.AssignmentsTitle)}
      </h1>
      <div className='assignments-empty-widget__content'>
        <div className='assignments-empty-widget__content-image'>

          <svg width='72' height='72' viewBox='0 0 72 72'>
            <use xlinkHref='#blueAssignment' />
          </svg>

        </div>
        <div className='assignments-empty-widget__content-text'>
          <h1>{intl.formatMessage(messages.newAssignmentsTitle)}</h1>
          <p><FormattedMessage
            id='HomePage.widget.assignments.assignmentsDue'
            defaultMessage='You have {number} assignments due'
            values={{number: 0}}
             />
          </p>
        </div>
      </div>
    </div>
  );

  return dueAssignments?.length === 0 ? widgetEmpty : widget();
};

export default Assignments;
