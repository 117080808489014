import React, {Fragment, useState, useEffect} from 'react';
import {notEmptyOrNil} from '../../utils';
import PropTypes from 'prop-types';
import {defineMessages} from 'react-intl';
import useIntl from '../../hooks/use-intl';
import ChapterList from './ChapterList';
import GenericSearchBar from '../../components/common/GenericSearchBar';
import { Button } from '@els/els-react--button';
import classnames from 'classnames';
import Menu from './Menu';
import {head, contains} from 'ramda';

const messages = defineMessages({
  chapterSearchPlaceholder: {
    id: 'BookReader.search.chapter',
    defaultMessage: 'Search this chapter'
  },
  backToFullOutlineButton: {
    id: 'BookReader.search.backToFullOutline',
    defaultMessage: 'Back to full outline'
  },
  expandAllSections: {
    id: 'BookReader.outline.expandAllSections',
    defaultMessage: 'Expand all sections'
  },
  collapseAllSections: {
    id: 'BookReader.outline.collapseAllSections',
    defaultMessage: 'Collapse all sections'
  },
  contentSectionNavigation: {
    id: 'BookReader.text.contentNav',
    defaultMessage: 'Book and Chapter Navigation'
  },
  viewAllChaptersButton: {
    id: 'BookReader.text.viewAllChapters',
    defaultMessage: 'View all chapters'
  },
  outlineCloseButton: {
    id: 'BookReader.outline.closeButton',
    defaultMessage: 'Close'
  },
  EMCJournalSearchPlaceholder: {
    id: 'BookReader.search.article',
    defaultMessage: 'Search within this article'
  }
});

const tlidMap = {};

const nestMenu = function nestMenu (arr, acc, level, tl) {
  const next = head(arr);
  if (!next) {
    return acc;
  }
  const lastTl = next.level === 0 ? next.sectionid : tl;
  if (level === next.level) {
    const item = arr.shift();
    const children = nestMenu(arr, [], level + 1, lastTl);
    if (item.level === 0) {
      item.expanded = false;
    }
    tlidMap[item.sectionid] = lastTl;
    acc = acc.concat({ ...item, topLevelId: lastTl, children });
  } else {
    return acc;
  }
  return nestMenu(arr, acc, level, lastTl);
};

const searchOutlineItems = (item, query) => {
  if (item.children.length > 0) {
    item.children = item.children.reduce((acc, child) => {
      const childResult = searchOutlineItems({...child}, query);
      if (childResult) {
        acc.push(childResult);
      }
      return acc;
    }, []);
  }

  const sectionElement = document.getElementById(item.sectionid);
  if (sectionElement && sectionElement.parentElement.querySelector('.c-ckm-reader-content--highlighted') && item.children.length === 0) {
    item.containsHighlight = true;
  }

  if (item.subtitle.match(new RegExp(query, 'ig')) || item.containsHighlight || item.children.length > 0) {
    return item;
  }
};

const performOutlineSearch = (items, query) => {
  return items.reduce((acc, item) => {
    const itemResult = searchOutlineItems({...item}, query);
    if (itemResult) {
      acc.push(itemResult);
    }
    return acc;
  }, []);
};

const setExpandAllSections = (items, val) => items.map((item) => ({...item, expanded: val}));

const Outline = (props) => {
  const intl = useIntl();
  const {
    searchSubmit,
    searchClear,
    showSearchResults,
    showChapterSearch,
    showRefs,
    outlineData,
    isMobile,
    isTablet,
    currentSectionID,
    scrollFn,
    query,
    citation: {contenttype},
    hideChapterList
  } = props;

  const nestedOutlineData = nestMenu([...outlineData], [], 0);
  const [expanded, setExpanded] = useState(false);
  const [isTocOpen, setIsTocOpen] = useState(false);
  const [modifiedOutlineData, setModifiedOutlineData] = useState(nestedOutlineData);
  const [isChapterView, setIsChapterView] = useState(false);

  const showChapterListDropdown = !isMobile && !isTablet && contains(contenttype, ['BOOK', 'JOURNAL', 'EMC']);
  const showSearchBar = showChapterSearch && contains(contenttype, ['BOOK', 'JOURNAL', 'EMC']);
  const showBackToFullOutlineButton = showSearchResults;
  const showExpandAndCollapseButtons = !showSearchResults;
  const showCloseButton = isMobile || isTablet;

  const tocContentClass = classnames({
    'c-ckm-toc-panel__content': true,
    'c-ckm-toc-panel__content--is-open': isTocOpen,
    'c-ckm-toc-panel__content-height--auto': outlineData.length === 0
  });

  const buttonClasses = classnames([
    'u-els-anchorize',
    'u-els-padding-1o2',
    'u-ckm-width--full',
    'c-ckm-toc-panel__button'
  ]);

  const toggleToC = () => {
    setIsTocOpen(!isTocOpen);
    setIsChapterView(false);
  };

  const expandSection = (id) => {
    setModifiedOutlineData(() => {
      const updatedOutlineData = [...modifiedOutlineData];

      for (let i = 0; i < updatedOutlineData.length; i++) {
        if (updatedOutlineData[i].sectionid === id && !updatedOutlineData[i].expanded) {
          updatedOutlineData[i].expanded = true;
          break;
        }
      }

      return updatedOutlineData;
    });
  };

  const toggleAllSections = () => {
    setModifiedOutlineData(setExpandAllSections(modifiedOutlineData, !expanded));
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (notEmptyOrNil(query)) {
      const newOutlineData = setExpandAllSections(nestedOutlineData, true);
      setModifiedOutlineData(performOutlineSearch(newOutlineData, query));
    } else {
      setModifiedOutlineData(nestedOutlineData);
    }
  }, [query]);

  useEffect(() => {
    if (notEmptyOrNil(currentSectionID)) {
      const sectionID = tlidMap[currentSectionID];
      if (notEmptyOrNil(sectionID)) {
        expandSection(sectionID);
      }
    }
  }, [currentSectionID]);

  useEffect(() => {
    setModifiedOutlineData(nestMenu([...outlineData], [], 0));
  }, [outlineData]);

  const searchClearRef = React.createRef();

  const getSearchBarPlaceholder = () => {
    switch (contenttype) {
      case 'BOOK':
        return messages.chapterSearchPlaceholder;
      case 'EMC':
      case 'JOURNAL':
        return messages.EMCJournalSearchPlaceholder;
      default:
        return messages.chapterSearchPlaceholder;
    }
  };

  return (
    <>
      <div className='c-ckm-toc-panel__heading'>
        {isMobile || isTablet
          ? <button className='c-ckm-toc-panel__heading-button' onClick={toggleToC}>
            <h2 className='c-ckm-toc-panel__heading-title'>
              {intl.formatMessage(messages.contentSectionNavigation)}
            </h2>
            <div className='c-ckm-filter__heading-trigger-icon c-ckm-icon c-ckm-icon--arrow-down-blue' />
          </button>
          : <h2 className='c-ckm-toc-panel__heading-title u-els-hide-visually'>
            {intl.formatMessage(messages.contentSectionNavigation)}
          </h2>}
      </div>
      <div className={tocContentClass}>
        {showChapterListDropdown && !hideChapterList && <ChapterList {...props} />}
        {!showChapterListDropdown && !isChapterView && !hideChapterList &&
          <div className='u-els-padding-top-1o2'>
            <button className='u-els-anchorize c-els-link c-els-link--with-icon c-els-link--with-icon-emphasis' type='button' onClick={() => setIsChapterView(true)}>
              <svg className='c-els-link__icon o-els-icon-svg o-els-icon-svg--1x o-els-icon-svg--middle'>
                <use xlinkHref='#icon-sprite_els-hmds-icon-chevron-left' />
              </svg>
              <span className='c-els-link__text'>{intl.formatMessage(messages.viewAllChaptersButton)}</span>
            </button>
          </div>}
        {showSearchBar && !isChapterView &&
          <div className='u-els-padding-top-1o2 u-els-padding-bottom-1o2'>
            <GenericSearchBar
              containerClass='c-ckm-toc-panel__search-bar'
              placeholderClass='c-ckm-toc-panel__search-bar-placeholder'
              placeholder={intl.formatMessage(getSearchBarPlaceholder())}
              onSubmit={searchSubmit}
              onClear={searchClear}
              clearRef={searchClearRef}
            />
          </div>}
        {modifiedOutlineData?.length > 0 &&
          <div className='c-ckm-toc-panel__section'>
            {showBackToFullOutlineButton &&
              <button className={buttonClasses} type='button' onClick={() => { searchClearRef.current.click(); }}>
                <svg className='o-els-icon-svg o-els-icon-svg--1x u-els-margin-right-1o4 o-els-icon-svg--middle'>
                  <use xlinkHref='#icon-sprite_els-hmds-icon-chevron-left' />
                </svg>
                {intl.formatMessage(messages.backToFullOutlineButton)}
              </button>}
            {showExpandAndCollapseButtons && !isChapterView &&
              <button onClick={toggleAllSections} className={buttonClasses} type='button'>
                {!expanded &&
                  <>
                    {intl.formatMessage(messages.expandAllSections)}
                    <svg className='o-els-icon-svg o-els-icon-svg--1x o-els-icon-svg--middle u-els-margin-left-1o4 u-els-color-secondary' aria-hidden='true'>
                      <use xlinkHref='#icon-sprite_els-hmds-icon-chevron-down' />
                    </svg>
                  </>}
                {expanded &&
                  <>
                    {intl.formatMessage(messages.collapseAllSections)}
                    <svg className='o-els-icon-svg o-els-icon-svg--1x o-els-icon-svg--middle u-els-margin-left-1o2 u-els-color-secondary' aria-hidden='true'>
                      <use xlinkHref='#icon-sprite_els-hmds-icon-chevron-up' />
                    </svg>
                  </>}
              </button>}
            <div className='c-ckm-toc-panel__list-container'>
              {isChapterView
              ? <ChapterList {...props} displayType='menu' />
             : <Menu
                 items={modifiedOutlineData}
                 showRefs={!showSearchResults && showRefs}
                 scrollFn={scrollFn}
                 currentSectionID={currentSectionID}
                 highlightQuery={showSearchResults ? query : ''}
                 showExpandAndCollapseButton
                 expandSection={expandSection}
               />}
            </div>
            {showCloseButton &&
              <Button
                type={Button.Types.PRIMARY}
                className='c-els-button--responsive'
                onClick={toggleToC}
                aria-label={intl.formatMessage(messages.outlineCloseButton)}
                title={intl.formatMessage(messages.outlineCloseButton)}
              >
                {intl.formatMessage(messages.outlineCloseButton)}
              </Button>}
          </div>}
      </div>
    </>
  );
};

Outline.propTypes = {
  citation: PropTypes.shape({
    hubeid: PropTypes.string.isRequired,
    chapternum: PropTypes.string,
    chptitle: PropTypes.string,
    contenttype: PropTypes.string.isRequired
  }),
  outlineData: PropTypes.array,
  searchSubmit: PropTypes.func.isRequired,
  searchClear: PropTypes.func.isRequired,
  showSearchResults: PropTypes.bool,
  showChapterSearch: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  scrollFn: PropTypes.func.isRequired,
  query: PropTypes.string,
  currentSectionId: PropTypes.string
};

Outline.defaultProps = {
  showChapterSearch: true
};

Outline.displayName = 'Outline';

export default Outline;
