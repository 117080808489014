import React, {useState, Fragment, useEffect} from 'react';
import { useIntl, useCookie } from '../../hooks';
import Modal from '../../components/common/Modal';
import { defineMessages, FormattedMessage } from 'react-intl';

const BUTTON_LINK = 'https://forms.office.com/Pages/ResponsePage.aspx?id=P-50kiWUCUGif5-xXBBnXc5F_shw3thJryDlS4ABmS5UQ01KU1NKRTczREJDWU1ZWDdIRVVBRFZDVCQlQCN0PWcu';
const SUPPRESS_COOKIE = 'ck_suppress_notification';

const messages = defineMessages({
  notificationModalTitle: {
    id: 'SurveyNotification.modal.title',
    defaultMessage: 'Test Notification'
  }
});

const isDateWithinRange = () => {
  const endDate = new Date('2020-05-16');
  const currentDate = new Date();

  return endDate > currentDate;
};

const showModal = isDateWithinRange();

const SurveyNotification = (props) => {
  const intl = useIntl();

  const [cookie, setCookie] = useCookie(SUPPRESS_COOKIE);

  const [isOpen, setIsOpen] = useState(cookie === false);
  const [signUpOpened, setSignUpOpened] = useState(false);

  useEffect(() => {
    if (showModal && cookie !== true) {
      setIsOpen(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSignUpClicked = () => {
    setSignUpOpened(true);
    setCookie(true);
  };

  const onModalClose = () => {
    setIsOpen(false);
    setCookie(true);
  };

  return (
    <>
      {isOpen && <Modal
        handleModalClose={onModalClose}
        classExtra='c-ckm-survey'
        ariaTitleText={intl.formatMessage(messages.notificationModalTitle)}
                 >
        <div className='c-ckm-survey__content'>
          <button
            onClick={onModalClose}
            className='c-ckm-modal__close c-ckm-icon--lg c-ckm-icon--cross-lg u-ckm-cursor-pointer'
            aria-label='Close modal'
          />
          {!signUpOpened && <>
            <h2>
              <FormattedMessage
                id='SurveyNotification.message.header'
                defaultMessage='Give ClinicalKey Student feedback. Get a gift certificate.'
              />
            </h2>
            <h3 className='c-ckm-survey__subheading u-els-font-size-h4'>
              <FormattedMessage
                id='SurveyNotification.message.subheader'
                defaultMessage='Elsevier User Experience Advisor Program'
              />
            </h3>
            <p>
              <FormattedMessage
                id='SurveyNotification.message.description1'
                defaultMessage='We offer $25 - $50 gift certificates (typically Amazon) as thank you gifts for participating in feedback sessions.'
              />
            </p>
            <p>
              <FormattedMessage
                id='SurveyNotification.message.description2'
                defaultMessage='Sessions are usually conducted online and over the phone.'
              />
            </p>
            <a
              className='c-ckm-button c-ckm-button--primary c-ckm-button--small'
              href={BUTTON_LINK}
              target='_blank'
              onClick={onSignUpClicked} rel='noreferrer'
            >
              <FormattedMessage
                id='SurveyNotification.link.signUp'
                defaultMessage='Yes, sign me up.'
              />
            </a>
            <button className='c-ckm-button c-ckm-button--secondary c-ckm-button--small' onClick={onModalClose}>
              <FormattedMessage
                id='SurveyNotification.button.noThanks'
                defaultMessage='No, thanks.'
              />
            </button>
          </>}

          {signUpOpened && <>
            <span className='c-ckm-icon--lg c-ckm-icon--square-confirmation-white' />
            <p>
              <FormattedMessage
                id='SurveyNotification.message.thanks'
                defaultMessage='Thanks for your interest in participating in our feedback program!'
              />
            </p>
            <button className='c-ckm-button c-ckm-button--primary c-ckm-button--small' onClick={onModalClose}>
              <FormattedMessage
                id='SurveyNotification.button.close'
                defaultMessage='Close'
              />
            </button>
          </>}
        </div>
      </Modal>}
    </>
  );
};

export default SurveyNotification;
