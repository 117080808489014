import en from './en';
import de from './de';
import es from './es';
import fr from './fr';
import pt from './pt';

/* eslint-disable */
import nursing_en from './nursing-en';
import nursing_de from './nursing-de';
import nursing_es from './nursing-es';
import nursing_fr from './nursing-fr';
import nursing_pt from './nursing-pt';

const configs = {
  en,
  de: {...en, ...de},
  es: {...en, ...es},
  fr: {...en, ...fr},
  pt: {...en, ...pt},
  nursing_en,
  nursing_de: {...nursing_en, nursing_de},
  nursing_es: {...nursing_en, nursing_es},
  nursing_fr: {...nursing_en, nursing_fr},
  nursing_pt: {...nursing_en, nursing_pt}
};

export default (locale = 'en', productContext) => {
  const productPrefix = productContext !== 'student' ? `${productContext}_` : '';
  return configs[`${productPrefix}${locale}`] || configs.en;
}

/* eslint-enable */
