import React from 'react';
import {findDOMNode} from 'react-dom';

const withOutsideEvent = Component => {
  return class extends Component {
    componentDidMount () {
      document.addEventListener('click', this.handleOutsideClick, true);
      document.addEventListener('focus', this.handleOutsideFocus, true);
    }

    componentWillUnmount () {
      document.removeEventListener('click', this.handleOutsideClick, true);
      document.removeEventListener('focus', this.handleOutsideFocus, true);
    }

    handleOutsideClick = evt => {
      if (
        !this.node ||
        (!this.node.contains(evt.target) &&
        typeof this.wrappedComponent.handleClickOutside === 'function')
      ) {
        this.wrappedComponent.handleClickOutside(evt);
      }
    };

    handleOutsideFocus = evt => {
      if (
        !this.node ||
        (!this.node.contains(evt.target) &&
          typeof this.wrappedComponent.handleFocusOutside === 'function')
      ) {
        this.wrappedComponent.handleFocusOutside(evt);
      }
    };

    render () {
      return (
        <Component
          {...this.props}
          ref={c => {
            this.wrappedComponent = c;
            // eslint-disable-next-line react/no-find-dom-node
            this.node = findDOMNode(c);
          }}
        />
      );
    }
  };
};

export default withOutsideEvent;
