import React from 'react';
import InternalLink from './InternalLink';
import NNNSearchDropdown from './NNNSearchDropDown';
import { isMobile } from '../../utils/elementUtils';

const NNNHeaderTabs = ({ label, crumbType, nnnRef }) => {
  return (
    <div className='c-ckm-nnn__header-tabs'>
      <ul className='c-ckm-nnn__list'>
        {Object.entries(label).map(([name, message]) => {
          return (
            <li className='c-ckm-nnn__list-item' key={name}>
              <InternalLink
                className={`c-ckm-nnn__link ${name === crumbType ? 'c-ckm-nnn__link--active' : ''}`}
                href={`/nnn/${name}/codigos`}
                linkRef={nnnRef}
                {...(crumbType === name && { 'aria-current': 'page' })}
              >
                {message}
              </InternalLink>
            </li>
          );
        })}
      </ul>
      <div>
        {!isMobile() && <NNNSearchDropdown />}
      </div>
    </div>
  );
};

export default NNNHeaderTabs;
