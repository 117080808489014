import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { dropLast } from 'ramda';
import { Button } from '@els/els-react--button';
import { defineMessages } from 'react-intl';
import InternalLink from '../../components/common/InternalLink';
import BookCoverImg from '../../components/common/BookCoverImg';
import CKApi from '../../services/api-helper';
import classNames from 'classnames';
import { ELSLoadingShape } from '@els/meded-ui-common-react';
import LearningImage from '../../assets/img/Ebooks.png';
import { MainContext } from '../../context/main-context';
import { getVideoThumbnailUrl } from '../../utils/stringUtils';
import { isMobile } from '../../utils/elementUtils';
import { CONTENT_TYPE_NAMES } from '../../constants';
import { useIntl } from '../../hooks';

const messages = defineMessages({
  ContinueLearningTitle: {
    id: 'HomePage.widget.continueLearning.title',
    defaultMessage: 'Continue learning'
  },
  ContinueReadingTitle: {
    id: 'HomePage.widget.continueReading.title',
    defaultMessage: 'Continue reading'
  },
  Recent: {
    id: 'HomePage.widget.Recent.title',
    defaultMessage: 'Recently viewed'
  },
  SectionLabel: {
    id: 'HomePage.widget.continueLearning.label.section',
    defaultMessage: 'Section'
  },
  BookSectionLabel: {
    id: 'HomePage.widget.continueLearning.label.bookSection',
    defaultMessage: 'Book section'
  },
  EMCSectionLabel: {
    id: 'HomePage.widget.continueLearning.label.emcSection',
    defaultMessage: 'EMC section'
  },
  JournalSectionLabel: {
    id: 'HomePage.widget.continueLearning.label.journalSection',
    defaultMessage: 'Journal section'
  },
  ChapterLabel: {
    id: 'HomePage.widget.continueLearning.label.chapter',
    defaultMessage: 'Chapter'
  },
  BookLabel: {
    id: 'HomePage.widget.continueLearning.label.bookTitle',
    defaultMessage: 'Book'
  },
  EMCLabel: {
    id: 'HomePage.widget.continueLearning.label.emcTitle',
    defaultMessage: 'EMC'
  },
  JournalLabel: {
    id: 'HomePage.widget.continueLearning.label.journalTitle',
    defaultMessage: 'Journal'
  },
  VideoLabel: {
    id: 'HomePage.widget.continueLearning.label.video',
    defaultMessage: 'Video'
  },
  EmptyText: {
    id: 'HomePage.widget.continueLearning.emptyText',
    defaultMessage: 'Browse e-books, images, videos, and disease summaries available exclusively on ClinicalKey Student.'
  },
  EmptyTitle: {
    id: 'HomePage.widget.continueLearning.emptyTitle',
    defaultMessage: 'Get started'
  },
  BrowseButton: {
    id: 'HomePage.widget.continueLearning.browseButton',
    defaultMessage: 'Browse'
  }
});

const refCallback = (elHeight, setHeightFunc, el) => {
  if (el) {
    if (el.offsetHeight > elHeight) setHeightFunc(el.offsetHeight);
  }
};

const getSectionLabel = (contentType) => {
  switch (contentType) {
    case CONTENT_TYPE_NAMES.EMC:
      return messages.EMCSectionLabel;
    case CONTENT_TYPE_NAMES.JOURNAL:
      return messages.JournalSectionLabel;
    default:
      return messages.BookSectionLabel;
  }
};

const returnThumbnail = (item, isColumnView) => {
  return (
    <BookCoverImg
      hubeid={item.hubEid}
      sourcetitle={item.sourceTitle}
      getHeight={() => { }}
      issn={item.issn}
      contentType={item.contentType}
      src={item.coverimage}
      className={isColumnView ? 'c-ckm-thumbnail__img--small' : 'c-ckm-thumbnail__img--middle'}
    />
  );
};

export const RecentItem = (props) => {
  const intl = useIntl();
  const { item, isLoading, isColumnView } = props;
  const [sectionTitleHeight, setSectionTitleHeight] = useState(null);
  const sectionTitleMaxHeight = 80;
  const shouldTruncateSectionTitle = sectionTitleMaxHeight < sectionTitleHeight;
  const sectionTitleClass = classNames(
    'c-ckm-widget__section-title',
    { 'c-ckm-widget__section-title--truncated': shouldTruncateSectionTitle }
  );

  const renderRecentItem = () => {
    if (item.contentType === 'BOOK' || item.contentType === 'EMC' || item.contentType === 'JOURNAL') {
      return (
        <>
          <div className='o-els-flex-layout__item'>
            <h5>
              {intl.formatMessage(getSectionLabel(item.contentType))}
            </h5>
            <h3
              className={sectionTitleClass}
              style={shouldTruncateSectionTitle ? { height: sectionTitleMaxHeight } : null}
              ref={(e) => refCallback(sectionTitleHeight, setSectionTitleHeight, e)}
            >
              <InternalLink href={`/content/book/${item.eid}#${item.sectionId}`} className='c-ckm-widget__title-link'>{item.sectionTitle}</InternalLink>
            </h3>
          </div>
          <div className='o-els-flex-layout__item'>
            {returnThumbnail(item, isColumnView)}
          </div>
        </>
      );
    }

    if (item.contentType === 'VIDEO') {
      const imgSrc = getVideoThumbnailUrl(item.refImage);

      const playIconClass = classNames({
        'c-ckm-thumbnail__play-icon': true,
        'c-ckm-icon--lg': true,
        'c-ckm-icon--play-video-lg': true,
        'c-ckm-icon--play-scale': !isColumnView
      });

      return (
        <>
          <div>
            <h5>
              {intl.formatMessage(messages.VideoLabel)}
            </h5>
            <h3 className='c-ckm-widget__section-title'>
              <InternalLink
                href={`/content/video/${item.eid}?startTime=${item.videoPosition || 0}`}
              >{item.itemTitle}</InternalLink>
            </h3>
          </div>
          <div className='c-ckm-thumbnail__img-recentVideo-container'>
            <InternalLink
              href={`/content/video/${item.eid}?startTime=${item.videoPosition || 0}`}
              className='c-ckm-thumbnail__button'
              onClick={() => {}}
            >
              <div className={`c-ckm-thumbnail c-ckm-thumbnail__img-recentVideo${isColumnView ? '--small' : ''}`}>
                <img
                  className={`${isColumnView ? 'c-ckm-thumbnail__img--medium' : 'c-ckm-thumbnail__img--large'} c-ckm-thumbnail__img-recent`}
                  src={imgSrc}
                  alt={item.sourceTitle}
                />
                <div className='c-ckm-thumbnail__play-button'>
                  <span className={playIconClass}>&nbsp;</span>
                </div>
              </div>
            </InternalLink>
          </div>
        </>
      );
    }
  };

  return (
    <>
      {isLoading
        ? <ELSLoadingShape height='181px' viewBox='0 0 474 181' preserveAspectRatio='none' animate>
          <rect x='0' y='0' width='84' height='24' />
          <rect x='0' y='28' width='312' height='24' />
          <rect x='0' y='54' width='255' height='24' />
          <rect x='0' y='90' width='58' height='24' />
          <rect x='0' y='118' width='312' height='24' />
          <rect x='0' y='144' width='312' height='24' />
          <rect x='344' y='0' width='130' height='181' />
        </ELSLoadingShape>
        : renderRecentItem()}
    </>
  );
};

const RecentItems = (props) => {
  const { doRedirect, intl } = useContext(MainContext);
  const [items, setItems] = useState(props.items ? props.items : []);
  const [isLoading, setIsLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);
  const itemsToShow = 2;

  useEffect(() => {
    CKApi.get('/student/api/content/recentlyViewed')
      .then(({data}) => {
        // only show recentlyViewed items with section titles
        const sections = data ? data.filter(item => item.sectionTitle || item.contentType === 'VIDEO') : undefined;

        sections && sections.length
          ? setItems(dropLast((sections.length - itemsToShow), sections))
          : setIsEmpty(true);
        setIsLoading(false);
      })
      .catch(response => {
        setIsEmpty(true);
        setIsLoading(false);
      });
  }, []);

  const classNameModifier = items.length > 1 ? '--multiple' : '';
  const isColumnView = isMobile() || props.widgetType === 'half';

  const getEmptyWidget = () => props.widgetType !== 'half' ? <>
    <div className='u-els-text-center'>
      <h3>
        {intl.formatMessage(messages.EmptyTitle)}
      </h3>
    </div>
    <div className='u-els-margin-bottom u-els-text-center'>
      <img
        src={LearningImage} alt=''
      />
    </div>
    <div className='u-els-margin-bottom u-els-text-center'>
      <h4>
        {intl.formatMessage(messages.EmptyText)}
      </h4>
    </div>
    <div className='o-els-flex-layout__item u-els-text-center'>
      <Button onClick={() => doRedirect('/browse/books')} type={Button.Types.PRIMARY}>
        {intl.formatMessage(messages.BrowseButton)}
      </Button>
    </div>
  </> : <>
    <div className='u-els-text-left u-els-margin-bottom'>
      <h3 className='recentItems-empty__title'>
        {intl.formatMessage(messages.EmptyTitle)}
      </h3>
    </div>
    <div className='recentItems-empty__container u-els-text-left'>
      <h4 className='recentItems-empty__text'>
        {intl.formatMessage(messages.EmptyText)}
      </h4>
      <div className='recentItems-empty__img u-els-text-center'>
        <img
          src={LearningImage} alt=''
        />
      </div>
    </div>
    <div className='recentItems-empty__btn-container o-els-flex-layout__item u-els-text-left'>
      <Button onClick={() => doRedirect('/browse/books')} type={Button.Types.PRIMARY} className='c-els-button--small'>
        {intl.formatMessage(messages.BrowseButton)}
      </Button>
    </div>
  </>;

  return (
    <div className={`c-ckm-widget-recentItems${classNameModifier} ${isColumnView && 'c-ckm-widget-recentItems-column'}`}>
      {isEmpty
        ? getEmptyWidget()
        : <>
          <h3 className='u-els-margin-bottom'>
            {intl.formatMessage(messages.Recent)}
          </h3>
          <div className='o-els-flex-layout o-els-flex-layout--space-between o-els-flex-layout--wrap'>
            {
              items.map((item, index) => {
                return (
                  <div className={`o-els-flex-layout o-els-flex-layout--space-between c-ckm-widget-recentItems${classNameModifier}__item ${isColumnView ? 'c-ckm-widget-recentItems__item--column' : ''}`} key={index}>
                    <RecentItem
                      item={item}
                      isLoading={isLoading}
                      isColumnView={isColumnView}
                    />
                  </div>
                );
              })
            }
          </div>
        </>}
    </div>
  );
};

RecentItems.propTypes = {
  items: PropTypes.array,
  widgetType: PropTypes.string
};

export default RecentItems;
