import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import useIntl from '../../hooks/use-intl';
import { Button } from '@els/els-react--button';
import { Icon } from '@els/els-react--icon';
import { Flyout } from '@els/els-react--flyout';
import CKApi from '../../services/api-helper';
import { MainContext } from '../../context/main-context';

// TODO: Make this component work for both books and clinical skills

const messages = defineMessages({
  saveButtonLabel: {
    id: 'saveButton.saveButtonLabel',
    defaultMessage: 'Save'
  },
  savedButtonLabel: {
    id: 'saveButton.savedButtonLabel',
    defaultMessage: 'Saved'
  },
  saveButtonFlyout: {
    id: 'saveButton.saveButtonFlyout',
    defaultMessage: 'Save book'
  },
  saveButtonFlyoutSkill: {
    id: 'saveButton.saveButtonFlyoutSkill',
    defaultMessage: 'Save skill'
  },
  removeButtonFlyout: {
    id: 'saveButton.removeButtonFlyout',
    defaultMessage: 'Remove from saved'
  },
  removeButtonFlyoutSkill: {
    id: 'saveButton.removeButtonFlyoutSkill',
    defaultMessage: 'Remove from saved skills'
  },
  addSavedBookHeader: {
    id: 'saveButton.toast.addBookHeader',
    defaultMessage: 'Added to Saved'
  },
  addSavedBookBody: {
    id: 'saveButton.toast.addBookBody',
    defaultMessage: 'has been added to your saved books'
  },
  removeSavedBookHeader: {
    id: 'saveButton.toast.removeBookHeader',
    defaultMessage: 'Removed from Saved'
  },
  removeSavedBookBody: {
    id: 'saveButton.toast.removeBookBody',
    defaultMessage: 'has been removed from your saved books'
  },
  errorToastHeader: {
    id: 'saveButton.toast.errorSaveHeader',
    defaultMessage: 'Error'
  },
  errorSavingToastBody: {
    id: 'saveButton.toast.errorSaveBody',
    defaultMessage: 'There was an error trying to save the book. Please try again later.'
  },
  errorRemovingToastBody: {
    id: 'saveButton.toast.errorRemoveBody',
    defaultMessage: 'There was an error trying to remove the book. Please try again later.'
  }
});

const sharedSuccessToastProperties = {
  isVisible: true,
  icon: '#icon-sprite_els-hmds-icon-checkmark',
  iconColor: 'positive',
  color: 'positive'
};

const sharedErrorToastProperties = {
  isVisible: true,
  icon: '#icon-sprite_els-hmds-icon-alert-triangle',
  iconColor: 'negative',
  color: 'negative'
};

const SaveButton = (props) => {
  const context = useContext(MainContext);
  const intl = useIntl();
  const [isSaved, setIsSaved] = useState(props.isSaved ? props.isSaved : false);
  const [isVisible, setIsVisible] = useState(true);
  const [savedId, setSavedId] = useState(props.savedId);
  const iconSprite = isSaved ? Icon.Sprites.STAR_SOLID : Icon.Sprites.STAR;
  const successfulMessage = props.isSkill ? messages.saveButtonFlyoutSkill : messages.saveButtonFlyout;
  const removeButtonFlyoutMessage = props.isSkill ? messages.removeButtonFlyoutSkill : messages.removeButtonFlyout;
  const saveButtonFlyoutText = isSaved ? removeButtonFlyoutMessage : successfulMessage;

  useEffect(() => {
    setIsSaved(props.isSaved);
  }, [props.isSaved]);

  const handleSaveButton = (evt) => {
    if (isSaved) { // book is already saved, let's remove it
      CKApi.delete(`/student/api/content/savedBooks/removeBook/${savedId}`)
        .then(({data}) => {
          setIsSaved(false);
          setIsVisible(false);
          setTimeout(() => { setIsVisible(true); }, 100); // Temporarily remove the button to refresh the flyout
          context.addToast({
            title: intl.formatMessage(messages.removeSavedBookHeader),
            body: `<em>${props.sourcetitle}</em> ${intl.formatMessage(messages.removeSavedBookBody)}`,
            ...sharedSuccessToastProperties
          });
        }).catch(() => {
          context.addToast({
            title: intl.formatMessage(messages.errorToastHeader),
            body: intl.formatMessage(messages.errorRemovingToastBody),
            ...sharedErrorToastProperties
          });
        });
    } else { // save the book
      CKApi.post('/student/api/content/savedBooks', {
        hubeid: props.hubeid
      })
        .then((response) => {
          setIsSaved(true);
          setIsVisible(false);
          setSavedId(response.data.id);
          setTimeout(() => { setIsVisible(true); }, 100); // Temporarily remove the button to refresh the flyout
          context.addToast({
            title: intl.formatMessage(messages.addSavedBookHeader),
            body: `<em>${props.sourcetitle}</em> ${intl.formatMessage(messages.addSavedBookBody)}`,
            ...sharedSuccessToastProperties
          });
        })
        .catch(() => {
          context.addToast({
            title: intl.formatMessage(messages.errorToastHeader),
            body: intl.formatMessage(messages.errorSavingToastBody),
            ...sharedErrorToastProperties
          });
        });
    }

    setTimeout(() => { setIsVisible(true); }, 100); // Temporarily remove the button to refresh the flyout
  };

  return (
    <>
      {isVisible &&
        <Flyout
          className=''
          trigger='hover'
          placement='top'
          theme='simple'
          flyout={intl.formatMessage(saveButtonFlyoutText)}
          shouldCloseOnESC
          id='save-btn-flyout'
        >
          <Button
            onClick={handleSaveButton}
            type={Button.Types.LINK}
            className='c-ckm-savebutton'
            id='save-btn'
          >
            <Icon
              a11y={{ name: intl.formatMessage(saveButtonFlyoutText) }}
              sprite={iconSprite}
              className=''
              textAlignment='bottom'
              size={Icon.Sizes.XS}
              isVisible
              id='save-btn-icon'
            >
              {props.showLabel && (isSaved ? intl.formatMessage(messages.savedButtonLabel) : intl.formatMessage(messages.saveButtonLabel))}
            </Icon>
          </Button>
        </Flyout>}
    </>
  );
};

export default SaveButton;

SaveButton.propTypes = {
  showLabel: PropTypes.bool,
  isSaved: PropTypes.bool,
  isSkill: PropTypes.bool,
  savedId: PropTypes.string,
  hubeid: PropTypes.string.isRequired,
  sourcetitle: PropTypes.string.isRequired
};
