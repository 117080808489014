import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import { MainContext } from '../../context/main-context';
import { Modal } from '@els/els-react--modal';
import { Button } from '@els/els-react--button';
import { Icon } from '@els/els-react--icon';
import Form from '../../components/form/Form';
import FormRadioGroup from '../../components/form/FormRadioGroup';
import { TextInput } from '@els/els-react--text-input';
import CKApi from '../../services/api-helper';

const messages = defineMessages({
  buttonAltText: {
    id: 'CitationExportButton.altText',
    defaultMessage: 'Export Citation'
  },
  modalHeader: {
    id: 'CitationExportModal.header',
    defaultMessage: 'Export Citation'
  },
  fileFieldLabel: {
    id: 'CitationExportModal.file.label',
    defaultMessage: 'Export File'
  },
  fileFieldOption1: {
    id: 'CitationExportModal.file.option1',
    defaultMessage: 'BibTeX (for Mendeley)'
  },
  fileFieldOption2: {
    id: 'CitationExportModal.file.option2',
    defaultMessage: 'RIS (Mendeley, Zotero, Reference Manager, Procite)'
  },
  fileFieldOption3: {
    id: 'CitationExportModal.file.option3',
    defaultMessage: 'Text'
  },
  contentFieldLabel: {
    id: 'CitationExportModal.content.label',
    defaultMessage: 'Content'
  },
  contentFieldOption1: {
    id: 'CitationExportModal.content.option1',
    defaultMessage: 'Citation and Abstract'
  },
  contentFieldOption2: {
    id: 'CitationExportModal.content.option2',
    defaultMessage: 'Citation only'
  },
  modalButtonCancel: {
    id: 'CitationExportModal.button.cancel',
    defaultMessage: 'Cancel'
  },
  modalButtonExport: {
    id: 'CitationExportModal.button.export',
    defaultMessage: 'Export'
  },
  apiError: {
    id: 'CitationExportModal.error',
    defaultMessage: 'Error exporting citation'
  },
  cite: {
    id: 'CitationExportModal.cite',
    defaultMessage: 'Cite'
  },
  copyCitation: {
    id: 'CitationExportModal.copy',
    defaultMessage: 'Copy Citation'
  },
  citationCopied: {
    id: 'CitationExportModal.copied',
    defaultMessage: 'Citation copied to your clipboard'
  }
});

const CitationExportModal = (props) => {
  const { eid, onClose } = props;
  const context = useContext(MainContext);
  const { intl, addToast } = context;
  const [fileValue, setFileValue] = useState('BIB_TEX');
  const [contentValue, setContentValue] = useState('true');
  const [textCitation, setTextCitation] = useState('');
  const durableUrlTemplate = context.productConfig.path + '/content/book/{citation.eid}';

  const selectFile = (name, value) => {
    setFileValue(value);
  };

  const selectContent = (name, value) => {
    setContentValue(value);
    getCitationText();
  };

  const exportSubmit = (userInputs, eid, onClose) => {
    context.doRedirect(`/student/api/content/export-citation?eids=${eid}&format=${userInputs.file}&includeAbstract=${userInputs.content}&durableUrlTemplate=${durableUrlTemplate}`);
    onClose();
  };

  const getCitationText = () => {
    CKApi.get(`/student/api/content/export-citation?eids=${eid}&format=TEXT&includeAbstract=${contentValue}&durableUrlTemplate=${durableUrlTemplate}`)
      .then(({data}) => {
        setTextCitation(data);
      });
  };

  const copyCitation = () => {
    navigator.clipboard.writeText(textCitation);
    addToast({
      body:
        `<SuccessMessage>
          ${intl.formatMessage(messages.citationCopied)}
        </SuccessMessage>`,
      isVisible: true,
      autoHide: true,
      icon: '#icon-sprite_els-hmds-icon-checkmark',
      iconColor: 'positive',
      color: 'positive'
    });
  };

  useEffect(() => {
    getCitationText();
  }, []);

  return (
    <>
      <Modal onClose={props.onClose}>
        <h3>{intl.formatMessage(messages.modalHeader)}</h3>

        <Form
          intl={intl}
          validSubmit={(userInputs) => { exportSubmit(userInputs, eid, onClose); }}
          apiError={{}}
          buttonSubmitText={intl.formatMessage(messages.modalButtonExport)}
        >
          <FormRadioGroup
            label={intl.formatMessage(messages.fileFieldLabel)}
            options={
              [
                {
                  label: intl.formatMessage(messages.fileFieldOption1),
                  value: 'BIB_TEX'
                },
                {
                  label: intl.formatMessage(messages.fileFieldOption2),
                  value: 'RIS'
                },
                {
                  label: intl.formatMessage(messages.fileFieldOption3),
                  value: 'TEXT'
                }
              ]
            }
            value={fileValue}
            onChange={selectFile}
            selectedByDefault
            name='file'
          />
          <FormRadioGroup
            label={intl.formatMessage(messages.contentFieldLabel)}
            options={
              [
                {
                  label: intl.formatMessage(messages.contentFieldOption1),
                  value: 'true'
                },
                {
                  label: intl.formatMessage(messages.contentFieldOption2),
                  value: 'false'
                }
              ]
            }
            value={contentValue}
            onChange={selectContent}
            selectedByDefault
            name='content'
          />

          {fileValue === 'TEXT' &&
            <div className='u-els-margin-top'>
              <div className='o-els-flex-layout u-els-margin-top'>
                <div className='o-els-flex-layout__item o-els-flex-layout__item--grow'>
                  <TextInput
                    label=''
                    shouldHideLabel
                    value={textCitation}
                    readOnly
                  />
                </div>
                <div className='o-els-flex-layout__item'>
                  <Button
                    type={Button.Types.PRIMARY}
                    onClick={copyCitation}
                    className='c-els-button--small u-els-margin-left-1o2'
                    data-testid='citationCopyLinkBtn'
                    id='citationCopyLinkBtn'
                  >
                    <Icon
                      a11y={{ name: intl.formatMessage(messages.copyCitation) }}
                      sprite={Icon.Sprites.DUPLICATE_DOCUMENT}
                      id='citation-copy-icon'
                      size={Icon.Sizes.S}
                      textAlignment='middle'
                      className='u-els-margin-right-1o2'
                      isVisible
                    />
                    {intl.formatMessage(messages.copyCitation)}
                  </Button>
                </div>
              </div>

              {/* <TextInput
              value={textCitation}
              hasIcon={true}
              iconRight={false}
              iconProps={{ id: 'copyCitationIcon', sprite: 'DuplicateDocument', a11y: {}}}
              iconCallback={function(e){console.log("Icon clicked",e)}}
              label=""
            /> */}
            </div>}

          <Button
            type={Button.Types.SECONDARY}
            className='c-ckm-button c-ckm-button--secondary c-ckm-button u-ckm-margin--top u-ckm-margin--right'
            onClick={onClose}
            aria-label={context.intl.formatMessage(messages.modalButtonCancel)}
            title={context.intl.formatMessage(messages.modalButtonCancel)}
          >
            {intl.formatMessage(messages.modalButtonCancel)}
          </Button>
        </Form>

      </Modal>
    </>
  );
};

CitationExportModal.propTypes = {
  eid: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

const CitationExport = (props) => {
  const { eid, showLabel } = props;
  const context = useContext(MainContext);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        type={Button.Types.LINK}
        className={props.size ? '' : 'c-ckm-reader-content__citation'}
        onClick={openModal}
        aria-label={context.intl.formatMessage(messages.buttonAltText)}
        title={context.intl.formatMessage(messages.buttonAltText)}
      >
        <Icon
          a11y={{ name: context.intl.formatMessage(messages.buttonAltText) }}
          sprite={Icon.Sprites.CLOSE_QUOTES}
          id='citation-export-icon'
          textAlignment='bottom'
          size={props.size ? props.size : Icon.Sizes.XS}
          isVisible
        >
          {showLabel && context.intl.formatMessage(messages.cite)}
        </Icon>
      </Button>

      {isModalOpen &&
        <CitationExportModal eid={eid} onClose={closeModal} />}

    </>
  );
};

CitationExport.propTypes = {
  eid: PropTypes.string.isRequired
};

CitationExport.defaultProps = {
  eid: ''
};

export default CitationExport;
