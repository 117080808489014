import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import MultimediaSearchResultGridItem from './MultimediaSearchResultGridItem';
import classNames from 'classnames';
import { MainContext } from '../../context/main-context';
import {returnDefaultCollectionId} from '../../utils/collectionUtils';
import CKApi from '../../services/api-helper';

const SearchResultsGrid = React.forwardRef((props, ref) => {
  const context = useContext(MainContext);

  const gridClassName = classNames(
    'c-ckm-search-results-grid', {
      'c-ckm-search-results-grid--loading': props.isLoading,
      'c-ckm-search-results-grid--browse-container': props.isBrowse
    });

  const [collections, setCollections] = useState(props.collections);
  const [defaultCollectionId, setDefaultCollectionId] = useState(returnDefaultCollectionId(props.collections || [], context.user));

  const getCollections = () => {
    CKApi.get('/student/api/collections')
      .then(({ data }) => {
        if (data.results.totalCount) {
          setCollections(data.results.collections);
          setDefaultCollectionId(returnDefaultCollectionId(data.results.collections, this.context.user));
        }
      })
      .catch((e) => { });
  };

  const updateDefaultCollectionId = (newCollectionId) => {
    setDefaultCollectionId(newCollectionId);
  };

  return (
    <ol data-testid='searchResultsGridTestId' className={gridClassName}>
      {props.results.map((result, index) => {
        return (
          <MultimediaSearchResultGridItem
            key={index}
            {...props}
            {...result}
            type={result.contenttype || result.srctype}
            clickHandler={props.clickHandler}
            position={index}
            ref={ref}
            collections={collections}
            defaultCollectionId={defaultCollectionId}
            updateCollections={getCollections}
            updateDefaultCollectionId={updateDefaultCollectionId}
          />
        );
      })}
    </ol>
  );
});

SearchResultsGrid.displayName = 'SearchResultsGrid';
export default SearchResultsGrid;

SearchResultsGrid.propTypes = {
  results: PropTypes.array.isRequired
};
