import React, { useState, useContext, useEffect } from 'react';
import CKApi from '../../../../services/api-helper';
import InlineLoader from '../../../../components/common/InlineLoader';
import { MainContext } from '../../../../context/main-context';
import CaseSection from './CaseSection';
import { defineMessages } from 'react-intl';
import { CASE_STATES } from '../../utils/pjbConstants';

const messages = defineMessages({
  continueCaseSectionHeader: {
    id: 'Home.contnueCaseSection.header',
    defaultMessage: 'Continue case'
  },
  continueCaseSectionMessage: {
    id: 'Home.contnueCaseSection.message',
    defaultMessage: 'You have no cases in progress. Browse all cases to find a case.'
  }
});

const ContinueCaseSection = (props) => {
  const [continuableCases, setContinuableCases] = useState([]);
  const [loading, setLoading] = useState(continuableCases.length === 0);
  const [hasError, setHasError] = useState(false);
  const mostRecentCase = continuableCases.length > 0 ? (continuableCases.reduce((a, b) => (a.startedTimestamp > b.startedTimestamp ? a : b))).caseId : '';
  const context = useContext(MainContext);
  const user = context.user;

  useEffect(() => {
    const ecrId = user.account_ecr_id;
    CKApi.get(`/student/api/guideline/tenants/${ecrId}/all_content/status/${CASE_STATES.STARTED}`)
      .then(({data}) => {
        setContinuableCases(data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        setHasError(true);
      });
  }, []);

  return (
    <>
      {loading && !hasError && <InlineLoader />}
      {!loading &&
        <CaseSection
          title={props.intl.formatMessage(messages.continueCaseSectionHeader)}
          message={props.intl.formatMessage(messages.continueCaseSectionMessage)}
          intl={props.intl}
          imageClass='c-ckm-practice__continue-case-none-image'
          status={CASE_STATES.STARTED}
          mostRecentCase={mostRecentCase}
        />}
    </>
  );
};

export default ContinueCaseSection;
