import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {defineMessages} from 'react-intl';
import { MainContext } from '../context/main-context';
import StudyToolsImage from '../assets/img/artwork-studytools.png';

const messages = defineMessages({
  close: {
    id: 'StudyTools.text.close',
    defaultMessage: 'Don\'t show again'
  },
  header: {
    id: 'StudyTools.header.header',
    defaultMessage: 'Read, Highlight, Bookmark, Take & Export Notes and more'
  },
  info: {
    id: 'StudyTools.text.offline',
    defaultMessage: 'Offline tools are available through Bookshelf'
  }
});

class StudyToolsPopup extends Component {
  static contextType = MainContext;

  static propTypes = {
    clickCallback: PropTypes.func
  };

  render () {
    const {intl} = this.context;
    return (
      <div className='c-ckm-study-tools'>
        <div className='c-ckm-study-tools__nubbin c-ckm-study-tools__nubbin--right c-ckm-icon--lg c-ckm-icon--tip-left-menu u-ckm-transform--x-flip' />
        <div className='c-ckm-study-tools__close'>
          <button className='c-ckm-link' onClick={this.props.clickCallback}>
            <span className='c-ckm-study-tools__close-text'>{intl.formatMessage(messages.close)}</span>
            <span className='c-ckm-icon--lg c-ckm-icon--cross-lg' />
          </button>
        </div>
        <h1 className='c-ckm-study-tools__heading'>
          {intl.formatMessage(messages.header)}
        </h1>
        <p className='c-ckm-study-tools__info'>
          {intl.formatMessage(messages.info)}
        </p>
        <img src={StudyToolsImage} alt='' />
      </div>
    );
  }
}

StudyToolsPopup.displayName = 'StudyToolsPopup';
export default StudyToolsPopup;
